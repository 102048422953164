import { request } from 'helpers';
import { stripeConstants } from 'modules/stripe/stripeConstants';
import StateManager from 'state-wrangler';

const { actions, selectors } = stripeConstants;

const initial = {
  [selectors.STATE_KEY_PAYMENT_DATA]: null,
};

const stripeReducer = (initialState = initial, action = {}) => {
  const { payload } = action;
  const state = new StateManager(initialState);

  switch(action.type) {
    case request(actions.GET_PAYMENT_DATA).start:
      return state.update(selectors.STATE_KEY_PAYMENT_DATA_LOADING, true);

    case request(actions.GET_PAYMENT_DATA).success:
      return state.update(selectors.STATE_KEY_PAYMENT_DATA, payload);

    case request(actions.GET_PAYMENT_DATA).complete:
      return state.update(selectors.STATE_KEY_PAYMENT_DATA_LOADING, false);

    case request(actions.ADD_PAYMENT_METHOD).success:
      return state.update(selectors.STATE_KEY_PAYMENT_DATA, payload);

    case request(actions.UPDATE_PAYMENT_METHOD).success:
      return state.update(selectors.STATE_KEY_PAYMENT_DATA, payload);

    case request(actions.REMOVE_PAYMENT_METHOD).success:
      return state.update(selectors.STATE_KEY_PAYMENT_DATA, payload);


    default:
      return initialState;
  }
};

export { stripeReducer };