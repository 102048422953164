import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleWebhooksIdempotently } from 'helpers';
import { appActions, expensesActions, institutionsActions } from 'modules';

const {
  NODE_ENV,
  VITE_BASE_URL: baseUrl,
  VITE_WEBSOCKET_PORT: websocketPort,
} = process.env;

const inProduction = NODE_ENV === 'production';

const PlaidWebSockets = () => {
  const lastProcessedWebhookRef = useRef({ priority: Number.MAX_VALUE, webhook_type: '' });
  const { tokenInfo } = useSelector(state => state.auth);
  const { selectedAccountId, paginationData } = useSelector(state => state.institutions);

  const token = tokenInfo?.refreshToken || tokenInfo?.accessToken;
  const socketRef = useRef(null);
  const selectedAccountIdRef = useRef(selectedAccountId);
  const pageLimit = paginationData?.pageLimit;
  const pageLimitRef = useRef(pageLimit);
  const dispatch = useDispatch();

  const addNotification = useCallback(payload => dispatch(appActions.addNotification(payload)), [ dispatch ]);

  const getTransactions = useCallback((payload, callbacks) => {
    dispatch(institutionsActions.getTransactions(payload, callbacks));
  }, [ dispatch ]);

  const setTransactionsDataLoading = useCallback(payload => {
    dispatch(institutionsActions.setTransactionsDataLoading(payload));
  }, [ dispatch ]);

  const updateInstitutionErrorState = useCallback((payload, callbacks) => {
    dispatch(institutionsActions.updateInstitutionErrorState(payload, callbacks));
  }, [ dispatch ]);

  const getBalances = useCallback((payload, callbacks) => {
    dispatch(expensesActions.getBalances(payload, callbacks));
  }, [ dispatch ]);

  useEffect(() => {
    selectedAccountIdRef.current = selectedAccountId;
    pageLimitRef.current = pageLimit;
  }, [ selectedAccountId, pageLimit ]);

  const socketCleanup = args => {
    const { alreadyClosed } = args || {};

    if (socketRef.current && !alreadyClosed) {
      socketRef.current.close();
    }

    socketRef.current = null;
    pageLimitRef.current = null;
    selectedAccountIdRef.current = null;
  };

  const openWebSocket = useCallback(() => {
    if (!token) {
      console.error('No valid token found for Plaid WebSocket connection.');
      return;
    }

    if (socketRef.current) {
      console.log('Closing existing Plaid WebSocket connection...');
      socketCleanup();
    }

    const protocol = inProduction ? 'wss' : 'ws';
    const socketUrl = inProduction
      ? `${protocol}://${baseUrl}/ws?token=${token}`
      : `${protocol}://localhost:${websocketPort}?token=${token}`;

    const socket = new WebSocket(socketUrl);
    socketRef.current = socket;

    socket.onopen = () => {
      console.log('Plaid WebSocket connection open...');
    };

    socket.onmessage = e => {
      const data = JSON.parse(e.data);
      const hasData = data !== undefined;

      const transactionsPayload = {
        token,
        pageLimit: pageLimitRef.current,
        linkedAccountId: selectedAccountIdRef.current,
      };

      const statusPayload = {
        token,
        itemId: data?.item_id,
      };

      if (hasData) {
        handleWebhooksIdempotently({
          ...data,
          lastProcessedWebhook: lastProcessedWebhookRef.current,

          setLastProcessedWebhook: newWebhook => {
            lastProcessedWebhookRef.current = newWebhook;
          },

          addNotification,

          getPlaidSyncTransactions: () => {
            if (selectedAccountIdRef.current) {
              const callbacks = {
                onSuccess: () => getBalances({ token, linkedAccountId: selectedAccountIdRef.current }),
              };

              getTransactions(transactionsPayload, callbacks);
            }
          },

          startTransactionsLoadingState: newState => setTransactionsDataLoading(newState),

          changeInstitutionErrorStatus: needsAttention => (
            updateInstitutionErrorState({ ...statusPayload, ...needsAttention })
          ),
        });

        return;
      }

      console.error('Invalid Plaid WebSocket message:', data);
    };

    socket.onclose = () => {
      console.log('Plaid WebSocket connection closed.');
      socketCleanup({ alreadyClosed: true });

      // Attempt to reconnect
      if (inProduction) setTimeout(openWebSocket, 5000);
    };

    socket.onerror = error => {
      console.error('Plaid WebSocket error:', error);
    };

    return () => socketCleanup();
  }, [
    token,
    setTransactionsDataLoading,
    getTransactions,
    updateInstitutionErrorState,
    getBalances,
    addNotification,
  ]);

  useEffect(() => {
    openWebSocket();

    return () => socketCleanup();
  }, [ openWebSocket ]);

  return null;
};

export { PlaidWebSockets };
