import { useSelector } from 'react-redux';
import { Font, XymPrice, XymSpacer, GripIcon } from 'components';
import { appConstants } from 'modules';
import { expenseTooltip } from 'helpers';
import { Progress, PrivacyMask } from 'xerum';
import { ExpenseHolder, NameArea, ProgressArea, CompactLayout, FrequencyName, DragIcon } from './styles';
import { ExpenseIcon } from './ExpenseIcon';
import { ExpenseAutoSpend } from './ExpenseAutoSpend';
import { withTheme } from 'styled-components';
import { flags } from 'utility';
import moment from 'moment';

const light = appConstants.themes.light;

const CompactExpense = withTheme(props => {
  const {
    theme,
    hideAutoExpenses,
    hideTooltips,
    expense,
    setAutoSpendConfirmContent,
    callback,
    dragOverId,
    setDragOverId,
    disableDrag,
  } = props;

  const { frequencies } = useSelector(state => state.app);
  const { generalPreferences: { privacyMode, selectedTheme } } = useSelector(state => state.preferences);
  const { autoFundSources, expensesData } = useSelector(state => state.expenses);
  const { features: { autoFunding } } = flags;

  const {
    _id,
    name,
    icon,
    current,
    needed,
    dueDate,
    color,
    autoFundSourceId,
    isGoal,
    funded,
    useDailyAutoFundCalculations,
    frequencyId,
    onTrackData,
  } = expense;

  const isDraggingOver = dragOverId === _id;
  const autoFundSource = autoFundSources?.find(source => source._id === autoFundSourceId);
  const frequencyName = frequencies?.find(frequency => frequency._id === frequencyId)?.name || '';
  const lightTheme = selectedTheme === light;
  const darkGrey = theme.modes[selectedTheme]?.darkGrey;
  const onPrimary = theme.modes[selectedTheme]?.onPrimary;
  const offWhite = theme.modes[selectedTheme]?.offWhite;
  const accent = theme.modes[selectedTheme]?.accent;
  const lightGrey = theme.modes[selectedTheme]?.lightGrey;
  const frenchGrey = theme.colors.neutral.frenchGrey;
  const titleColor = lightTheme ? darkGrey : offWhite;
  const offTrackExists = expensesData?.some(expense => !expense.onTrackData?.onTrack);

  const rawDate = moment(dueDate, 'YYYY-MM-DD');
  const transactionYear = rawDate.year();
  const today = moment().format('YYYY-MM-DD');
  const yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD');
  const thisYear = moment().year();
  const isToday = today === moment(dueDate).format('YYYY-MM-DD');
  const isYesterday = yesterday === moment(dueDate).format('YYYY-MM-DD');
  const tomorrow = moment().add(1, 'days').format('YYYY-MM-DD') === moment(dueDate).format('YYYY-MM-DD');
  const yearFormat = transactionYear !== thisYear ? ', YYYY' : '';

  const autoCalculation = () => (
    <Font size={0.875}>
      <Font weight='semibold' size={0.875}>Automatic</Font>
      {!funded && (
        <>
          :&nbsp;
          <XymPrice
            size={0.875}
            value={onTrackData?.catchUpRatePerDay || onTrackData?.normalRatePerDay}
          />
          /day
        </>
      )}
    </Font>
  );

  const handleDragStart = e => {
    e.dataTransfer.setData('text/plain', _id);
    if (!isDraggingOver) setDragOverId(_id);
  };

  const handleDragOver = e => {
    e.preventDefault();
    if (!isDraggingOver) setDragOverId(_id);
  };

  const handleDragLeave = () => setDragOverId(null);
  const handleDragEnd = () => setDragOverId(null);

  return (
    <ExpenseHolder
      $theme={theme}
      $selectedTheme={selectedTheme}
      $isDraggingOver={isDraggingOver}
      draggable={!disableDrag}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDragEnd={handleDragEnd}
      data-expense-id={_id}
    >
      <DragIcon $isDraggingOver={isDraggingOver} $visible={!disableDrag}>
        <GripIcon width={1.25} color={lightTheme ? frenchGrey : frenchGrey + 35} />
      </DragIcon>

      <CompactLayout $hideAutoExpenses={hideAutoExpenses} $isGoal={isGoal} onClick={() => callback?.()}>
        <ExpenseIcon
          name={name}
          icon={icon}
          size={3.375}
          fontSize={1}
        />

        <NameArea>
          <Font mobileSize={0.875} color={titleColor} weight='semibold'>
            {privacyMode ? <PrivacyMask length={8} /> : name}

            <XymSpacer size={0.25} across={true} />
            {!hideTooltips && !privacyMode && (
              <>
                {expenseTooltip({
                  expense,
                  condition: onTrackData?.onTrack === false,
                  isWarning: true,
                })}

                {expenseTooltip({ expense, condition: funded === true })}

                {expenseTooltip({
                  expense,
                  condition: current > needed && offTrackExists,
                  isInfo: true,
                })}
              </>
            )}
          </Font>

          <Font size={0.875} weight='semibold'>
            <XymPrice mobileSize={0.875} value={current} color={onPrimary} />

            <XymSpacer size={0.5} across={true}/>
            <Font>|</Font>
            <XymSpacer size={0.5} across={true}/>

            <XymPrice mobileSize={0.875} value={needed} color={accent} />
          </Font>

          {dueDate && (
            <Font size={0.875}>
              By&nbsp;
              {isToday && 'Today'}
              {isYesterday && 'Yesterday'}
              {tomorrow && 'Tomorrow'}
              {!isToday && !isYesterday && !tomorrow && moment(dueDate).format(`MMMM Do${yearFormat}`)}
            </Font>
          )}

          <FrequencyName>
            <Font size={0.875} weight='medium' color={lightTheme ? frenchGrey : frenchGrey + 50}>
              {frequencyName}
            </Font>
          </FrequencyName>
        </NameArea>

        <ProgressArea>
          <XymSpacer size={autoFunding && autoFundSourceId ? 0.25 : 0.5} />

          {autoFunding && autoFundSource && (
            <>
              <Font size={0.875} weight='semibold'>
                <XymSpacer size={0.25} across={true} />
                <Font size={0.875}>
                  <Font weight='semibold' size={0.875}>
                    Funding from
                  </Font>:&nbsp;

                  {privacyMode ? <PrivacyMask /> : autoFundSource?.name}
                </Font>
              </Font>
              <XymSpacer size={0.5} />
            </>
          )}

          {useDailyAutoFundCalculations && (
            <>
              <Font size={0.875} weight='semibold'>
                <XymSpacer size={0.25} across={true} />
                {privacyMode
                  ? <Font size={0.875}><PrivacyMask /></Font>
                  : autoCalculation()
                }
              </Font>
              <XymSpacer size={0.5} />
            </>
          )}

          <Progress
            current={current}
            total={needed}
            noPct={true}
            color={color || accent}
            bgColor={lightTheme ? lightGrey : darkGrey}
          />
        </ProgressArea>

        <ExpenseAutoSpend
          visible={!hideAutoExpenses && !isGoal}
          setAutoSpendConfirmContent={setAutoSpendConfirmContent}
          {...expense}
        />
      </CompactLayout>
    </ExpenseHolder>
  );
});

export { CompactExpense };