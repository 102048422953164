import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cardTypes, months, years, states, stripeActions } from 'modules';
import { Font, XymField, XymSelect, XymConfirmButtons, XymSpacer } from 'components';
import { Formik } from 'formik';
import { Toggle } from 'xerum';
import {
  StyledForm,
  PersonalFields,
  CardFields,
  CardDetailFields,
  AddressFields,
  PostalFields,
  DefaultToggle,
} from './paymentFormStyles';
import { withTheme } from 'styled-components';
import * as yup from 'yup';
import valid from 'card-validator';
import moment from 'moment';
import _ from 'lodash';

const PaymentForm = withTheme(props => {
  const { theme, setConfirmContent } = props;
  const { tokenInfo } = useSelector(state => state.auth);
  const { paymentData } = useSelector(state => state.stripe);
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);

  const dispatch = useDispatch();

  const addPaymentMethod = useCallback((payload, callbacks) => {
    dispatch(stripeActions.addPaymentMethod(payload, callbacks));
  }, [ dispatch ]);

  const visaIndex = 0;
  const januaryIndex = 0;
  const currentYearIndex = 0;
  const alabamaIndex = 0;

  const defaults = {
    firstName: '',
    lastName: '',
    cardNumber: '',
    cvv: '',
    cardType: cardTypes[visaIndex],
    expirationMonth: months[januaryIndex]?.value,
    expirationYear: years()[currentYearIndex]?.label,
    billingAddress: '',
    billingCity: '',
    billingState: states[alabamaIndex].abbreviation,
    billingZip: '',
    defaultPayment: paymentData?.methods?.length === 0,
  };

  const validationSchema = yup.object().shape({
    firstName: yup.string().required('First name is required.'),
    lastName: yup.string().required('Last name is required.'),
    cardNumber: yup.string()
      .required('Card number is required.')
      .test('test-card-number', 'Card number is invalid.', value => valid.number(value).isValid)
      .trim(),
    cvv: yup.string().required('Required.'),
    cardType: yup.string().required('Type is required.'),
    expirationMonth: yup.string().required('Month is required.'),
    expirationYear: yup.string().required('Year is required.'),
    billingAddress: yup.string().required('Address is required.'),
    billingCity: yup.string().required('City is required.'),
    billingState: yup.string().required('Required.'),
    billingZip: yup.string().required('Zip code is required.'),
    defaultPayment: yup.boolean(),
  });

  const handleSubmit = args => {
    const { values, setSubmitting } = args;
    const {
      firstName,
      lastName,
      cardNumber,
      cvv,
      cardType,
      expirationMonth,
      expirationYear,
      billingAddress,
      billingAddressContinued,
      billingCity,
      billingState,
      billingZip,
      defaultPayment,
    } = values;

    const callbacks = {
      onSuccess: () => setConfirmContent(null),
      onComplete: () => setSubmitting(false),
    };

    // NOTE: Regex to remove all dashes and spaces from card number
    const cardNumberRegex = /[- ]/g;

    const payload = {
      token: tokenInfo?.accessToken,
      firstName,
      lastName,
      cardNumber: cardNumber.replace(cardNumberRegex, ''),
      cvv,
      cardType,
      expiration: moment(`${expirationYear}-${expirationMonth}`, 'YYYY-MM').toDate(),
      billingAddress,
      billingAddressContinued,
      billingCity,
      billingState,
      billingZip,
      defaultPayment,
    };

    addPaymentMethod(payload, callbacks);
  };

  return (
    <Formik
      initialValues={defaults}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={(values, handlers) => {
        const { setSubmitting } = handlers;
        handleSubmit({ values, setSubmitting });
      }}
    >
      {form => (
        <StyledForm>
          <PersonalFields>
            <div>
              <XymField
                form={form}
                name='firstName'
                label={<Font size={0.875} weight='semibold'>First name (Cardholder)</Font>}
                spacing={0.5}
              />
            </div>

            <div>
              <XymField
                form={form}
                name='lastName'
                label={<Font size={0.875} weight='semibold'>Last name (Cardholder)</Font>}
                spacing={0.5}
              />
            </div>
          </PersonalFields>

          <CardFields>
            <div>
              <XymField
                form={form}
                name='cardNumber'
                label={<Font size={0.875} weight='semibold'>Card number</Font>}
                placeholder='i.e. 0000 0000 0000 0000'
                spacing={0.5}
              />
            </div>

            <div>
              <XymField
                form={form}
                name='cvv'
                label={<Font size={0.875} weight='semibold'>CVV</Font>}
                spacing={0.5}
              />
            </div>
          </CardFields>

          <CardDetailFields>
            <div>
              <XymSelect
                form={form}
                data={cardTypes}
                name='cardType'
                label={<Font size={0.875} weight='semibold'>Card type</Font>}
                noResultsText='Network not found'
                callback={_.noop}
                spacing={0.5}
              />
            </div>

            <div>
              <XymSelect
                form={form}
                data={months.map(item => ({ value: item.label, label: item.value }))}
                name='expirationMonth'
                label={<Font size={0.875} weight='semibold'>Expiration Month</Font>}
                noResultsText='Invalid'
                callback={_.noop}
                spacing={0.5}
              />
            </div>

            <div>
              <XymSelect
                form={form}
                data={years()}
                name='expirationYear'
                label={<Font size={0.875} weight='semibold'>Expiration Year</Font>}
                noResultsText='Invalid year'
                callback={_.noop}
                spacing={0.5}
              />
            </div>
          </CardDetailFields>

          <AddressFields>
            <div>
              <XymField
                form={form}
                name='billingAddress'
                label={<Font size={0.875} weight='semibold'>Billing Address</Font>}
                spacing={0.5}
              />
            </div>

            <div>
              <XymField
                form={form}
                name='billingAddressContinued'
                label={<Font size={0.875} weight='semibold'>Apt. Unit etc.</Font>}
                optional={true}
                spacing={0.5}
              />
            </div>
          </AddressFields>

          <PostalFields>
            <div>
              <XymField
                theme={theme}
                form={form}
                name='billingCity'
                label={<Font size={0.875} weight='semibold'>City</Font>}
                spacing={0.5}
              />
            </div>

            <div>
              <XymSelect
                form={form}
                data={states.map((state, index) => ({ value: index, label: state.abbreviation }))}
                name='billingState'
                label={<Font size={0.875} weight='semibold'>State</Font>}
                noResultsText='State not found'
                callback={_.noop}
                spacing={0.5}
              />
            </div>

            <div>
              <XymField
                form={form}
                name='billingZip'
                label={<Font size={0.875} weight='semibold'>Zip code</Font>}
                spacing={0.5}
              />
            </div>

            <DefaultToggle>
              Default
              <XymSpacer across={true} />
              <Toggle theme={theme} selectedTheme={selectedTheme} form={form} name='defaultPayment' />
            </DefaultToggle>
          </PostalFields>

          <XymConfirmButtons
            form={form}
            confirmText='Add payment'
            setConfirmContent={setConfirmContent}
          />
        </StyledForm>
      )}
    </Formik>
  );
});

export { PaymentForm };