import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { appActions, institutionsActions, expensesActions } from 'modules';
import { InstitutionIcon, Font, XymSpacer, XymPrice } from 'components';
import { accountChangeNotification } from 'helpers';
import {
  StyledAccount,
  Icon,
  AccountName,
  AccountDetails,
  AccountType,
} from './styles';
import { PrivacyMask } from 'xerum';
import { withTheme } from 'styled-components';
import _ from 'lodash';

const Account = withTheme(props => {
  const { theme, account, onlyAccount, displayOnly, fullWidth, pulseSelect, isActiveSelection } = props;
  const { account_id, name, type, mask, startingBalance } = account || {};
  const { selectedAccountId } = useSelector(state => state.institutions);

  const {
    generalPreferences: { privacyMode, selectedTheme },
  } = useSelector(state => state.preferences);

  const primary = theme.modes[selectedTheme]?.primary;
  const onPrimary = theme.modes[selectedTheme]?.onPrimary;
  const active = isActiveSelection || account_id === selectedAccountId;
  const dispatch = useDispatch();

  const clearTransactionsData = useCallback(payload => {
    dispatch(institutionsActions.clearTransactionsData(payload));
  }, [ dispatch ]);

  const clearAnalysisData = useCallback(payload => dispatch(expensesActions.clearAnalysisData(payload)), [ dispatch ]);

  const setSelectedAccountId = useCallback(payload => {
    dispatch(institutionsActions.setSelectedAccountId(payload));
  }, [ dispatch ]);

  const addNotification = useCallback(payload => {
    dispatch(appActions.addNotification(payload));
  }, [ dispatch ]);

  return (
    <StyledAccount
      key={account_id}
      $theme={theme}
      $selectedTheme={selectedTheme}
      $pulseSelect={pulseSelect}
      $active={active || onlyAccount}
      $fullWidth={fullWidth}
      onClick={() => {
        if (!displayOnly && selectedAccountId !== account_id) {
          clearAnalysisData(null);
          clearTransactionsData(null);
          setSelectedAccountId(account_id);
          accountChangeNotification({ addNotification, name, mask, privacyMode });
        }
      }}
    >
      <Icon>
        <XymSpacer size={0.5} across={true} />
        <InstitutionIcon width={3.75} color={active && primary} />
      </Icon>

      <AccountName>
        <Font weight='semibold' mobileSize={0.875} tabletSize={0.875}>
          {privacyMode ? <PrivacyMask length={8} /> : name}
        </Font>
      </AccountName>

      <AccountType>
        <Font
          weight='semibold'
          color={active ? primary + 90 : onPrimary + 90}
          size={0.75}
          mobileSize={0.75}
          tabletSize={0.75}
        >
          {privacyMode ? <PrivacyMask length={8} /> : _.startCase(type)}
          {startingBalance && (
            <>
              &nbsp; • &nbsp;
              Starting: &nbsp;
              {privacyMode
                ? <PrivacyMask length={8} />
                : <XymPrice value={startingBalance} size={0.75} color={active ? primary + 90 : onPrimary + 90} />
              }
            </>
          )}
        </Font>

        <XymSpacer size={0.125} />
      </AccountType>

      <AccountDetails>
        <Font size={0.875} mobileSize={0.75} tabletSize={0.75}>
          •••• ... {mask}
        </Font>
      </AccountDetails>
    </StyledAccount>
  );
});

export { Account };