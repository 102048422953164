import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { institutionsActions, preferencesActions, timeFrames } from 'modules';
import { Font, XymTabs, XymConfirm, PlaidLink } from 'components';
import { overviewTabs } from './localControllers/overviewTabs';
import { StyledOverview, Layout, MobileLayout } from './styles';
import { Activity } from 'pages/Overview/Activity/Activity';
import { Analysis } from './Analysis/Analysis';
import { Upcoming } from './Upcoming/Upcoming';
import { Trends } from 'pages/Overview/Trends/Trends';
import { TransactionSlideOver } from 'pages/Overview/TransactionSlideOver/TransactionSlideOver';
import { Loading } from 'xerum';
import { withTheme } from 'styled-components';
import _ from 'lodash';

const Overview = withTheme(props => {
  const { theme } = props;
  const { mobileMode, tabletMode, tabletLandscapeMode, bannerContent } = useSelector(state => state.app);
  const { tokenInfo } = useSelector(state => state.auth);
  const { institutionsData, institutionDataLoading } = useSelector(state => state.institutions);

  const {
    upcomingPreferences: { rangeId },
    generalPreferences: { selectedTheme },
  } = useSelector(state => state.preferences);

  const [ selectedWindow, setSelectedWindow ] = useState(timeFrames[0].value);
  const [ incomeInUseContent, setIncomeInUseContent ] = useState(null);

  const hasInstitutions = !_.isEmpty(institutionsData);
  const token = tokenInfo?.refreshToken || tokenInfo?.accessToken;
  const desktopMode = !mobileMode && !tabletMode && !tabletLandscapeMode;
  const dispatch = useDispatch();


  const setSlideOverTransactionId = useCallback(payload => {
    dispatch(institutionsActions.setSlideOverTransactionId(payload));
  }, [ dispatch ]);

  const updateUserPreferences = useCallback((payload, callbacks) => {
    dispatch(preferencesActions.updateUserPreferences(payload, callbacks));
  }, [ dispatch ]);

  const upcomingArgs = { token, selectedWindow, setSelectedWindow, updateUserPreferences, desktopMode, bannerContent };

  useEffect(() => {
    setSelectedWindow(rangeId);
  }, [ rangeId, setSelectedWindow ]);

  useEffect(() => {
    return () => setSlideOverTransactionId(null);
  }, [ setSlideOverTransactionId ]);

  return (
    <StyledOverview $hasInstitutions={hasInstitutions}>
      <Loading
        theme={theme}
        selectedTheme={selectedTheme}
        isLoading={!hasInstitutions && institutionDataLoading}
        hasData={hasInstitutions}
        noFailIcon={true}
        failText={<PlaidLink description={true} />}
        text={
          <Font size={1} weight='semibold'>
            Loading overview...
          </Font>
        }
      >
        <Layout $hasInstitutions={hasInstitutions} $bannerContent={bannerContent}>
          <Activity />
          <Analysis />
          <Upcoming {...upcomingArgs} />
          <Trends />
        </Layout>

        <MobileLayout>
          {hasInstitutions && <XymTabs spacing={1} content={overviewTabs(upcomingArgs)} contentFirst={true} />}
        </MobileLayout>
      </Loading>

      <TransactionSlideOver setConfirmContent={setIncomeInUseContent} />

      <XymConfirm
        confirmContent={incomeInUseContent}
        blank={true}
        onClose={() => setIncomeInUseContent(null)}
      />
    </StyledOverview>
  );
});

export { Overview };