import { appConstants } from 'modules';

const { error } = appConstants.messageTypes;

const handleErrorWebhooks = async args => {
  const { error: { error_code = '' }, changeInstitutionErrorStatus, addNotification } = args || {};

  switch (error_code) {
    case 'ITEM_LOGIN_REQUIRED':
      addNotification({
        message: 'An institution requires reauthentication. Repair this link in "My institutions".',
        icon: 'fa-solid fa-handshake-slash',
        type: error,
      });

      changeInstitutionErrorStatus({ needsAttention: true });
      break;

    case 'PRODUCTS_NOT_SUPPORTED':
      addNotification({
        message: 'Institution does not support this action.',
        type: error,
      });

      break;

    case 'ITEM_NOT_FOUND':
      addNotification({
        message: 'Institution not found.',
        type: error,
      });

      break;

    case 'INSTITUTION_NOT_RESPONDING':
      addNotification({
        message: 'Institution is not responding to requests, please try again soon.',
        type: error,
      });

      break;

    default:
      break;
  }
};

const handleTransactionWebhooks = args => {
  const { webhook_code, addNotification, getPlaidSyncTransactions, startTransactionsLoadingState } = args || {};

  switch (webhook_code) {
    case 'INITIAL_UPDATE':
      addNotification({
        message: 'Syncing... transactions may be unavailable until complete.',
        icon: 'fa-solid fa-refresh',
      });

      startTransactionsLoadingState(true);
      break;

    case 'SYNC_UPDATES_AVAILABLE':
      addNotification({
        message: 'Transactions available.',
      });

      getPlaidSyncTransactions();
      break;

    default:
      break;
  }
};

const handleItemWebhooks = args => {
  const { webhook_code, addNotification, changeInstitutionErrorStatus } = args || {};

  switch (webhook_code) {
    case 'ERROR':
      handleErrorWebhooks(args);
      break;

    case 'LOGIN_REPAIRED':
      addNotification({
        message: 'Institution link repaired.',
        icon: 'fa-solid fa-handshake',
        type: appConstants.messageTypes.success,
      });

      changeInstitutionErrorStatus({ needsAttention: false });
      break;

    case 'NEW_ACCOUNTS_AVAILABLE':
      break;

    default:
      break;
  }
};

const getTransactionWebhookPriority = args => {
  const { webhook_code } = args || {};

  switch (webhook_code) {
    case 'INITIAL_UPDATE':
      return 3;

    case 'HISTORICAL_UPDATE':
      return 2;

    case 'SYNC_UPDATES_AVAILABLE':
      return 1;

    default:
      return Number.MAX_VALUE;
  }
};

const handleWebhooksIdempotently = (args = {}) => {
  const { webhook_type, lastProcessedWebhook, setLastProcessedWebhook } = args;
  const processedWebhookPayload = { ...args };

  if (webhook_type === 'TRANSACTIONS') {
    const currentPriority = getTransactionWebhookPriority(args);
    const isHigherPriority = currentPriority < lastProcessedWebhook?.priority;

    processedWebhookPayload.priority = currentPriority;
    isHigherPriority && handleTransactionWebhooks(args);
  }

  if (webhook_type === 'ERROR') {
    handleErrorWebhooks(args);
  }

  if (webhook_type === 'ITEM') {
    handleItemWebhooks(args);
  }

  setLastProcessedWebhook(processedWebhookPayload);
};

export {
  handleWebhooksIdempotently,
};
