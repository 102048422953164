import { useSelector } from 'react-redux';
import { Font, SettingsNav, ChangePasswordForm, XymTabs } from 'components';
import { StyledSecurity, Layout, MobileLayout, SecurityContent } from './styles';
import { securityTabs } from './localControllers/securityTabs';
import { MFA } from './Sections/MFA';
import { TrustedDevices } from './Sections/TrustedDevices';
import { SessionHistory } from './Sections/SessionHistory';
import { flags } from 'utility';

const Security = () => {
  const { mobileMode, tabletMode, tabletLandscapeMode, hybridMode, bannerContent } = useSelector(state => state.app);
  const { tokenInfo } = useSelector(state => state.auth);
  const { features: { mfa } } = flags;
  const desktopMode = !mobileMode && !tabletMode && !tabletLandscapeMode && !hybridMode;
  const securityArgs = { desktopMode, mfa, bannerContent };

  const buildSections = () => {
    if (tokenInfo?.accessToken) {
      return (
        <>
          <ChangePasswordForm />
          {mfa && <MFA />}
          <TrustedDevices />
          <SessionHistory />
        </>
      );
    }

    return <Font weight='semibold'>No token found.</Font>;
  };

  return (
    <StyledSecurity>
      <Layout>
        <SettingsNav />

        <SecurityContent $mfa={mfa}>
          {buildSections()}
        </SecurityContent>
      </Layout>

      <MobileLayout>
        <XymTabs spacing={1} content={securityTabs(securityArgs)} contentFirst={true} />
      </MobileLayout>
    </StyledSecurity>
  );
};

export { Security };