import { useCallback } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { authActions } from 'modules';
import { emailIncrementorCheck } from 'helpers';
import { P, Font, XymField, XymSpacer } from 'components';
import { Button } from 'xerum';
import { Form, Formik } from 'formik';
import styled, { withTheme } from 'styled-components';
import * as yup from 'yup';

const StyledWaitlist = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const defaultValues = {
  email: '',
};

const validationSchema = yup.object().shape({
  email: yup.string()
    .email('Invalid email.')
    .required('Email is required.')
    .test('email-incrementor', 'Email incrementors are not allowed.', value => emailIncrementorCheck(value)),
});

const WaitlistForm = withTheme(props => {
  const { theme } = props;
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const addUserToWaitlist = useCallback((payload, callbacks) => {
    dispatch(authActions.addUserToWaitlist(payload, callbacks));
  }, [ dispatch ]);

  const handleSubmit = (values, actions) => {
    const { setSubmitting } = actions;
    const { email } = values;
    const payload = { email };
    const callbacks = {
      onSuccess: () => navigate('/waitlist-success'),
      onComplete: () => setSubmitting(false),
      onFail: () => setSubmitting(false),
    };

    addUserToWaitlist(payload, callbacks);
  };

  const buildForm = () => {
    return (
      <Formik
        initialValues={defaultValues}
        validationSchema={validationSchema}
        enableReinitialization={true}
        onSubmit={handleSubmit}
      >
        {form => (
          <Form>
            <XymField
              form={form}
              name='email'
              type='email'
              autoComplete='email'
              icon='fa-solid fa-envelope'
              label={<Font size={0.875} weight='semibold'>Email</Font>}
              spacing={2}
            />

            <Button
              theme={theme}
              selectedTheme={selectedTheme}
              type='submit'
              disabled={form.isSubmitting}
              callback={form.handleSubmit}
              text={
                <Font weight='medium' mobileSize={0.875}>
                  {form.isSubmitting ? 'Sending request...' : 'Join the waitlist'}
                </Font>
              }
            />

            <XymSpacer />

            <P size={0.875}>
              Help us gauge interest, get feedback, and keep you updated.
              We&apos;ll never spam you, share, or sell your information.
            </P>

            <XymSpacer size={0.5} />

            <Font size={0.875} weight='semibold'>
              Your data is always encrypted.
            </Font>

            <XymSpacer size={2} />

            <P size={0.875}>
              Change your mind? <Link to='/waitlist-remove'>Remove yourself</Link>.
            </P>
          </Form>
        )}
      </Formik>
    );
  };

  return (
    <StyledWaitlist>
      {buildForm()}
    </StyledWaitlist>
  );
});

export { WaitlistForm };