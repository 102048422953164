import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { expensesActions } from 'modules';
import { Center, NoData, Font, XymField, XymSelect, XymConfirmButtons, XymSpacer } from 'components';
import { Formik, Form } from 'formik';
import { Button } from 'xerum';
import { withTheme } from 'styled-components';
import * as yup from 'yup';
import _ from 'lodash';

const AutoFundSourcesForm = withTheme(props => {
  const { theme, edit, setConfirmContent } = props;
  const { generalPreferences: { privacyMode, selectedTheme } } = useSelector(state => state.preferences);
  const { autoFundSources } = useSelector(state => state.expenses);
  const { tokenInfo } = useSelector(state => state.auth);
  const { selectedAccountId, incomeSources } = useSelector(state => state.institutions);
  const token = tokenInfo?.refreshToken || tokenInfo?.accessToken;
  const error = theme.modes[selectedTheme]?.error;
  const errorHover = theme.modes[selectedTheme]?.errorHover;
  const onPrimary = theme.modes[selectedTheme]?.onPrimary;
  const dispatch = useDispatch();

  const addAutoFundSource = useCallback((payload, callbacks) => {
    dispatch(expensesActions.addAutoFundSource(payload, callbacks));
  }, [ dispatch ]);

  const updateAutoFundSource = useCallback((payload, callbacks) => {
    dispatch(expensesActions.updateAutoFundSource(payload, callbacks));
  }, [ dispatch ]);

  const removeAutoFundSource = useCallback((payload, callbacks) => {
    dispatch(expensesActions.removeAutoFundSource(payload, callbacks));
  }, [ dispatch ]);

  const autoFundOptions = useMemo(() => autoFundSources?.map(item => {
    const { _id, name } = item;
    return { value: _id, label: name };
  }) || [], [ autoFundSources ]);

  const sourceOptions = useMemo(() => {
    const defaultOption = { value: 0, label: 'Select income source' };

    const additionalSources = incomeSources.map(source => {
      const sourceId = source.merchant || source.categoryId;
      const inUse = autoFundSources?.find(source => source.merchantId === sourceId);
      const isSelf = _.isEqual(sourceId, inUse?.merchantId);
      const usedByName = inUse?.name;
      const disabled = (edit && !_.isEmpty(inUse) && !isSelf) || (!edit && !_.isEmpty(inUse));

      return {
        value: sourceId,
        label: source.merchant,
        disabled,
        note: disabled && `(${usedByName})`,
      };
    }) || [];

    return [ defaultOption, ...additionalSources ];
  }, [ incomeSources, autoFundSources, edit ]);

  const defaults = {
    sourceName: edit && !_.isEmpty(autoFundSources) ? autoFundOptions?.[0]?.label || '' : '',
    merchantId: edit
      ? sourceOptions?.find(income => income.value === autoFundSources?.[0]?.merchantId)?.value
      : sourceOptions?.[0]?.value,
  };

  const validationSchema = yup.object().shape({
    sourceName: yup.string().required('Source name is required.'),
    merchantId: yup.string()
      .required('Income source is required.')
      .notOneOf([ '0', 0 ], 'Income source is required.'),
  });

  const handleSubmit = args => {
    const { values, setSubmitting } = args;
    const { autoFundSourceId, sourceName, merchantId } = values;

    const callbacks = {
      onSuccess: () => setConfirmContent(null),
      onComplete: () => setSubmitting(false),
    };

    const payload = {
      id: edit ? autoFundSourceId : null,
      linkedAccountId: selectedAccountId,
      name: sourceName,
      merchantId,
      token,
    };

    edit ? updateAutoFundSource(payload, callbacks) : addAutoFundSource(payload, callbacks);
  };

  return (
    <Formik
      initialValues={defaults}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={(values, handlers) => {
        const { setSubmitting } = handlers;
        handleSubmit({ values, setSubmitting });
      }}
    >
      {form => (
        <Form>
          {!_.isEmpty(autoFundSources) && <XymSpacer size={1.5} mobileSize={0.5} />}

          {_.isEmpty(incomeSources) && (
            <Center>
              <XymSpacer />

              <NoData
                icon='fa-solid fa-list'
                text='No income sources'
                subText={(
                  <>
                    Start categorizing deposits as income,<br />
                    to begin using them as auto-fund sources.
                  </>
                )}
              />

              <XymSpacer size={2} />
            </Center>
          )}

          {!_.isEmpty(incomeSources) && (
            <>
              <XymSelect
                form={form}
                data={autoFundOptions}
                name='autoFundSourceId'
                visible={edit || false}
                label={<Font size={0.875} weight='semibold'>Your auto-fund sources</Font>}
                noResultsText='Source not found'
                privacy={privacyMode}
                callback={sourceId => {
                  const source = autoFundSources?.find(source => source._id === sourceId);

                  if (source) {
                    form?.setFieldValue('merchantId', source?.merchantId);
                    form?.setFieldValue('sourceName', source?.name);
                  }
                }}
              />

              <XymField
                form={form}
                name='sourceName'
                placeholder='i.e. My payday'
                privacy={privacyMode}
                label={(
                  <Font size={0.875} weight='semibold'>
                    {edit
                      ? 'What would you like to rename this auto-funding source?'
                      : 'What should we call this auto-funding source?'
                    }
                  </Font>
                )}
              />

              <XymSelect
                form={form}
                data={sourceOptions}
                name='merchantId'
                label={<Font size={0.875} weight='semibold'>Income source</Font>}
                noResultsText='Income source not found'
                noSpacing={true}
                privacy={privacyMode}
                callback={_.noop}
              />

              <XymSpacer />

              <XymConfirmButtons
                form={form}
                confirmText={edit ? 'Save source' : 'Add source'}
                privacy={privacyMode}
                setConfirmContent={setConfirmContent}
                customCancel={edit}
                customCancelText={edit && 'Remove source'}
                customCancelButtonType={edit && 'solid'}
                customCancelButtonColor={edit && error}
                customCancelButtonHoverColor={edit && errorHover}
                customCancelCallback={() => {
                  if (edit) {
                    const callbacks = {
                      onSuccess: () => setConfirmContent(null),
                    };

                    const payload = {
                      id: form?.values?.autoFundSourceId,
                      linkedAccountId: selectedAccountId,
                      token,
                    };

                    removeAutoFundSource(payload, callbacks);
                  }
                }}
              />
            </>
          )}

          {(edit || _.isEmpty(incomeSources)) && (
            <>
              {!_.isEmpty(incomeSources) && <XymSpacer size={0.5} />}

              <Button
                theme={theme}
                selectedTheme={selectedTheme}
                type='button'
                buttonType='ghost'
                text={<Font weight='medium' mobileSize={0.875}>Cancel</Font>}
                color={onPrimary}
                hoverColor={onPrimary}
                callback={() => {
                  form?.resetForm();
                  setConfirmContent(null);
                }}
              />
            </>
          )}
        </Form>
      )}
    </Formik>
  );
});

export { AutoFundSourcesForm };