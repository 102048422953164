import { appConstants } from 'modules';
import { getColor } from 'helpers';
import styled from 'styled-components';

const { themes, layout, spacing, breakpoints } = appConstants;
const { layoutWidth } = layout;
const { gap, extraSmallGap } = spacing;
const { tablet, tabletLandscape } = breakpoints;
const { light } = themes;

export const StyledMFA = styled('div')`
  grid-area: mfa;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;

  @media screen and (max-width: ${layoutWidth}rem) {
    display: grid;
    gap: ${gap}rem;
    grid-template-rows: auto 1fr;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      'title .'
      'securityKeys authenticators';
  }

  @media screen and (max-width: ${tablet}rem) {
    display: flex;
    flex-direction: column;
  }
`;

export const SecurityKeys = styled('div')`
  grid-area: securityKeys;
`;

export const Authenticators = styled('div')`
  grid-area: authenticators;
`;

export const StyledMFAActions = styled('div')`
  display: ${props => props.$visible ? 'flex' : 'none'};
  flex-direction: column;
  width: 100%;
`;

export const StyledRemoveConfirm = styled('div')`
  max-width: 27rem;
`;

export const RemovedItem = styled('div')`
  display: inline-flex;
`;

export const StyledDevice = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const DeviceName = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: ${props => {
    const trustedDevice = props.$trustedDevice;
    const active = props.$active;

    if (trustedDevice && !active) {
      return getColor(props, 'onPrimary') + 90;
    }

    return getColor(props, 'onPrimary');
  }};

  span {
    display: flex;
    align-items: center;
  }
`;

export const DeviceActions = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const StyledSessionHistory = styled('div')`
  grid-area: history;
  width: 100%;
  height: fit-content;

  ul {
    border: 0.0625rem solid ${props => {
      const lightTheme = props.$selectedTheme === light;
      return getColor(props, lightTheme ? 'lightGrey' : 'darkGrey');
    }};

    li {
      min-height: 4.125rem;

      &:nth-child(even) {
        @media (hover: hover) {
          &:hover {
            background-color: ${props => getColor(props, 'grey') + 15};
          }
        }
      }

      &:nth-child(odd) {
        background-color: ${props => {
          const lightTheme = props.$selectedTheme === light;
          return getColor(props, lightTheme ? 'offWhite' : 'darkGrey');
        }};

        @media (hover: hover) {
          &:hover {
            background-color: ${props => getColor(props, 'grey') + 15};
          }
        }
      }

      &:first-child {
        background-color: ${props => getColor(props, 'darkGrey')};

        @media (hover: hover) {
          &:hover {
            background-color: ${props => getColor(props, 'darkGrey')};
          }
        }
      }
    }

    li:first-of-type {
      div:first-of-type {
        display: inline-flex;
        gap: ${extraSmallGap}rem;
        align-items: center;
      }
    }
  }
`;

export const StyledTrustedDevices = styled('div')`
  grid-area: devices;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;

  @media screen and (max-width: ${tabletLandscape}rem) {
    height: inherit;
  }
`;