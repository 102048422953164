/* eslint-disable max-len */
import { useSelector } from 'react-redux';
import { getColor } from 'helpers';
import styled, { withTheme } from 'styled-components';

const PlusSVG = styled('svg')`
  width: ${props => props.$width || 1}rem;
  height: ${props => props.$height || 1}rem;
  fill: ${props => props.color || getColor(props, 'grey')};
  transform: ${props => `rotate(${props.$rotation}deg)`};
  cursor: pointer;
`;

const Plus = withTheme(props => {
  const { theme, color, bgColor, width, height, rotation } = props;
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const primary = getColor(props, 'primary');
  const onPrimary = getColor(props, 'onPrimary');

  return (
    <PlusSVG
      $theme={theme}
      $selectedTheme={selectedTheme}
      $width={width}
      $height={height}
      $rotation={rotation}
      color={bgColor || onPrimary}
      viewBox='0 0 16 16'
      fill='currentColor'
    >
      <g>
        <circle cx='8' cy='8' r='8' />
        <path d='M3.75732 8H12.2426' stroke={color || primary} strokeWidth='2' strokeLinecap='round'/>
        <path d='M8 12.2426V3.75728' stroke={color || primary} strokeWidth='2' strokeLinecap='round'/>
      </g>
    </PlusSVG>
  );
});

export { Plus };