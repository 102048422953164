import { appConstants } from 'modules';
import styled from 'styled-components';

const { layout, spacing, breakpoints } = appConstants;
const { layoutWidth, settingsNavWidth } = layout;
const { extraLargeGap, mediumGap, gap } = spacing;
const { tabletLandscape, mobile } = breakpoints;

export const StyledSubscription = styled('div')`
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const Layout = styled('div')`
  display: grid;
  grid-template-columns: ${settingsNavWidth}rem 1fr;
  gap: 0 ${extraLargeGap}rem;
  max-width: ${layoutWidth}rem;
  width: 100%;
  height: 100%;

  @media screen and (max-width: ${layoutWidth}rem) {
    gap: 0 ${mediumGap}rem;
  }

  @media screen and (max-width: ${tabletLandscape}rem) {
    display: none;
  }
`;

export const MobileLayout = styled('div')`
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
  padding: ${mediumGap}rem;
  overflow-y: auto;

  @media screen and (max-width: ${tabletLandscape}rem) {
    display: flex;
    padding: ${mediumGap}rem ${mediumGap}rem ${gap}rem ${mediumGap}rem;
  }

  @media screen and (max-width: ${mobile}rem) {
    padding: ${gap}rem;
    width: 100dvw;
  }
`;

export const SubscriptionContent = styled('div')`
  display: grid;
  gap: ${extraLargeGap}rem;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'details payments'
    'history history';

  max-width: ${layoutWidth}rem;
  padding: ${extraLargeGap}rem ${extraLargeGap}rem ${extraLargeGap}rem 0;
  overflow: auto;
  width: 100%;

  @media screen and (max-width: ${layoutWidth}rem) {
    padding: ${mediumGap}rem ${mediumGap}rem ${mediumGap}rem 0;
  }

  @media screen and (max-width: ${layoutWidth}rem) {
    gap: ${mediumGap}rem;
    max-width: unset;
    padding-right: ${mediumGap}rem;
  }

  @media screen and (max-width: ${tabletLandscape}rem) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);
    grid-template-areas:
      'details'
      'payments'
      'history';

    max-width: unset;
    padding-right: ${mediumGap}rem;
    height: calc(100dvh - 14rem);
  }

  @media screen and (max-width: ${tabletLandscape}rem) {
    padding: ${mediumGap}rem;
  }

  @media screen and (max-width: ${mobile}rem) {
    padding: 1rem;
  }
`;