import { useSelector } from 'react-redux';
import { getColor, hexValid } from 'helpers';
import { appConstants } from 'modules';
import styled, { withTheme } from 'styled-components';

const { light } = appConstants.themes;

const StyledHR = styled('div')`
  display: ${props => props.$visible ? 'block' : 'none'};
  width: ${props => props.$width ? `${props.$width}rem` : '100%'};
  height: ${props => props.$height || 0.0625}rem;
  border-radius: 1rem;
  background-color: ${props => {
    const lightTheme = props.$selectedTheme === light;
    const color = lightTheme ? getColor(props,'lightGrey') + 95 : getColor(props, 'lightGrey') + 20;

    if (hexValid(props.$color)) {
      return props.$color || color;
    }

    return color;
  }};
`;


const HR = withTheme(props => {
  const { theme, visible, width, height, color } = props;
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);

  return (
    <StyledHR
      $theme={theme}
      $selectedTheme={selectedTheme}
      $visible={visible}
      $width={width}
      $height={height}
      $color={color}
    />
  );
});

export { HR };