import { useEffect, useState, useCallback } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { appConstants, authActions } from 'modules';
import { tokenValid, emailIncrementorCheck } from 'helpers';
import { Font, H3, XymField, XymTooltip, Center, XymSpacer } from 'components';
import { Button, Checkbox } from 'xerum';
import { Form, Formik } from 'formik';
import styled, { withTheme } from 'styled-components';
import * as yup from 'yup';
import _ from 'lodash';

const { themes, spacing } = appConstants;
const { extraLargeGap, gap } = spacing;
const { light } = themes;

const StyledLogin = styled('div')`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: ${extraLargeGap}rem ${gap}rem ${gap}rem;

  form {
    max-width: 27rem;
  }
`;

const TrustedDevice = styled('div')`
  display: inline-flex;
  align-items: center;
`;

const defaultValues = {
  email: '',
  password: '',
  trustedDevice: false,
};

const validationSchema = yup.object().shape({
  email: yup.string()
    .email('Invalid email.')
    .required('Email is required.')
    .test('email-incrementor', 'Email incrementors are not allowed.', value => emailIncrementorCheck(value)),
  password: yup.string().required('Password is required.'),
});

const LoginForm = withTheme(props => {
  const { theme } = props;
  const [ passwordVisible, setPasswordVisible ] = useState(false);
  const { tokenInfo } = useSelector(state => state.auth);
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const accent = theme.modes[selectedTheme]?.accent;
  const darkGrey = theme.modes[selectedTheme]?.darkGrey;
  const white = theme.modes[selectedTheme]?.white;
  const dispatch = useDispatch();

  const login = useCallback((payload, callbacks) => {
    dispatch(authActions.login(payload, callbacks));
  }, [ dispatch ]);

  const navigate = useNavigate();
  const location = useLocation();
  const token = tokenInfo?.accessToken;
  const lightTheme = selectedTheme === light;

  useEffect(() => {
    const { state } = location;

    if (token) {
      const expired = !tokenValid(token);
      const route = !_.isEmpty(state) ? state.from.pathname + state.from.search : '/';
      if (!expired) navigate(route);
    }
  }, [ token, navigate, location ]);

  const handleSubmit = (values, actions) => {
    const { setSubmitting } = actions;
    const { email, password, trustedDevice } = values;

    const callbacks = {
      onComplete: () => {
        setSubmitting(false);
        navigate('/');
      },
    };

    const payload = {
      email: email.toLowerCase(),
      password,
      trustedDevice,
    };

    login(payload, callbacks);
  };

  const buildForm = () => {
    if (!token) {
      return (
        <Formik
          initialValues={defaultValues}
          validationSchema={validationSchema}
          enableReinitialization={true}
          onSubmit={handleSubmit}
        >
          {form => (
            <Form>
              <H3 color={lightTheme ? darkGrey : white}>Log in</H3>
              <XymSpacer size={2} />

              <XymField
                form={form}
                name='email'
                type='email'
                autoComplete='email'
                icon='fa-solid fa-envelope'
                label={<Font size={0.875} weight='semibold'>Email</Font>}
              />

              <XymField
                form={form}
                name='password'
                type={passwordVisible ? 'text' : 'password'}
                icon={passwordVisible ? 'fa-solid fa-eye' : 'fa-solid fa-eye-slash'}
                iconCallback={() => setPasswordVisible(!passwordVisible)}
                label={<Font size={0.875} weight='semibold'>Password</Font>}
                autoComplete='current-password'
                spacing={2}
              />

              <Center>
                <TrustedDevice>
                  <Checkbox
                    theme={theme}
                    selectedTheme={selectedTheme}
                    form={form}
                    name='trustedDevice'
                    label='Trust this device.'
                    checkColor={lightTheme ? accent : white}
                  />

                  <XymTooltip
                    text='Adds this device to your trusted devices and keeps you logged in.'
                  />
                </TrustedDevice>
              </Center>

              <XymSpacer size={0.75} />

              <Button
                theme={theme}
                selectedTheme={selectedTheme}
                type='submit'
                disabled={form.isSubmitting}
                callback={form.handleSubmit}
                text={
                  <Font weight='medium' mobileSize={0.875}>
                    {form.isSubmitting ? 'Logging in...' : 'Login'}
                  </Font>
                }
              />

              <XymSpacer />

              <Center>
                <Link to='/create-account'>Create account</Link> or&nbsp;
                <Link to='/reset-password'>Reset password</Link>
              </Center>
            </Form>
          )}
        </Formik>
      );
    }
  };

  return (
    <StyledLogin>
      {buildForm()}
    </StyledLogin>
  );
});

export { LoginForm };