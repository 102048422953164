
const stripeConstants = {
  actions: {
    GET_PAYMENT_DATA: 'modules/stripe/GET_PAYMENT_DATA',
    ADD_PAYMENT_METHOD: 'modules/stripe/ADD_PAYMENT_METHOD',
    UPDATE_PAYMENT_METHOD: 'modules/stripe/UPDATE_PAYMENT_METHOD',
    REMOVE_PAYMENT_METHOD: 'modules/stripe/REMOVE_PAYMENT_METHOD',
  },

  selectors: {
    STATE_KEY_PAYMENT_DATA: 'paymentData',
    STATE_KEY_PAYMENT_DATA_LOADING: 'paymentDataLoading',
  },
};

export { stripeConstants };