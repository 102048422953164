import { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { messagesActions } from 'modules';
import { animate } from 'helpers';
import { XymDropdown, Font, NoData, XymSpacer } from 'components';
import { Badge } from 'xerum';
import { StyledInbox, MessageActions, BadgeHolder } from './styles';
import { Messages } from './Messages';
import { withTheme } from 'styled-components';
import _ from 'lodash';

const XymInbox = withTheme(props => {
  const { theme, count, visible } = props;
  const { mobileMode } = useSelector(state => state.app);
  const { tokenInfo } = useSelector(state => state.auth);
  const { messagesData, messagesDataLoading } = useSelector(state => state.messages);
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const [ inboxSpacing, setInboxSpacing ] = useState(1.25);
  const [ inboxVisible, setInboxVisible ] = useState(false);
  const badgeRef = useRef();
  const animationRef = useRef();
  const hasUnreadMessages = count > 0;
  const messages = useMemo(() => messagesData?.messageList || [], [ messagesData ]);
  const hasMessages = messages?.length > 0;
  const dispatch = useDispatch();

  const handleAnimationIn = useCallback(() => {
    animate({ ref: animationRef.current, offset: mobileMode ? 10 : -10, duration: 0.2 });
  }, [ mobileMode ]);

  const handleAnimationOut = () => {
    animate({
      ref: animationRef.current,
      offset: mobileMode ? 10 : -10,
      transitionOut: true,
      onComplete: () => setInboxVisible(!inboxVisible),
    });
  };

  useEffect(() => {
    if (inboxVisible) handleAnimationIn();
  }, [ inboxVisible, handleAnimationIn ]);

  const markAllMessagesRead = useCallback((payload, callback) => (
    dispatch(messagesActions.markAllMessagesRead(payload, callback))
  ), [ dispatch ]);

  useEffect(() => {
    const newWidth = badgeRef.current?.offsetWidth / 16;
    const widthDifferent = badgeRef.current && inboxSpacing !== newWidth;

    if (widthDifferent) setInboxSpacing(newWidth);
  }, [ count, badgeRef, inboxSpacing ]);

  const handleMarkAllRead = () => {
    if (hasUnreadMessages) {
      const payload = { token: tokenInfo?.accessToken };
      markAllMessagesRead(payload);
    }
  };

  const buildMessages = () => {
    if (_.isEmpty(messages) && messagesDataLoading) {
      return <NoData text='Loading messages...' />;
    }

    if (!_.isEmpty(messages)) {
      return <Messages />;
    }

    if (_.isEmpty(messages) && !messagesDataLoading) {
      return (
        <>
          <XymSpacer size={2.5} />
          <NoData
            icon='fa-solid fa-envelope-open-text'
            text='All caught up!'
            subText='New messages will appear here.'
          />
        </>
      );
    }
  };

  return (
    <>
      <StyledInbox $visible={visible}>
        {!mobileMode && <XymSpacer size={0.1875} />}

        <BadgeHolder>
          <Badge
            theme={theme}
            selectedTheme={selectedTheme}
            ref={badgeRef}
            count={count}
            icon='fa-solid fa-envelope'
            textSize={0.75}
            iconSize={1.25}
            counterOnly={mobileMode}
            posY={0.125}
            posX={!mobileMode ? 0.25 : 0}
            iconPosY={mobileMode ? -0.125 : 0}
            strokeWidth={0.1875}
            callback={() => !inboxVisible ? setInboxVisible(true) : handleAnimationOut()}
          />

          {mobileMode && <Font size={0.625}>Inbox</Font>}
        </BadgeHolder>

        <XymDropdown
          ref={animationRef}
          visible={inboxVisible}
          setVisible={handleAnimationOut}
          width={19}
          posX={mobileMode ? 1 : 0.125}
          posY={mobileMode ? 4.5 : 3}
          bottom={mobileMode}
          minHeight={14.75}
          maxHeight={20}
          horizontalPadding={0.75}
          verticalPadding={1.25}
        >
          <MessageActions
            $theme={theme}
            $selectedTheme={selectedTheme}
            $visible={hasMessages}
            $hasUnreadMessages={hasUnreadMessages}
          >
            <Font size={0.9} weight='semibold' onClick={handleMarkAllRead}>
              Mark all as read
            </Font>
          </MessageActions>

          {hasMessages && <XymSpacer />}

          {buildMessages()}
        </XymDropdown>
      </StyledInbox>

      {!mobileMode && <XymSpacer size={inboxSpacing} across={true} />}
    </>
  );
});

export { XymInbox };