import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { appConstants } from 'modules';
import { Close, Font, XymSpacer } from 'components';
import { Tag } from 'xerum';
import styled, { withTheme } from 'styled-components';
import _ from 'lodash';

const { themes } = appConstants;
const light = themes.light;

const StyledFileContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: ${props => _.isEmpty(props.$expensesData) ? 0.5 : 0}rem 0 0.5rem 0;
`;

const FileArea = withTheme(forwardRef((props, ref) => {
  const { theme, fileName, fileExtension, importData, setImportData } = props;

  const { mobileMode } = useSelector(state => state.app);
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);

  const lightTheme = selectedTheme === light;
  const { expensesData } = useSelector(state => state.expenses);
  const secondary = theme.modes[selectedTheme]?.secondary;
  const onSecondary = theme.modes[selectedTheme]?.onSecondary;
  const white = theme.modes[selectedTheme]?.white;
  const darkGrey = theme.modes[selectedTheme]?.darkGrey;
  const paynesGrey = theme.colors.neutral.paynesGrey;
  const shouldTruncate = fileName.length > (mobileMode ? 32 : 50);
  const truncatedFileName = `${fileName.slice(0, mobileMode ? 32 : 50)}... ${fileExtension}`;

  const fileText = (
    <Font size={0.875} mobileSize={0.75}>
      <i className='fa-solid fa-file' />
      <XymSpacer across={true} />
      {`${shouldTruncate ? truncatedFileName : fileName}`}
    </Font>
  );

  if (_.isEmpty(importData)) return;

  return (
    <StyledFileContainer $expensesData={expensesData}>
      <Tag
        text={fileText}
        textColor={lightTheme ? secondary : onSecondary}
        removable={true}
        allClick={mobileMode}
        bgColor={lightTheme ? paynesGrey : paynesGrey + 75}
        borderRadius={1}
        verticalPadding={0.5}
        closeIcon={<Close visible={true} color={white} bgColor={darkGrey} rotation={45} />}
        callback={() => {
          ref.current.value = '';
          setImportData(null);
        }}
      />

      <XymSpacer />
    </StyledFileContainer>
  );
}));

export { FileArea };