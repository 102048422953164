import { useSelector } from 'react-redux';
import { appConstants } from 'modules';
import { hexValid } from 'helpers';
import { Font } from 'components';
import { Tag } from 'xerum';
import { withTheme } from 'styled-components';

const { light } = appConstants.themes;

const StandardTag = withTheme(props => {
  const { theme, tag, textColor, textSize, bgColor, isOnTrack } = props;
  const { generalPreferences: { privacyMode, selectedTheme } } = useSelector(state => state.preferences);
  const lightTheme = selectedTheme === light;
  const secondary = theme.modes[selectedTheme]?.secondary;
  const onSecondary = theme.modes[selectedTheme]?.onSecondary;
  const paynesGrey = theme.colors.neutral.paynesGrey;

  return (
    <Tag
      text={privacyMode ? 'Private' : <Font size={0.875} weight={!isOnTrack ? 'semibold' : 'medium'}>{tag}</Font>}
      textColor={hexValid(textColor) && !privacyMode ? textColor : (lightTheme ? secondary : onSecondary)}
      textSize={textSize}
      bgColor={hexValid(bgColor) && !privacyMode ? bgColor : (lightTheme ? paynesGrey : paynesGrey + 75)}
      borderRadius={1}
      verticalPadding={0.5}
    />
  );
});

export { StandardTag };