import { Fragment, useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Routes } from 'react-router-dom';
import { appActions, appConstants, authActions, authConstants, rootActions } from 'modules';
import { makeRoutes, autoLogoutOrRefresh, sessionCheck, tokenValid } from 'helpers';
import { GlobalStyles, StyledApp, MainContent } from './styles';
import { Header, Footer } from 'pages';
import { Font, XymConfirm } from 'components';
import { Notifications, Loading, Modal } from 'xerum';
import { withTheme } from 'styled-components';
import _ from 'lodash';

const { names, breakpoints } = appConstants;
const { appName, refreshTokenKeyName, accessTokenKeyName } = names;

const App = withTheme(props => {
  const { theme } = props;
  const { tokenInfo, userInfo, tokenInfoLoading, invalidatingTokens } = useSelector(state => state.auth);
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const {
    modalContent,
    notifications,
    mobileMode,
    tabletMode,
    tabletLandscapeMode,
    hybridMode,
    bannerContent,
    globalConfirmContent,
  } = useSelector(state => state.app);
  const token = useMemo(() => tokenInfo?.accessToken, [ tokenInfo ]);
  const trustedDevice = useMemo(() => tokenInfo?.refreshToken && tokenValid(tokenInfo?.refreshToken), [ tokenInfo ]);
  const secondary = theme.modes[selectedTheme]?.secondary;
  const onSecondary = theme.modes[selectedTheme]?.onSecondary;
  const desktopMode = !mobileMode && !tabletMode;
  const isAdmin = userInfo?.roles?.includes(authConstants.roles.admin);
  const dispatch = useDispatch();

  const setModalContent = useCallback(payload => {
    dispatch(appActions.setModalContent(payload));
  }, [ dispatch ]);

  const setGlobalConfirmContent = useCallback(payload => {
    dispatch(appActions.setGlobalConfirmContent(payload));
  }, [ dispatch ]);

  const addNotification = useCallback(payload => {
    dispatch(appActions.addNotification(payload));
  }, [ dispatch ]);

  const removeNotification = useCallback(payload => {
    dispatch(appActions.removeNotification(payload));
  }, [ dispatch ]);

  const getNewTokens = useCallback((payload, callbacks) => {
    dispatch(authActions.getNewTokens(payload, callbacks));
  }, [ dispatch ]);

  const verifyToken = useCallback((payload, callbacks) => {
    dispatch(authActions.verifyToken(payload, callbacks));
  }, [ dispatch ]);

  const invalidateToken = useCallback((payload, callback) => {
    dispatch(authActions.invalidateToken(payload, callback));
  }, [ dispatch ]);

  const logout = useCallback(() => dispatch(rootActions.logout()), [ dispatch ]);
  const setHybridMode = useCallback(payload => dispatch(appActions.setHybridMode(payload)), [ dispatch ]);
  const setMobileMode = useCallback(payload => dispatch(appActions.setMobileMode(payload)), [ dispatch ]);
  const setTabletMode = useCallback(payload => dispatch(appActions.setTabletMode(payload)), [ dispatch ]);
  const setTabletLandscapeMode = useCallback(payload => {
    dispatch(appActions.setTabletLandscapeMode(payload));
  }, [ dispatch ]);

  useEffect(() => {
    const handleAppMode = () => {
      const width = window.innerWidth;
      const { tablet, mobile, tabletLandscape, hybrid } = breakpoints;
      const tabletLandscapeAsPx = tabletLandscape * 16;
      const tabletAsPx = tablet * 16;
      const mobileAsPx = mobile * 16;
      const hybridAsPx = hybrid * 16;
      const fullDesktop = width > hybridAsPx;
      const isTabletLandscape = width <= tabletLandscapeAsPx && width > tabletAsPx;
      const isTablet = width <= tabletAsPx && width > mobileAsPx;
      const isHybrid = width <= hybridAsPx && width > tabletLandscapeAsPx;
      const isMobile = width <= mobileAsPx;

      if (fullDesktop) {
        mobileMode && setMobileMode(false);
        tabletMode && setTabletMode(false);
        hybridMode && setHybridMode(false);
        tabletLandscapeMode && setTabletLandscapeMode(false);
        return;
      }

      if (isHybrid && !hybridMode) {
        mobileMode && setMobileMode(false);
        tabletMode && setTabletMode(false);
        tabletLandscapeMode && setTabletLandscapeMode(false);
        setHybridMode(true);
        return;
      }

      if (isTabletLandscape && !tabletLandscapeMode) {
        mobileMode && setMobileMode(false);
        tabletMode && setTabletMode(false);
        hybridMode && setHybridMode(false);
        setTabletLandscapeMode(true);
        return;
      }

      if (isTablet && !tabletMode) {
        mobileMode && setMobileMode(false);
        tabletLandscapeMode && setTabletLandscapeMode(false);
        hybridMode && setHybridMode(false);
        setTabletMode(true);
        return;
      }

      if (isMobile && !mobileMode) {
        tabletMode && setTabletMode(false);
        tabletLandscapeMode && setTabletLandscapeMode(false);
        hybridMode && setHybridMode(false);
        setMobileMode(true);
        return;
      }
    };

    window.addEventListener('resize', handleAppMode);

    if (_.isEmpty(tabletMode) || _.isEmpty(mobileMode)) handleAppMode();
    return () => window.removeEventListener('resize', handleAppMode);
  }, [
    mobileMode,
    tabletMode,
    tabletLandscapeMode,
    hybridMode,
    setMobileMode,
    setTabletMode,
    setTabletLandscapeMode,
    setHybridMode,
  ]);

  useEffect(() => {
    const existingSettings = JSON.parse(localStorage.getItem(appName));
    const refreshToken = existingSettings?.[refreshTokenKeyName];
    const accessToken = existingSettings?.[accessTokenKeyName];

    if (refreshToken && !token) {
      getNewTokens({ refreshToken, trustedDevice: true });
      return;
    }

    if (accessToken && !trustedDevice && !token) verifyToken({ accessToken });
  }, [ getNewTokens, trustedDevice, token, verifyToken ]);

  useEffect(() => {
    const existingSettings = JSON.parse(localStorage.getItem(appName));
    const refreshToken = existingSettings?.[refreshTokenKeyName];

    if (token) {
      const autoActionArgs = {
        token,
        refreshToken,
        trustedDevice,
        logout,
        addNotification,
        getNewTokens,
        invalidateToken,
      };

      autoLogoutOrRefresh(autoActionArgs);
      return () => clearInterval(sessionCheck);
    }
  }, [ token, logout, addNotification, trustedDevice, getNewTokens, invalidateToken ]);

  const renderApp = () => {
    return (
      <StyledApp>
        {token && <Header token={token} logout={logout} />}

        <MainContent $token={token} $bannerContent={bannerContent} $mobileMode={mobileMode}>
          <Routes>
            {makeRoutes({ token, isAdmin })}
          </Routes>
        </MainContent>

        {token && <Footer setModalContent={setModalContent} />}
      </StyledApp>
    );
  };

  return (
    <Fragment>
      <GlobalStyles $theme={theme} $selectedTheme={selectedTheme} />

      <Notifications
        theme={theme}
        selectedTheme={selectedTheme}
        notifications={notifications}
        bottom={!desktopMode ? 5 : 4}
        bgColor={secondary}
        textColor={onSecondary}
        removeNotification={removeNotification}
      />

      <Loading
        theme={theme}
        selectedTheme={selectedTheme}
        isLoading={tokenInfoLoading || invalidatingTokens}
        hasData={tokenInfo}
        renderOnFail={true}
        text={
          <Font size={1} weight='semibold'>
            {tokenInfoLoading ? 'Authenticating' : 'Logging out'}...
          </Font>
        }
      >
        {renderApp()}

        <Modal
          theme={theme}
          selectedTheme={selectedTheme}
          titleText={modalContent?.title}
          visible={!_.isEmpty(modalContent)}
          bgClose={true}
          onClose={() => {
            modalContent?.onClose?.();
            setModalContent(null);
          }}
        >
          {modalContent?.content}
        </Modal>

        <XymConfirm
          confirmContent={globalConfirmContent?.content}
          confirmText={globalConfirmContent?.confirmText}
          onConfirm={globalConfirmContent?.onConfirm}
          confirmButtonColor={globalConfirmContent?.confirmButtonColor}
          confirmButtonHoverColor={globalConfirmContent?.confirmButtonHoverColor}
          confirmDisabled={globalConfirmContent?.confirmDisabled}
          useOverflow={globalConfirmContent?.useOverflow}
          privacy={globalConfirmContent?.privacy}
          blank={globalConfirmContent?.blank}
          onClose={() => setGlobalConfirmContent(null)}
        />
      </Loading>
    </Fragment>
  );
});

export { App };