import { getColor } from 'helpers';
import { appConstants } from 'modules';
import styled, { css } from 'styled-components';

const { breakpoints, themes } = appConstants;
const { hybrid, tabletLandscape, mobile } = breakpoints;
const { light } = themes;

export const AccountBuilder = styled('div')`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
  max-width: 54rem;

  @media screen and (max-width: 53.75rem) {
    grid-template-columns: 1fr;
    max-width: unset;
  }
`;

export const AddAccountArea = styled('div')`
  padding: 1rem;
`;

export const RemoveItem = styled('div')`
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -0.9125rem;
  left: -0.85rem;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 50%;
  background-color: ${props => getColor(props, 'primary')};

  @media screen and (max-width: ${hybrid}rem) {
    display: flex;
  }
`;

export const AccountItemHolder = styled('div')`
  position: relative;
  height: fit-content;
  width: 100%;
  display: flex;

  @media (hover: hover) {
    &:hover {
      ${RemoveItem} {
        display: ${props => props.$displayOnly ? 'none' : 'flex'};
      }
    }
  }
`;

export const AccountListArea = styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: fill-available;
  height: auto;
  padding: 1.5rem;
  margin: 0 1rem;
  user-select: none;
  border-radius: 0.5rem;
  border: 0.09375rem solid ${props => {
    const lightTheme = props.$selectedTheme === light;
    const lightColor = getColor(props, 'lightGrey');
    const darkColor = getColor(props, 'lightGrey') + 50;

    if (props.$needsAttention) return getColor(props, 'error');
    return lightTheme ? lightColor : darkColor;
  }};

  ${props => props.$noAccounts && css`
    align-items: center;
    justify-content: center;
  `}

  @media screen and (max-width: ${tabletLandscape}rem) {
    padding: 1rem;
  }
`;

export const DetailFields = styled('div')`
  display: grid;
  grid-template-columns: 1fr 8.65rem;
  gap: 1rem;

  @media screen and (max-width: ${mobile}rem) {
    display: flex;
    flex-direction: column;
  }
`;