import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { institutionsActions, expensesActions } from 'modules';
import { stripNumbersAndRepeats } from 'helpers';
import { XymField, XymSelect, XymSpacer, Font } from 'components';
import { IncomeInUseConfirm } from 'pages/Overview/IncomeInUseConfirm';
import { Button } from 'xerum';
import { Formik, Form } from 'formik';
import { withTheme } from 'styled-components';
import * as yup from 'yup';
import _ from 'lodash';

const TransactionForm = withTheme(props => {
  const { theme, transaction, searchQuery, setConfirmContent } = props;

  const { generalPreferences: { privacyMode, selectedTheme } } = useSelector(state => state.preferences);

  const {
    selectedAccountId,
    transactionsDataLoading,
    paginationData,
    transactionsData,
  } = useSelector(state => state.institutions);

  const { categories } = useSelector(state => state.app);
  const { tokenInfo } = useSelector(state => state.auth);
  const { expensesData, autoFundSources } = useSelector(state => state.expenses);
  const token = tokenInfo?.refreshToken || tokenInfo?.accessToken;
  const currentPage = paginationData?.currentPage || 1;
  const manualTransaction = transaction?.metadata?.manualTransaction;
  const warning = theme.modes[selectedTheme]?.warning;
  const error = theme.modes[selectedTheme]?.error;
  const errorHover = theme.modes[selectedTheme]?.errorHover;
  const isDebit = transaction?.data?.amount < 0;
  const dispatch = useDispatch();

  const updateTransaction = useCallback((payload, callbacks) => {
    dispatch(institutionsActions.updateTransaction(payload, callbacks));
  }, [ dispatch ]);

  const removeTransaction = useCallback((payload, callbacks) => {
    dispatch(institutionsActions.removeTransaction(payload, callbacks));
  }, [ dispatch ]);

  const getBalances = useCallback((payload, callbacks) => {
    dispatch(expensesActions.getBalances(payload, callbacks));
  }, [ dispatch ]);

  const expenses = useMemo(() => expensesData?.filter(expense => !expense.isGoal) || [], [ expensesData ]);
  const goals = useMemo(() => expensesData?.filter(expense => expense.isGoal) || [], [ expensesData ]);

  const defaults = {
    autoSpendExpenseId: transaction?.metadata?.autoSpendExpenseId || '',
    category: transaction?.metadata?.categoryId || '',
    memo: transaction?.metadata?.memo || '',
  };

  const validationSchema = yup.object().shape({
    memo: yup.string().trim(),
  });

  const expenseDestinations = useMemo(() => {
    return expenses?.map(expense => {
      const { _id, name } = expense;
      return { value: _id, label: name };
    });
  }, [ expenses ]);

  const goalDestinations = useMemo(() => {
    return goals?.map(goal => {
      const { _id, name } = goal;
      return { value: _id, label: name };
    });
  }, [ goals ]);

  const expensesAndGoals = useMemo(() => {
    const allExpensesAndGoals = [ ...expenseDestinations || [], ...goalDestinations || [] ];
    const expensesAndGoals = [
      { value: 0, label: 'Select expense or goal...' },
      ...allExpensesAndGoals,
    ];

    return expensesAndGoals;
  }, [ expenseDestinations, goalDestinations ]);

  const allCategories = useMemo(() => {
    const options = [
      { value: 0, label: 'Select a category...' },
      ...categories
        ?.filter(category => !isDebit ? !_.toLower(category.name).includes('income') : category)
        ?.map(category => ({ value: category._id, label: category.name })) || [],
    ];

    return options;
  }, [ categories, isDebit ]);

  const incomeInUseCheck = args => {
    const { values, transactions, removing } = args;
    const { category } = values;

    const merchantName = transaction?.data?.merchant_name || transaction?.data?.name;
    const currentCategoryId = +category;
    const currentCategory = categories.find(category => category._id === transaction.metadata.categoryId);
    const newCategory = categories.find(category => category._id === currentCategoryId);
    const changingCategoryFromIncome = !_.toLower(newCategory?.name).includes('income');
    const newCategoryIsIncome = _.toLower(newCategory?.name).includes('income');
    const instances = transactions?.filter?.(transaction => {
      const categoryMatch = transaction.metadata.categoryId === currentCategoryId;
      const workingName = _.toLower(transaction.data?.merchant_name || transaction.data?.name);
      const nameMatch = stripNumbersAndRepeats(_.toLower(workingName))
        === stripNumbersAndRepeats(_.toLower(merchantName));

      return categoryMatch && nameMatch;
    }) || [];

    const isOnlyInstanceLeft = instances.length === 1;
    const activeAutoFundSource = autoFundSources?.find(source => {
      const existingMerchant = _.toLower(source.merchantId);
      const workingMerchant = stripNumbersAndRepeats(_.toLower(merchantName));
      return existingMerchant === workingMerchant;
    });

    const removingOnlyRemainingActiveIncomeInstance = newCategoryIsIncome
      && removing
      && !_.isEmpty(activeAutoFundSource)
      && isOnlyInstanceLeft;

    const assignmentCount = expenses.filter(expense => (
      expense.autoFundSourceId === activeAutoFundSource?._id
    )).length;

    if ((changingCategoryFromIncome && !newCategoryIsIncome) || removingOnlyRemainingActiveIncomeInstance) {
      const response = {
        currentCategory,
        newCategory,
        newCategoryIsIncome,
        changingCategoryFromIncome,
        activeAutoFundSource,
        assignmentCount,
        incomeInUse: !_.isEmpty(activeAutoFundSource),
        removingOnlyRemainingActiveIncomeInstance,
      };

      return response;
    }

  };

  const handleRemoveManualTransaction = form => {
    const args = { values: form.values, transactions: transactionsData, removing: true };

    const {
      incomeInUse,
      activeAutoFundSource,
      assignmentCount,
      removingOnlyRemainingActiveIncomeInstance,
    } = incomeInUseCheck(args) || {};

    const payload = {
      token,
      linkedAccountId: selectedAccountId,
      transactionId: transaction.metadata.transactionId,
      pageNumber: currentPage,
      search: searchQuery,
    };

    const callbacks = {
      onSuccess: () => {
        getBalances({ token, linkedAccountId: selectedAccountId });
        setConfirmContent(null);
      },
      onFail: () => form.setSubmitting(false),
      onComplete: () => form.setSubmitting(false),
    };

    removingOnlyRemainingActiveIncomeInstance && incomeInUse
      ? setConfirmContent(
          <IncomeInUseConfirm
            assignedExpenses={assignmentCount || 0}
            autoFundSource={activeAutoFundSource}
            setConfirmContent={setConfirmContent}
            callback={() => removeTransaction(payload, callbacks)}
          />,
        )
      : removeTransaction(payload, callbacks);
  };

  const handleSubmit = args => {
    const { values, setSubmitting } = args;
    const { autoSpendExpenseId, category, memo } = values;

    const callbacks = {
      onSuccess: () => setConfirmContent(null),
      onComplete: () => setSubmitting(false),
      onFail: () => setSubmitting(false),
    };

    const payload = {
      transactionId: transaction?.metadata?.transactionId,
      token,
      autoSpendExpenseId: +autoSpendExpenseId !== 0 ? autoSpendExpenseId : null,
      categoryId: +category !== 0 ? +category : null,
      pageNumber: currentPage,
      memo: !_.isEmpty(memo) ? memo : null,
      search: searchQuery || '',
    };

    const { incomeInUse, activeAutoFundSource, assignmentCount } = incomeInUseCheck(args) || {};

    incomeInUse
      ? setConfirmContent(
          <IncomeInUseConfirm
            assignedExpenses={assignmentCount || 0}
            autoFundSource={activeAutoFundSource}
            setConfirmContent={setConfirmContent}
            callback={() => updateTransaction(payload, callbacks)}
          />,
        )
      : updateTransaction(payload, callbacks);
  };

  return (
    <Formik
      initialValues={defaults}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={(values, handlers) => {
        const { setSubmitting } = handlers;
        handleSubmit({ values, setSubmitting });
      }}
    >
      {form => (
        <Form>
          {transaction?.data?.pending && (
            <>
              <Font size={0.875} weight='semibold'>
                <Font color={warning}>
                  <i className='fa-solid fa-exclamation-triangle' />
                  <XymSpacer size={0.5} across={true} />
                </Font>

                Transaction pending &mdash; unable to edit at this time.
              </Font>

              <XymSpacer />
            </>
          )}

          <XymSelect
            form={form}
            data={allCategories}
            name='category'
            optional={true}
            label={<Font size={0.875} weight='semibold'>Category</Font>}
            noResultsText='Category not found'
            privacy={privacyMode}
            disabled={privacyMode || transaction?.data?.pending}
          />

          <XymSelect
            form={form}
            data={expensesAndGoals}
            name='autoSpendExpenseId'
            optional={true}
            visible={!isDebit}
            label={<Font size={0.875} weight='semibold'>Auto-spend</Font>}
            noResultsText='Auto-spend not found'
            privacy={privacyMode}
            disabled={privacyMode || transaction?.data?.pending}
          />

          <XymField
            form={form}
            name='memo'
            textarea={true}
            textareaRows={6}
            label={<Font size={0.875} weight='semibold'>Memo</Font>}
            optional='Optional'
            placeholder='Type memo here...'
            privacy={privacyMode}
            disabled={privacyMode || transaction?.data?.pending}
            spacing={1.5}
          />

          <Button
            theme={theme}
            selectedTheme={selectedTheme}
            type='submit'
            text={<Font weight='medium' mobileSize={0.875}>Save</Font>}
            disabled={privacyMode || form.isSubmitting || transaction?.data?.pending}
            callback={form.handleSubmit}
          />

          {manualTransaction && (
            <>
              <XymSpacer size={0.5} />

              <Button
                theme={theme}
                selectedTheme={selectedTheme}
                color={error}
                hoverColor={errorHover}
                type='button'
                text={<Font weight='medium' mobileSize={0.875}>Remove</Font>}
                disabled={privacyMode || form.isSubmitting || transactionsDataLoading}
                callback={() => handleRemoveManualTransaction(form)}
              />
            </>
          )}
        </Form>
      )}
    </Formik>
  );
});

export { TransactionForm };