import { appConstants } from 'modules';
import { hexValid } from 'helpers';
import styled, { css } from 'styled-components';

const { layout } = appConstants;
const { textWidth } = layout;

const StyledP = styled('p')`
  margin: 0;
  max-width: ${textWidth}rem;
  font-size: ${props => props.$mobileSize || props.$tabletSize || props.$size || 0.9375}rem;
  ${props => hexValid(props.$color) && css`color: ${props.$color};`}
`;

const P = props => {
  const { children, mobileSize, size, color } = props;

  return (
    <StyledP $size={size} $mobileSize={mobileSize} $color={color}>
      {children}
    </StyledP>
  );
};

export { P };