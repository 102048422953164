import { appConstants } from 'modules';
import styled from 'styled-components';

const { layout, breakpoints } = appConstants;
const { settingsNavWidth } = layout;
const { mobile, layoutWidth, hybrid } = breakpoints;

export const StyledMaintenanceDetails = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 0;

  @media screen and (max-width: ${mobile}rem) {
    justify-content: space-between;
  }
`;

export const StyledActions = styled('div')`
  display: flex;
  flex-direction: column;
  max-width: ${settingsNavWidth}rem;

  @media screen and (max-width: ${hybrid}rem) {
    max-width: unset;
  }
`;

export const MaintenanceHolder = styled('div')`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${layoutWidth}rem) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
  }
`;