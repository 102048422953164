import { Navigate } from 'react-router-dom';
import { H2 } from 'components/Typography/H2';
import { NotFound } from 'xerum';
import { appConstants } from 'modules';
import { theme } from 'theme';
import {
  Overview,
  Expenses,
  MyAccount,
  MyInstitutions,
  Security,
  Subscription,
  Support,
  Login,
  CreateAccount,
  ResetPassword,
  SetPassword,
  PrivacyPolicy,
  TermsOfService,
  Waitlist,
  WaitlistSuccess,
  WaitlistRemove,
  AdminDashboard,
  VerifyEmail,
  SecurityPolicy,
} from 'pages';

const routes = [
  // Private routes
  {
    path: '/',
    element: <Navigate to="/overview" />,
    authenticate: true,
  },
  {
    path: '/overview',
    element: <Overview />,
    authenticate: true,
  },
  {
    path: '/expenses',
    element: <Expenses />,
    authenticate: true,
  },
  {
    path: '/goals',
    element: <Expenses onGoals={true} />,
    authenticate: true,
  },
  {
    path: '/my-account',
    element: <MyAccount />,
    authenticate: true,
  },
  {
    path: '/my-institutions',
    element: <MyInstitutions />,
    authenticate: true,
  },
  {
    path: '/security',
    element: <Security />,
    authenticate: true,
  },
  {
    path: '/subscription',
    element: <Subscription />,
    authenticate: true,
    adminsOnly: true,
  },
  {
    path: '/support',
    element: <Support />,
    authenticate: true,
  },

  // Private admin routes
  {
    path: '/admin-dashboard',
    element: <AdminDashboard />,
    authenticate: true,
    adminsOnly: true,
  },

  // Public routes
  {
    path: '*',
    element: <NotFound
      theme={theme}
      selectedTheme={appConstants.themes.light}
      icon='fa-solid fa-sad-tear'
      title={<H2>Unga Bunga!  We bungled the request ...</H2>}
      message="We'll try to do better, next time."
    />,
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/create-account',
    element: <CreateAccount />,
  },
  {
    path: '/verify-email',
    element: <VerifyEmail />,
  },
  {
    path: '/reset-password',
    element: <ResetPassword />,
  },
  {
    path: '/set-password',
    element: <SetPassword />,
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicy />,
  },
  {
    path: '/security-policy',
    element: <SecurityPolicy />,
  },
  {
    path: '/terms-of-service',
    element: <TermsOfService />,
  },
  {
    path: '/waitlist',
    element: <Waitlist />,
  },
  {
    path: '/waitlist-success',
    element: <WaitlistSuccess />,
  },
  {
    path: '/waitlist-remove',
    element: <WaitlistRemove />,
  },
];

export { routes };