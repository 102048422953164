import { gsap } from 'gsap';

export const animate = args => {
  const {
    ref,
    direction,
    duration,
    delay,
    transitionOut,
    noFade,
    noSlide,
    offset,
    easeStrength,
    onComplete,
  } = args || {};

  const translateKey = direction === 'left' || direction === 'right' ? 'translateX' : 'translateY';
  const ease = `back.out(${easeStrength || 0})`;
  const defaultInDuration = 0.35;
  const defaultOutDuration = 0.2;
  const defaultDelay = 0;

  const baseOptions = {
    duration: duration || (transitionOut ? defaultOutDuration : defaultInDuration),
    delay: delay || defaultDelay,
    ease,
  };

  const configFrom = {
    ...baseOptions,
    [translateKey]: transitionOut || noSlide ? 0 : offset || 0,
    opacity: transitionOut || noFade ? 1 : 0,
  };

  const configTo = {
    ...baseOptions,
    [translateKey]: transitionOut && !noSlide ? offset || 0 : 0,
    opacity: transitionOut && !noFade ? 0 : 1,
    onComplete,
  };

  gsap.fromTo(ref, configFrom, configTo);
};