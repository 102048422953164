import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { appConstants, institutionsActions } from 'modules';
import { Font, ExpenseIcon, XymPrice } from 'components';
import { StyledTransactionItem, Icon, Amount, Category, TransactionInfo, TransactionDetails } from './styles';
import { TransactionTitle } from './TransactionTitle';
import { TransactionSubTitle } from './TransactionSubTitle';
import { withTheme } from 'styled-components';
import _ from 'lodash';

const { themes } = appConstants;
const { light } = themes;

const TransactionItem = withTheme(props => {
  const { theme, transaction, isLastItem, nextActive } = props;
  const { mobileMode, categories } = useSelector(state => state.app);
  const { slideOverTransactionId } = useSelector(state => state.institutions);
  const { generalPreferences: { privacyMode, selectedTheme } } = useSelector(state => state.preferences);
  const lightTheme = selectedTheme === light;
  const darkGrey = theme.modes[selectedTheme]?.darkGrey;
  const onPrimary = theme.modes[selectedTheme]?.onPrimary;
  const offWhite = theme.modes[selectedTheme]?.offWhite;
  const accent = theme.modes[selectedTheme]?.accent;
  const accentHover = theme.modes[selectedTheme]?.accentHover;
  const tuftsBlue = theme.colors.accent.tuftsBlue;
  const paynesGrey = theme.colors.neutral.paynesGrey;

  const active = slideOverTransactionId === transaction?.metadata?.transactionId;

  const activeTitleColor = lightTheme ? offWhite : darkGrey;
  const inactiveTitleColor = lightTheme ? darkGrey : offWhite;
  const titleColor = active ? activeTitleColor : inactiveTitleColor;
  const activeCreditPriceColor = lightTheme ? accentHover : tuftsBlue;
  const creditPriceColor = active ? activeCreditPriceColor : accent;
  const dispatch = useDispatch();

  const setSlideOverTransactionId = useCallback(payload => {
    dispatch(institutionsActions.setSlideOverTransactionId(payload));
  }, [ dispatch ]);

  const handleTransactionClick = transaction => {
    const { metadata: { transactionId } } = transaction;
    const isDifferent = slideOverTransactionId !== transactionId;

    if (!_.isEmpty(slideOverTransactionId) && isDifferent) {
      setSlideOverTransactionId(transactionId);
      return;
    }

    setSlideOverTransactionId(isDifferent ? transactionId : null);
  };

  const buildTransaction = () => {
    if (!_.isEmpty(transaction)) {
      const { metadata, data } = transaction || {};
      const { autoSpendExpenseId, categoryId } = metadata || {};
      const { name, merchant_name, amount } = data || {};
      const isCredit = amount < 0;
      const isDebit = amount > 0;
      const hasSubTitle = isDebit || (isCredit && autoSpendExpenseId);
      const category = categories?.find(category => category._id === categoryId)?.name || '';

      return (
        <>
          <Icon>
            <ExpenseIcon
              size={mobileMode ? 2.5 : 3}
              fontSize={0.875}
              bgColorLight={active && onPrimary}
              bgColorDark={active && paynesGrey}
              name={merchant_name || name}
              logo={null}
              noMargin={true}
            />
          </Icon>

          <TransactionInfo>
            <TransactionTitle transaction={transaction} active={active} />
            <TransactionSubTitle transaction={transaction} hasSubTitle={hasSubTitle} active={active} />
          </TransactionInfo>

          <TransactionDetails>
            <Amount>
              <Font weight='semibold'>
                <XymPrice
                  theme={theme}
                  selectedTheme={selectedTheme}
                  size={0.9375}
                  mobileSize={0.875}
                  value={amount * -1}
                  useMinus={isDebit}
                  usePlus={isCredit}
                  color={isCredit ? creditPriceColor : titleColor}
                />
              </Font>
            </Amount>

            <Category>
              <Font size={0.6875}>
                {privacyMode ? 'Private' : category}
              </Font>
            </Category>
          </TransactionDetails>
        </>
      );
    }
  };

  return (
    <StyledTransactionItem
      $theme={theme}
      $selectedTheme={selectedTheme}
      $isLastItem={isLastItem}
      $active={active}
      $nextActive={nextActive}
      onClick={() => handleTransactionClick(transaction)}
    >
      {buildTransaction()}
    </StyledTransactionItem>
  );
});

export { TransactionItem };