/* eslint-disable no-unused-vars */
import { actionCreator } from 'helpers';
import { messagesConstants } from 'modules';
import * as api from 'modules/messages/messagesApi';

const messagesActions = {
  getMessages: (payload, callbacks) => {
    const type = messagesConstants.actions.GET_MESSAGES;
    const args = { type, payload, callbacks };
    return api.getMessages(args);
  },

  updateMessage: (payload, callbacks) => {
    const type = messagesConstants.actions.UPDATE_MESSAGE;
    const args = { type, payload, callbacks };
    return api.updateMessage(args);
  },

  markAllMessagesRead: (payload, callbacks) => {
    const type = messagesConstants.actions.MARK_ALL_MESSAGES_READ;
    const args = { type, payload, callbacks };
    return api.markAllMessagesRead(args);
  },

  deleteMessage: (payload, callbacks) => {
    const type = messagesConstants.actions.DELETE_MESSAGE;
    const args = { type, payload, callbacks };
    return api.deleteMessage(args);
  },

  sendSupportMessage: (payload, callbacks) => {
    const type = messagesConstants.actions.SEND_SUPPORT_MESSAGE;
    const args = { type, payload, callbacks };
    return api.sendSupportMessage(args);
  },
};

export { messagesActions };