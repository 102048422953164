import { useSelector } from 'react-redux';
import { Font, XymPrice, XymSpacer } from 'components';
import styled, { withTheme } from 'styled-components';

const StyledBreakdownItem = styled('div')`
  display: inline-flex;
  align-items: center;
`;

const BreakdownItem = withTheme(props => {
  const { theme, value, label, separate, noPlus } = props;
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const lightGrey = theme.modes[selectedTheme]?.lightGrey;

  return (
    <StyledBreakdownItem>
      <Font size={0.875} weight='medium'>
        <XymPrice
          value={value}
          size={0.875}
          useMinus={true}
          usePlus={noPlus !== undefined ? !noPlus : true}
          color={lightGrey}
        />
      </Font>

      <XymSpacer size={0.4} across={true} />

      <Font size={0.875} weight='medium' color={lightGrey + 75}>
        {label}
      </Font>

      {separate && (
        <>
          <XymSpacer size={0.95} across={true} />
          <Font size={0.45}>
            <i className='fa-solid fa-circle' />
          </Font>
          <XymSpacer size={0.95} across={true} />
        </>
      )}
    </StyledBreakdownItem>
  );
});

export { BreakdownItem };