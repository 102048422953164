import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { appConstants, appActions, expensesActions } from 'modules';
import { Font, Add, XymTabs } from 'components';
import { autoSpendTabs } from 'pages/Expenses/localControllers/autoSpendTabs';
import { Tag } from 'xerum';
import { withTheme } from 'styled-components';
import { flags } from 'utility';

const { light } = appConstants.themes;

const AddAutoSpendTag = withTheme(props => {
  const { theme, expenseId, setAutoSpendConfirmContent } = props;
  const { categories, merchants } = useSelector(state => state.app);
  const { generalPreferences: { privacyMode, selectedTheme } } = useSelector(state => state.preferences);
  const { expensesData } = useSelector(state => state.expenses);
  const { tokenInfo } = useSelector(state => state.auth);
  const { selectedAccountId } = useSelector(state => state.institutions);
  const { features: { autoSpendMerchants } } = flags;

  const expense = expensesData?.find(expense => expense._id === expenseId);
  const token = tokenInfo?.refreshToken || tokenInfo?.accessToken;
  const lightTheme = selectedTheme === light;
  const frenchGrey = theme.colors.neutral.frenchGrey;
  const paynesGrey = theme.colors.neutral.paynesGrey;
  const secondary = theme.modes[selectedTheme]?.secondary;
  const grey = theme.modes[selectedTheme]?.grey;
  const lightGrey = theme.modes[selectedTheme]?.lightGrey;
  const dispatch = useDispatch();

  const updateExpense = useCallback((payload, callbacks) => {
    dispatch(expensesActions.updateExpense(payload, callbacks));
  }, [ dispatch ]);

  const addNotification = useCallback(payload => {
    dispatch(appActions.addNotification(payload));
  }, [ dispatch ]);

  const handleUpdateExpense = newTag => {
    const tagExists = expense?.autoSpendTags?.find(tag => tag._id === `${newTag._id}`);
    const callbacks = {
      onSuccess: () => setAutoSpendConfirmContent(null),
    };

    const payload = {
      id: expense?._id,
      linkedAccountId: selectedAccountId,
      useDailyAutoFundCalculations: expense?.useDailyAutoFundCalculations,
      autoSpendTags: [ ...expense?.autoSpendTags || [], newTag ],
      meta: { noLoadingState: true },
      token,
    };

    if (tagExists) {
      addNotification('Auto-spend tag already exists.');
      return;
    }

    updateExpense(payload, callbacks);
  };

  const addAutoSpend = e => {
    e.stopPropagation();

    const tabArgs = {
      merchants,
      categories,
      privacyMode,
      setConfirmContent: setAutoSpendConfirmContent,
      autoSpendMerchants,
      handleUpdateExpense,
    };

    const payload = <XymTabs visible={autoSpendMerchants} content={autoSpendTabs(tabArgs)} />;
    setAutoSpendConfirmContent(payload);
  };

  return (
    <Font weight='semibold'>
      <Tag
        text={<Font weight='semibold' size={0.875} mobileSize={0.75}>Auto-spend</Font>}
        textSize={0.875}
        verticalPadding={0.4375}
        horizontalPadding={1}
        removable={true}
        textColor={lightTheme ? paynesGrey : frenchGrey}
        bgColor={secondary}
        borderSize={0.0625}
        borderRadius={1}
        borderColor={lightTheme ? lightGrey : lightGrey + 50}
        closeIcon={
          <Add
            color={lightTheme ? grey : lightGrey}
            bgColor={lightTheme ? lightGrey : lightGrey + 50}
          />
        }
        closeColor={lightTheme ? frenchGrey : paynesGrey}
        iconLeft={true}
        allClick={true}
        callback={addAutoSpend}
      />
    </Font>
  );
});

export { AddAutoSpendTag };