import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { securityActions } from 'modules';
import { Font, XymField, XymConfirmButtons } from 'components';
import { Formik, Form } from 'formik';
import * as yup from 'yup';

const UpdateTrustedDeviceForm = props => {
  const { device, setConfirmContent } = props;
  const { generalPreferences: { privacyMode } } = useSelector(state => state.preferences);
  const { tokenInfo } = useSelector(state => state.auth);
  const { trustedDevices } = useSelector(state => state.security);
  const token = tokenInfo?.refreshToken || tokenInfo?.accessToken;
  const dispatch = useDispatch();

  const updateTrustedDevice = useCallback((payload, callbacks) => {
    dispatch(securityActions.updateTrustedDevice(payload, callbacks));
  }, [ dispatch ]);

  const defaults = { deviceName: device?.name || '' };
  const validationSchema = yup.object().shape({
    deviceName: yup.string()
      .required('A new name is required.')
      .notOneOf([ device?.name ], "This is already the device's name.")
      .notOneOf([ ...trustedDevices?.map(device => device.name) || [] ], 'This name is already in use.')
      .trim(),
  });

  const handleSubmit = args => {
    const { values, setSubmitting } = args;
    const { deviceName } = values;

    const payload = { token, id: device?._id, name: deviceName };
    const callbacks = {
      onSuccess: () => setConfirmContent(null),
      onComplete: () => setSubmitting(false),
    };

    updateTrustedDevice(payload, callbacks);
  };

  return (
    <Formik
      initialValues={defaults}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={(values, handlers) => {
        const { setSubmitting } = handlers;
        handleSubmit({ values, setSubmitting });
      }}
    >
      {form => (
        <Form>
          <XymField
            form={form}
            name='deviceName'
            type='text'
            placeholder='Type your new device name here...'
            label={<Font size={0.875} weight='semibold'>New device name</Font>}
            privacy={privacyMode}
            spacing={1.5}
          />

          <XymConfirmButtons
            form={form}
            confirmText='Change name'
            setConfirmContent={setConfirmContent}
          />
        </Form>
      )}
    </Formik>
  );
};

export { UpdateTrustedDeviceForm };