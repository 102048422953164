import { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { stripeActions } from 'modules';
import { Font, XymConfirm, XymSpacer } from 'components';
import { StyledPaymentActions } from './styles';
import { RemovePaymentConfirm } from './RemovePaymentConfirm';
import { withTheme } from 'styled-components';

const PaymentActions = withTheme(props => {
  const { theme, method, hideActions } = props;
  const { id, defaultPayment } = method;

  const [ confirmContent, setConfirmContent ] = useState(null);
  const { tokenInfo } = useSelector(state => state.auth);
  const { generalPreferences: { privacyMode, selectedTheme } } = useSelector(state => state.preferences);
  const error = theme.modes[selectedTheme]?.error;
  const errorHover = theme.modes[selectedTheme]?.errorHover;
  const dispatch = useDispatch();

  const updatePaymentMethod = useCallback((payload, callbacks) => {
    dispatch(stripeActions.updatePaymentMethod(payload, callbacks));
  }, [ dispatch ]);

  const removePaymentMethod = useCallback((payload, callbacks) => {
    dispatch(stripeActions.removePaymentMethod(payload, callbacks));
  }, [ dispatch ]);

  const handleSetDefault = () => {
    const payload = {
      id,
      token: tokenInfo?.accessToken,
      defaultPayment: true,
    };

    updatePaymentMethod(payload);
  };

  const handleRemove = () => {
    const payload = <RemovePaymentConfirm method={method} />;
    setConfirmContent(payload);
  };

  return (
    <StyledPaymentActions>
      {!hideActions && (
        <>
          {!privacyMode && !defaultPayment && (
            <Font size={0.875} weight='semibold' onClick={handleSetDefault}>
              <Link to='#'>Set default</Link>
            </Font>
          )}

          <XymSpacer across={true} />

          <Font size={0.875} weight='semibold' onClick={handleRemove}>
            <Link to='#'>Remove</Link>
          </Font>

          <XymSpacer across={true} />
        </>
      )}

      <XymConfirm
        confirmContent={confirmContent}
        confirmButtonColor={error}
        confirmButtonHoverColor={errorHover}
        confirmText='Remove payment'
        onClose={() => setConfirmContent(null)}
        onConfirm={() => {
          const payload = { id, token: tokenInfo?.accessToken };
          const callbacks = { onSuccess: () => setConfirmContent(null) };
          removePaymentMethod(payload, callbacks);
        }}
      />
    </StyledPaymentActions>
  );
});

export { PaymentActions };