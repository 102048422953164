import { useMemo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { appConstants } from 'modules';
import { Font, NoData, XymSpacer } from 'components';
import { StyledPaymentHistory } from './styles';
import { Table, PrivacyMask } from 'xerum';
import { withTheme } from 'styled-components';
import moment from 'moment';
import _ from 'lodash';

const { breakpoints, themes } = appConstants;
const { tablet } = breakpoints;
const { light } = themes;

const PaymentHistory = withTheme(props => {
  const { theme } = props;
  const { bannerContent } = useSelector(state => state.app);
  const { paymentData, paymentDataLoading } = useSelector(state => state.stripe);
  const { generalPreferences: { privacyMode, selectedTheme } } = useSelector(state => state.preferences);
  const darkGrey = theme.modes[selectedTheme]?.darkGrey;
  const offWhite = theme.modes[selectedTheme]?.offWhite;
  const lightTheme = selectedTheme === light;
  const titleColor = lightTheme ? darkGrey : offWhite;
  const paymentHistory = useMemo(() => paymentData?.paymentHistory, [ paymentData ]);

  const rowContent = useMemo(() => (
    paymentHistory?.map(item => {
      const { product, invoiceId, pricePaid, date, paymentId } = item;
      const paymentMethod = paymentData?.methods?.find(method => method.id === paymentId);
      const frequencyName = _.capitalize(product.frequency?.name);

      return {
        td1: product?.name ? `${product.name} (${frequencyName})` : 'Entry missing',
        td2: privacyMode ? <PrivacyMask /> : invoiceId,
        td3: pricePaid && `$${pricePaid}`,
        td4: privacyMode ? <PrivacyMask /> : date && moment(date).format('MMMM DD, YYYY'),
        td5: paymentMethod && `${_.startCase(paymentMethod?.cardType)} ... ${paymentMethod?.cardNumber?.slice(-4)}`,
      };
    })
  ), [ privacyMode, paymentHistory, paymentData ]);

  const defaultContent = {
    headers: [
      <Font key='product' weight='semibold'>Product</Font>,
      <Font key='invoiceNumber' weight='semibold'>Invoice #</Font>,
      <Font key='amount' weight='semibold'>Amount</Font>,
      <Font key='date' weight='semibold'>Date</Font>,
      <Font key='paymentMethod' weight='semibold'>Method</Font>,
  ],
    rows: rowContent || [],
  };

  const [ content, setContent ] = useState(defaultContent);

  useEffect(() => {
    if (!_.isEmpty(paymentHistory) && !_.isEqual(content.rows, rowContent)) {
      setContent({ ...content, rows: rowContent });
    }
  }, [ paymentHistory, content, rowContent, privacyMode ]);

  const buildSessionTable = () => {
    if (paymentDataLoading) {
      return <NoData text='Loading payment history...' />;
    }

    if (!_.isEmpty(paymentHistory)) {
      const receivedArray = sortedArray => setContent({ ...content, rows: sortedArray });

      return (
        <Table
          theme={theme}
          selectedTheme={selectedTheme}
          content={content}
          sortable={receivedArray}
          mobileSize={tablet}
          columnLayout='1fr 9.25rem repeat(2, 8.625rem) 9rem'
        />
      );
    }

    return (
      <NoData
        icon='fa-solid fa-table'
        text='No payment history'
        subText='Subscription invoices will appear here.'
      />
    );
  };

  return (
    <StyledPaymentHistory $theme={theme} $selectedTheme={selectedTheme} $bannerContent={bannerContent}>
      <Font weight='semibold' size={1.25} mobileSize={1} tabletSize={1} color={titleColor}>
        Payment History
      </Font>

      <XymSpacer size={1.375} />
      {buildSessionTable()}
    </StyledPaymentHistory>
  );
});

export { PaymentHistory };