
const authConstants = {
  actions: {
    GET_NEW_TOKENS: 'modules/auth/GET_NEW_TOKENS',
    VERIFY_TOKEN: 'modules/auth/VERIFY_TOKEN',
    VERIFY_EMAIL: 'modules/auth/VERIFY_EMAIL',
    INVALIDATE_TOKEN: 'modules/auth/INVALIDATE_TOKEN',
    CREATE_USER: 'modules/auth/CREATE_USER',
    UPDATE_PASSWORD: 'modules/auth/UPDATE_PASSWORD',
    DELETE_USER: 'modules/auth/DELETE_USER',
    SEND_VERIFICATION_EMAIL: 'modules/auth/SEND_VERIFICATION_EMAIL',
    SEND_PASSWORD_RESET_EMAIL: 'modules/auth/SEND_PASSWORD_RESET_EMAIL',
    LOG_IN: 'modules/auth/LOG_IN',
    GET_USER_INFO: 'modules/auth/GET_USER_INFO',
    ADD_USER_TO_WAITLIST: 'modules/auth/ADD_USER_TO_WAITLIST',
    REMOVE_USER_FROM_WAITLIST: 'modules/auth/REMOVE_USER_FROM_WAITLIST',
    GET_USER_STATISTICS: 'modules/auth/GET_USER_STATISTICS',
  },

  selectors: {
    STATE_KEY_TOKEN_INFO: 'tokenInfo',
    STATE_KEY_TOKEN_INFO_LOADING: 'tokenInfoLoading',
    STATE_KEY_USER_INFO: 'userInfo',
    STATE_KEY_USER_INFO_LOADING: 'userInfoLoading',
    STATE_KEY_INVALIDATING_TOKENS: 'invalidatingTokens',
    STATE_KEY_USER_STATISTICS_DATA: 'userStatisticsData',
  },

  roles: {
    user: 'user',
    support: 'support',
    developer: 'developer',
    admin: 'admin',
  },
};

export { authConstants };