import { H3, P, Center, XymSpacer } from 'components';
import { AutoFunding, FullWidth } from './styles';

const {
  VITE_GCP_STORAGE_BUCKET: storageBucket,
} = process.env;

const AboutAutoFunding = () => {
  const src = `${storageBucket}/Waitlist/Add-Auto-Fund-Source.png`;

  return (
    <AutoFunding>
      <Center>
        <img src={src} id='addAutoFundSource' alt='Move money preview.' />
      </Center>

      <FullWidth>
        <H3>Never miss a payment with auto-funding</H3>
        <XymSpacer />
        <P>
          Assign an income stream to your Expense to automatically fund it.
          Automatic funding will prioritize funding expenses by due date,
          so you&apos;ll always have the expenses due first, funded on time.
        </P>
      </FullWidth>
    </AutoFunding>
  );
};

export { AboutAutoFunding };