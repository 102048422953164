let animationFrameId;

const verticalLinePlugin = {
  id: 'verticalLine',
  beforeEvent(chart, args) {
    const event = args.event.native;

    if (!event) return;

    if (event.type === 'mousemove' || event.type === 'touchmove') {
      const canvasPosition = {
        x: event.offsetX,
        y: event.offsetY,
      };

      const mousePosition = {
        x: canvasPosition.x,
        y: canvasPosition.y,
      };

      chart.config.options.plugins.verticalLinePlugin.mousePosition = mousePosition;

      const xScale = chart.scales.x;
      const index = xScale.getValueForPixel(mousePosition.x);
      const activeElements = [];

      chart.data.datasets.forEach((dataset, datasetIndex) => {
        const hasValue = dataset.data[index] !== undefined;
        if (hasValue) activeElements.push({ datasetIndex, index });
      });

      const chartActiveTooltipCount = chart.tooltip._active.length;
      const activeElementsCount = activeElements.length;
      const lengthMismatch = chartActiveTooltipCount !== activeElementsCount;

      const activeElementsMatch = chart.tooltip._active.every((element, idx) => {
        const indexMatch = element.index === activeElements[idx]?.index;
        const datasetIndexMatch = element.datasetIndex === activeElements[idx]?.datasetIndex;
        return indexMatch && datasetIndexMatch;
      });

      const activeElementsChanged = lengthMismatch || !activeElementsMatch;

      if (activeElementsChanged) {
        chart.tooltip.setActiveElements(activeElements);
        chart.tooltip.update(true);
      }

      if (animationFrameId) cancelAnimationFrame(animationFrameId);
      animationFrameId = requestAnimationFrame(() => chart.draw());

      return;
    }

    if (event.type === 'mouseout') {
      chart.config.options.plugins.verticalLinePlugin.mousePosition = null;
      chart.tooltip.setActiveElements([]);
      chart.tooltip.update(true);

      if (animationFrameId) cancelAnimationFrame(animationFrameId);
      animationFrameId = requestAnimationFrame(() => chart.draw());
    }
  },

  afterDraw(chart) {
    const { mousePosition } = chart.config.options.plugins.verticalLinePlugin || {};

    if (!mousePosition) return;

    const ctx = chart.ctx;
    const x = mousePosition.x;
    const topY = chart.scales.y.top;
    const bottomY = chart.scales.y.bottom;

    ctx.save();
    ctx.beginPath();
    ctx.moveTo(x, topY);
    ctx.lineTo(x, bottomY);
    ctx.lineWidth = 1;
    ctx.strokeStyle = 'rgba(85, 102, 115, 0.5)';
    ctx.stroke();
    ctx.restore();
  },
};

export { verticalLinePlugin };
