import { appConstants } from 'modules';
import styled, { css } from 'styled-components';

const { layout, spacing, breakpoints } = appConstants;
const { layoutWidth } = layout;
const { extraLargeGap, mediumGap, gap } = spacing;
const { mobile } = breakpoints;

export const StyledOverview = styled('div')`
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  ${props => !props.$hasInstitutions && css`padding: 1rem;`}
`;

export const Layout = styled('div')`
  display: ${props => props.$hasInstitutions ? 'grid' : 'flex'};
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: ${props => `${props.$bannerContent ? '0.7fr 1fr' : 'repeat(2, 1fr)'}`};
  gap: ${gap}rem ${extraLargeGap}rem;
  grid-template-areas:
    'activity trends trends upcoming'
    'activity analysis analysis upcoming';

  max-width: ${layoutWidth}rem;
  width: 100%;
  padding: ${mediumGap}rem ${extraLargeGap}rem;
  overflow-y: auto;

  @media screen and (max-width: ${layoutWidth}rem) {
    display: none;
  }
`;

export const MobileLayout = styled('div')`
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
  padding: ${mediumGap}rem;
  overflow-y: auto;

  @media screen and (max-width: ${layoutWidth}rem) {
    display: flex;
    padding: ${mediumGap}rem;
  }

  @media screen and (max-width: ${mobile}rem) {
    padding: ${gap}rem;
    width: 100dvw;
  }
`;

export const StyledIncomeInUseConfirm = styled('div')`
  max-width: 27rem;
`;

export const RemovedItem = styled('div')`
  display: flex;
`;