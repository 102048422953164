import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  appConstants,
  userMenuRoutes,
  mainRoutes,
  adminRoutes,
  appActions,
  authActions,
  rootActions,
  institutionsActions,
  expensesActions,
  authConstants,
} from 'modules';
import { animate } from 'helpers';
import { PrivacyPolicy, SecurityPolicy, TermsOfService } from 'pages';
import { H4, Font, HR, XymSpacer, UserIcon, Chevron } from 'components';
import { Copyright, PrivacyMask } from 'xerum';
import { MenuItem, Nav, NavHolder } from 'components/General/SettingsNav';
import { withTheme } from 'styled-components';
import { flags } from 'utility';
import {
  StyledMobileNav,
  UserName,
  FooterNav,
  FooterInfo,
  CloseHolder,
  Background,
  UserNameArea,
  Accounts,
  Account,
} from './mobileUserMenuStyles';
import PackageJSON from '../../../../../package.json';
import _ from 'lodash';

const { version } = PackageJSON;
const { themes, names } = appConstants;
const { appName, accessTokenKeyName } = names;
const { light } = themes;

const MobileUserMenu = withTheme(props => {
  const { theme, visible, closeMobileUserMenu } = props;
  const [ readyToClose, setReadyToClose ] = useState(false);
  const { mobileMode, tabletMode } = useSelector(state => state.app);
  const { userInfo } = useSelector(state => state.auth);
  const { institutionsData, selectedAccountId } = useSelector(state => state.institutions);

  const {
    generalPreferences: { selectedTheme },
  } = useSelector(state => state.preferences);

  const { pathname } = useLocation();
  const { features: { subscriptionSettings } } = flags;

  const allAccounts = institutionsData?.reduce((previous, current) => {
    const { accounts } = current;
    return [ ...previous, ...accounts ];
  }, []);

  const lightTheme = selectedTheme === light;
  const frenchGrey = theme.colors.neutral.frenchGrey;
  const copyrightColor = lightTheme ? frenchGrey : frenchGrey + 50;
  const darkGrey = theme.modes[selectedTheme]?.darkGrey;
  const offWhite = theme.modes[selectedTheme]?.offWhite;
  const titleColor = lightTheme ? darkGrey : offWhite;
  const desktopMode = !mobileMode && !tabletMode;
  const privacyPolicy = <PrivacyPolicy inModal={true} />;
  const securityPolicy = <SecurityPolicy inModal={true} />;
  const termsOfService = <TermsOfService inModal={true} />;
  const existingSettings = JSON.parse(localStorage.getItem(appName));
  const accessToken = existingSettings?.[accessTokenKeyName];
  const email = userInfo?.email;
  const isAdmin = userInfo?.roles?.includes(authConstants.roles.admin);
  const userNavRef = useRef();
  const bgRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const setModalContent = useCallback(payload => dispatch(appActions.setModalContent(payload)), [ dispatch ]);
  const logout = useCallback(() => dispatch(rootActions.logout()), [ dispatch ]);

  const invalidateToken = useCallback((payload, callbacks) => {
    dispatch(authActions.invalidateToken(payload, callbacks));
  }, [ dispatch ]);

  const clearTransactionsData = useCallback(payload => {
    dispatch(institutionsActions.clearTransactionsData(payload));
  }, [ dispatch ]);

  const clearAnalysisData = useCallback(payload => dispatch(expensesActions.clearAnalysisData(payload)), [ dispatch ]);

  const setSelectedAccountId = useCallback(payload => {
    dispatch(institutionsActions.setSelectedAccountId(payload));
  }, [ dispatch ]);

  const handleAnimationIn = useCallback(() => {
    animate({ ref: userNavRef.current, offset: -400, noFade: true, direction: 'right' });
  }, []);

  const handleBGAnimationIn = useCallback(() => {
    animate({ ref: bgRef.current, noSlide: true });
  }, []);

  const handleAnimationOut = useCallback(() => {
    animate({
      ref: userNavRef.current,
      offset: -400,
      direction: 'right',
      noFade: true,
      transitionOut: true,
      onComplete: () => closeMobileUserMenu(!visible),
    });
  }, [ visible, closeMobileUserMenu ]);

  const handleBGAnimationOut = useCallback(() => {
    animate({ ref: bgRef.current, transitionOut: true, noSlide: true });
  }, []);

  useEffect(() => {
    if (visible) {
      handleBGAnimationIn();
      handleAnimationIn();
    }
  }, [ visible, handleBGAnimationIn, handleAnimationIn ]);

  useEffect(() => {
    const handleOutsideClick = e => {
      const outsideClick = !userNavRef.current?.contains(e.target);
      if (outsideClick) {
        handleBGAnimationOut();
        handleAnimationOut();
      }
    };

    if (visible) setReadyToClose(true);

    if (visible && readyToClose) {
      document.addEventListener('click', handleOutsideClick);

      return () => {
        document.removeEventListener('click', handleOutsideClick);
        setReadyToClose(false);
      };
    }
  }, [ visible, readyToClose, handleBGAnimationOut, handleAnimationOut, setReadyToClose ]);

  const buildNav = adminNav => {
    const links = [ ...(mobileMode && mainRoutes) || [], ...userMenuRoutes, ...(adminNav && adminRoutes) || [] ];
    const filtered = links.filter(route => {
      if (!subscriptionSettings) {
        const validRoute = route !== 'subscription';
        return validRoute;
      }

      return route;
    });

    return filtered.map((route, index) => {
      const formatted = _.startCase(route);
      const title = _.capitalize(_.toLower(formatted));
      const visible = index !== filtered.length - 1;
      const active = pathname.includes(route);

      return (
        <Fragment key={route}>
          <MenuItem
            $theme={theme}
            $selectedTheme={selectedTheme}
            $active={active}
            $useMobilePadding={true}
            onClick={() => {
              if (!active) navigate(`/${route}`);
              closeMobileUserMenu();
            }}
          >
            <Font mobileSize={0.875} weight='semibold'>
              {title}
            </Font>
          </MenuItem>

          <HR height={0.125} visible={visible} />
        </Fragment>
      );
    });
  };

  const handleLogout = () => {
    const payload = { accessToken };
    const callbacks = {
      onSuccess: () => logout?.(),
    };

    invalidateToken(payload, callbacks);
  };

  const buildFooterNav = () => {
    const links = [
      { title: 'Log out', action: handleLogout, visible: true },
      { title: 'Privacy policy', content: privacyPolicy, visible: !desktopMode },
      { title: 'Security policy', content: securityPolicy, visible: !desktopMode },
      { title: 'Terms of Service', content: termsOfService, visible: !desktopMode },
    ];

    return links.map((item, index) => {
      const { title, content, action, visible } = item;

      if (visible) {
        return (
          <Fragment key={index}>
            <MenuItem
              $theme={theme}
              $selectedTheme={selectedTheme}
              $useFooterPadding={true}
              $noLeftPadding={true}
              onClick={() => {
                if (action) {
                  action();
                  closeMobileUserMenu();
                  return;
                }

                setModalContent({ title: <H4>{title}</H4>, content });
                closeMobileUserMenu();
              }}
            >
              <Font mobileSize={0.875} weight='semibold'>
                {title}
              </Font>
            </MenuItem>
          </Fragment>
        );
      }
    });
  };

  const buildAccounts = () => {
    if (!_.isEmpty(allAccounts)) {
      return allAccounts.map((account, index) => {
        const { name, mask, account_id } = account;
        const active = account_id === selectedAccountId;

        return (
          <Account
            key={index}
            $theme={theme}
            $selectedTheme={selectedTheme}
            $active={active}
            onClick={() => {
              if (selectedAccountId !== account_id) {
                clearAnalysisData(null);
                clearTransactionsData(null);
                setSelectedAccountId(account_id);
              }
            }}
          >
            <Font size={0.75} weight='semibold'>{name}</Font>
            <Font size={0.75}><PrivacyMask length={4} /> ... {mask}</Font>
          </Account>
        );
      });
    }
  };

  return (
    <>
      <StyledMobileNav
        ref={userNavRef}
        $theme={theme}
        $selectedTheme={selectedTheme}
        $visible={visible}
        $mobileMode={mobileMode}
        $compensateTop={!desktopMode}
      >
        <NavHolder>
          <Nav>
            <UserNameArea>
              <UserName>
                <Font mobileSize={0.875} weight='semibold' color={titleColor}>
                  <UserIcon />
                  <XymSpacer size={0.4375} across={true} />
                  {email}
                </Font>
              </UserName>

              <CloseHolder onClick={() => {
                handleBGAnimationOut();
                handleAnimationOut();
              }}>
                <Chevron direction='left' width={1} height={1} />
              </CloseHolder>
            </UserNameArea>


            {buildNav(isAdmin)}

            <XymSpacer size={2.5} />

            <Accounts $visible={allAccounts?.length > 1}>
              {buildAccounts()}
              <XymSpacer />
            </Accounts>
          </Nav>

          <FooterNav>
            <XymSpacer />
            {buildFooterNav()}
            {!desktopMode && <XymSpacer />}

            <FooterInfo $visible={!desktopMode}>
              <Font size={0.875} color={copyrightColor}>
                <Copyright name={`${appName} Software™, LLC`} />
              </Font>

              <Font size={0.875} color={copyrightColor}>
                v{version}
              </Font>
            </FooterInfo>
          </FooterNav>
        </NavHolder>
      </StyledMobileNav>

      <Background
        ref={bgRef}
        $theme={theme}
        $selectedTheme={selectedTheme}
        $visible={visible}
      />
    </>
  );
});

export { MobileUserMenu };