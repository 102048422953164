/* eslint-disable max-len */
import { useSelector } from 'react-redux';
import { appConstants } from 'modules';
import { H1, H4, Font, XymSpacer } from 'components';
import styled, { withTheme } from 'styled-components';

const { themes, spacing } = appConstants;
const { largeGap } = spacing;
const { light } = themes;

const StyledWaitlistSuccess = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100dvh;
`;

const SuccessArea = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: fit-content;
  width: fit-content;
  padding: ${largeGap}rem;
  border-radius: 0.5rem;

  #emoji {
    // Keyframe animation to slow move emoji up and down
    animation: bounce 2s infinite;
    animation-timing-function: ease-in-out;

    @keyframes bounce {
      0% { transform: translateY(0); }
      50% { transform: translateY(-0.5rem); }
      100% { transform: translateY(0); }
    }
  }

  h3 {
    text-align: center;
  }
`;

const TwemojiPartyingFace = props => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='6rem' height='6rem' viewBox='0 0 36 36' {...props}>
      <circle cx='17' cy='19' r='17' fill='#FFCC4D' />
      <ellipse cx='17.999' cy='26' fill='#664500' rx='2' ry='2.5' />
      <path fill='#664500' d='M8.111 21.383a1 1 0 0 1-.845-1.533c.916-1.453 3.701-3.938 7.69-2.962a1 1 0 0 1-.476 1.942c-3.604-.882-5.502 2.056-5.521 2.086a1.001 1.001 0 0 1-.848.467zm11.973-3.742a1 1 0 0 1-.634-1.774c3.176-2.604 6.762-1.562 8.215-.646a1 1 0 0 1-1.064 1.694c-.138-.084-3.052-1.823-5.884.499a.992.992 0 0 1-.633.227z' />
      <path fill='#E2A62D' d='M13.346 31.273a.75.75 0 0 1-.722-.954c.437-1.54.258-3.029-.49-4.086c-.497-.702-1.205-1.131-1.943-1.178c-.414-.025-.728-.382-.702-.795s.381-.751.795-.701c1.193.074 2.313.733 3.073 1.807c1.011 1.429 1.27 3.383.709 5.361a.749.749 0 0 1-.72.546zm11.037-3.061a.744.744 0 0 1-.412-.124c-1.167-.77-1.82-2.117-1.792-3.695c.029-1.635.809-3.153 1.984-3.869a.748.748 0 0 1 1.03.251a.748.748 0 0 1-.251 1.03c-.735.448-1.244 1.499-1.264 2.614c-.02 1.055.389 1.936 1.118 2.417a.75.75 0 0 1-.413 1.376z' />
      <path fill='#DD2E44' d='M17.179 2.72a.325.325 0 0 0-.189-.091S1.066-.394.377.214C-.311.823.74 16.998.74 16.998c.005.081.023.15.067.199c.604.684 4.758-2.004 9.279-6.001c4.522-3.998 7.697-7.792 7.093-8.476z' />
      <path fill='#EA596E' d='M.349.271a.486.486 0 0 0-.038.123C.47 1.765 2.006 13.046 2.963 16.572c1.436-.803 2.895-1.894 4.609-3.253C6.116 10.654 1.158.146.349.271z' />
      <path fill='#3B88C3' d='m29.902 29.229l-10.573-1.303c-1.13-.102-3.117-.112-3.015-1.902c.093-1.623 2.04-1.373 3.479-1.16l10.638 1.774l-.529 2.591z' />
      <path fill='#88C9F9' d='m30.43 26.639l-4.222-.724c-.494-.089-.934.647-.956 1.426c-.025.866.227 1.304.726 1.406l4.144.512l.308-2.62z' />
      <path fill='#3B88C3' d='m34.918 26.341l-2.622 2.411l-4.687-5.097l2.622-2.411a3.372 3.372 0 0 1 4.751.199l.135.147a3.373 3.373 0 0 1-.199 4.751z' />
      <ellipse cx='29.952' cy='26.203' fill='#88C9F9' rx='2.77' ry='3.462' transform='rotate(-42.597 29.954 26.205)' />
      <ellipse cx='29.952' cy='26.203' fill='#269' rx='1.385' ry='2.077' transform='rotate(-42.597 29.954 26.205)' />
      <circle cx='2.5' cy='33.5' r='1.5' fill='#55ACEE' />
      <circle cx='29' cy='2' r='2' fill='#55ACEE' />
      <path fill='#EA596E' d='M4.864 29.246L2.526 23.63L.412 29.675zM26 5l-4 1l1-4z' />
      <path fill='#77B255' d='M31.999 13L36 7.999L33 6z' />
    </svg>
  );
};

const WaitlistSuccess = withTheme(props => {
  const { theme } = props;
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const lightTheme = selectedTheme === light;
  const offWhite = theme.modes[selectedTheme]?.offWhite;
  const darkGrey = theme.modes[selectedTheme]?.darkGrey;
  const accent = theme.modes[selectedTheme]?.accent;
  const titleColor = lightTheme ? darkGrey : offWhite;

  return (
    <StyledWaitlistSuccess>
      <SuccessArea $theme={theme} $selectedTheme={selectedTheme}>
        <TwemojiPartyingFace id='emoji' />

        <XymSpacer />

        <H1 color={accent}>You did it!</H1>

        <XymSpacer size={0.5} />

        <H4 color={titleColor}>
          You&apos;re on the waitlist.
        </H4>

        <Font>
          We&apos;ll let you know when things are ready.
        </Font>
      </SuccessArea>
    </StyledWaitlistSuccess>
  );
});

export { WaitlistSuccess };