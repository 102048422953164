import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { securityActions, authActions } from 'modules';
import { Font, HR, XymConfirm, XymSpacer, UpdateTrustedDeviceForm, XymTooltip } from 'components';
import { PrivacyMask } from 'xerum';
import { StyledDevice, DeviceName, DeviceActions } from './styles';
import { RemoveDeviceConfirm } from './RemoveDeviceConfirm';
import { DeviceInfoItem } from './DeviceInfoItem';
import { withTheme } from 'styled-components';
import moment from 'moment';
import _ from 'lodash';

const SecurityDevice = withTheme(props => {
  const { theme, device, hideActions, lastItem, hideHR, xymDeviceType, privacy, inModal } = props;
  const {
    _id, name: deviceName,
    ipAddress,
    lastUsed,
    platform,
    city,
    region,
    country,
    isMobile,
    isTablet,
    isDesktop,
    linkedAccessToken,
    linkedRefreshToken,
  } = device;
  const [ renameConfirmContent, setRenameConfirmContent ] = useState(null);
  const [ removeConfirmContent, setRemoveConfirmContent ] = useState(null);
  const { mobileMode } = useSelector(state => state.app);
  const { tokenInfo } = useSelector(state => state.auth);
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const token = tokenInfo?.refreshToken || tokenInfo?.accessToken;
  const error = theme.modes[selectedTheme]?.error;
  const errorHover = theme.modes[selectedTheme]?.errorHover;
  const currentDevice = linkedRefreshToken === token || linkedAccessToken === token;
  const dispatch = useDispatch();

  const removeTrustedDevice = useCallback((payload, callbacks) => {
    dispatch(securityActions.removeTrustedDevice(payload, callbacks));
  }, [ dispatch ]);

  const getUserInfo = useCallback((payload, callbacks) => {
    dispatch(authActions.getUserInfo(payload, callbacks));
  }, [ dispatch ]);

  const handleRename = () => {
    const payload = <UpdateTrustedDeviceForm device={device} setConfirmContent={setRenameConfirmContent} />;
    setRenameConfirmContent(payload);
  };

  const handleRemove = () => {
    const payload = (
      <RemoveDeviceConfirm
        privacy={privacy}
        device={device}
        xymDeviceType={xymDeviceType}
        setConfirmContent={setRemoveConfirmContent} />
    );

    setRemoveConfirmContent(payload);
  };

  const getIcon = () => {
    switch(xymDeviceType) {
      case 'authenticator':
        return 'lock';

      case 'device':
        if (isMobile) return 'mobile-screen';
        if (isDesktop) return 'desktop';
        if (isTablet) return 'tablet-screen-button';
        return 'keyboard';

      default:
        return 'key';
    }
  };

  const lastUsedValue = () => {
    const today = moment().format('MMM Do, YYYY');
    const yesterday = moment().subtract(1, 'days').format('MMM Do, YYYY');
    const lastKnownDate = moment(lastUsed).format('MMM Do, YYYY');
    const timestamp = moment(lastUsed).format('hh:mm:ss A');
    const usedToday = lastKnownDate === today && `Today @ ${timestamp}`;
    const usedYesterday = lastKnownDate === yesterday && `Yesterday @ ${timestamp}`;

    return usedToday || usedYesterday || moment(lastUsed).format('MMM DD, YYYY @ hh:mm:ss A');
  };

  return (
    <StyledDevice>
      <XymSpacer size={0.875} />

      <DeviceName
        $theme={theme}
        $selectedTheme={selectedTheme}
        $trustedDevice={xymDeviceType === 'device' && !inModal}
        $active={currentDevice}
      >
        <Font weight='semibold' size={inModal ? 1 : 0.875}>
          <XymSpacer across={true} />
          <i className={`fa-solid fa-${getIcon()}`} />
          <XymSpacer across={true} size={0.5} />

          {privacy ? <PrivacyMask /> : deviceName}
          {!privacy && xymDeviceType === 'device' && !inModal && (
            <XymTooltip position={mobileMode ? 'bottom' : 'right'}>
              <DeviceInfoItem title='Name' value={deviceName} />
              <DeviceInfoItem title='Last used' value={lastUsedValue()} />
              <DeviceInfoItem title='Platform' value={platform} />
              {!_.isEmpty(city) && <DeviceInfoItem title='City' value={city} />}
              {!_.isEmpty(region) && <DeviceInfoItem title='Region' value={region} />}
              <DeviceInfoItem title='Country' value={country} />
              <DeviceInfoItem title='IP' value={ipAddress} wrap={true} />
            </XymTooltip>
          )}
        </Font>

        <DeviceActions>
          {!hideActions && (
            <>
              <Font size={0.875} weight='semibold' onClick={handleRename}>
                <Link to='#'>Rename</Link>
                <XymSpacer across={true} />
              </Font>

              <Font size={0.875} weight='semibold' onClick={handleRemove}>
                <Link to='#'>Remove</Link>
                <XymSpacer across={true} />
              </Font>
            </>
          )}
        </DeviceActions>
      </DeviceName>

      <XymSpacer size={0.875} />

      <HR height={0.125} visible={!lastItem && !hideHR} />

      <XymConfirm
        confirmContent={renameConfirmContent}
        blank={true}
        onClose={() => setRenameConfirmContent(null)}
      />

      <XymConfirm
        confirmContent={removeConfirmContent}
        confirmButtonColor={error}
        confirmButtonHoverColor={errorHover}
        confirmText={xymDeviceType === 'securityKey' ? 'Waiting...' : 'Remove device'}
        confirmDisabled={xymDeviceType === 'securityKey'}
        blank={xymDeviceType === 'authenticator'}
        onClose={() => setRemoveConfirmContent(null)}
        onConfirm={() => {
          if (xymDeviceType === 'device') {
            const callbacks = {
              onSuccess: () => getUserInfo({ token }),
            };

            removeTrustedDevice({ token, id: _id }, callbacks);
            return;
          }

          if (xymDeviceType === 'securityKey') return;
          if (xymDeviceType === 'authenticator') return;
        }}
      />
    </StyledDevice>
  );
});

export { SecurityDevice };