import { H1, P, Center, XymSpacer } from 'components';
import { Transactions } from './styles';

const { VITE_APP_NAME: appName } = process.env;

const AboutTransactions = () => {
  return (
    <Transactions>
      <Center>
        <H1>Meaningful transactions</H1>
        <XymSpacer />
        <P>
          When you open {appName}, you&apos;ll see a complete overview of your finances &mdash;
          including all transaction details, upcoming expenses, and goal progress.
        </P>
      </Center>
    </Transactions>
  );
};

export { AboutTransactions };