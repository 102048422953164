import { useSelector } from 'react-redux';
import { Font, XymPrice, P, XymTooltip, XymSpacer } from 'components';
import { StyledBreakdownTotal, BreakdownItem, BreakdownNote } from './styles';
import { withTheme } from 'styled-components';
import { Loading } from 'xerum';
import _ from 'lodash';

const BreakdownTotal = withTheme(props => {
  const { theme } = props;

  const {
    analysisPreferences: { contractor },
    generalPreferences: { selectedTheme },
  } = useSelector(state => state.preferences);
  const { expensesAnalysis, expensesDataLoading } = useSelector(state => state.expenses);
  const { expensesData } = useSelector(state => state.expenses);

  const onPrimary = theme.modes[selectedTheme]?.onPrimary;

  const {
    totalNeededEachMonth,
    lastFullMonthsEarningsIncomeSources,
    lastMonthsEarningsAllSources,
  } = expensesAnalysis || {};

  return (
    <StyledBreakdownTotal>
      <BreakdownItem $gap={0.125}>
        <Loading
          theme={theme}
          selectedTheme={selectedTheme}
          isLoading={expensesDataLoading}
          hasData={!_.isEmpty(expensesAnalysis) && !expensesDataLoading}
          failIcon='fa-solid fa-minus'
          column={true}
          noText={true}
          height='fit-content'
        >
          <Font weight='bold'>
            <XymPrice color={onPrimary} value={totalNeededEachMonth} size={1.75} /> &nbsp;/ mo

            <XymTooltip>
              <Font size={0.75}>
                {lastFullMonthsEarningsIncomeSources > 0 && (
                  <>
                    Percentages are based exclusively on last month&apos;s earnings, from
                    all merchants categorized as income.
                  </>
                )}

                {lastMonthsEarningsAllSources > 0 && !lastFullMonthsEarningsIncomeSources && (
                  <>
                    Percentages are based on last month&apos;s deposits, excluding transfers.
                    Use income categories on specific merchants for more accurate results.
                  </>
                )}

                {!lastMonthsEarningsAllSources && !lastFullMonthsEarningsIncomeSources && (
                  <>
                    Percentages are based on this value. Calculations shift to using
                    income when deposits are detected in the previous month, or specific merchants are
                    categorized as income, in the previous month.
                  </>
                )}
              </Font>
            </XymTooltip>
          </Font>
        </Loading>

        <Font id='totalCaption' weight='semibold' color={onPrimary + 90}>
          Needed to fund spending
        </Font>
      </BreakdownItem>

      <BreakdownNote $visible={_.isEmpty(expensesData)}>
        <XymSpacer size={0.25} />

        <P size={0.9375} mobileSize={0.875}>
          This will be the total, net funds, needed to maintain spending for this account, however &mdash;&nbsp;
          you currently have no expenses or goals. Add some, to see your recommended income and breakdown.
        </P>
      </BreakdownNote>

      <BreakdownNote $visible={!_.isEmpty(expensesData)}>
        <XymSpacer size={0.25} />

        <P size={0.9375} mobileSize={0.875}>
          Your total, net funds, needed to maintain spending for this account.
          These figures can help prioritize financial decisions, negotiate
          a {contractor ? 'rate' : 'salary'} with a new {contractor ? 'client' : 'employer'},
          or test what-if scenarios by adjusting expenses and goals.
        </P>
      </BreakdownNote>
    </StyledBreakdownTotal>
  );
});

export { BreakdownTotal };