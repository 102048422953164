import { useEffect, useCallback, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  appActions,
  authActions,
  authConstants,
  securityActions,
  expensesActions,
  preferencesActions,
  institutionsActions,
  appConstants,
} from 'modules';
import { institutionByAccountId, handlePlaidUpdate } from 'helpers';
import { XymSpacer, Font, PlaidWebSockets } from 'components';
import { Banner, Button } from 'xerum';
import { PrimaryHeader } from './PrimaryHeader';
import { SafeToUse } from './SafeToUse';
import { withTheme } from 'styled-components';
import _ from 'lodash';

const { light } = appConstants.themes;

const Header = withTheme(props => {
  const { theme } = props;
  const { bannerContent } = useSelector(state => state.app);
  const { tokenInfo, userInfo } = useSelector(state => state.auth);
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);

  const {
    institutionsData,
    selectedAccountId,
    institutionDataLoading,
    linkToken,
    linkTokenLoading,
  } = useSelector(state => state.institutions);

  const institution = useMemo(() => {
    return institutionByAccountId({ institutionsData, accountId: selectedAccountId });
  }, [ selectedAccountId, institutionsData ]);

  const [ currentInstitution, setCurrentInstitution ] = useState(institution);

  const lightTheme = selectedTheme === light;
  const token = tokenInfo?.refreshToken || tokenInfo?.accessToken;
  const onPrimary = theme.modes[selectedTheme]?.darkGrey;
  const biceBlue = theme.colors.accent.biceBlue;
  const offWhite = theme.colors.shades.offWhite;
  const payload = useMemo(() => ({ token }), [ token ]);
  const isAdmin = userInfo?.roles?.includes(authConstants.roles.admin);
  const firstLoad = !_.isEmpty(institution) && _.isEmpty(currentInstitution);
  const sameInstitution = _.isEqual(currentInstitution, institution);
  const needsAttention = institution?.needsAttention;
  const dispatch = useDispatch();

  const updatePlaidLink = useCallback((payload, callbacks) => {
    dispatch(institutionsActions.updatePlaidLink(payload, callbacks));
  }, [ dispatch ]);

  const linkArgs = useMemo(() => {
    return {
      token,
      itemId: institution?.itemId,
      updatePlaidLink,
    };
  }, [ token, institution, updatePlaidLink ]);

  const repairMessage = useMemo(() => ({
    message: (
      <>
        Your institution is asking for reauthentication with Plaid.

        <XymSpacer across={true} />

        <Button
          theme={theme}
          selectedTheme={selectedTheme}
          color={biceBlue}
          textColor={!lightTheme && offWhite}
          hoverColor={onPrimary}
          text={<Font weight='semibold' mobileSize={0.875}>Reauthenticate</Font>}
          borderSize={0.09375}
          height={2}
          width='fit-content'
          padding='0.25rem 1rem'
          onClick={() => handlePlaidUpdate(linkArgs)}
        />
      </>
    ),
    noClose: true,
  }), [ theme, selectedTheme, linkArgs, lightTheme, onPrimary, biceBlue, offWhite ]);

  const getBalances = useCallback((payload, callbacks) => {
    dispatch(expensesActions.getBalances(payload, callbacks));
  }, [ dispatch ]);

  const resetBalances = useCallback(payload => dispatch(expensesActions.resetBalances(payload)), [ dispatch ]);
  const updateBanner = useCallback(payload => dispatch(appActions.setBannerContent(payload)), [ dispatch ]);

  const getUserPreferences = useCallback((payload, callbacks) => {
    dispatch(preferencesActions.getUserPreferences(payload, callbacks));
  }, [ dispatch ]);

  const getExpensesData = useCallback((payload, callbacks) => {
    dispatch(expensesActions.getExpensesData(payload, callbacks));
  }, [ dispatch ]);

  const getSecurityData = useCallback((payload, callbacks) => {
    dispatch(securityActions.getSecurityData(payload, callbacks));
  }, [ dispatch ]);

  const getMiscData = useCallback((payload, callbacks) => {
    dispatch(appActions.getMiscData(payload, callbacks));
  }, [ dispatch ]);

  const getUserStatisticsData = useCallback((payload, callbacks) => {
    dispatch(authActions.getUserStatistics(payload, callbacks));
  }, [ dispatch ]);

  const getInstitutions = useCallback((payload, callbacks) => {
    dispatch(institutionsActions.getInstitutions(payload, callbacks));
  }, [ dispatch ]);

  const getTransactions = useCallback((payload, callbacks) => {
    dispatch(institutionsActions.getTransactions(payload, callbacks));
  }, [ dispatch ]);

  const getData = useCallback(() => {
    if (token) {
      getUserPreferences(payload);
      getMiscData(payload);
      getSecurityData(payload);
    }
  }, [ token, payload, getSecurityData, getMiscData, getUserPreferences ]);

  const getInstitutionData = useCallback(() => {
    if (token) {
      getInstitutions(payload);
    }
  }, [ token, payload, getInstitutions ]);

  const getTransactionsData = useCallback(() => {
    if (selectedAccountId) {
      const payload = {
        token,
        linkedAccountId: selectedAccountId,
        existingDataOnly: !firstLoad,
      };

      const callbacks = {
        onSuccess: () => {
          const balancesPayload = { token, linkedAccountId: selectedAccountId };

          if (!firstLoad && !payload.existingDataOnly) {
            getBalances(balancesPayload);
          }
        },
      };

      getTransactions(payload, callbacks);
    }
  }, [ token, selectedAccountId, firstLoad, getBalances, getTransactions ]);

  const getExpenseData = useCallback(() => {
    if (token && !_.isEmpty(selectedAccountId)) {
      const expensesPayload = {
        ...payload,
        linkedAccountId: selectedAccountId,
      };

      getExpensesData(expensesPayload);
    }

    if (_.isEmpty(selectedAccountId)) {
      resetBalances();
    }
  }, [ selectedAccountId, token, payload, resetBalances, getExpensesData ]);

  const getAdminData = useCallback(() => {
    if (token && isAdmin) {
      getUserStatisticsData(payload);
    }
  }, [ token, isAdmin, payload, getUserStatisticsData ]);

  useEffect(() => getData(), [ getData ]);
  useEffect(() => getInstitutionData(), [ getInstitutionData ]);
  useEffect(() => getExpenseData(), [ getExpenseData ]);
  useEffect(() => getTransactionsData(), [ getTransactionsData ]);
  useEffect(() => getAdminData(), [ getAdminData ]);

  useEffect(() => {
    if (!sameInstitution) setCurrentInstitution(institution);
  }, [ sameInstitution, institution, setCurrentInstitution ]);

  useEffect(() => {
    updateBanner(needsAttention ? repairMessage : null);
  }, [ repairMessage, selectedTheme, selectedAccountId, needsAttention, updateBanner ]);

  return (
    <header>
      <PrimaryHeader />
      <SafeToUse />

      <PlaidWebSockets />

      <Banner
        theme={theme}
        selectedTheme={selectedTheme}
        center={true}
        sharp={true}
        padding={needsAttention && '0.5rem 1rem'}
        noClose={bannerContent?.noClose}
        visible={bannerContent?.message}
        disabled={institutionDataLoading || linkToken || linkTokenLoading}
        callback={() => updateBanner(null)}
      >
        <Font tabletSize={0.875} mobileSize={0.875}>
          {bannerContent?.message}
        </Font>
      </Banner>
    </header>
  );
});

export { Header };