
export const expensesSortOptions = [
  { value: 1, label: 'Needed: Sooner' },
  { value: 2, label: 'Needed: Later' },
  { value: 3, label: 'Amount: Smaller' },
  { value: 4, label: 'Amount: Larger' },
  { value: 5, label: 'Name: A-Z' },
  { value: 6, label: 'Name: Z-A' },
  { value: 7, label: 'Progress: More funded' },
  { value: 8, label: 'Progress: Less funded' },
  { value: 9, label: 'Requires attention' },
];

export const timeFrames = [
  { value: 7, label: 'Next 7 Days' },
  { value: 14, label: 'Next 14 Days' },
  { value: 21, label: 'Next 21 Days' },
  { value: 30, label: 'Next 30 Days' },
];