import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { ExpenseIcon, Font, XymSpacer, P } from 'components';
import { ImportExportMode } from './styles';
import { withTheme } from 'styled-components';
import _ from 'lodash';

const RemoveAndRestore = withTheme(forwardRef((props, ref) => {
  const { theme, replaceAllActive, setImportData, setImportMode, setIsMergeOperation } = props;

  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const { expensesData } = useSelector(state => state.expenses);
  const paynesGrey = theme.colors.neutral.paynesGrey;

  return (
    <ImportExportMode
      $theme={theme}
      $selectedTheme={selectedTheme}
      $active={replaceAllActive}
      $visible={true}
      onClick={() => {
        ref.current.value = '';
        setImportData(null);
        setImportMode(true);
        setIsMergeOperation(false);
        ref.current.click();
      }}
    >
      <ExpenseIcon
        icon='fa-solid fa-eraser'
        bgColorDark={replaceAllActive && paynesGrey}
        size={3}
        fontSize={1.5}
        alwaysVisible={true}
      />

      <div>
        <Font weight='semibold'>
          {!_.isEmpty(expensesData) && 'Remove and restore from backup.'}
          {_.isEmpty(expensesData) && 'Restore expenses from backup.'}
        </Font>

        <XymSpacer size={0.125} />

        <P size={0.75}>
          {!_.isEmpty(expensesData) && 'Removes all existing expenses and goals, before importing '}
          {_.isEmpty(expensesData) && 'Imports a set of expenses and goals, '}
            from a previously exported JSON file, for use with this account.
        </P>
      </div>
    </ImportExportMode>
  );
}));

export { RemoveAndRestore };