import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { appConstants } from 'modules';
import { Font, XymSpacer } from 'components';
import { TagOverflow } from './styles';
import styled, { withTheme } from 'styled-components';
import _ from 'lodash';

const { spacing } = appConstants;
const { smallGap } = spacing;

const TagArea = styled('div')`
  display: inline-flex;
  flex-wrap: wrap;
  gap: ${smallGap}rem 0;
`;

const AutoSpendOverflow = withTheme(props => {
  const { theme, overflowTags, coordinates, setCoordinates, allHidden } = props;
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const overflowRef = useRef();

  useEffect(() => {
    const expenseHolder = document.querySelector('#expenseHolder');
    const handleOutsideClick = e => !overflowRef.current?.contains(e.target) && setCoordinates(null);

    if (!_.isEmpty(coordinates)) {
      expenseHolder?.addEventListener('scroll', () => setCoordinates(null));
      document.addEventListener('click', handleOutsideClick);
    }

    return () => {
      expenseHolder?.removeEventListener('scroll', () => setCoordinates(null));
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [ coordinates, setCoordinates ]);

  return (
    <TagOverflow
      ref={overflowRef}
      $theme={theme}
      $selectedTheme={selectedTheme}
      $yPosition={coordinates?.y}
      $visible={!_.isEmpty(coordinates)}
    >
      <Font size={0.875} weight='semibold'>
        {!allHidden ? 'Remaining a' : 'A'}uto-spend tags...
        <XymSpacer size={1.75} />
      </Font>

      <TagArea>
        {overflowTags}
      </TagArea>
    </TagOverflow>
  );
});

export { AutoSpendOverflow };