import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Font, XymSpacer } from 'components';
import { accountChangeNotification } from 'helpers';
import {
  userMenuRoutes,
  adminRoutes,
  appConstants,
  authActions,
  rootActions,
  institutionsActions,
  expensesActions,
  appActions,
  authConstants,
} from 'modules';
import { UserMenuLink, StyledUserMenu, MenuArea, AccountsArea, AccountsHolder } from './styles';
import { Account } from './mobileUserMenuStyles';
import { withTheme } from 'styled-components';
import { PrivacyMask } from 'xerum';
import { flags } from 'utility';
import _ from 'lodash';

const { names } = appConstants;
const { appName, accessTokenKeyName } = names;

const UserMenu = withTheme(props => {
  const { theme } = props;
  const { userInfo } = useSelector(state => state.auth);
  const { institutionsData, selectedAccountId } = useSelector(state => state.institutions);

  const isAdmin = userInfo?.roles?.includes(authConstants.roles.admin);

  const {
    generalPreferences: { privacyMode, selectedTheme },
  } = useSelector(state => state.preferences);

  const { pathname } = useLocation();
  const { features: { subscriptionSettings } } = flags;

  const allAccounts = institutionsData?.reduce((previous, current) => {
    const { accounts } = current;
    return [ ...previous, ...accounts ];
  }, []);

  const hasMultipleAccounts = !_.isEmpty(allAccounts) && allAccounts?.length > 1;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const invalidateToken = useCallback((payload, callbacks) => {
    dispatch(authActions.invalidateToken(payload, callbacks));
  }, [ dispatch ]);

  const clearTransactionsData = useCallback(payload => {
    dispatch(institutionsActions.clearTransactionsData(payload));
  }, [ dispatch ]);

  const addNotification = useCallback(payload => {
    dispatch(appActions.addNotification(payload));
  }, [ dispatch ]);

  const setSelectedAccountId = useCallback(payload => {
    dispatch(institutionsActions.setSelectedAccountId(payload));
  }, [ dispatch ]);

  const clearAnalysisData = useCallback(payload => dispatch(expensesActions.clearAnalysisData(payload)), [ dispatch ]);
  const logout = useCallback(() => dispatch(rootActions.logout()), [ dispatch ]);

  const existingSettings = JSON.parse(localStorage.getItem(appName));
  const accessToken = existingSettings?.[accessTokenKeyName];

  const handleLogout = () => {
    const payload = { accessToken };
    const callbacks = {
      onSuccess: () => logout?.(),
    };

    invalidateToken(payload, callbacks);
  };

  const buildUserLinks = adminNav => {
    const links = [ ...userMenuRoutes, ...(adminNav && adminRoutes) || [] ];
    const filtered = links.filter(route => {
      if (!subscriptionSettings) {
        const validRoute = route !== 'subscription';
        return validRoute;
      }

      return route;
    });

    const menuItems = filtered.map(route => {
      const formatted = _.startCase(route);
      const title = _.capitalize(_.toLower(formatted));
      const active = _.toLower(pathname).includes(route);

      return (
        <UserMenuLink
          key={route}
          $theme={theme}
          $selectedTheme={selectedTheme}
          $active={active}
          onClick={() => !active && navigate(`/${route}`)}
        >
          <Font weight='semibold'>
            {title}
          </Font>

          <XymSpacer size={2.25} />
        </UserMenuLink>
      );
    });

    return (
      <MenuArea>
        {menuItems}
        <XymSpacer size={0.5} />

        <UserMenuLink $theme={theme} $selectedTheme={selectedTheme} onClick={handleLogout}>
          <Font weight='semibold'>
            Log out
          </Font>
        </UserMenuLink>
      </MenuArea>
    );
  };

  const buildAccounts = () => {
    if (hasMultipleAccounts) {
      const accounts = allAccounts.map((account, index) => {
        const { name, mask, account_id } = account;
        const active = account_id === selectedAccountId;

        return (
          <Account
            key={index}
            $theme={theme}
            $selectedTheme={selectedTheme}
            $active={active}
            onClick={() => {
              if (selectedAccountId !== account_id) {
                clearAnalysisData(null);
                clearTransactionsData(null);
                setSelectedAccountId(account_id);
                accountChangeNotification({ addNotification, name, mask, privacyMode });
              }
            }}
          >
            <Font size={0.75} weight='semibold'>
              {privacyMode ? <PrivacyMask length={8} /> : name}
            </Font>
            <Font size={0.75}><PrivacyMask length={4} /> ... {mask}</Font>
          </Account>
        );
      });

      return (
        <AccountsArea>
          <AccountsHolder>
            {accounts}
          </AccountsHolder>
        </AccountsArea>
      );
    }
  };

  return (
    <StyledUserMenu $multipleAccounts={hasMultipleAccounts}>
      {buildAccounts()}
      {buildUserLinks(isAdmin)}
    </StyledUserMenu>
  );
});

export { UserMenu };