import { useSelector } from 'react-redux';
import { appConstants } from 'modules';
import { expenseTooltip } from 'helpers';
import { Font, XymSpacer, XymPrice } from 'components';
import { NameArea, TitleAndTooltips, FrequencyName } from './styles';
import { PrivacyMask } from 'xerum';
import { withTheme } from 'styled-components';
import { flags } from 'utility';

const { light } = appConstants.themes;

const ExpenseName = withTheme(props => {
  const { theme, expense } = props;
  const {
    name,
    current,
    needed,
    onTrackData,
    autoFundSourceId,
    funded,
    frequencyId,
    useDailyAutoFundCalculations,
  } = expense;

  const { frequencies } = useSelector(state => state.app);
  const { generalPreferences: { privacyMode, selectedTheme } } = useSelector(state => state.preferences);
  const { autoFundSources, expensesData } = useSelector(state => state.expenses);
  const { features: { autoFunding } } = flags;
  const autoFundSource = autoFundSources?.find(source => source._id === autoFundSourceId);
  const frequencyName = frequencies?.find(frequency => frequency._id === frequencyId)?.name || '';
  const darkGrey = theme.modes[selectedTheme]?.darkGrey;
  const offWhite = theme.modes[selectedTheme]?.offWhite;
  const frenchGrey = theme.colors.neutral.frenchGrey;
  const lightTheme = selectedTheme === light;
  const titleColor = lightTheme ? darkGrey : offWhite;
  const offTrackExists = expensesData?.some(expense => !expense.onTrackData?.onTrack);

  const autoCalculation = () => (
    <Font size={0.875}>
      <Font weight='semibold' size={0.875}>Automatic</Font>
      {!funded && (
        <>
          :&nbsp;
          <XymPrice
            size={0.875}
            value={onTrackData?.catchUpRatePerDay || onTrackData?.normalRatePerDay}
          />
          /day
        </>
      )}
    </Font>
  );

  return (
    <NameArea>
      <TitleAndTooltips>
        <Font color={titleColor} weight='semibold'>
          {privacyMode ? <PrivacyMask length={name.length} /> : name}
        </Font>

        <XymSpacer size={0.25} across={true} />
        {expenseTooltip({ expense, condition: onTrackData?.onTrack === false && !privacyMode, isWarning: true })}
        {expenseTooltip({ expense, condition: funded === true && !privacyMode })}
        {expenseTooltip({
          expense,
          condition: current > needed && !privacyMode && offTrackExists,
          isInfo: true,
        })}
        <XymSpacer size={0.25} across={true} />

        <FrequencyName>
          <Font size={0.875} weight='medium' color={lightTheme ? frenchGrey : frenchGrey + 50}>
            {frequencyName}
          </Font>
        </FrequencyName>
      </TitleAndTooltips>

      {autoFunding && autoFundSource && (
        <Font size={0.875}>
          <Font weight='semibold' size={0.875}>
            Funding from
          </Font>:&nbsp;

          <Font size={0.875}>
            {privacyMode ? <PrivacyMask /> : autoFundSource?.name}
          </Font>
        </Font>
      )}

      {useDailyAutoFundCalculations && (
        <div>
          {privacyMode
            ? <Font size={0.875}><PrivacyMask /></Font>
            : autoCalculation()
          }
        </div>
      )}
    </NameArea>
  );
});

export { ExpenseName };