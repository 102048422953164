import { appConstants } from 'modules';

const { themes } = appConstants;

const colors = {
  neutral: {
    charcoal: '#333f47',
    davysGrey: '#333436',
    paynesGrey: '#556673',
    cadetGrey: '#7e91a0',
    frenchGrey: '#c4cdd4',
    tropicalIndigo: '#8580ed',
    mountbattenPink: '#847996',
  },

  accent: {
    celestialBlue: '#009bf5',
    pictonBlue: '#42baff',
    tuftsBlue: '#008bdb',
    biceBlue: '#0070b1',
  },

  success: {
    oceanGreen: '#50b990',
    aquamarine: '#14eb97',
  },

  warning: {
    orangeYellow: '#f0b800',
    oldGold: '#e2c051',
  },

  error: {
    indianRed: '#d45454',
    congoPink: '#eb655e',
  },

  shades: {
    trueBlack: '#000000',
    black: '#1b1d1d',
    jet: '#2d2d2f',
    white: '#ffffff',
    offWhite: '#fafafa',
  },
};

const modes = {
  [themes.light]: {
    primary: colors.shades.white,
    onPrimary: colors.neutral.paynesGrey,
    secondary: colors.shades.offWhite,
    onSecondary: colors.neutral.paynesGrey,
    accent: colors.accent.celestialBlue,
    onAccent: colors.shades.offWhite,
    accentHover: colors.accent.pictonBlue,
    onAccentHover: colors.shades.offWhite,

    white: colors.shades.white,
    offWhite: colors.shades.offWhite,
    lightGrey: colors.neutral.frenchGrey,
    grey: colors.neutral.paynesGrey,
    darkGrey: colors.neutral.charcoal,
    black: colors.shades.jet,

    success: colors.success.oceanGreen,
    warning: colors.warning.orangeYellow,
    error: colors.error.indianRed,
    errorHover: colors.error.congoPink,
  },

  [themes.dark]: {
    primary: colors.shades.jet,
    onPrimary: colors.neutral.frenchGrey,
    secondary: colors.neutral.davysGrey,
    onSecondary: colors.neutral.frenchGrey,
    accent: colors.accent.celestialBlue,
    onAccent: colors.shades.jet,
    accentHover: colors.accent.pictonBlue,
    onAccentHover: colors.shades.jet,

    white: colors.shades.white,
    offWhite: colors.shades.offWhite,
    lightGrey: colors.neutral.frenchGrey,
    grey: colors.neutral.cadetGrey,
    darkGrey: colors.shades.black,
    black: colors.shades.black,

    success: colors.success.oceanGreen,
    warning: colors.warning.oldGold,
    error: colors.error.indianRed,
    errorHover: colors.error.congoPink,
  },
};

const theme = { colors, modes };

export { theme };