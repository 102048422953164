import { appConstants } from 'modules';
import styled from 'styled-components';

const { breakpoints } = appConstants;
const { mobile } = breakpoints;

export const StyledSelfManagedLink = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: fit-content;
  padding: 1rem;
  overflow-y: auto;
  margin: 0 auto;

  @media screen and (max-width: ${mobile}rem) {
    display: none;
  }
`;

export const ActionArea = styled('div')`
  display: flex;
  flex-direction: column;
  grid-area: cta;
  width: 100%;
  height: fit-content;
`;