import { useSelector } from 'react-redux';
import { appConstants } from 'modules';
import { Chevron, XymSpacer } from 'components';
import { DatePicker, FieldError } from 'xerum';
import { withTheme } from 'styled-components';

const { light } = appConstants.themes;

const XymDatePicker = withTheme(props => {
  const {
    theme,
    form,
    name,
    label,
    spacing,
    noSpacing,
    visible,
    optional,
    defaultDate,
    disablePastDates,
    disableFutureDates,
    disabled,
    noLabel,
    noDateText,
    fontSize,
    callback,
  } = props;

  const { mobileMode } = useSelector(state => state.app);
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const frenchGrey = theme.colors.neutral.frenchGrey;
  const white = theme.colors.shades.white;
  const primary = theme.modes[selectedTheme]?.primary;
  const onPrimary = theme.modes[selectedTheme]?.onPrimary;
  const darkGrey = theme.modes[selectedTheme]?.darkGrey;
  const lightTheme = selectedTheme === light;
  const showField = visible ?? true;

  if (!showField) return null;

  return (
    <>
      <DatePicker
        theme={theme}
        selectedTheme={selectedTheme}
        form={form}
        defaultDate={defaultDate}
        disablePastDates={disablePastDates}
        disableFutureDates={disableFutureDates}
        disabled={disabled}
        icon={<Chevron />}
        prevIcon={<Chevron direction='left' color={lightTheme ? white : darkGrey} />}
        nextIcon={<Chevron direction='right' color={lightTheme ? white : darkGrey} />}
        bgHoverColor={lightTheme ? frenchGrey : frenchGrey + 50}
        disabledTextColor={lightTheme ? frenchGrey : frenchGrey + 50}
        disabledBGHoverColor={lightTheme ? frenchGrey : frenchGrey + 50}
        name={name}
        label={label}
        noLabel={noLabel}
        noDateText={noDateText}
        fontSize={fontSize || 0.875}
        labelColor={lightTheme ? darkGrey : onPrimary}
        placeholderColor={lightTheme ? frenchGrey : frenchGrey + 50}
        activeTextColor={primary}
        activeTextHoverColor={primary}
        optional={optional}
        callback={callback}
        iconSize={0.75}
        borderRadius={0.25}
        mobileSize={mobileMode && 0.875}
        fontFamily='Inter-Regular'
        headerFontFamily='Inter-Bold'
      />

      {form && <FieldError theme={theme} selectedTheme={selectedTheme} name={name} />}
      {!noSpacing && <XymSpacer size={spacing || 1.5} />}
    </>
  );
});

export { XymDatePicker };