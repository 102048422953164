import { useSelector } from 'react-redux';
import { Font, XymField, XymConfirmButtons } from 'components';
import { Formik, Form } from 'formik';
import * as yup from 'yup';

const AuthCodeForm = props => {
  const { setConfirmContent } = props;
  const { tokenInfo } = useSelector(state => state.auth);

  const defaults = { authCode: '' };
  const validationSchema = yup.object().shape({
    authCode: yup.string().required('Authenticator code is required.'),
  });

  const handleSubmit = args => {
    const { values, setSubmitting } = args;
    const { authCode } = values;

    const callbacks = {
      onSuccess: () => setConfirmContent(null),
      onComplete: () => setSubmitting(false),
    };

    const payload = {
      token: tokenInfo?.accessToken,
      authCode,
    };

    // TODO: Add redux action here...
    console.log(payload, callbacks);
  };

  return (
    <Formik
      initialValues={defaults}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={(values, handlers) => {
        const { setSubmitting } = handlers;
        handleSubmit({ values, setSubmitting });
      }}
    >
      {form => (
        <Form>
          <XymField
            form={form}
            name='authCode'
            label={<Font size={0.875} weight='semibold'>Authenticator code</Font>}
            placeholder='Type authenticator code here...'
            spacing={1.5}
          />

          <XymConfirmButtons
            form={form}
            confirmText='Remove device'
            setConfirmContent={setConfirmContent}
          />
        </Form>
      )}
    </Formik>
  );
};

export { AuthCodeForm };