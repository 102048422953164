import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { appConstants, timeFrames } from 'modules';
import { sort } from 'helpers';
import {
  XymSelect,
  Font,
  PreviewExpense,
  NoData,
  XymSpacer,
  XymConfirm,
  MoveMoneyForm,
} from 'components';
import { StyledUpcoming, TitleArea, ExpensesList } from './styles';
import { Button, Loading } from 'xerum';
import { withTheme } from 'styled-components';
import moment from 'moment';
import _ from 'lodash';

const { light } = appConstants.themes;

const Upcoming = withTheme(props => {
  const { theme, token, updateUserPreferences, selectedWindow, setSelectedWindow } = props;
  const { mobileMode, tabletMode, tabletLandscapeMode, hybridMode } = useSelector(state => state.app);
  const [ moveMoneyConfirmContent, setMoveMoneyConfirmContent ] = useState(null);
  const [ dragOverId, setDragOverId ] = useState(null);

  const {
    upcomingPreferences: { rangeId },
    generalPreferences: { selectedTheme },
  } = useSelector(state => state.preferences);

  const { expensesData, expensesDataLoading } = useSelector(state => state.expenses);

  const lightTheme = selectedTheme === light;
  const darkGrey = theme.modes[selectedTheme]?.darkGrey;
  const offWhite = theme.modes[selectedTheme]?.offWhite;
  const titleColor = lightTheme ? darkGrey : offWhite;
  const desktopMode = !mobileMode && !tabletMode && !tabletLandscapeMode && !hybridMode;
  const navigate = useNavigate();

  const expenses = useMemo(() => {
    return sort(expensesData?.filter(expense => !expense.isGoal), 'dueDate');
  }, [ expensesData ]);

  const handleDrop = e => {
    e.preventDefault();
    const draggedExpenseId = e.dataTransfer.getData('text/plain');
    const droppedOnExpenseId = e.target.closest('[data-expense-id]')?.getAttribute('data-expense-id');

    if (dragOverId) setDragOverId(null);

    if (draggedExpenseId && droppedOnExpenseId && draggedExpenseId !== droppedOnExpenseId) {
      setMoveMoneyConfirmContent(
        <MoveMoneyForm
          from={draggedExpenseId}
          to={droppedOnExpenseId}
          setConfirmContent={setMoveMoneyConfirmContent}
        />,
      );
    }
  };

  const buildExpenses = () => {
    const expenseList = expenses.map((expense, index) => {
      const { _id, dueDate } = expense;
      const dueDateAsDate = moment(dueDate);
      const today = moment();
      const dueDateDifference = dueDateAsDate.diff(today, 'days');
      const hasDueDate = !_.isEmpty(dueDate);
      const dueInNextTwoWeeks = dueDateDifference >= 0 && dueDateDifference <= +rangeId;

      if (hasDueDate && dueInNextTwoWeeks) {
        return (
          <PreviewExpense
            key={_id || index}
            expense={expense}
            dragOverId={dragOverId}
            setDragOverId={setDragOverId}
          />
        );
      }
    }).filter(expense => expense);

    return (
      <>
        {!_.isEmpty(expenseList)
          ? <div>
              {desktopMode && <XymSpacer />}
              {expenseList}
            </div>
          : <NoData
              icon='fa-solid fa-circle-check'
              text='Nothing due soon'
              subText={`Expenses due in the next ${rangeId} days will appear here.`}
            />
        }
      </>
    );
  };

  return (
    <StyledUpcoming>
      <TitleArea>
        {desktopMode && (
          <Font size={1.25} tabletSize={1} mobileSize={1} weight='semibold' color={titleColor}>
            Upcoming
          </Font>
        )}

        {desktopMode && (
          <XymSelect
            data={timeFrames}
            name='timeframeOptions'
            localDefault={selectedWindow}
            noResultsText='Time frames not found'
            callback={newValue => {
              if (!_.isEqual(selectedWindow, +newValue)) {
                setSelectedWindow(+newValue);
                updateUserPreferences({ token, upcomingRangeId: +newValue });
              }
            }}
            noSpacing={!desktopMode}
          />
        )}
      </TitleArea>

      <Loading
        theme={theme}
        selectedTheme={selectedTheme}
        isLoading={expensesDataLoading}
        hasData={rangeId && !_.isEmpty(expenses) && !expensesDataLoading}
        renderOnFail={true}
        noFailIcon={true}
        failText={
          <NoData
            icon='fa-solid fa-calendar-days'
            text='No expenses found'
            subText={`Add an expense by selecting "Manage expenses".`}
          />
        }
        text={
          <Font size={1} weight='semibold'>
            Loading expenses...
          </Font>
        }
      >
        <ExpensesList onDragOver={e => e.preventDefault()} onDrop={handleDrop}>
          {buildExpenses()}
        </ExpensesList>
      </Loading>

      <Button
        theme={theme}
        selectedTheme={selectedTheme}
        callback={() => navigate('/expenses')}
        text={<Font weight='medium' mobileSize={0.875}>Manage expenses</Font>}
      />

      <XymConfirm
        confirmContent={moveMoneyConfirmContent}
        useOverflow={false}
        blank={true}
        onClose={() => setMoveMoneyConfirmContent(null)}
      />
    </StyledUpcoming>
  );
});

export { Upcoming };