import { useSelector } from 'react-redux';
import { getColor } from 'helpers';
import styled, { withTheme } from 'styled-components';

const HamburgerMenuSVG = styled('svg')`
  width: ${props => props.$width || 1.75}rem;
  height: ${props => props.$height || 1.25}rem;
  fill: ${props => props.$color || getColor(props, 'onPrimary')};
`;

const HamburgerMenuIcon = withTheme(props => {
  const { theme, color, width, height } = props;
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);

  return (
    <HamburgerMenuSVG
      $theme={theme}
      $selectedTheme={selectedTheme}
      $width={width}
      $height={height}
      $color={color}
      viewBox='0 0 28 20'
      fill='currentColor'
    >
      <rect width='28' height='4' rx='2' />
      <rect y='8' width='28' height='4' rx='2' />
      <rect y='16' width='28' height='4' rx='2' />
    </HamburgerMenuSVG>
  );
});

export { HamburgerMenuIcon };