import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Font, XymPrice, Center, XymTooltip } from 'components';
import { RecommendationsArea, Recommendation } from './styles';
import { withTheme } from 'styled-components';
import { Loading } from 'xerum';
import _ from 'lodash';

const Recommendations = withTheme(props => {
  const { theme } = props;

  const {
    analysisPreferences: { contractor },
    generalPreferences: { privacyMode, selectedTheme },
  } = useSelector(state => state.preferences);

  const { expensesAnalysis, expensesDataLoading } = useSelector(state => state.expenses);
  const lightGrey = theme.modes[selectedTheme]?.lightGrey;

  const { recommendedIncome, totalNeededEachMonth } = expensesAnalysis || {};

  const w2Data = useMemo(() => recommendedIncome?.w2 || {}, [ recommendedIncome ]);
  const contractorData = useMemo(() => recommendedIncome?.contractor || {}, [ recommendedIncome ]);
  const w2Salary =  useMemo(() => w2Data.recommendedGrossAnnualIncome, [ w2Data ]);
  const w2HourlyRate = useMemo(() => w2Data.recommendedHourlyRate, [ w2Data ]);
  const w2MonthlyIncome = useMemo(() => w2Data.recommendedGrossMonthlyIncome, [ w2Data ]);
  const contractorSalaray = useMemo(() => contractorData.recommendedGrossAnnualIncome, [ contractorData ]);
  const contractorHourlyRate = useMemo(() => contractorData.recommendedHourlyRate, [ contractorData ]);
  const contractorMonthlyIncome = useMemo(() => contractorData.recommendedGrossMonthlyIncome, [ contractorData ]);

  const taxRate = useMemo(() => {
    return contractor ? contractorData.taxRate : w2Data.taxRate;
  }, [ contractor, contractorData, w2Data ]);

  const recommendedGrossAnnualIncome = contractor ? contractorSalaray : w2Salary;
  const recommendedGrossMonthlyIncome = contractor ? contractorMonthlyIncome : w2MonthlyIncome;
  const recommendedHourlyRate = contractor ? contractorHourlyRate : w2HourlyRate;
  const categoryLimit = taxRate?.recommendedCategoryLimit;
  const highestCategory = taxRate?.highestSpendingCategory;
  const netSpendingWithLimit = contractor ? contractorData.netSpendingWithLimit : w2Data.netSpendingWithLimit;

  const recommendedTooltip = (
    <Font size={0.75} block={true}>
      {privacyMode && (
        <>
          Privacy mode is enabled.
        </>
      )}

      {totalNeededEachMonth <= 0 && (
        <>
          Once you&apos;ve added expenses or goals, this figure will be based on your highest spending category.
          An additional tax rate will be applied to the final figure.  Start adding expenses or goals to
          see your recommended gross montly income.
        </>
      )}

      {totalNeededEachMonth > 0 && !privacyMode && (
        <>
          Based on {_.toLower(_.startCase(highestCategory))} &mdash; your highest spending category.&nbsp;
          {_.capitalize(_.startCase(highestCategory))} should not exceed more than {categoryLimit}%
          of your gross monthly income. In this scenario,
          ${netSpendingWithLimit?.toLocaleString(undefined, { maximumFractionDigits: 2 })}.
          A {contractor ? 'generic' : ''} {taxRate?.rate}%&nbsp;
          {contractor ? 'income tax + self-employment' : 'W-2'} tax rate has been added
          for the final figure.
        </>
      )}
    </Font>
  );

  const annualTooltip = (
    <Font size={0.75} block={true}>
      Recommended gross monthly income multiplied by 12 months.
    </Font>
  );

  const rateTooltip = (
    <Font size={0.75} block={true}>
      Recommended gross annual income divided by 2,080 hours.
      Based on a 40-hour work week and 52 weeks a year.
    </Font>
  );

  return (
    <RecommendationsArea>
      <Recommendation $theme={theme} $selectedTheme={selectedTheme} $gap={0.125}>
        <Loading
          theme={theme}
          selectedTheme={selectedTheme}
          isLoading={expensesDataLoading}
          hasData={!_.isEmpty(expensesAnalysis) && !expensesDataLoading}
          failIcon='fa-solid fa-minus'
          column={true}
          noText={true}
          height='fit-content'
        >
          <Font weight='bold'>
            <XymPrice
              color={lightGrey}
              value={recommendedGrossMonthlyIncome}
              size={1.5}
              mobileSize={1.25}
            /> &nbsp;/ mo

            <XymTooltip text={recommendedTooltip} iconColor={lightGrey} />
          </Font>
        </Loading>

        <Center>
          <Font size={0.9} mobileSize={0.875} weight='semibold' color={lightGrey + 90}>
            Recommended gross monthly
          </Font>
        </Center>
      </Recommendation>

      <Recommendation $theme={theme} $selectedTheme={selectedTheme} $gap={0.125}>
        <Loading
          theme={theme}
          selectedTheme={selectedTheme}
          isLoading={expensesDataLoading}
          hasData={!_.isEmpty(expensesAnalysis) && !expensesDataLoading}
          failIcon='fa-solid fa-minus'
          column={true}
          noText={true}
          height='fit-content'
        >
          <Font weight='bold'>
            <XymPrice
              color={lightGrey}
              value={recommendedGrossAnnualIncome}
              size={1.5}
              mobileSize={1.25}
            /> &nbsp;/ yr

            <XymTooltip text={annualTooltip} iconColor={lightGrey} />
          </Font>
        </Loading>

        <Center>
          <Font size={0.9} mobileSize={0.875} weight='semibold' color={lightGrey + 90}>
            Recommended gross annual
          </Font>
        </Center>
      </Recommendation>

      <Recommendation $theme={theme} $selectedTheme={selectedTheme} $gap={0.125}>
        <Loading
          theme={theme}
          selectedTheme={selectedTheme}
          isLoading={expensesDataLoading}
          hasData={!_.isEmpty(expensesAnalysis) && !expensesDataLoading}
          failIcon='fa-solid fa-minus'
          column={true}
          noText={true}
          height='fit-content'
        >
          <Font weight='bold'>
            <XymPrice
              color={lightGrey}
              value={recommendedHourlyRate}
              size={1.5}
              mobileSize={1.25}
            /> &nbsp;/ hr

            <XymTooltip text={rateTooltip} iconColor={lightGrey} />
          </Font>
        </Loading>

        <Center>
          <Font size={0.9} mobileSize={0.875} weight='semibold' color={lightGrey + 90}>
            Recommended hourly
          </Font>
        </Center>
      </Recommendation>
    </RecommendationsArea>
  );
});

export { Recommendations };