import { useEffect, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { months } from 'modules/stripe/stripeEnums';
import { StyledChart } from './styles';
import { getOptions } from './chartOptions';
import { ChartProvider, useChartContext } from './chartContext';
import { theme } from 'theme';
import moment from 'moment';
import { Chart } from './chart';

const light = 'light';
const currentMonth = moment().month() + 1;
const capAtCurrentMonth = false;
const capAtCurrentDay = false;
const monthLabels = months.map(month => month.abbreviation).splice(0, capAtCurrentMonth ? currentMonth : 12);
const daysThisMonth = moment().daysInMonth();
const todaysDate = moment().date();
const dayLabels = Array
  .from({ length: daysThisMonth }, (item, i) => i + 1)
  .splice(0, capAtCurrentDay ? todaysDate : daysThisMonth);

const getData = args => {
  const {
    yearToDate,
    selectedTheme,
    secondaryColor,
    desktopMode,
    trends,
    earnSpentSelected,
    selectedTrend,
  } = args;
  const { earned, totalSpent, categorySpending } = trends || {};
  const lightTheme = selectedTheme === light;
  const accent = theme.modes[selectedTheme]?.accent;
  const mountbattenPink = theme.colors.neutral.mountbattenPink;
  const tropicalIndigo = theme.colors.neutral.tropicalIndigo;
  const complimentSetColor = lightTheme ? mountbattenPink : tropicalIndigo;
  const error = theme.modes[selectedTheme]?.error;
  const primarySetColor = earnSpentSelected ? accent : error;
  const secondSetColor = earnSpentSelected ? error : secondaryColor;
  const workingCategorySpending = categorySpending?.find(category => category.category._id === selectedTrend);

  const getData = args => {
    const { isMainMetric } = args || {};

    switch (selectedTrend) {
      case 0:
        return isMainMetric
          ? earned?.[yearToDate ? 'earnedThisYearChartData' : 'earnedThisMonthChartData'] || []
          : totalSpent?.[yearToDate ? 'totalSpentThisYearChartData' : 'totalSpentThisMonthChartData'] || [];

      default:
        return isMainMetric
          ? workingCategorySpending
            ?.[yearToDate ? 'categorySpentThisYearChartData' : 'categorySpentThisMonthChartData'] || []
          : workingCategorySpending
            ?.[yearToDate ? 'categorySpentLastYearChartData' : 'categorySpentLastMonthChartData'] || [];
    }
  };

  const sharedOptions = { fill: false, borderWidth: desktopMode ? 4 : 3, tension: 0.25 };
  const earnedLastYearOrLastMonth = yearToDate ? earned?.earnedLastYearChartData : earned?.earnedLastMonthChartData;
  const spentLastYearOrLastMonth = yearToDate
    ? totalSpent?.totalSpentLastYearChartData
    : totalSpent?.totalSpentLastMonthChartData;

  const data = {
    labels: yearToDate ? monthLabels : dayLabels,
    datasets: [
      {
        data: getData({ isMainMetric: true }),
        borderColor: primarySetColor,
        pointColor: primarySetColor,
        pointBackgroundColor: primarySetColor,
        pointHighlightStroke: primarySetColor,
        ...sharedOptions,
      },
      {
        data: earnSpentSelected && earnedLastYearOrLastMonth,
        borderColor: complimentSetColor,
        pointColor: complimentSetColor,
        pointBackgroundColor: complimentSetColor,
        pointHighlightStroke: complimentSetColor,
        ...sharedOptions,
      },
      {
        data: getData({ isMainMetric: false }),
        borderColor: secondSetColor,
        pointColor: secondSetColor,
        pointBackgroundColor: secondSetColor,
        pointHightlightStroke: secondSetColor,
        ...sharedOptions,
      },
      {
        data: earnSpentSelected && spentLastYearOrLastMonth,
        borderColor: secondaryColor,
        pointColor: secondaryColor,
        pointBackgroundColor: secondaryColor,
        pointHightlightStroke: secondaryColor,
        ...sharedOptions,
      },
    ],
  };

  return data;
};

const TrendsChartContent = props => {
  const { yearToDate, secondaryColor, earnSpentSelected, selectedTrend } = props;
  const { mobileMode, tabletMode, bannerContent } = useSelector(state => state.app);
  const { trends } = useSelector(state => state.institutions);
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const { setMousePosition } = useChartContext();
  const desktopMode = !mobileMode && !tabletMode;
  const chartRef = useRef();
  const chartInstance = useRef(null);
  const onPrimary = theme.modes[selectedTheme]?.onPrimary;

  const options = useMemo(() => {
    const optionsArgs = { yearToDate, onPrimary, desktopMode };
    return getOptions(optionsArgs, setMousePosition);
  }, [ yearToDate, onPrimary, desktopMode, setMousePosition ]);

  const data = useMemo(() => {
    const dataArgs = {
      yearToDate,
      selectedTheme,
      secondaryColor,
      desktopMode,
      trends,
      earnSpentSelected,
      selectedTrend,
    };
    return getData(dataArgs);
  }, [ yearToDate, selectedTheme, secondaryColor, desktopMode, trends, earnSpentSelected, selectedTrend ]);

  useEffect(() => {
    const ctx = chartRef.current?.getContext('2d');
    Chart.defaults.font.family = 'Inter-SemiBold';
    Chart.defaults.font.size = 14;

    chartInstance.current?.destroy();
    chartInstance.current = new Chart(ctx, {
      type: 'line',
      data,
      options: {
        ...options,
        plugins: {
          verticalLinePlugin: {
            mousePosition: null,
          },
          legend: {
            display: false,
          },
        },
      },
    });

    const handleResize = () => {
      chartInstance.current?.destroy();
      chartInstance.current = new Chart(ctx, {
        type: 'line',
        data,
        options: {
          ...options,
          plugins: {
            verticalLinePlugin: {
              mousePosition: null,
            },
            legend: {
              display: false,
            },
          },
        },
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      chartInstance.current?.destroy();
    };
  }, [ data, options, setMousePosition ]);

  return (
    <StyledChart $bannerContent={bannerContent}>
      <canvas ref={chartRef} />
    </StyledChart>
  );
};

const TrendsChart = props => (
  <ChartProvider>
    <TrendsChartContent {...props} />
  </ChartProvider>
);

export { TrendsChart };
