import { Fragment } from 'react';
import { Footnote, XymSpacer } from 'components';
import _ from 'lodash';

const Note = props => {
  const { expensesData } = props;

  return (
    <Fragment>
      <Footnote maxWidth={38}>
        {!_.isEmpty(expensesData) && (
          <>
            Exporting will only include expenses and goals for the selected account.
            To back up multiple accounts, export each one individually &mdash;&nbsp;
          </>
        )}

        {!_.isEmpty(expensesData) ? 'a' : 'A'}uto-fund sources will need to be re-assigned to newly imported expenses.
      </Footnote>

      <XymSpacer size={0.125} />
    </Fragment>
  );
};

export { Note };