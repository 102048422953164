import { Fragment, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { appConstants } from 'modules';
import { Font, NoData, PaymentForm, XymConfirm, XymSpacer } from 'components';
import { StyledDetails, Actions, DetailsHolder } from './styles';
import { Button } from 'xerum';
import { PaymentMethod } from './PaymentMethod';
import { withTheme } from 'styled-components';
import _ from 'lodash';

const { light } = appConstants.themes;

const Payments = withTheme(props => {
  const { theme } = props;
  const [ confirmContent, setConfirmContent ] = useState(null);
  const { mobileMode, tabletMode, tabletLandscapeMode } = useSelector(state => state.app);
  const { paymentData, paymentDataLoading } = useSelector(state => state.stripe);
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const desktopMode = !mobileMode && !tabletMode && !tabletLandscapeMode;
  const offWhite = theme.modes[selectedTheme]?.offWhite;
  const darkGrey = theme.modes[selectedTheme]?.darkGrey;
  const lightTheme = selectedTheme === light;
  const titleColor = lightTheme ? darkGrey : offWhite;
  const paymentMethods = useMemo(() => paymentData?.methods || [], [ paymentData ]);

  const handleAddPayment = () => {
    const payload = <PaymentForm setConfirmContent={setConfirmContent} />;
    setConfirmContent(payload);
  };

  const buildPayments = () => {
    if (paymentDataLoading) {
      return <NoData text='Loading payment methods...' />;
    }

    if (!_.isEmpty(paymentMethods)) {
      return paymentMethods.map((method, index) => {
        const { id } = method;
        const lastItem = index === paymentMethods.length - 1;

        return (
          <Fragment key={id || _.uniqueId('method_')}>
            <PaymentMethod method={method} />
            {!lastItem && <XymSpacer size={1.97} />}
          </Fragment>
        );
      });
    }

    return (
      <NoData
        icon='fa-solid fa-credit-card'
        text='No payment methods'
        subText='Add a payment method, below.'
      />
    );
  };

  return (
    <StyledDetails>
      <Font weight='semibold' size={1.25} mobileSize={1} tabletSize={1} color={titleColor}>
        Payment Methods
      </Font>

      <DetailsHolder>
        <XymSpacer size={1.75} />
        {buildPayments()}
        <XymSpacer size={1.75} />
      </DetailsHolder>

      <Actions>
        <Button
          theme={theme}
          selectedTheme={selectedTheme}
          text={<Font weight='medium' mobileSize={0.875}>Add payment method</Font>}
          callback={handleAddPayment}
        />

        {desktopMode && <XymSpacer size={0.5} />}
      </Actions>

      <XymConfirm
        confirmContent={confirmContent}
        useOverflow={mobileMode}
        blank={true}
        onClose={() => setConfirmContent(null)}
      />
    </StyledDetails>
  );
});

export { Payments };