import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { expensesActions } from 'modules';
import { XymSpacer, Footnote, Font, XymConfirmButtons } from 'components';
import { StyledIncomeInUseConfirm, RemovedItem } from './styles';
import { withTheme } from 'styled-components';

const IncomeInUseConfirm = withTheme(props => {
  const {
    theme,
    assignedExpenses = 0,
    autoFundSource,
    setConfirmContent,
    callback,
  } = props;

  const { tokenInfo } = useSelector(state => state.auth);
  const { generalPreferences: { selectedTheme, privacyMode } } = useSelector(state => state.preferences);
  const { selectedAccountId } = useSelector(state => state.institutions);
  const { autoFundSourcesLoading, expensesDatLoading } = useSelector(state => state.expenses);
  const token = tokenInfo?.refreshToken || tokenInfo?.accessToken;
  const error = theme.modes[selectedTheme]?.error;
  const errorHover = theme.modes[selectedTheme]?.errorHover;
  const dispatch = useDispatch();

  const removeAutoFundSource = useCallback((payload, callbacks) => {
    dispatch(expensesActions.removeAutoFundSource(payload, callbacks));
  }, [ dispatch ]);

  return (
    <StyledIncomeInUseConfirm>
        <Font weight='semibold'>
          You are removing an {assignedExpenses > 0 ? 'active ' : ''}
          income source &mdash; are you sure you want to remove the following?
        </Font>

        <XymSpacer size={1.875} />

        <RemovedItem>
          <XymSpacer across={true} />

          <Font weight='semibold' block={true}>
            {autoFundSource?.name || 'Missing name'}<br />

            <Font weight='regular'>
              Assigned to {assignedExpenses} {assignedExpenses === 1 ? 'expense' : 'expenses'}
            </Font>
          </Font>

          <XymSpacer across={true} />
        </RemovedItem>

        <XymSpacer size={1.875} />

        <Font>
          Your transaction will continue updating once you confirm.
        </Font>

        <XymSpacer />

        <Footnote>
          Auto-fund source will be unassigned from any attached expenses,
          and removed. This merchant will no longer be available in the auto-fund
          source list.  You can recategorize this merchant with an income category to make it
          available as an auto-fund source again &mdash; the auto-fund source will need to be
          recreated and reassigned to expenses.
        </Footnote>

        <XymSpacer />

        <XymConfirmButtons
          confirmText='Continue'
          privacy={privacyMode}
          setConfirmContent={setConfirmContent}
          confirmColor={error}
          confirmHoverColor={errorHover}
          disabled={autoFundSourcesLoading || expensesDatLoading}
          callback={() => {
            const callbacks = {
              onSuccess: callback,
            };

            const payload = {
              id: autoFundSource?._id,
              linkedAccountId: selectedAccountId,
              token,
            };

            removeAutoFundSource(payload, callbacks);
          }}
        />
      </StyledIncomeInUseConfirm>
  );
});

export { IncomeInUseConfirm };