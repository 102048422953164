import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { ExpenseIcon, Font, XymSpacer, P } from 'components';
import { ImportExportMode } from './styles';
import { withTheme } from 'styled-components';
import _ from 'lodash';

const Merge = withTheme(forwardRef((props, ref) => {
  const { theme, mergeActive, setImportData, setImportMode, setIsMergeOperation } = props;

  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const { expensesData } = useSelector(state => state.expenses);
  const paynesGrey = theme.colors.neutral.paynesGrey;

  return (
    <ImportExportMode
      $theme={theme}
      $selectedTheme={selectedTheme}
      $active={mergeActive}
      $visible={!_.isEmpty(expensesData)}
      onClick={() => {
        ref.current.value = '';
        setImportData(null);
        setImportMode(true);
        setIsMergeOperation(true);
        ref.current.click();
      }}
    >
      <ExpenseIcon
        icon='fa-solid fa-code-merge'
        bgColorDark={mergeActive && paynesGrey}
        size={3}
        fontSize={1.5}
        alwaysVisible={true}
      />

      <div>
        <Font weight='semibold'>
          Merge backup expenses with existing expenses.
        </Font>

        <XymSpacer size={0.125} />

        <P size={0.75}>
          Keeps existing expenses and goals, while adding imported expenses.
          Expenses that already exist with the same name will be skipped &mdash; keeping the existing version.
        </P>
      </div>
    </ImportExportMode>
  );
}));

export { Merge };