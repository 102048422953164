import { H3, P, Center, XymSpacer } from 'components';
import { Goals, FullWidth } from './styles';

const {
  VITE_APP_NAME: appName,
  VITE_GCP_STORAGE_BUCKET: storageBucket,
} = process.env;

const AboutGoals = () => {
  const src = `${storageBucket}/Waitlist/Goal.png`;

  return (
    <Goals>
      <Center>
        <img src={src} id='goal' alt='Goal preview.' />
      </Center>

      <FullWidth>
        <H3>Save with Goals</H3>
        <XymSpacer />
        <P>
          Emergencies, a nest-egg, maybe a vacation? Whatever your goal is,
          {appName} can help you achieve it.  Similar to expenses, you
          can set what you need, when you need it, and your goal will
          automatically move funds, daily, until your goal is fully funded.
        </P>
      </FullWidth>
    </Goals>
  );
};

export { AboutGoals };