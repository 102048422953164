import { appConstants } from 'modules';
import { getColor } from 'helpers';
import { theme } from 'theme';
import styled, { css } from 'styled-components';

const { light } = appConstants.themes;
const colors = theme.colors;

export const ImportExportContainer = styled('div')`
  display: grid;
  grid-template-rows: repeat(3, auto);
  gap: 1rem;
  width: fit-content;
`;

export const ImportExportMode = styled('div')`
  display: ${props => props.$visible ? 'inline-flex' : 'none'};
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  width: 100%;
  padding: 1rem;
  border-radius: 0.3rem;
  cursor: pointer;
  user-select: none;
  border: 0.0625rem solid ${props => {
    const lightTheme = props.$selectedTheme === light;
    const paynesGrey = colors.neutral.paynesGrey;
    const frenchGrey = colors.neutral.frenchGrey;
    const borderColor = lightTheme ? frenchGrey : paynesGrey;

    return props.$isActive ? borderColor : 'transparent';
  }};

${props => props.$active && css`
    background-color: ${props => {
      const lightTheme = props.$selectedTheme === light;
      const color = getColor(props, lightTheme ? 'darkGrey' : 'offWhite');
      return color;
    }};
    color: ${props => getColor(props, 'primary')};
  `}

  @media (hover: hover) {
    &:hover {
      background-color: ${props => {
        const lightTheme = props.$selectedTheme === light;
        const bgColor = getColor(props, lightTheme ? 'lightGrey' : 'darkGrey') + (lightTheme ? 50 : '');

        return !props.$active && bgColor;
      }};
    }
  }
`;