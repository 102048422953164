
export const getUserPreferences = args => {
  const { type, payload, callbacks } = args;
  const { token } = payload;

  return {
    type,
    path: '/users/preferences',
    method: 'GET',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    ...callbacks,
  };
};

export const updateUserPreferences = args => {
  const { type, payload, callbacks } = args;
  const { token, ...withoutToken } = payload;

  return {
    type,
    path: '/users/preferences',
    method: 'PUT',
    body: JSON.stringify(withoutToken),
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    ...callbacks,
  };
};