import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { appConstants } from 'modules';
import { withTheme } from 'styled-components';
import { Font, XymSpacer } from 'components';
import { Address, CityStateZip } from './styles';

const { light } = appConstants.themes;

const TxAddress = withTheme(props => {
  const { theme, transaction } = props;
  const { generalPreferences: { privacyMode, selectedTheme } } = useSelector(state => state.preferences);
  const lightTheme = selectedTheme === light;
  const onPrimary = theme.modes[selectedTheme]?.onPrimary;
  const frenchGrey = theme.colors.neutral.frenchGrey;
  const { data } = transaction || {};
  const { location } = data || {};
  const { address, city, region, postal_code, country } = location || {};
  const hasAddress = address && city && region && postal_code;

  return (
    <Address $visible={hasAddress}>
      <XymSpacer size={1.5} />

      <Font weight='semibold'>
        Address
      </Font>

      <Font size={0.875}>
        {privacyMode ? 'Private' : address}
      </Font>

      {!privacyMode && (
        <Font size={0.875}>
          <CityStateZip>
            {city} {region}, {postal_code}

            {country && (
              <Fragment>
                <XymSpacer size={0.5} across={true} />
                <Font color={lightTheme ? frenchGrey : onPrimary + 50}>|</Font>
                <XymSpacer size={0.5} across={true} />

                {country}
              </Fragment>
            )}
          </CityStateZip>
        </Font>
      )}
    </Address>
  );
});

export { TxAddress };