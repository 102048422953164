import { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { appActions, expensesActions, appConstants } from 'modules';
import { exportExpenses, normalizeExpenses } from 'helpers';
import { XymSpacer, Font, XymConfirmButtons, FileUploader, H4, NoData, Center } from 'components';
import { RemoveAndRestore } from './RemoveAndRestore';
import { Merge } from './Merge';
import { Export } from './Export';
import { FileArea } from './FileArea';
import { Note } from './Note';
import { ImportExportContainer } from './styles';
import { withTheme } from 'styled-components';
import { Loading } from 'xerum';
import _ from 'lodash';

const { themes } = appConstants;
const light = themes.light;

const ImportExportWizard = withTheme(props => {
  const { theme, setConfirmContent } = props;
  const [ isMergeOperation, setIsMergeOperation ] = useState(false);
  const [ importMode, setImportMode ] = useState(null);
  const [ importData, setImportData ] = useState(null);
  const { institutionsData, selectedAccountId } = useSelector(state => state.institutions);
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const { tokenInfo } = useSelector(state => state.auth);
  const { expensesData, expensesImporting } = useSelector(state => state.expenses);

  const lightTheme = selectedTheme === light;
  const token = tokenInfo?.refreshToken || tokenInfo?.accessToken;
  const replaceAllActive = importMode && !isMergeOperation && !_.isEmpty(importData);
  const mergeActive = importMode && isMergeOperation && !_.isEmpty(importData);
  const exportActive = importMode === false && !isMergeOperation;
  const noSelection = !replaceAllActive && !mergeActive && !exportActive;
  const onAccent = theme.modes[selectedTheme]?.onAccent;
  const grey = theme.modes[selectedTheme]?.grey;
  const paynesGrey = theme.colors.neutral.paynesGrey;
  const disabledTextColor = lightTheme ? grey : paynesGrey;
  const inputRef = useRef(null);
  const fileName = inputRef.current?.files?.[0]?.name || '';
  const fileExtension = fileName.split('.').pop();
  const dispatch = useDispatch();

  const addNotification = useCallback(payload => {
    dispatch(appActions.addNotification(payload));
  }, [ dispatch ]);

  const importExpenses = useCallback((payload, callbacks) => {
    dispatch(expensesActions.importExpenses(payload, callbacks));
  }, [ dispatch ]);

  const getConfirmText = () => {
    if (!selectedAccountId) return 'Awaiting link...';
    if (!importData && _.isEmpty(expensesData)) return 'No expenses';
    if (noSelection) return 'Awaiting selection...';

    if (expensesImporting) {
      return (
        <Loading
          theme={theme}
          selectedTheme={selectedTheme}
          isLoading={expensesImporting}
          iconColor={expensesImporting ? disabledTextColor : onAccent}
          textColor={expensesImporting ? disabledTextColor : onAccent}
          noFailIcon={true}
          text={<Font size={1} weight='semibold'>Importing...</Font>}
          failText={<Font weight='semibold'>Import failed</Font>}
        />
      );
    }

    if (exportActive) return 'Export expenses';
    if (mergeActive) return 'Merge expenses';

    return 'Restore expenses';
  };

  const handleImportExport = () => {
    if (importMode) {
      const payload = {
        importData,
        isMergeOperation,
        linkedAccountId: selectedAccountId,
        meta: { noLoadingState: true },
        token,
      };

      const callbacks = {
        onSuccess: () => setConfirmContent(null),
      };

      normalizeExpenses({
        importData,
        isMergeOperation,
        addNotification,
        callback: () => importExpenses(payload, callbacks),
      });

      return;
    }

    exportExpenses({
      institutionsData,
      selectedAccountId,
      expensesData,
      callback: () => setConfirmContent(null),
    });
  };

  const handleFileUpload = e => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = e => setImportData(JSON.parse(e.target?.result));
      reader.readAsText(file);
    }
  };

  useEffect(() => {
    return () => setImportData(null);
  }, []);

  return (
    <div>
      <H4>
        {selectedAccountId && 'Select option to begin...'}
      </H4>

      <XymSpacer />

      <FileUploader ref={inputRef} accept='.json' callback={handleFileUpload} />

      {!selectedAccountId && (
        <Center>
          <NoData
            icon='fa-solid fa-link'
            text='No linked institution(s)'
            subText='Link to begin importing or exporting data.'
          />

          <XymSpacer size={2} />
        </Center>
      )}

      {selectedAccountId && (
        <ImportExportContainer>
          <RemoveAndRestore
            ref={inputRef}
            replaceAllActive={replaceAllActive}
            setImportData={setImportData}
            setImportMode={setImportMode}
            setIsMergeOperation={setIsMergeOperation}
          />

          <Merge
            ref={inputRef}
            mergeActive={mergeActive}
            setImportData={setImportData}
            setImportMode={setImportMode}
            setIsMergeOperation={setIsMergeOperation}
          />

          <Export
            ref={inputRef}
            exportActive={exportActive}
            setImportData={setImportData}
            setImportMode={setImportMode}
            setIsMergeOperation={setIsMergeOperation}
          />

          <FileArea
            ref={inputRef}
            importData={importData}
            fileName={fileName}
            fileExtension={fileExtension}
            setImportData={setImportData}
          />

          <Note expensesData={expensesData} />
        </ImportExportContainer>
      )}

      <XymConfirmButtons
        confirmText={getConfirmText()}
        setConfirmContent={setConfirmContent}
        disabled={expensesImporting || (!importData && _.isEmpty(expensesData)) || noSelection}
        callback={handleImportExport}
      />
    </div>
  );
});

export { ImportExportWizard };