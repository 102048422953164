import { useSelector } from 'react-redux';
import { Font, SettingsNav } from 'components';
import { StyledSupport, Layout, SupportContent } from './styles';
import { Details } from './Details';

const Support = () => {
  const { tokenInfo } = useSelector(state => state.auth);

  const buildSections = () => {
    if (tokenInfo?.accessToken) {
      return <Details />;
    }

    return <Font weight='semibold'>No token found.</Font>;
  };

  return (
    <StyledSupport>
      <Layout>
        <SettingsNav />

        <SupportContent>
          {buildSections()}
        </SupportContent>
      </Layout>
    </StyledSupport>
  );
};

export { Support };