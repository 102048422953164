import { AutoFundSourcesForm } from 'components';
import _ from 'lodash';

export const autoFundTabs = args => {
  const { setConfirmContent, autoFundSources } = args;

  const tabContent = [
    {
      name: 'Add new',
      content: (
        <AutoFundSourcesForm setConfirmContent={setConfirmContent} />
      ),
    },
  ];

  if (!_.isEmpty(autoFundSources)) {
    tabContent.push({
      name: 'Edit existing',
      content: !_.isEmpty(autoFundSources) && (
        <AutoFundSourcesForm edit={true} setConfirmContent={setConfirmContent} />
      ),
    });
  }

  return tabContent;
};