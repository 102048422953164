import { useSelector } from 'react-redux';
import { appConstants } from 'modules';
import { Font, SupportForm } from 'components';
import { StyledDetails, DetailsHolder } from './styles';
import { withTheme } from 'styled-components';
import { Spacer } from 'xerum';

const { light } = appConstants.themes;

const Details = withTheme(props => {
  const { theme } = props;
  const { bannerContent } = useSelector(state => state.app);
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const darkGrey = theme.modes[selectedTheme]?.darkGrey;
  const offWhite = theme.modes[selectedTheme]?.offWhite;
  const lightTheme = selectedTheme === light;

  return (
    <StyledDetails>
      <Font weight='semibold' size={1.25} mobileSize={1} tabletSize={1} color={lightTheme ? darkGrey : offWhite}>
        Get in touch
      </Font>

      <DetailsHolder $bannerContent={bannerContent}>
        <Spacer size={2} />
        <SupportForm />
      </DetailsHolder>
    </StyledDetails>
  );
});

export { Details };