import { H1, P, Center, XymSpacer } from 'components';
import { ExpensesAndGoals } from './styles';

const {
  VITE_APP_NAME: appName,
  VITE_GCP_STORAGE_BUCKET: storageBucket,
} = process.env;

const AboutExpenses = () => {
  const src = `${storageBucket}/Waitlist/Expenses.png`;

  return (
    <ExpensesAndGoals>
      <Center>
        <H1>Expenses and Goals</H1>
        <XymSpacer />
        <P>
          Tell {appName} how much you need, when you need it, and how often
          you need it. Add any merchant or category to that Expense and
          watch as {appName} automatically deducts from your Expense when a
          charge from that merchant or category posts to your account.
        </P>
      </Center>

      <Center>
        <img src={src} id='expenses' alt='Expenses preview.' />
      </Center>
    </ExpensesAndGoals>
  );
};

export { AboutExpenses };