import { useEffect, useCallback, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { expensesSortOptions, expensesActions, preferencesActions } from 'modules';
import {
  Font,
  Expense,
  ExpenseSlideOver,
  ExpenseForm,
  XymFilter,
  XymSelect,
  XymConfirm,
  NoData,
  XymSpacer,
  CompactExpense,
  MoveMoneyForm,
  PlaidLink,
} from 'components';
import { Button, Loading, Modal } from 'xerum';
import { StyledExpenses, Layout, ExpenseHolder, TitleArea, MobileActions } from './styles';
import { withTheme } from 'styled-components';
import _ from 'lodash';

const Expenses = withTheme(props => {
  const { theme, onGoals } = props;
  const { mobileMode, tabletMode, tabletLandscapeMode } = useSelector(state => state.app);
  const { expensesData, expensesDataLoading, slideOverExpenseId } = useSelector(state => state.expenses);

  const {
    expensesPreferences,
    goalsPreferences,
    generalPreferences: { selectedTheme },
  } = useSelector(state => state.preferences);

  const { selectedAccountId, institutionDataLoading, institutionsData } = useSelector(state => state.institutions);
  const { tokenInfo } = useSelector(state => state.auth);
  const { expenseTypes, bannerContent } = useSelector(state => state.app);
  const token = tokenInfo?.refreshToken || tokenInfo?.accessToken;
  const hasInstitutions = !_.isEmpty(institutionsData);
  const expensesSortId = expensesPreferences?.sortId;
  const goalsSortId = goalsPreferences?.sortId;
  const desktopMode = !mobileMode && !tabletMode && !tabletLandscapeMode;
  const mobileDevice = mobileMode || tabletMode || tabletLandscapeMode;
  const expenses = useMemo(() => expensesData?.filter(expense => !expense.isGoal) || [], [ expensesData ]);
  const goals = useMemo(() => expensesData?.filter(expense => expense.isGoal) || [], [ expensesData ]);
  const [ expenseData, setExpenseData ] = useState(onGoals ? goals : expenses);

  const [ removeConfirmContent, setRemoveConfirmContent ] = useState(null);
  const [ autoFundConfirmContent, setAutoFundConfirmContent ] = useState(null);
  const [ autoSpendConfirmContent, setAutoSpendConfirmContent ] = useState(null);
  const [ moveMoneyConfirmContent, setMoveMoneyConfirmContent ] = useState(null);
  const [ learnMoreContent, setLearnMoreContent ] = useState(null);
  const [ selectedGoal, setSelectedGoal ] = useState(goalsSortId);
  const [ selectedExpense, setSelectedExpense ] = useState(expensesSortId);
  const [ dragOverId, setDragOverId ] = useState(null);
  const [ filteredData, setFilteredData ] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const setSlideOverExpenseId = useCallback((payload, callbacks) => {
    dispatch(expensesActions.setSlideOverExpenseId(payload, callbacks));
  }, [ dispatch ]);

  const getExpensesData = useCallback((payload, callbacks) => {
    dispatch(expensesActions.getExpensesData(payload, callbacks));
  }, [ dispatch ]);

  const updateUserPreferences = useCallback((payload, callbacks) => {
    dispatch(preferencesActions.updateUserPreferences(payload, callbacks));
  }, [ dispatch ]);

  const handleExpenseOrGoal = () => {
    setSlideOverExpenseId(!slideOverExpenseId);
  };

  useEffect(() => {
    setSelectedGoal(goalsSortId);
  }, [ goalsSortId, setSelectedGoal ]);

  useEffect(() => {
    setSelectedExpense(expensesSortId);
  }, [ expensesSortId, setSelectedExpense ]);

  useEffect(() => {
    const newData = expensesData?.filter(expense => onGoals ? expense.isGoal : !expense.isGoal)
      .map(expense => {
        const { expenseTypeId } = expense;
        const expenseTypeName = expenseTypes?.find(type => type._id === expenseTypeId)?.name || '';

        // We are finding the corresponding expense type's friendly name, and injecting it into the expense object
        // so users can filter "Essential", "Debt" or "Personal spending" expenses, instead of using raw ID's: 1, 2, 3.
        return { ...expense, expenseTypeName: expenseTypeName };
      });

    if (!_.isEmpty(expensesData) && !_.isEqual(expenseData, newData)) {
      setExpenseData(newData);
    }
  }, [ onGoals, expensesData, expenseData, expenseTypes, setExpenseData ]);

  const handleExpenseEdit = (_id, expense) => {
    const isDifferent = slideOverExpenseId !== expense?._id;

    if (!_.isEmpty(slideOverExpenseId) && isDifferent) {
      setSlideOverExpenseId(expense?._id);
      return;
    }

    setSlideOverExpenseId(isDifferent ? expense?._id : null);
  };

  const searchKeys = [
    'expenseData.name',
    'expenseData.needed',
    'expenseData.expenseTypeName',
    'expenseData.autoSpendTags.name',
    'expenseData.autoSpendTags.merchantName',
  ];

  const buildExpenses = () => {
    if (!_.isEmpty(onGoals ? goals : expenses) && !_.isEmpty(filteredData)) {
      return expenseData?.map((expense, index) => {
        const { _id, name, needed, expenseTypeName, autoSpendTags } = expense;
        const filterMatch = filteredData?.includes(name)
          || filteredData?.includes(needed)
          || autoSpendTags?.find(tag => filteredData?.includes(tag?.name))
          || autoSpendTags?.find(merchantName => filteredData?.includes(merchantName))
          || filteredData?.includes(expenseTypeName);

        if (filterMatch) {
          return mobileMode
            ? <CompactExpense
                key={_id || index}
                setAutoSpendConfirmContent={setAutoSpendConfirmContent}
                callback={() => handleExpenseEdit(_id, expense)}
                expense={expense}
                dragOverId={dragOverId}
                setDragOverId={setDragOverId}
              />
            : <Expense
                key={_id || index}
                setAutoSpendConfirmContent={setAutoSpendConfirmContent}
                callback={() => handleExpenseEdit(_id, expense)}
                expense={expense}
                dragOverId={dragOverId}
                setDragOverId={setDragOverId}
              />;
        }
      });
    }

    if (_.isEmpty(onGoals ? goals : expenses)) {
      const mode = onGoals ? 'goal' : 'expense';

      return (
        <NoData
          icon='fa-solid fa-calendar-days'
          text={`No ${onGoals ? 'goals' : 'expenses'} found`}
          subText={
            `Select ${mobileDevice ? 'New ' : '+'}${_.capitalize(mode)}, ${mobileDevice ? 'below' : 'above'}.`
          }
        />
      );
    }

    if (_.isEmpty(filteredData)) {
      return (
        <NoData
          text={`No ${onGoals ? 'goals' : 'expenses'} found`}
          subText='Try adjusting your search.'
          icon='fa-solid fa-search'
        />
      );
    }
  };

  const handleDrop = e => {
    e.preventDefault();
    const draggedExpenseId = e.dataTransfer.getData('text/plain');
    const droppedOnExpenseId = e.target.closest('[data-expense-id]')?.getAttribute('data-expense-id');

    if (dragOverId) setDragOverId(null);

    if (draggedExpenseId && droppedOnExpenseId && draggedExpenseId !== droppedOnExpenseId) {
      setMoveMoneyConfirmContent(
        <MoveMoneyForm
          from={draggedExpenseId}
          to={droppedOnExpenseId}
          setConfirmContent={setMoveMoneyConfirmContent}
        />,
      );
    }
  };

  return (
    <>
      <StyledExpenses>
        <Layout $hasInstitutions={hasInstitutions && !expensesDataLoading}>
          {hasInstitutions && !expensesDataLoading && (
            <TitleArea>
              <XymFilter
                placeholder={(
                  `Name, type, auto-spend${!mobileMode ? ' tag, or' : ','} amount${!mobileMode ? ' needed' : ''}...`
                )}
                visible={!onGoals}
                data={{ expenseData }}
                callback={results => setFilteredData(results)}
                include={searchKeys}
              />

              <XymFilter
                placeholder='Name, or amount needed...'
                visible={onGoals}
                data={{ expenseData }}
                callback={results => setFilteredData(results)}
                include={searchKeys}
              />

              {!desktopMode && <XymSpacer size={0.25} />}

              <XymSelect
                data={expensesSortOptions}
                name='expenseSortOptions'
                localDefault={onGoals ? selectedGoal : selectedExpense}
                noResultsText='Sort option not found'
                noSpacing={!desktopMode}
                callback={newValue => {
                  if (!_.isEqual(onGoals ? selectedGoal : selectedExpense, +newValue)) {
                    const expensesPayload = {
                      token,
                      linkedAccountId: selectedAccountId,
                      [onGoals ? 'goalsSortIdOverride' : 'expensesSortIdOverride']: +newValue,
                    };

                    onGoals ? setSelectedGoal(+newValue) : setSelectedExpense(+newValue);
                    updateUserPreferences({ token, [onGoals ? 'goalsSortId' : 'expensesSortId']: +newValue });
                    getExpensesData(expensesPayload);
                  }
                }}
              />
            </TitleArea>
          )}

          <Loading
            theme={theme}
            selectedTheme={selectedTheme}
            isLoading={institutionDataLoading || expensesDataLoading}
            hasData={hasInstitutions}
            noFailIcon={true}
            failText={<PlaidLink description={true} />}
            text={
              <Font size={1} weight='semibold'>
                Loading {onGoals ? 'goals' : 'expenses'}...
              </Font>
            }
          >
            <ExpenseHolder
              id='expenseHolder'
              $hasExpenses={!expensesDataLoading && !_.isEmpty(onGoals ? goals : expenses)}
              $noResults={_.isEmpty(filteredData)}
              $bannerContent={bannerContent}
              onDragOver={e => e.preventDefault()}
              onDrop={handleDrop}
            >
              {buildExpenses()}
            </ExpenseHolder>

            <MobileActions $visible={hasInstitutions && mobileDevice}>
              <Button
                theme={theme}
                selectedTheme={selectedTheme}
                callback={handleExpenseOrGoal}
                text={<Font weight='medium' mobileSize={0.875}>New {onGoals ? 'goal' : 'expense'}</Font>}
              />

              <Button
                theme={theme}
                selectedTheme={selectedTheme}
                callback={() => navigate(`/${onGoals ? 'expenses' : 'goals'}`)}
                text={<Font weight='medium' mobileSize={0.875}>Manage {onGoals ? 'expenses' : 'goals'}</Font>}
              />
            </MobileActions>
          </Loading>

        </Layout>
      </StyledExpenses>

      <ExpenseSlideOver
        isGoal={onGoals}
        content={(
          <ExpenseForm
            isGoal={onGoals}
            setRemoveConfirmContent={setRemoveConfirmContent}
            setAutoFundConfirmContent={setAutoFundConfirmContent}
            setAutoSpendConfirmContent={setAutoSpendConfirmContent}
            setLearnMoreContent={setLearnMoreContent}
          />
        )}
      />

      <XymConfirm
        confirmContent={removeConfirmContent}
        blank={true}
        onClose={() => setRemoveConfirmContent(null)}
      />

      <XymConfirm
        confirmContent={moveMoneyConfirmContent}
        useOverflow={false}
        blank={true}
        onClose={() => setMoveMoneyConfirmContent(null)}
      />

      <XymConfirm
        confirmContent={autoFundConfirmContent}
        useOverflow={false}
        blank={true}
        onClose={() => setAutoFundConfirmContent(null)}
      />

      <XymConfirm
        confirmContent={autoSpendConfirmContent}
        useOverflow={false}
        blank={true}
        onClose={() => setAutoSpendConfirmContent(null)}
      />

      <Modal
        theme={theme}
        selectedTheme={selectedTheme}
        titleText={learnMoreContent?.title}
        visible={!_.isEmpty(learnMoreContent)}
        bgClose={true}
        onClose={() => setLearnMoreContent(null)}
      >
        {learnMoreContent?.content}
      </Modal>
    </>
  );
});

export { Expenses };