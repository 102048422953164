/* eslint-disable max-len */
import { useSelector } from 'react-redux';
import { getColor } from 'helpers';
import styled, { withTheme } from 'styled-components';

const SearchIconSVG = styled('svg')`
  width: ${props => props.$width || 1.3125}rem;
  height: ${props => props.$height || 1.3125}rem;
  fill: ${props => props.color || getColor(props, 'onPrimary')};
  stroke: ${props => props.color || getColor(props, 'onPrimary')};
  cursor: pointer;
`;

const SearchIcon = withTheme(props => {
  const { theme, color, width, height } = props;
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);

  return (
    <SearchIconSVG
      $theme={theme}
      $selectedTheme={selectedTheme}
      $width={width}
      $height={height}
      color={color}
      viewBox='0 0 21 21'
      fill='currentColor'
      stroke='currentColor'
    >
      <circle cx='8.44719' cy='8.44714' r='4.97306' transform='rotate(-45 8.44719 8.44714)' fill='#00000000' strokeWidth='2'/>
      <path d='M14.9279 16.6256C15.3033 17.0307 15.936 17.0548 16.3411 16.6794C16.7462 16.304 16.7703 15.6713 16.3949 15.2662L14.9279 16.6256ZM11.3339 12.7471L14.9279 16.6256L16.3949 15.2662L12.8009 11.3877L11.3339 12.7471Z' stroke='none' />
    </SearchIconSVG>
  );
});

export { SearchIcon };