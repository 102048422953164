import { appConstants } from 'modules';
import styled, { css } from 'styled-components';

const { layout, spacing, breakpoints } = appConstants;
const { layoutWidth } = layout;
const { extraLargeGap, largeGap, mediumGap, gap, smallGap, extraSmallGap } = spacing;
const { tabletLandscape, mobile } = breakpoints;

export const StyledExpenses = styled('div')`
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const Layout = styled('div')`
  display: ${props => props.$hasInstitutions ? 'grid' : 'flex'};
  align-items: ${props => props.$hasInstitutions ? 'flex-start' : 'center'};
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  gap: ${mediumGap}rem ${extraLargeGap}rem;
  grid-template-areas:
    'title'
    'expenses';

  max-width: ${layoutWidth}rem;
  ${props => (props.$hasInstitutions) && css`width: 100%;`}
  padding: ${largeGap}rem ${extraLargeGap}rem;

  @media screen and (max-width: ${layoutWidth}rem) {
    gap: ${gap + smallGap}rem;
    padding: ${mediumGap}rem;
  }

  @media screen and (max-width: ${tabletLandscape}rem) {
    display: flex;
    flex-direction: column;
    height: calc(100dvh - 12rem - 2rem);
    width: 100dvw;
  }

  @media screen and (max-width: ${mobile}rem) {
    padding: ${gap}rem;
    height: calc(100dvh - 10rem);
  }
`;

export const TitleArea = styled('div')`
  display: grid;
  grid-area: title;
  grid-template-columns: 1fr 20rem;
  grid-template-rows: 1fr;
  align-items: center;
  gap: 0 ${mediumGap}rem;
  width: 100%;

  @media screen and (max-width: ${tabletLandscape}rem) {
    display: flex;
    flex-direction: column-reverse;
    gap: ${extraSmallGap}rem;
  }
`;

export const ExpenseHolder = styled('div')`
  display: grid;
  grid-area: expenses;
  grid-template-columns: ${props => props.$hasExpenses && !props.$noResults ? 'repeat(2, 1fr)' : '1fr'};
  gap: 2rem ${largeGap}rem;
  padding: 0 ${gap + smallGap}rem;
  height: ${props => props.$hasExpenses && !props.$noResults ? 'fit-content' : '100%'};
  width: 100%;
  max-height: ${props => `calc(100dvh - ${props.$bannerContent ? 30 : 27}rem)`};
  overflow-y: auto;

  @media screen and (max-width: ${layoutWidth}rem) {
    max-height: ${props => `calc(100dvh - ${props.$bannerContent ? 27.5 : 24.5}rem)`};
    gap: 2.25rem 2.5rem;
  }

  @media screen and (max-width: ${tabletLandscape}rem) {
    display: flex;
    flex-direction: column;
    gap: ${mediumGap}rem;
    height: 100%;
    max-height: ${props => `calc(100dvh - ${props.$bannerContent ? 37.5 : 34.5}rem)`};
  }

  @media screen and (max-width: ${mobile}rem) {
    max-height: unset;
    height: ${props => `calc(100dvh - ${props.$bannerContent ? 31.25 : 28.25}rem)`};
  }
`;

export const MobileActions = styled('div')`
  display: ${props => props.$visible ? 'flex' : 'none'};
  flex-direction: column;
  gap: ${smallGap}rem;
  width: 100%;
`;