import { useSelector } from 'react-redux';
import { Font, XymSpacer } from 'components';
import { Button } from 'xerum';
import { StyledMFAActions } from './styles';
import { withTheme } from 'styled-components';
import _ from 'lodash';

const MFAActions = withTheme(props => {
  const { theme, visible } = props;
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);

  return (
    <StyledMFAActions $visible={visible}>
      <Button
        theme={theme}
        selectedTheme={selectedTheme}
        text={<Font weight='medium' mobileSize={0.875}>Add security key</Font>}
        callback={_.noop}
      />

      <XymSpacer mobileSize={0.5} />

      <Button
        theme={theme}
        selectedTheme={selectedTheme}
        text={<Font weight='medium' mobileSize={0.875}>Add authenticator</Font>}
        callback={_.noop}
      />
    </StyledMFAActions>
  );
});

export { MFAActions };