import { Font, Footnote, XymSpacer } from 'components';
import { PaymentMethod } from './PaymentMethod';
import { StyledRemoveConfirm, RemovedItem } from './styles';

const RemovePaymentConfirm = props => {
  const { method } = props;

  return (
    <StyledRemoveConfirm>
      <Font weight='semibold'>
        Are you sure you want to remove the following?
      </Font>

      <XymSpacer />

      <RemovedItem>
        <XymSpacer across={true} />
        <PaymentMethod method={method} hideActions={true} />
        <XymSpacer across={true} />
      </RemovedItem>

      <XymSpacer size={1.875} />

      <Footnote>
        Please be sure you have at least one other payment method added and set as
        default to avoid disruption of service.
      </Footnote>
    </StyledRemoveConfirm>
  );
};

export { RemovePaymentConfirm };