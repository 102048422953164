/* eslint-disable max-len */
import { useEffect } from 'react';
import { Loader } from 'google-maps';
import _ from 'lodash';

const useGoogleMaps = mapArgs => {
  const { apiKey, mapRef, locationData, transaction, fallbackMessage, offWhite, privacyMode } = mapArgs;

  useEffect(() => {
    if (mapRef.current && !fallbackMessage.current) {
      fallbackMessage.current = mapRef.current.innerHTML;
    }

    const loadMap = async () => {
      const { lat, lon: lng } = locationData || {};
      const readyToBuild = !privacyMode && mapRef.current && lat && lng && !_.isEmpty(transaction?.data);

      if (readyToBuild) {
        try {
          if (!window.google) {
            const loader = new Loader(apiKey);
            window.google = await loader.load();
          }

          mapRef.current.innerHTML = '';
          const config = { center: { lat, lng }, zoom: 11 };
          const map = new window.google.maps.Map(mapRef.current, config);
          const icon = {
            url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(
              `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="${offWhite}">` +
              `<path d="M16 0C9.383 0 4 5.383 4 12.001c0 4.667 3.13 10.572 9.391 17.618a2.406 2.406 0 0 0 3.218 0C24.869 22.573 28 16.668 28 12 28 5.383 22.617 0 16 0zm0 17a5 5 0 1 1 0-10 5 5 0 0 1 0 10z"/>` +
              `</svg>`,
            )}`,
            scaledSize: new window.google.maps.Size(48, 48),
          };
          const marker = new window.google.maps.Marker({ position: { lat, lng }, map, icon });
          return marker;
        } catch (error) {
          console.log(error);
        }
      }

      if (window.google && !readyToBuild) {
        mapRef.current.innerHTML = fallbackMessage.current;
      }

      return;
    };

    loadMap();
  }, [ locationData, mapRef, transaction, fallbackMessage, offWhite, privacyMode, apiKey ]);
};

export { useGoogleMaps };