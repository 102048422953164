
const messagesConstants = {
  actions: {
    GET_MESSAGES: 'modules/messages/GET_MESSAGES',
    UPDATE_MESSAGE: 'modules/messages/UPDATE_MESSAGE',
    DELETE_MESSAGE: 'modules/messages/DELETE_MESSAGE',
    MARK_ALL_MESSAGES_READ: 'modules/messages/MARK_ALL_MESSAGES_READ',
    SEND_SUPPORT_MESSAGE: 'modules/messages/SEND_SUPPORT_MESSAGE',
  },

  selectors: {
    STATE_KEY_MESSAGES_DATA: 'messagesData',
    STATE_KEY_MESSAGES_DATA_LOADING: 'messagesDataLoading',
  },
};

export { messagesConstants };