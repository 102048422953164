import { Font, Footnote, XymSpacer } from 'components';
import { StyledRemoveConfirm, RemovedItem } from './styles';
import moment from 'moment';

const UnsubscribeConfirm = props => {
  const { expires } = props;

  return (
    <StyledRemoveConfirm>
      <Font weight='semibold'>
        Are you sure you want to unsubscribe?
      </Font>

      <XymSpacer />

      <RemovedItem>
        <XymSpacer size={2.25} across={true} />

        <div>
          You will have access to all features until:
          <Font weight='semibold'>
            {moment(expires).format('MMMM Do, YYYY')}
          </Font>
        </div>

        <XymSpacer size={2.25} across={true} />
      </RemovedItem>

      <XymSpacer size={1.875} />

      <Footnote>
       Your account will be placed in an inactive state.
       You can always come back and resubscribe whenever you need to.
       Your expenses, goals and other app settings will be here for you when
       you are ready to return.
      </Footnote>
    </StyledRemoveConfirm>
  );
};

export { UnsubscribeConfirm };