import { Fragment, forwardRef } from 'react';
import styled from 'styled-components';

const StyledInput = styled('input')`
  visibility: hidden;
  position: absolute;
`;

const FileUploader = forwardRef((props, ref) => {
  const { callback, ...rest } = props;

  return (
    <Fragment>
      <StyledInput ref={ref} type='file' onChange={callback} {...rest} />
    </Fragment>
  );
});

export { FileUploader };