import styled from 'styled-components';
import { Font } from 'components';

const StyledDeviceInfoItem = styled('div')`
  span {
    display: inline-flex;
  }
`;

const TitleValueHolder = styled('div')`
  display: inline-flex;
`;

const DeviceInfoItem = props => {
  const { title, value, wrap } = props;

  return (
    <StyledDeviceInfoItem>
      <TitleValueHolder>
        <Font weight='semibold' size={0.75}>
          {title}:&nbsp;
        </Font>

        <Font size={0.75} wrap={wrap}>
          {value}
        </Font>
      </TitleValueHolder>
    </StyledDeviceInfoItem>
  );
};

export { DeviceInfoItem };