import { appConstants } from 'modules';
import styled from 'styled-components';

const { breakpoints } = appConstants;
const { mobile } = breakpoints;

export const StyledDetails = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  @media screen and (max-width: ${mobile}rem) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
  }
`;

export const DetailsHolder = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: column;

  @media screen and (max-width: ${mobile}rem) {
    height: ${props => props.$bannerContent ? 'calc(100dvh - 16.25rem)' : 'calc(100dvh - 13.25rem)'};

    form {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
`;