import { useCallback, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { appConstants, institutionsActions } from 'modules';
import { animate } from 'helpers';
import { Font, Chevron } from 'components';
import { SlideOver } from 'xerum';
import { TxContent } from './TxContent';
import { withTheme } from 'styled-components';

const { themes, layout } = appConstants;
const { headerHeight, ribbonHeight, footerHeight } = layout;
const { light } = themes;
const topOffset = headerHeight + ribbonHeight;

const TransactionSlideOver = withTheme(props => {
  const { theme, setConfirmContent } = props;
  const { mobileMode, tabletMode, tabletLandscapeMode } = useSelector(state => state.app);
  const { slideOverTransactionId } = useSelector(state => state.institutions);
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const desktopMode = !mobileMode && !tabletMode && !tabletLandscapeMode;
  const lightTheme = selectedTheme === light;
  const trueBlack = theme.colors.shades.trueBlack;
  const darkGrey = theme.modes[selectedTheme]?.darkGrey;
  const grey = theme.modes[selectedTheme]?.grey;
  const offWhite = theme.modes[selectedTheme]?.offWhite;
  const titleColor = lightTheme ? darkGrey : offWhite;
  const restPosition = 720;
  const animationRef = useRef();
  const dispatch = useDispatch();

  const setSlideOverTransactionId = useCallback(payload => {
    dispatch(institutionsActions.setSlideOverTransactionId(payload));
  }, [ dispatch ]);

  const handleAnimationIn = useCallback(() => {
    animate({
      ref: animationRef.current,
      offset: restPosition,
      direction: !mobileMode && 'left',
      noFade: true,
    });
  }, [ mobileMode ]);

  const handleAnimationOut = useCallback(() => {
    if (animationRef.current) {
      animate({
        ref: animationRef.current,
        offset: restPosition,
        direction: !mobileMode && 'left',
        noFade: true,
        duration: 0.35,
        transitionOut: true,
        onComplete: () => setSlideOverTransactionId(null),
      });
    }
  }, [ mobileMode, setSlideOverTransactionId ]);

  useEffect(() => {
    slideOverTransactionId ? handleAnimationIn() : handleAnimationOut();
  }, [ slideOverTransactionId, handleAnimationIn, handleAnimationOut ]);

  const getTopOffset = () => {
    if (mobileMode) return 8;
    return !desktopMode ? 0 : topOffset;
  };

  const getBottomOffset = () => {
    if (mobileMode) return null;
    return !desktopMode ? 0 : footerHeight;
  };

  return (
    <SlideOver
      ref={animationRef}
      theme={theme}
      selectedTheme={selectedTheme}
      title={<Font size={1.17} weight='semibold' color={titleColor}>Transaction Details</Font>}
      closeIcon={<Chevron width={1} height={1} direction={mobileMode ? 'down' : 'right'} />}
      onClose={handleAnimationOut}
      width={27}
      boxShadow={`0 ${mobileMode ? -0.125 : 0}rem 0.5rem ${lightTheme ? grey : trueBlack}`}
      topOffset={getTopOffset()}
      bottomOffset={getBottomOffset()}
      visible={slideOverTransactionId}
      mobileMode={mobileMode}
      zIndex={3}
    >
      <TxContent setConfirmContent={setConfirmContent} />
    </SlideOver>
  );
});

export { TransactionSlideOver };