import styled from 'styled-components';

const StyledColumn = styled('div')`
  display: flex;
  flex-direction: column;
`;

const Column = props => {
  const { children } = props;

  return (
    <StyledColumn>
      {children}
    </StyledColumn>
  );
};

export { Column };