const preferencesConstants = {
  actions: {
    GET_USER_PREFERENCES: 'modules/preferences/GET_USER_PREFERENCES',
    UPDATE_USER_PREFERENCES: 'modules/preferences/UPDATE_USER_PREFERENCES',
    SET_PRIVACY_MODE: 'modules/preferences/SET_PRIVACY_MODE',
    SET_SELECTED_THEME: 'modules/preferences/SET_SELECTED_THEME',
  },

  selectors: {
    STATE_KEY_PREFERENCES_LOADING: 'preferencesLoading',
    STATE_KEY_GENERAL_PREFERENCES: 'generalPreferences',
    STATE_KEY_EXPENSES_PREFERENCES: 'expensesPreferences',
    STATE_KEY_GOALS_PREFERENCES: 'goalsPreferences',
    STATE_KEY_UPCOMING_PREFERENCES: 'upcomingPreferences',
    STATE_KEY_TRENDS_PREFERENCES: 'trendsPreferences',
    STATE_KEY_TRANSACTION_PREFERENCES: 'transactionsPreferences',
    STATE_KEY_ANALYSIS_PREFERENCES: 'analysisPreferences',
  },
};

export { preferencesConstants };