import { getColor } from 'helpers';
import { appConstants } from 'modules';
import styled, { css, keyframes } from 'styled-components';

const { spacing, breakpoints, themes } = appConstants;
const { tabletLandscape, hybrid, mobile } = breakpoints;
const { gap, smallGap } = spacing;
const { light } = themes;

const pulse = args => {
  const { props, lightTheme } = args || {};

  return keyframes`
    0% {
      border: 0.125rem solid ${getColor(props, 'primary')};
      background-color: ${getColor(props, lightTheme ? 'lightGrey' : 'darkGrey') + (lightTheme ? 50 : '')};
    }
    50% {
      border: 0.125rem solid ${getColor(props, 'onPrimary')};
      background-color: ${getColor(props, lightTheme ? 'lightGrey' : 'darkGrey') + (lightTheme ? 50 : '')};
    }
    100% {
      border: 0.125rem solid ${getColor(props, 'primary')};
      background-color: ${getColor(props, lightTheme ? 'lightGrey' : 'darkGrey') + (lightTheme ? 50 : '')};
    }
  `;
};

export const StyledAccount = styled('div')`
  display: grid;
  gap: 0 ${gap}rem;
  grid-template-columns: auto 1fr;
  grid-template-rows: repeat(3, auto);
  cursor: pointer;
  color: ${props => props.$active ? getColor(props, 'primary') : 'inherit'};
  background-color: ${props => props.$active ? getColor(props, 'onPrimary') : 'inherit'};
  border-radius: 0.5rem;
  border: 0.125rem solid ${props => getColor(props, 'primary')};
  padding: ${smallGap}rem 1.25rem ${smallGap}rem ${smallGap}rem;
  grid-template-areas:
    'icon accountName'
    'icon accountType'
    'icon accountDetails';

  min-width: 16rem;
  width: ${props => props.$fullWidth ? '100%' : 'fit-content'};

  ${props => props.$pulseSelect && css`
    animation: ${pulse({ props, lightTheme: props.$selectedTheme === light })} 2s infinite;
  `};

  @media (hover: hover) {
    &:hover {
      color: ${props => !props.$active && getColor(props, 'onPrimary')};
      background-color: ${props => {
        const lightTheme = props.$selectedTheme === light;
        return !props.$active && getColor(props, lightTheme ? 'lightGrey' : 'darkGrey') + (lightTheme ? 50 : '');
      }};
    }
  }

  @media screen and (max-width: ${hybrid}rem) {
    min-width: unset;
    max-width: unset;
    width: 100%;
  }
`;

export const Icon = styled('div')`
  grid-area: icon;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RepairText = styled('div')`
  display: ${props => props.$visible ? 'inline-flex' : 'none'};
  position: absolute;
  top: -0.65rem;
  right: 0.75rem;
  background-color: ${props => getColor(props, 'primary')};
  color: ${props => getColor(props, 'error')};
  padding: 0rem 0.5rem;
`;

export const RightPane = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: fit-content;
  overflow-y: auto;
  width: 100%;
`;

export const AccountsArea = styled('div')`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3rem;
  width: 100%;
  height: ${props => props.$isLoading ? '100%' : 'fit-content'};
  padding: 1rem;
  overflow-y: auto;

  ul svg {
    cursor: default;
    position: relative;
    top: 0.125rem;
  }

  @media screen and (max-width: 56.25rem) {
    gap: 1.5rem;
    display: flex;
    flex-wrap: wrap;
  }

  @media screen and (max-width: ${mobile}rem) {
    heighth: fit-content;
  }

  ${props => props.$isLoading && css`
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`;

export const RemoveItem = styled('div')`
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -0.9125rem;
  left: -0.85rem;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 50%;
  background-color: ${props => getColor(props, 'primary')};

  @media screen and (max-width: ${hybrid}rem) {
    display: flex;
  }
`;

export const AccountsHolder = styled('div')`
  position: relative;
  display: inline-flex;
  flex-wrap: wrap;
  gap: ${gap}rem;
  width: fill-available;
  height: fit-content;
  padding: 1.5rem;
  margin: 0.6rem;
  user-select: none;
  border-radius: 0.5rem;
  border: 0.09375rem solid ${props => {
    const lightTheme = props.$selectedTheme === light;
    const lightColor = getColor(props, 'lightGrey');
    const darkColor = getColor(props, 'lightGrey') + 50;

    if (props.$needsAttention) return getColor(props, 'error');
    return lightTheme ? lightColor : darkColor;
  }};

  @media (hover: hover) {
    &:hover {
      ${RemoveItem} {
        display: ${props => props.$displayOnly ? 'none' : 'flex'};
      }
    }
  }

  @media screen and (max-width: ${tabletLandscape}rem) {
    padding: ${gap}rem;
  }
`;

export const AccountName = styled('div')`
  grid-area: accountName;
  display: flex;
  align-items: center;
`;

export const AccountType = styled('div')`
  grid-area: accountType;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const AccountDetails = styled('div')`
  grid-area: accountDetails;
  display: flex;
  align-items: center;
`;

export const StyledAccounts = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${gap}rem;
  width: 100%;
  height: 100%;

  @media screen and (max-width: 53.75rem) {
    flex-grow: 1;
    flex-shrink: 1;
    align-items: space-between;
  }
`;

export const StyledConfirm = styled('div')`
  width: 26rem;

  @media screen and (max-width: 53.75rem) {
    width: 100%;
  }
`;

export const RemovedItem = styled('div')`
  display: inline-flex;
`;

export const InstitutionName = styled('div')`
  display: inline-flex;
  position: absolute;
  top: -0.65rem;
  left: 0.75rem;
  background-color: ${props => getColor(props, 'primary')};
  padding: 0rem 0.5rem;
`;

export const LinkSource = styled('div')`
  display: inline-flex;
  position: absolute;
  right: 0.65rem;
  bottom: -0.55rem;
  color: ${props => getColor(props, 'onPrimary') + 50};
  background-color: ${props => getColor(props, 'primary')};
  padding: 0rem 0.5rem;
`;