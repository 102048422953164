import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { appConstants } from 'modules';
import { Dropdown } from 'xerum';
import { withTheme } from 'styled-components';

const { light } = appConstants.themes;

const XymDropdown = withTheme(forwardRef((props, animationRef) => {
  const {
    theme,
    visible,
    setVisible,
    width,
    maxWidth,
    posX,
    posY,
    bottom,
    padding,
    horizontalPadding,
    verticalPadding,
    leftJustify,
    minHeight,
    maxHeight,
    bgColor,
    borderColor,
    children,
  } = props;
  const { mobileMode } = useSelector(state => state.app);
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const lightTheme = selectedTheme === light;
  const lightGrey = theme.modes[selectedTheme]?.lightGrey;

  return (
    <Dropdown
      ref={animationRef}
      theme={theme}
      selectedTheme={selectedTheme}
      visible={visible}
      setVisible={setVisible}
      zIndex={3}
      width={width}
      maxWidth={maxWidth}
      posX={posX}
      posY={posY}
      bottom={bottom}
      leftJustify={leftJustify}
      padding={padding}
      borderColor={borderColor || (!lightTheme && lightGrey + 50)}
      bgColor={bgColor}
      horizontalPadding={horizontalPadding}
      verticalPadding={verticalPadding}
      minHeight={minHeight}
      maxHeight={maxHeight}
      isMobile={mobileMode}
    >
      {children}
    </Dropdown>
  );
}));

export { XymDropdown };