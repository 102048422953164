import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { appConstants, authActions } from 'modules';
import { paramsToObject } from 'helpers';
import { Font } from 'components';
import { Loading } from 'xerum';
import styled, { withTheme } from 'styled-components';
import _ from 'lodash';

const { names } = appConstants;
const { sessionTokenKeyName } = names;

const StyledVerification = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const VerifyEmail = withTheme(props => {
  const { theme } = props;
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const { search } = useLocation();
  const sessionToken = paramsToObject(search)?.[sessionTokenKeyName];
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const verifyEmail = useCallback((payload, callbacks) => {
    dispatch(authActions.verifyEmail(payload, callbacks));
  }, [ dispatch ]);

  const checkSessionTokenAndVerify = useCallback(() => {
    if (!_.isEmpty(sessionToken)) {
      const payload = { sessionToken };
      const callbacks = {
        onComplete: () => navigate('/my-account'),
      };

      verifyEmail(payload, callbacks);
    }
  }, [ sessionToken, verifyEmail, navigate ]);

  useEffect(() => {
    checkSessionTokenAndVerify();
  }, [ checkSessionTokenAndVerify ]);

  return (
    <StyledVerification>
      <Loading
        theme={theme}
        selectedTheme={selectedTheme}
        isLoading={sessionToken}
        renderOnFail={true}
        text={
          <Font size={1} weight='semibold'>
            Verifying email...
          </Font>
        }
      >
        Missing or invalid session token.
      </Loading>
    </StyledVerification>
  );
});

export { VerifyEmail };