import { actionCreator } from 'helpers';
import { appConstants } from 'modules';
import * as api from 'modules/app/appApi';

const appActions = {
  setBannerContent: payload => actionCreator(appConstants.actions.SET_BANNER_CONTENT, payload),
  addNotification: payload => actionCreator(appConstants.actions.ADD_NOTIFICATION, payload),
  removeNotification: payload => actionCreator(appConstants.actions.REMOVE_NOTIFICATION, payload),
  clearNotifications: () => actionCreator(appConstants.actions.CLEAR_NOTIFICATIONS),
  setModalContent: payload => actionCreator(appConstants.actions.SET_MODAL_CONTENT, payload),
  setUserMenuVisible: payload => actionCreator(appConstants.actions.SET_USER_MENU_VISIBLE, payload),
  setTabletMode: payload => actionCreator(appConstants.actions.SET_TABLET_MODE, payload),
  setMobileMode: payload => actionCreator(appConstants.actions.SET_MOBILE_MODE, payload),
  setTabletLandscapeMode: payload => actionCreator(appConstants.actions.SET_TABLET_LANDSCAPE_MODE, payload),
  setHybridMode: payload => actionCreator(appConstants.actions.SET_HYBRID_MODE, payload),
  setMobileUserMenu: payload => actionCreator(appConstants.actions.SET_MOBILE_USER_MENU, payload),
  setGlobalConfirmContent: payload => actionCreator(appConstants.actions.SET_GLOBAL_CONFIRM_CONTENT, payload),

  getMiscData: (payload, callbacks) => {
    const type = appConstants.actions.GET_MISC_DATA;
    const args = { type, payload, callbacks };
    return api.getMiscData(args);
  },
};

export { appActions };