import { useSelector } from 'react-redux';
import { fonts } from 'controllers';
import { getFontFamily, hexValid, getColor } from 'helpers';
import styled, { withTheme } from 'styled-components';

const StyledD1 = styled('h1')`
  font-weight: unset;
  font-family: ${props => props.$weight ? getFontFamily(props) : fonts?.primary?.bold};
  color: ${props => hexValid(props.$color) || getColor(props, 'onPrimary')};
  font-size: 4rem;
  margin: 0;
`;

const D1 = withTheme(props => {
  const { theme, weight, color, children } = props;
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);

  return (
    <StyledD1
      $theme={theme}
      $selectedTheme={selectedTheme}
      $color={color}
      $weight={weight}
    >
      {children}
    </StyledD1>
  );
});

export { D1 };