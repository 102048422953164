import { H1, Font, P, Center, XymSpacer } from 'components';
import { Trends } from './styles';

const {
  VITE_GCP_STORAGE_BUCKET: storageBucket,
} = process.env;

const AboutTrends = () => {
  const src = `${storageBucket}/Waitlist/Trends.png`;

  return (
    <Trends>
      <Center>
        <H1>Your habits, visualized</H1>
        <XymSpacer />
        <P>
          Visually see if you are living at, above, or below your means with&nbsp;
          <Font weight='semibold'>Earned vs Spent</Font>.
          You can also take a look at how much you&apos;re spending on your morning
          coffee compared to last month, or the same time last year.  Knowing your
          habits can help you be more aware of where your money is going over time.
        </P>
      </Center>

      <Center>
        <img src={src} id='trends' alt='Trends preview.' />
      </Center>
    </Trends>
  );
};

export { AboutTrends };