import { useSelector } from 'react-redux';
import { Font, PaymentIcon, LabelTag, XymSpacer } from 'components';
import { StyledPaymentMethod, Icon, Name, Number } from './styles';
import { PaymentActions } from './PaymentActions';
import { withTheme } from 'styled-components';
import _ from 'lodash';

const PaymentMethod = withTheme(props => {
  const { theme, method, hideActions } = props;
  const { cardType, cardNumber, defaultPayment } = method;
  const { mobileMode } = useSelector(state => state.app);
  const { generalPreferences: { privacyMode, selectedTheme } } = useSelector(state => state.preferences);
  const grey = theme.modes[selectedTheme]?.grey;

  return (
    <StyledPaymentMethod>
      <Icon>
        {!mobileMode && <XymSpacer across={true} />}
        <PaymentIcon color={grey} />
      </Icon>

      <Name>
        <Font weight='semibold' mobileSize={0.875}>
          {_.startCase(cardType || 'Invalid')}
        </Font>

        <LabelTag
          visible={!privacyMode && defaultPayment && !hideActions}
          text={<Font size={0.875} mobileSize={0.75}>Default</Font>}
        />
      </Name>

      <Number>
        <Font size={0.875} mobileSize={0.75}>
          •••• ... {cardNumber?.slice(-4) || '????'}
        </Font>
      </Number>

      <PaymentActions method={method} hideActions={hideActions} />
    </StyledPaymentMethod>
  );
});

export { PaymentMethod };