import { appConstants } from 'modules';
import styled from 'styled-components';

const { breakpoints, spacing } = appConstants;
const { mediumGap, gap } = spacing;
const { tabletLandscape, mobile, hybrid } = breakpoints;

export const StyledTrends = styled('div')`
  grid-area: trends;
  display: grid;
  grid-template-rows: 3rem 1fr;
  gap: ${gap}rem;
  height: 100%;
  width: 100%;

  @media screen and (max-width: ${hybrid}rem) {
    height: ${props => props.$bannerContent ? 'calc(100dvh - 25rem)' : 'calc(100dvh - 22rem)'};
  }

  @media screen and (max-width: ${tabletLandscape}rem) {
    grid-template-rows: auto 1fr;
  }

  @media screen and (max-width: ${mobile}rem) {
    height: ${props => props.$bannerContent ? 'calc(100dvh - 19rem)' : 'calc(100dvh - 16rem)'};
  }
`;

export const TitleArea = styled('div')`
  display: grid;
  grid-template-columns: 1fr repeat(3, auto);
  grid-template-rows: repeat(2, auto);
  gap: 0.75rem 1.5rem;
  grid-template-areas:
    'trendsTitle trendsYtd trendsMenu'
    'trendsLegend trendsLegend trendsLegend';

  #trendsTitle {
    height: 100%;
    grid-area: trendsTitle;
  }

  #trendsYtd {
    grid-area: trendsYtd;
  }

  #trendsMenu {
    grid-area: trendsMenu;
  }

  #trendsLegend {
    grid-area: trendsLegend;
  }

  justify-content: center;
  width: 100%;

  @media screen and (max-width: ${tabletLandscape}rem) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);
    gap: ${gap}rem;
    align-items: center;
    grid-template-areas:
      'trendsMenu'
      'trendsLegend'
      'trendsYtd';
  }
`;

export const Legend = styled('div')`
  display: inline-flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media screen and (max-width: ${mobile}rem) {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const StyledChart = styled('div')`
  display: flex;
  position: relative;
  width: 100%;
  height: calc(100% - 1rem);

  @media screen and (max-width: ${tabletLandscape}rem) {
    width: calc(100% - ${mediumGap}rem);
  }

  canvas {
    width: 100%;
    height: 100%;
  }
`;

export const YearToDateToggle = styled('div')`
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;