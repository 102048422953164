
const securityConstants = {
  actions: {
    GET_SECURITY_DATA: 'modules/security/GET_SECURITY_DATA',
    UPDATE_TRUSTED_DEVICE: 'modules/security/UPDATE_TRUSTED_DEVICE',
    REMOVE_TRUSTED_DEVICE: 'modules/security/REMOVE_TRUSTED_DEVICE',
  },

  selectors: {
    STATE_KEY_SECURITY_KEYS: 'securityKeys',
    STATE_KEY_AUTHENTICATORS: 'authenticators',
    STATE_KEY_TRUSTED_DEVICES: 'trustedDevices',
    STATE_KEY_SESSION_HISTORY: 'sessionHistory',
    STATE_KEY_SECURITY_DATA_LOADING: 'securityDataLoading',
    STATE_KEY_TRUSTED_DEVICES_LOADING: 'trustedDevicesLoading',
  },
};

export { securityConstants };