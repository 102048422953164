import { H1, P, XymSpacer } from 'components';
import { InlineImages, QuickGlance, FullWidth } from './styles';

const {
  VITE_GCP_STORAGE_BUCKET: storageBucket,
} = process.env;

const AboutUpcomingAndProgress = () => {
  const src = `${storageBucket}/Waitlist/Analysis.png`;

  return (
    <QuickGlance>
      <FullWidth>
        <H1>At a glance</H1>
        <XymSpacer />
        <P>
          See your total spending for the month, a breakdown of your essentials,
          debt and personal spending &mdash; based on expense and goal types, tax rate and
          the 50:30:20 rule.
        </P>

        <XymSpacer />

        <P>
          A recommended gross household salary, annual income and hourly rate, will also be calculated based
          on your highest spending category.
        </P>
      </FullWidth>

      <InlineImages>
        <img src={src} id='analysis' alt='Analysis' />
      </InlineImages>
    </QuickGlance>
  );
};

export { AboutUpcomingAndProgress };