import { getColor } from 'helpers';
import { appConstants } from 'modules';
import { theme } from 'theme';
import { StyledHeader } from 'pages/App/Header/PrimaryHeader/styles';
import styled from 'styled-components';

const { themes, spacing, layout, breakpoints } = appConstants;
const { layoutWidth, ribbonHeight } = layout;
const { extraLargeGap, mediumGap, gap, extraSmallGap } = spacing;
const { tablet, tabletLandscape, mobile, hybrid } = breakpoints;
const { light } = themes;
const colors = theme.colors;

export const StyledRibbon = styled(StyledHeader)`
  position: relative;
  z-index: 2;
  height: ${ribbonHeight}rem;
  width: 100dvw;
  background-color: ${props => getColor(props, 'darkGrey')};
  color: ${props => getColor(props, 'lightGrey')};

  @media screen and (max-width: ${tablet}rem) {
    height: 5rem;
  }

  @media screen and (max-width: ${mobile}rem) {
    z-index: unset;
  }
`;

export const Layout = styled('div')`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  max-width: ${layoutWidth}rem;
  width: 100%;
  padding: 0 ${extraLargeGap}rem;

  @media screen and (max-width: ${hybrid}rem) {
    padding: 0 ${mediumGap}rem;
  }

  @media screen and (max-width: ${mobile}rem) {
    padding: 0 ${gap}rem;
  }
`;

export const Balance = styled('div')`
  position: relative;
  display: inline-flex;
  align-items: center;
  user-select: none;
  width: fit-content;
  padding: 0.25rem 1rem;
  margin: -0.25rem 0 0 0;
  cursor: pointer;

  background-color: ${props => {
    const frenchGrey = colors.neutral.frenchGrey;
    const bgColor = frenchGrey + 15;

    return props.$isDraggingOver ? bgColor : 'transparent';
  }};

  border: 0.125rem solid ${props => {
    const lightTheme = props.$selectedTheme === light;
    const paynesGrey = colors.neutral.paynesGrey;
    const frenchGrey = colors.neutral.frenchGrey;
    const borderColor = lightTheme ? frenchGrey : paynesGrey;

    return props.$isDraggingOver ? borderColor : 'transparent';
  }};

  border-radius: 0.5rem;
  transition: all 0.2s ease;

  svg {
    margin-top: 0.125rem;
  }

  @media screen and (max-width: ${mobile}rem) {
    margin-left: 0.5rem;
  }
`;

export const MobileBalances = styled('div')`
  display: flex;
  gap: ${extraSmallGap}rem;
  flex-direction: column;
  justify-content: center;
  width: fit-content;
`;

export const BreakdownBalances = styled(Balance)`
  cursor: ${props => props.$desktopMode ? 'unset' : 'pointer'};
  margin-left: -1rem;

  @media screen and (max-width: ${tablet}rem) {
    display: none;
  }
`;

export const StyledExtraActions = styled('div')`
  display: inline-flex;
  align-items: center;

  @media screen and (max-width: ${tabletLandscape}rem) {
    display: none;
  }
`;

export const SecondaryLeftGroup = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const DragIcon = styled('div')`
  position: absolute;
  top: -0.125rem;
  left: ${props => props.$isDraggingOver ? -2.5 : -1.5}rem;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;

  @media screen and (max-width: ${mobile}rem) {
    left: ${props => props.$isDraggingOver ? -2.25 : -1.5}rem;
  }
`;