import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Font, HamburgerMenuIcon } from 'components';
import { mainRoutes, appActions } from 'modules';
import { Balances } from './Balances';
import { SafeToUseActions } from './SafeToUseActions';
import { StyledRibbon, Layout, SecondaryLeftGroup } from './styles';
import { HamburgerMenu } from 'pages/App/Header/PrimaryHeader/styles';
import { withTheme } from 'styled-components';
import _ from 'lodash';

const SafeToUse = withTheme(props => {
  const { theme } = props;
  const { pathname } = useLocation();
  const { mobileMode, mobileUserMenu } = useSelector(state => state.app);
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const hamburgerColor = theme.modes[selectedTheme]?.lightGrey;
  const withoutSlash = pathname.replace('/', '');
  const formatted = _.startCase(withoutSlash);
  const pageName = _.capitalize(_.toLower(formatted));
  const showBalances = mainRoutes.includes(withoutSlash);
  const dispatch = useDispatch();
  const setMobileUserMenu = useCallback(payload => dispatch(appActions.setMobileUserMenu(payload)), [ dispatch ]);

  const pageTitle = (
    <Font size={1.3755} mobileSize={1.25} tabletSize={1.25} weight='semibold'>
      {pageName}
    </Font>
  );

  return (
    <StyledRibbon $theme={theme} $selectedTheme={selectedTheme}>
      <Layout>
        <SecondaryLeftGroup>
          {showBalances ? <Balances /> : pageTitle}
        </SecondaryLeftGroup>

        {mobileMode && (
          <HamburgerMenu
            $theme={theme}
            $selectedTheme={selectedTheme}
            onClick={() => setMobileUserMenu(!mobileUserMenu)}
          >
            <HamburgerMenuIcon color={hamburgerColor} />
          </HamburgerMenu>
        )}

        <SafeToUseActions />
      </Layout>
    </StyledRibbon>
  );
});

export { SafeToUse };