import { getColor } from 'helpers';
import { appConstants } from 'modules';
import styled from 'styled-components';

const { layout, spacing, breakpoints, themes } = appConstants;
const { layoutWidth, footerHeight } = layout;
const { extraLargeGap, mediumGap, smallGap } = spacing;
const { light } = themes;
const { tablet, hybrid, mobile } = breakpoints;

export const StyledFooter = styled('footer')`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${footerHeight}rem;
  width: 100dvw;
  background-color: ${props => getColor(props, 'darkGrey')};
  color: ${props => getColor(props, 'lightGrey')};

  @media screen and (max-width: ${tablet}rem) {
    height: ${footerHeight + 1}rem;
    background-color: ${props => getColor(props, 'primary')};
    color: ${props => getColor(props, 'onPrimary')};
    border-top: 0.0625rem solid ${props => {
      const lightTheme = props.$selectedTheme === light;
      const color = lightTheme ? getColor(props, 'grey') : getColor(props, 'lightGrey') + 50;
      return color;
    }};
  }

  @media screen and (max-width: ${mobile}rem) {
    z-index: unset;
  }
`;

export const Layout = styled('div')`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  max-width: ${layoutWidth}rem;
  width: 100%;
  height: 100%;
  padding: 0 ${extraLargeGap}rem;

  @media screen and (max-width: ${hybrid}rem) {
    padding: 0 ${mediumGap}rem;
  }

  @media screen and (max-width: ${mobile}rem) {
    margin-top: -${smallGap}rem;
  }
`;

export const DesktopLayout = styled(Layout)`
  display: ${props => props.$visible ? 'flex' : 'none'};
`;

export const MobileLayout = styled(Layout)`
  display: ${props => props.$visible ? 'flex' : 'none'};
  justify-content: center;

  @media screen and (max-width: ${mobile}rem) {
    padding: 0;
  }
`;

export const CopyArea = styled('div')`
  width: fit-content;
`;

export const FooterNav = styled('div')`
  display: inline-flex;
  align-items: center;
`;