/* eslint-disable max-len */
import { useSelector } from 'react-redux';
import { getColor } from 'helpers';
import styled, { withTheme } from 'styled-components';

const InstitutionIconSVG = styled('svg')`
  width: ${props => props.$width || 3.875}rem;
  height: ${props => props.$height || 3.0625}rem;
  fill: ${props => props.$color || getColor(props, 'grey')};
`;

const InstitutionIcon = withTheme(props => {
  const { theme, color, width, height, direction } = props;
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);

  return (
    <InstitutionIconSVG
      $theme={theme}
      $selectedTheme={selectedTheme}
      $width={width}
      $height={height}
      $direction={direction}
      $color={color}
      viewBox='0 0 62 49'
      fill='currentColor'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M29.641 0.297582L1.00489 17.9537C-0.71093 19.0116 0.0388021 21.6561 2.05455 21.6561H6.83701V40H4C2.89543 40 2 40.8954 2 42V47C2 48.1046 2.89543 49 4 49H58C59.1046 49 60 48.1046 60 47V42C60 40.8954 59.1046 40 58 40H54.6833V21.6561H59.8381C61.8621 21.6561 62.6055 18.9941 60.8743 17.9455L31.7269 0.28937C31.0851 -0.0993893 30.2797 -0.09622 29.641 0.297582ZM46 40V21.6561H41.5705V40H46ZM32.8872 40V21.6561H28.5454V40H32.8872ZM19.862 40V21.6561H15.5203V40H19.862Z'
      />
    </InstitutionIconSVG>
  );
});

export { InstitutionIcon };