import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { mainRoutes } from 'modules';
import { Font, XymSpacer } from 'components';
import { UserNav } from './UserNav';
import { AppNavArea, AppNavLink } from './styles';
import { withTheme } from 'styled-components';
import _ from 'lodash';

const AppNav = withTheme(props => {
  const { theme, isFooterNav } = props;
  const { mobileMode } = useSelector(state => state.app);
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const buildNavItems = () => {
    return mainRoutes.map((route, index) => {
      const active = _.toLower(pathname).includes(route);
      const lastItem = index === mainRoutes.length - 1;

      return (
        <Font key={index} weight='semibold'>
          <AppNavLink
            $theme={theme}
            $selectedTheme={selectedTheme}
            $active={active}
            onClick={() => navigate(`/${route}`)}
          >
            <Font weight='semibold' size={1} mobileSize={0.875}>
              {_.startCase(route)}
            </Font>
          </AppNavLink>

          {!lastItem && !mobileMode && (
            <XymSpacer size={2.5} across={true} />
          )}
        </Font>
      );
    });
  };

  return (
    <AppNavArea $isFooterNav={isFooterNav}>
      {!mobileMode && buildNavItems()}
      {mobileMode && <UserNav />}
    </AppNavArea>
  );
});

export { AppNav };