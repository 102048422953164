import { useSelector } from 'react-redux';
import { Font, XymSpacer } from 'components';
import { TimeAndType } from './styles';
import { TransactionSubTitle } from 'pages/Overview/Activity/TransactionSubTitle';
import moment from 'moment';
import _ from 'lodash';

const TxTimeAndType = props => {
  const { transaction } = props;
  const { generalPreferences: { privacyMode } } = useSelector(state => state.preferences);

  const { data, metadata } = transaction || {};
  const { autoSpendExpenseId } = metadata || {};
  const {
    amount,
    check_number,
    datetime,
    authorized_date,
    date,
    payment_channel,
  } = data || {};

  const { store_number } = location;
  const workingDate = authorized_date || date;
  const isCredit = amount < 0;
  const isDebit = amount > 0;
  const hasSubTitle = isDebit || (isCredit && autoSpendExpenseId);

  return (
    <TimeAndType>
      <XymSpacer size={0.5} />

      <Font size={0.875}>
        {datetime
          ? moment(datetime).format('MMMM Do, YYYY @ H:MM A')
          : moment(workingDate).format('MMMM Do, YYYY')
        }
      </Font>

      {!privacyMode && store_number && payment_channel === 'in store' && (
        <Font size={0.875} weight='semibold'>
          {_.capitalize(payment_channel)}
          <XymSpacer size={0.25} across={true} />
          (store #{store_number})
        </Font>
      )}

      {!privacyMode && check_number && (
        <Font size={0.875} weight='semibold'>
          Check
          (check #{check_number})
        </Font>
      )}

      <TransactionSubTitle transaction={transaction} hasSubTitle={hasSubTitle} size={0.875} />
    </TimeAndType>
  );
};

export { TxTimeAndType };