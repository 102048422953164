
export const getPaymentData = args => {
  const { type, callbacks } = args;

  return {
    type,
    path: '/stripe/payments',
    method: 'GET',
    ...callbacks,
  };
};

export const addPaymentMethod = args => {
  const { type, payload, callbacks } = args;
  const { token, ...withoutToken } = payload;

  return {
    type,
    path: '/stripe/payments/methods',
    method: 'POST',
    body: JSON.stringify(withoutToken),
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    ...callbacks,
  };
};

export const updatePaymentMethod = args => {
  const { type, payload, callbacks } = args;
  const { token, ...withoutToken } = payload;

  return {
    type,
    path: '/stripe/payments/methods',
    method: 'PUT',
    body: JSON.stringify(withoutToken),
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    ...callbacks,
  };
};

export const removePaymentMethod = args => {
  const { type, payload, callbacks } = args;
  const { token, ...withoutToken } = payload;

  return {
    type,
    path: '/stripe/payments/methods',
    method: 'DELETE',
    body: JSON.stringify(withoutToken),
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    ...callbacks,
  };
};