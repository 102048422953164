import { H3, P, Center, XymSpacer } from 'components';
import { MoveMoney, FullWidth } from './styles';
import { appConstants } from 'modules';

const {
  VITE_GCP_STORAGE_BUCKET: storageBucket,
} = process.env;

const { safeBalanceName } = appConstants.names;

const AboutMovingMoney = () => {
  const src = `${storageBucket}/Waitlist/Move-Money.png`;

  return (
    <MoveMoney>
      <Center>
        <img src={src} id='moveMoney' alt='Goal preview.' />
      </Center>

      <FullWidth>
        <H3>Balance the books...</H3>
        <XymSpacer />
        <P>
          Shift your funds around &mdash; as needed &mdash; between {safeBalanceName},
          or any of your Expenses or Goals.
        </P>
      </FullWidth>
    </MoveMoney>
  );
};

export { AboutMovingMoney };