import { useSelector } from 'react-redux';
import { PrivacyPolicy, TermsOfService, SecurityPolicy } from 'pages';
import { H4, Font, XymSpacer, XymLink } from 'components';
import { Copyright } from 'xerum';
import { StyledFooter, FooterNav, CopyArea, DesktopLayout, MobileLayout } from './styles';
import { withTheme } from 'styled-components';
import { AppNav } from 'pages/App//Header/PrimaryHeader/AppNav';
import PackageJSON from '../../../../package.json';

const { version } = PackageJSON;
const { VITE_COMPANY_NAME: companyName } = process.env;

const Footer = withTheme(props => {
  const { theme, setModalContent } = props;
  const { tabletMode, mobileMode } = useSelector(state => state.app);
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const lightGrey = theme.modes[selectedTheme]?.lightGrey;
  const privacyPolicy = <PrivacyPolicy inModal={true} />;
  const securityPolicy = <SecurityPolicy inModal={true} />;
  const termsOfService = <TermsOfService inModal={true} />;
  const desktopMode = !mobileMode && !tabletMode;

  return (
    <StyledFooter $theme={theme} $selectedTheme={selectedTheme}>
      <DesktopLayout $visible={desktopMode}>
        <Font size={0.875} color={lightGrey + 75}>
          <CopyArea>
            <Copyright name={companyName} />
          </CopyArea>
        </Font>

        <FooterNav>
          <Font color={lightGrey + 75} size={0.875}>
            v{version}
          </Font>

          <XymSpacer size={1.25} across={true} />

          <XymLink
            weight='medium'
            color={lightGrey}
            callback={() => setModalContent({
              title: <H4>Privacy Policy</H4>,
              content: privacyPolicy,
            })}
          >
            Privacy Policy
          </XymLink>

          <XymSpacer size={1.25} across={true} />

          <XymLink
            weight='medium'
            color={lightGrey}
            callback={() => setModalContent({
              title: <H4>Security Policy</H4>,
              content: securityPolicy,
            })}
          >
            Security Policy
          </XymLink>

          <XymSpacer size={1.25} across={true} />

          <XymLink
            weight='medium'
            color={lightGrey}
            callback={() => setModalContent({
              title: <H4>Terms of Service</H4>,
              content: termsOfService,
            })}
          >
            Terms of Service
          </XymLink>
        </FooterNav>
      </DesktopLayout>

      <MobileLayout $visible={!desktopMode}>
        <AppNav isFooterNav={true} />
      </MobileLayout>
    </StyledFooter>
  );
});

export { Footer };