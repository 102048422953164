import { useRef, useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { appConstants } from 'modules';
import { AutoSpendArea, AddTagArea, TagOverflowCount } from './styles';
import { AutoSpendTag, Font, XymSpacer } from 'components';
import { AddAutoSpendTag } from './AddAutoSpendTag';
import { AutoSpendOverflow } from './AutoSpendOverflow';
import { withTheme } from 'styled-components';
import _ from 'lodash';

const { light } = appConstants.themes;

const ExpenseAutoSpend = withTheme(props => {
  const { theme, visible, setAutoSpendConfirmContent, _id, autoSpendTags } = props;
  const { generalPreferences: { privacyMode, selectedTheme } } = useSelector(state => state.preferences);
  const [ coordinates, setCoordinates ] = useState(false);
  const [ visibleCount, setVisibleCount ] = useState(null);

  const lightTheme = selectedTheme === light;
  const autoSpendRef = useRef();
  const container = autoSpendRef?.current;
  const hasTags = !_.isEmpty(autoSpendTags);
  const paynesGrey = theme.colors.neutral.paynesGrey;
  const onPrimary = theme.modes[selectedTheme]?.[lightTheme ? 'secondary' : 'onSecondary'];
  const allHidden = hasTags && visibleCount === 0;

  const buildTags = () => {
    return autoSpendTags?.map((tag, index) => (
      <AutoSpendTag key={index} tag={tag} expenseId={_id} autoSpendTags={autoSpendTags} />
    ));
  };

  const visibleRenderCount = useCallback(tags => {
    let childrenToRender = 0;

    if (container) {
      tags?.reduce((previous, current) => {
        const currentWidth = current?.clientWidth;
        if (previous + currentWidth < container.clientWidth) childrenToRender++;
        return previous += currentWidth;
      }, 0);
    }

    return childrenToRender;
  }, [ container ]);

  useEffect(() => {
    const autoSpendArea = autoSpendRef.current;

    if (autoSpendArea) {
      const children = [ ...autoSpendArea.children ];
      const observedElements = [ autoSpendArea ];
      const resizeObserver = new ResizeObserver(() => {
        setVisibleCount(visibleRenderCount(children));
      });

      observedElements.forEach(element => resizeObserver.observe(element));
    }
  }, [ privacyMode, autoSpendTags, visibleRenderCount ]);

  const handleOverflow = e => {
    e.stopPropagation();
    setCoordinates(_.isEmpty(coordinates) ? { x: e.clientX, y: e.clientY } : null);
  };

  const renderAdditionalCount = () => {
    if (hasTags && visibleCount >= 0 && visibleCount < autoSpendTags?.length) {
      return (
        <>
          <TagOverflowCount $bgColor={lightTheme ? paynesGrey : paynesGrey + 75} onClick={handleOverflow}>
            <Font size={0.875} weight='semibold' color={onPrimary}>
              +{(autoSpendTags?.length - visibleCount)}
            </Font>

            <AutoSpendOverflow
              coordinates={coordinates}
              setCoordinates={setCoordinates}
              overflowTags={buildTags()?.splice(visibleCount)}
              allHidden={allHidden}
            />
          </TagOverflowCount>

          <XymSpacer size={1.5} mobileSize={0.75} tabletSize={1} across={true} />
        </>
      );
    }
  };

  if (visible) {
    return (
      <>
        <AutoSpendArea ref={autoSpendRef} $pushOutOfFrame={allHidden}>
          {buildTags()}
        </AutoSpendArea>

        <AddTagArea>
          {renderAdditionalCount()}
          <AddAutoSpendTag expenseId={_id} setAutoSpendConfirmContent={setAutoSpendConfirmContent} />
        </AddTagArea>
      </>
    );
  }
});

export { ExpenseAutoSpend };