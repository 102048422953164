/* eslint-disable max-len */
import { useSelector } from 'react-redux';
import { getColor } from 'helpers';
import styled, { withTheme } from 'styled-components';

const MinusSVG = styled('svg')`
  width: ${props => props.$width || 1}rem;
  height: ${props => props.$height || 1}rem;
  fill: ${props => props.color || getColor(props, 'grey')};
  cursor: pointer;
`;

const Minus = withTheme(props => {
  const { theme, color, bgColor, width, height } = props;
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const primary = getColor(props, 'primary');
  const onPrimary = getColor(props, 'onPrimary');

  return (
    <MinusSVG
      $theme={theme}
      $selectedTheme={selectedTheme}
      $width={width}
      $height={height}
      color={bgColor || onPrimary}
      viewBox='0 0 16 16'
      fill='currentColor'
    >
      <g>
        <circle cx='8' cy='8' r='8' />
        <path d='M3.75732 8H12.2426' stroke={color || primary} strokeWidth='2' strokeLinecap='round'/>
      </g>
    </MinusSVG>
  );
});

export { Minus };