import { WaitlistForm, XymSpacer } from 'components';
import { HeroImage, MobileFormArea } from './styles';
import { HeroDescription } from './HeroDescription';
import { PlaidSync } from './PlaidSync';
import { SafeBalance } from './SafeBalance';

const {
  VITE_GCP_STORAGE_BUCKET: storageBucket,
} = process.env;

const AboutTheApp = () => {
  const src = `${storageBucket}/Waitlist/Overview.png`;

  return (
    <>
      <HeroDescription />
      <XymSpacer size={4} />

      <HeroImage>
        <img src={src} id='overview' alt='Overview preview.' />
      </HeroImage>

      <MobileFormArea>
        <XymSpacer size={4} />
        <WaitlistForm />
      </MobileFormArea>

      <XymSpacer size={8} />
      <PlaidSync />
      <XymSpacer size={6} />

      <SafeBalance />
    </>
  );
};

export { AboutTheApp };