import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { appConstants } from 'modules';
import { Font, NoData, XymSpacer } from 'components';
import { StyledMFA, SecurityKeys, Authenticators } from './styles';
import { SecurityDevice } from './SecurityDevice';
import { MFAActions } from './MFAActions';
import { withTheme } from 'styled-components';
import _ from 'lodash';

const { light } = appConstants.themes;

const MFA = withTheme(props => {
  const { theme } = props;
  const { mobileMode, tabletMode, tabletLandscapeMode, hybridMode } = useSelector(state => state.app);
  const { userInfo, userInfoLoading } = useSelector(state => state.auth);
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const desktopMode = !mobileMode && !tabletMode && !tabletLandscapeMode && !hybridMode;
  const darkGrey = theme.modes[selectedTheme]?.darkGrey;
  const onPrimary = theme.modes[selectedTheme]?.onPrimary;
  const offWhite = theme.modes[selectedTheme]?.offWhite;
  const lightTheme = selectedTheme === light;
  const titleColor = lightTheme ? darkGrey : offWhite;

  const mfaDevices = useMemo(() => {
    return {
      securityKeys: userInfo?.devices?.securityKeys || [],
      authenticators: userInfo?.devices?.authenticators || [],
    };
  }, [ userInfo ]);

  const buildSecurityKeyList = () => {
    if (userInfoLoading) {
      return <NoData text='Loading security keys...' />;
    }

    if (!_.isEmpty(mfaDevices?.securityKeys)) {
      return mfaDevices.securityKeys.map((device, index) => {
        const lastItem = index === mfaDevices.securityKeys.length - 1;

        return (
          <SecurityDevice
            key={device?.id || index}
            device={device}
            lastItem={lastItem}
            type='securityKey'
          />
        );
      });
    }

    return (
      <div>
        <XymSpacer size={0.25} />
        <NoData
          icon='fa-solid fa-key'
          text='No security keys found'
          subText='Add a security key, below.'
        />
        <XymSpacer size={1.5} />
      </div>
    );
  };

  const buildAuthenticatorList = () => {
    if (userInfoLoading) {
      return <NoData text='Loading authenticators...' />;
    }

    if (!_.isEmpty(mfaDevices?.authenticators)) {
      return mfaDevices.authenticators.map((device, index) => {
        const lastItem = index === mfaDevices.authenticators.length - 1;

        return (
          <SecurityDevice
            key={device?.id || index}
            device={device}
            lastItem={lastItem}
            type='authenticator'
          />
        );
      });
    }

    return (
      <div>
        <XymSpacer size={0.25} />
        <NoData
          icon='fa-solid fa-lock'
          text='No authenticators found'
          subText='Add an authenticator, below.'
        />
        <XymSpacer size={1.5} />
      </div>
    );
  };

  return (
    <StyledMFA>
      <Font weight='semibold' size={1.25} mobileSize={1} tabletSize={1} color={titleColor}>
        Multi-Factor Authentication
      </Font>

      <SecurityKeys>
        {desktopMode && <XymSpacer size={1.375} />}

        <Font color={lightTheme ? darkGrey : onPrimary} weight='semibold'>
          Security Keys
        </Font>

        <XymSpacer size={0.375} />
        {buildSecurityKeyList()}
        <XymSpacer />
      </SecurityKeys>

      <Authenticators>
        <Font color={lightTheme ? darkGrey : onPrimary} weight='semibold'>
          Authenticators
        </Font>

        <XymSpacer size={0.375} />
        {buildAuthenticatorList()}
        <XymSpacer />
      </Authenticators>

      <MFAActions visible={desktopMode} />
    </StyledMFA>
  );
});

export { MFA };