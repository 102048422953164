import { getColor } from 'helpers';
import { appConstants } from 'modules';
import styled from 'styled-components';

const { breakpoints, spacing } = appConstants;
const { gap, smallGap, mediumGap, extraSmallGap } = spacing;
const { tabletLandscape, tablet, mobile, hybrid } = breakpoints;

export const StyledAnalysis = styled('div')`
  grid-area: analysis;
  display: grid;
  grid-template-rows: repeat(3, auto) 1fr;
  gap: 0;
  height: 100%;
  width: 100%;
  overflow: auto;

  @media screen and (max-width: ${hybrid}rem) {
    height: ${props => props.$bannerContent ? 'calc(100dvh - 25rem)' : 'calc(100dvh - 22rem)'};
    padding: 0 ${gap}rem;
  }

  @media screen and (max-width: ${tabletLandscape}rem) {
    gap: ${gap}rem;
  }

  @media screen and (max-width: ${mobile}rem) {
    height: ${props => props.$bannerContent ? 'calc(100dvh - 19rem)' : 'calc(100dvh - 16rem)'};
  }
`;

export const TitleArea = styled('div')`
  display: grid;
  grid-template-columns: 1fr auto 21rem;
  align-items: center;
  gap: 0 1.5rem;
  width: 100%;

  @media screen and (max-width: ${tabletLandscape}rem) {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: ${gap}rem;
  }
`;

export const RecommendationsArea = styled('ul')`
  display: inline-flex;
  gap: ${extraSmallGap}rem;
  width: 100%;
  margin: 0;
  padding: 0;

  @media screen and (max-width: ${tablet}rem) {
    display: flex;
    flex-direction: column;
  }
`;

export const Recommendation = styled('li')`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${props => props.$gap ?? smallGap}rem;
  padding: 0.5rem;
  background-color: ${props => getColor(props, 'darkGrey')};
  color: ${props => getColor(props, 'lightGrey')};
  min-width: 12.5rem;
  width: 100%;

  &:first-child {
    border-radius: ${extraSmallGap}rem 0 0 ${extraSmallGap}rem;
  }

  &:last-child {
    border-radius: 0 ${extraSmallGap}rem ${extraSmallGap}rem 0;
  }

  @media screen and (max-width: ${tablet}rem) {
    &:first-child {
      border-radius: ${extraSmallGap}rem ${extraSmallGap}rem 0 0;
    }

    &:last-child {
      border-radius: 0 0 ${extraSmallGap}rem ${extraSmallGap}rem;
    }
  }
`;

export const StyledBreakdownTotal = styled('div')`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${mediumGap}rem;
  width: 100%;
  align-items: center;
  justify-content: center;
  grid-template-areas:
    'total note note';

  @media screen and (max-width: ${tablet}rem) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto);
    grid-template-areas:
      'total'
      'note';
  }

  @media screen and (max-width: ${mobile}rem) {
    gap: ${smallGap}rem;
  }
`;

export const StyledTypeBreakdown = styled('div')`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${gap}rem;
  width: 100%;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: ${tablet}rem) {
    grid-template-columns: 1fr;
  }

  @media screen and (max-width: ${mobile}rem) {
    gap: ${smallGap}rem;
  }
`;

export const BreakdownItem = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${props => props.$gap ?? extraSmallGap}rem;
  padding: ${smallGap}rem;
`;

export const BreakdownNote = styled('div')`
  display: ${props => props.$visible ? 'flex' : 'none'};
  flex-direction: column;
  grid-area: note;

  @media screen and (max-width: ${tablet}rem) {
    margin: 0 auto;
  }
`;

export const ContractorToggle = styled('div')`
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;