import { useSelector } from 'react-redux';
import { Font, LabelTag, XymSpacer } from 'components';
import { PrivacyMask } from 'xerum';
import styled, { css } from 'styled-components';

const TextArea = styled('div')`
  display: flex;
  align-items: center;
  width: 100%;
  ${props => props.$minHeight && css`min-height: ${props.$minHeight}rem;`}
`;

const TextSection = props => {
  const { label, sectionText, labelColor, tagText, minHeight } = props;
  const { generalPreferences: { privacyMode } } = useSelector(state => state.preferences);

  return (
    <div>
      {label && (
        <>
          <Font color={labelColor} weight='semibold' mobileSize={0.875}>
            {label}
          </Font>

          <XymSpacer size={0.5} />
        </>
      )}

      <TextArea $minHeight={minHeight}>
        {privacyMode
          ? <PrivacyMask length={8} />
          : <Font mobileSize={0.875}>{sectionText}</Font>
        }

        {tagText && (
          <LabelTag
            visible={tagText}
            text={<Font size={0.875} mobileSize={0.75}>{tagText}</Font>}
          />
        )}
      </TextArea>
    </div>
  );
};

export { TextSection };