import { iconValid } from 'helpers';
import { Font, XymSpacer } from 'components';
import styled from 'styled-components';

const StyledNoData = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  width: 100%;
  user-select: none;
`;

const NoData = props => {
  const { id, icon, text, subText } = props;

  return (
    <StyledNoData id={id}>
      {iconValid(icon)
        ? <Font size={3}>
            <i className={`${icon}`} />
          </Font>
        : icon
      }

      <XymSpacer size={0.5} />

      <Font weight='semibold'>
        {text}
      </Font>

      {subText && (
        <>
          <XymSpacer size={0.125} />

          <Font size={0.875}>
            {subText}
          </Font>
        </>
      )}
    </StyledNoData>
  );
};

export { NoData };