import { Route } from 'react-router-dom';
import { paramsFromURL } from 'helpers';
import { routes } from 'controllers';
import { AuthRoute } from 'xerum';

const makeRoutes = args => {
  const { token, redirect, isAdmin } = args;

  return routes?.map((route, index) => {
    const { path, element, authenticate, adminsOnly } = route;

    const authElement = (
      <AuthRoute
        token={adminsOnly && !isAdmin ? 'noToken' : token}
        redirect={adminsOnly && !isAdmin ? '/' : redirect}
        from={{ pathname: path, search: paramsFromURL() }}
      />
    );

    if (authenticate || adminsOnly) {
      return (
        <Route key={index} element={authElement}>
          <Route path={path} element={element} />
        </Route>
      );
    }

    return <Route key={index} path={path} element={element} />;
  });
};

export { makeRoutes };