import { H3, P, PlaidIcon, Center, XymSpacer } from 'components';
import { SecureSync, FullWidth } from './styles';

const { VITE_APP_NAME: appName } = process.env;

const PlaidSync = () => {
  return (
    <SecureSync>
      <Center>
        <PlaidIcon />
      </Center>

      <FullWidth>
        <H3>Securely sync your bank or credit union</H3>
        <XymSpacer />
        <P>
          {appName} uses Plaid to sync with thousands of financial institutions
          across the US, Canada and the UK.  Plaid has best-in-class-encryption,
          so you can rest assured your data is safe.
        </P>
      </FullWidth>
    </SecureSync>
  );
};

export { PlaidSync };