import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { authActions } from 'modules';
import { emailIncrementorCheck } from 'helpers';
import { H3, Font, XymField, XymSpacer } from 'components';
import { Button } from 'xerum';
import { Form, Formik } from 'formik';
import styled, { withTheme } from 'styled-components';
import * as yup from 'yup';

const StyledWaitlist = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const defaultValues = {
  email: '',
};

const validationSchema = yup.object().shape({
  email: yup.string()
    .email('Invalid email.')
    .required('Email is required.')
    .test('email-incrementor', 'Email incrementors are not allowed.', value => emailIncrementorCheck(value)),
});

const WaitlistRemoveForm = withTheme(props => {
  const { theme, setRemoved } = props;
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const dispatch = useDispatch();

  const removeUserFromWaitlist = useCallback((payload, callbacks) => {
    dispatch(authActions.removeUserFromWaitlist(payload, callbacks));
  }, [ dispatch ]);

  const handleSubmit = (values, actions) => {
    const { setSubmitting } = actions;
    const { email } = values;
    const payload = { email };
    const callbacks = {
      onSuccess: () => setRemoved?.(true),
      onComplete: () => setSubmitting(false),
      onFail: () => setSubmitting(false),
    };

    removeUserFromWaitlist(payload, callbacks);
  };

  const buildForm = () => {
    return (
      <Formik
        initialValues={defaultValues}
        validationSchema={validationSchema}
        enableReinitialization={true}
        onSubmit={handleSubmit}
      >
        {form => (
          <Form>
            <H3>
              Waitlist removal
              <XymSpacer size={2} />
            </H3>

            <XymField
              form={form}
              name='email'
              type='email'
              autoComplete='email'
              icon='fa-solid fa-envelope'
              label={<Font size={0.875} weight='semibold'>Email</Font>}
              spacing={2}
            />

            <Button
              theme={theme}
              selectedTheme={selectedTheme}
              type='submit'
              disabled={form.isSubmitting}
              callback={form.handleSubmit}
              text={
                <Font weight='medium' mobileSize={0.875}>
                  {form.isSubmitting ? 'Sending request...' : 'Remove from waitlist'}
                </Font>
              }
            />
          </Form>
        )}
      </Formik>
    );
  };

  return (
    <StyledWaitlist>
      {buildForm()}
    </StyledWaitlist>
  );
});

export { WaitlistRemoveForm };