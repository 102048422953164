import { useSelector } from 'react-redux';
import { Font, SettingsNav } from 'components';
import { StyledMyInstitutions, Layout, InstitutionsContent } from './styles';
import { Accounts } from './Accounts';

const MyInstitutions = () => {
  const { tokenInfo } = useSelector(state => state.auth);

  const buildSections = () => {
    if (tokenInfo?.accessToken) {
      return <Accounts />;
    }

    return <Font weight='semibold'>No token found.</Font>;
  };

  return (
    <StyledMyInstitutions>
      <Layout>
        <SettingsNav />

        <InstitutionsContent>
          {buildSections()}
        </InstitutionsContent>
      </Layout>
    </StyledMyInstitutions>
  );
};

export { MyInstitutions };