import { useRef, useCallback, useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { appActions } from 'modules';
import { animate } from 'helpers';
import {
  Chevron,
  Font,
  UserIcon,
  XymInbox,
  XymDropdown,
  HamburgerMenuIcon,
  XymSpacer,
  XymConfirm,
  XymTabs,
  MoveMoneyForm,
} from 'components';
import { UserNavArea, User, HamburgerMenu } from './styles';
import { autoFundTabs } from 'pages/App/Header/localControllers/autoFundTabs';
import { ImportExportWizard } from 'pages/App/Header/ImportExportWizard/ImportExportWizard';
import { PrivacyMask } from 'xerum';
import { UserMenu } from './UserMenu';
import { NavButtons } from './NavButtons';
import { MobileUserMenu } from './MobileUserMenu';
import { withTheme } from 'styled-components';
import { flags } from 'utility';
import _ from 'lodash';

const UserNav = withTheme(props => {
  const { theme } = props;
  const {
    userMenuVisible,
    mobileMode,
    tabletMode,
    tabletLandscapeMode,
    mobileUserMenu,
  } = useSelector(state => state.app);
  const { userInfo } = useSelector(state => state.auth);
  const { messagesData } = useSelector(state => state.messages);
  const { institutionsData } = useSelector(state => state.institutions);
  const { autoFundSources } = useSelector(state => state.expenses);
  const [ confirmContent, setConfirmContent ] = useState(null);
  const { generalPreferences: { privacyMode, selectedTheme } } = useSelector(state => state.preferences);
  const { features: { messaging } } = flags;
  const mobileDevice = mobileMode || tabletMode || tabletLandscapeMode;

  const allAccounts = institutionsData?.reduce((previous, current) => {
    const { accounts } = current;
    return [ ...previous, ...accounts ];
  }, []);

  const hasMultipleAccounts = !_.isEmpty(allAccounts) && allAccounts?.length > 1;
  const email = userInfo?.email;
  const userMenuRef = useRef();
  const animationRef = useRef();
  const messages = useMemo(() => messagesData?.messageList || [], [ messagesData ]);
  const dispatch = useDispatch();
  const setUserMenuVisible = useCallback(payload => dispatch(appActions.setUserMenuVisible(payload)), [ dispatch ]);
  const setMobileUserMenu = useCallback(payload => dispatch(appActions.setMobileUserMenu(payload)), [ dispatch ]);

  const handleAnimationIn = useCallback(() => {
    animate({ ref: animationRef.current, offset: -10, duration: 0.2 });
  }, []);

  const handleAnimationOut = () => {
    animate({
      ref: animationRef.current,
      offset: -10,
      transitionOut: true,
      onComplete: () => setUserMenuVisible(!userMenuVisible),
    });
  };

  useEffect(() => {
    if (userMenuVisible) handleAnimationIn();
  }, [ userMenuVisible, handleAnimationIn ]);

  const handleMoveMoney = () => {
    const args = { setConfirmContent };
    const payload = <MoveMoneyForm {...args} />;
    setConfirmContent(payload);
  };

  const handleAutoFundSources = () => {
    const args = { setConfirmContent, autoFundSources };
    const payload = (
      <XymTabs
        content={autoFundTabs(args)}
        spacing={!_.isEmpty(autoFundSources)}
        visible={!_.isEmpty(autoFundSources)}
      />
    );

    setConfirmContent(payload);
  };

  const handleImportExport = () => {
    const args = { setConfirmContent };
    const payload = <ImportExportWizard { ...args } />;
    setConfirmContent(payload);
  };

  return (
    <UserNavArea>
      <NavButtons
        handleMoveMoney={handleMoveMoney}
        handleAutoFundSources={handleAutoFundSources}
        handleImportExport={handleImportExport}
      />

      <XymInbox visible={messaging} count={messages?.reduce?.((prev, current) => {
        return !current.read ? prev += 1 : prev;
      }, 0)} />

      <User ref={userMenuRef} onClick={() => !userMenuVisible ? setUserMenuVisible(true) : handleAnimationOut()}>
        <UserIcon />
        <XymSpacer size={0.4375} across={true} />

        <Font weight='semibold'>
          {privacyMode
            ? <PrivacyMask length={email?.length} />
            : email
          }
        </Font>
        <XymSpacer across={true} />
        <Chevron direction={userMenuVisible ? 'up' : 'down'} />

        <XymDropdown
          ref={animationRef}
          visible={userMenuVisible}
          setVisible={handleAnimationOut}
          width={hasMultipleAccounts && 30}
          padding='0.75rem 1.4375rem 1.45rem 1.4375rem'
        >
          <UserMenu />
        </XymDropdown>
      </User>

      {!mobileMode && (
        <HamburgerMenu
          $theme={theme}
          $selectedTheme={selectedTheme}
          onClick={() => setMobileUserMenu(!mobileUserMenu)}
        >
          <HamburgerMenuIcon />
        </HamburgerMenu>
      )}

      <MobileUserMenu visible={mobileUserMenu && mobileDevice} closeMobileUserMenu={() => setMobileUserMenu(false)} />

      <XymConfirm
        confirmContent={confirmContent}
        useOverflow={false}
        blank={true}
        onClose={() => setConfirmContent(null)}
      />
    </UserNavArea>
  );
});

export { UserNav };