import { appConstants } from 'modules';
import { getColor } from 'helpers';
import styled from 'styled-components';

const { spacing, layout, breakpoints } = appConstants;
const { layoutWidth, headerHeight } = layout;
const { extraLargeGap, gap, mediumGap } = spacing;
const { tablet, tabletLandscape, mobile, hybrid } = breakpoints;
const { light } = appConstants.themes;

export const StyledHeader = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${headerHeight}rem;
  width: 100dvw;
`;

export const Layout = styled('div')`
  display: inline-flex;
  justify-content: space-between;
  max-width: ${layoutWidth}rem;
  width: 100%;
  padding: 0 ${extraLargeGap}rem;

  @media screen and (max-width: ${hybrid}rem) {
    padding: 0 ${mediumGap}rem;
  }

  @media screen and (max-width: ${mobile}rem) {
    padding: 0 ${gap}rem;
  }
`;

export const LeftGroup = styled('div')`
  display: inline-flex;
  align-items: center;
`;

export const Brand = styled('div')`
  position: relative;
  display: inline-flex;
  align-items: center;
  user-select: none;
  cursor: ${props => props.$disableHomeLink ? 'default' : 'pointer'};

  @media screen and (max-width: ${mobile}rem) {
    display: none;
  }
`;

export const BetaBadge = styled('div')`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  right: 2.6rem;
  bottom: -0.5rem;
  padding: 0.125rem 0.25rem;
  border: 0.1875rem solid ${props => getColor(props, 'primary')};
  background-color: ${props => getColor(props, 'onPrimary')};
  color: ${props => getColor(props, 'primary')};
  border-radius: 0.5rem;
  width: 2.8475rem;

  @media screen and (max-width: ${tabletLandscape}rem) {
    position: relative;
    right: -0.75rem;
    bottom: -0.125rem;
  }

  @media screen and (max-width: ${tablet}rem) {
    position: relative;
    bottom: -0.175rem;
  }
`;

export const TradeSymbol = styled('span')`
  font-size: ${props => props.$size || 0.75}rem;
  position: absolute;
  top: 0.4rem;
  right: -0.4rem;
`;

export const AppNavArea = styled('div')`
  display: inline-flex;
  align-items: center;
  width: 100%;

  @media screen and (max-width: ${tablet}rem) {
    display: ${props => props.$isFooterNav ? 'inline-flex' : 'none'};
    gap: ${mediumGap}rem;
    justify-content: center;
  }

  @media screen and (max-width: ${mobile}rem) {
    gap: unset;
    justify-content: space-around;
  }
`;

export const AppNavLink = styled('div')`
  cursor: pointer;
  user-select: none;
  color: ${props => {
    const lightTheme = props.$selectedTheme === light;
    return getColor(props, props.$active ? 'accent' : lightTheme ? 'darkGrey' : 'offWhite');
  }};

  @media (hover: hover) {
    &:hover {
      color: ${props => getColor(props, 'accent')};
    }
  }
`;

export const UserNavArea = styled('div')`
  display: inline-flex;
  align-items: center;

  button {
    margin-top: 0.125rem;
  }

  button#relocatedMoveMoney,
  button#relocatedAutoFundSources {
    display: none;
  }

  @media screen and (max-width: ${tabletLandscape}rem) {
    button#relocatedMoveMoney,
    button#relocatedAutoFundSources {
      display: flex;
    }
  }

  @media screen and (max-width: ${mobile}rem) {
    padding: 0 ${gap}rem;
    width: 100%;
    justify-content: space-between;

    button#relocatedMoveMoney {
        padding-left: 0;
      }
    }
`;

export const HamburgerMenu = styled('div')`
  display: none;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      svg {
        fill: ${props => getColor(props, 'accent')};
      }
    }
  }

  @media screen and (max-width: ${tabletLandscape}rem) {
    display: flex;
  }
`;

export const RelocatedButtons = styled('div')`
  display: none;

  @media screen and (max-width: ${tabletLandscape}rem) {
    display: inline-flex;
    justify-content: flex-end;

    button {
      padding: ${gap}rem;
    }
  }

  @media screen and (max-width: ${mobile}rem) {
    justify-content: space-between;
  }
`;

export const User = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  cursor: pointer;
  user-select: none;

  @media screen and (max-width: ${tabletLandscape}rem) {
    display: none;
  }
`;

export const UserMenuLink = styled('div')`
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  color: ${props => getColor(props, props.$active ? 'accent' : 'onPrimary')};
  width: 100%;

  span {
    width: 100%;
  }

  @media (hover: hover) {
    &:hover {
      color: ${props => getColor(props, 'accent')};
    }
  }
`;

export const StyledUserMenu = styled('div')`
  display: grid;
  grid-template-columns: repeat(${props => props.$multipleAccounts ? 2 : 1}, auto);
  grid-gap: 1.5rem;
  width: 100%;
`;

export const MenuArea = styled('div')`
  display: flex;
  flex-direction: column;
  min-width: 9rem;
`;

export const AccountsArea = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const AccountsHolder = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  padding: 0.5rem 1rem 0.5rem 0;
  max-height: 13.75rem;
  overflow-y: auto;

  span {
    display: flex;
    align-items: center;
    min-width: 5rem;
  }

  span:last-child {
    justify-content: flex-end;
  }
`;