import { securityConstants } from 'modules/security/securityConstants';
import StateManager from 'state-wrangler';
import { request } from 'helpers';

const { actions, selectors } = securityConstants;

const initial = {};

const securityReducer = (initialState = initial, action = {}) => {
  const { payload } = action;
  const state = new StateManager(initialState);

  switch(action.type) {
    // Get trusted devices and session history
    case request(actions.GET_SECURITY_DATA).start:
      return state.update(selectors.STATE_KEY_SECURITY_DATA_LOADING, true);

    case request(actions.GET_SECURITY_DATA).success:
      return state.merge([
        { method: 'update', key: selectors.STATE_KEY_TRUSTED_DEVICES, payload: payload.devices.trustedDevices },
        { method: 'update', key: selectors.STATE_KEY_SECURITY_KEYS, payload: payload.devices.securityKeys },
        { method: 'update', key: selectors.STATE_KEY_AUTHENTICATORS, payload: payload.devices.authenticators },
        { method: 'update', key: selectors.STATE_KEY_SESSION_HISTORY, payload: payload.devices.sessionHistory },
      ]);

    case request(actions.GET_SECURITY_DATA).complete:
      return state.update(selectors.STATE_KEY_SECURITY_DATA_LOADING, false);



    // Update trusted device
    case request(actions.UPDATE_TRUSTED_DEVICE).start:
      return state.update(selectors.STATE_KEY_TRUSTED_DEVICES_LOADING, true);

    case request(actions.UPDATE_TRUSTED_DEVICE).success:
      return state.update(selectors.STATE_KEY_TRUSTED_DEVICES, payload.devices.trustedDevices);

    case request(actions.UPDATE_TRUSTED_DEVICE).complete:
      return state.update(selectors.STATE_KEY_TRUSTED_DEVICES_LOADING, false);



    // Remove trusted device
    case request(actions.REMOVE_TRUSTED_DEVICE).start:
      return state.update(selectors.STATE_KEY_TRUSTED_DEVICES_LOADING, true);

    case request(actions.REMOVE_TRUSTED_DEVICE).success:
      return state.update(selectors.STATE_KEY_TRUSTED_DEVICES, payload.devices.trustedDevices);

    case request(actions.REMOVE_TRUSTED_DEVICE).complete:
      return state.update(selectors.STATE_KEY_TRUSTED_DEVICES_LOADING, false);



    default:
      return initialState;
  }
};

export { securityReducer };