import { H3, P, Center, XymSpacer } from 'components';
import { TransactionNames, FullWidth } from './styles';

const {
  VITE_APP_NAME: appName,
  VITE_GCP_STORAGE_BUCKET: storageBucket,
} = process.env;

const AboutTransactionNames = () => {
  const src = `${storageBucket}/Waitlist/Activity.png`;

  return (
    <TransactionNames>
      <FullWidth>
        <H3>A clear view</H3>
        <XymSpacer />
        <P>
          No more cryptic and poorly formatted transaction names.
          {appName} does its best to give you a plain view on who charged you. There&apos;s
          more info at a glance, as well!  Whether it&apos;s a deposit, transfer, withdrawal,
          {appName} will show you the most relevant information so you aren&apos;t left with any questions.
        </P>
      </FullWidth>

      <Center>
        <img src={src} id='activity' alt='Activity preview.' />
      </Center>
    </TransactionNames>
  );
};

export { AboutTransactionNames };