import { appConstants } from 'modules';
import { getColor } from 'helpers';
import { fonts } from 'controllers';
import { theme } from 'theme';
import styled, { createGlobalStyle } from 'styled-components';

const { breakpoints } = appConstants;
const { mobile } = breakpoints;
const colors = theme.colors;

export const StyledApp = styled('div')`
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
  width: 100%;
  background-color: ${props => getColor(props, 'primary')};
`;

export const MainContent = styled('main')`
  width: 100%;
  height: ${props => {
    if (props.$token) {
      const getBannerCompensation = () => {
        if (props.$mobileMode) return '13rem';
        return '17rem';
      };

      const getDefaultCompensation = () => {
        if (props.$mobileMode) return '10rem';
        return '14rem';
      };

      return props.$bannerContent
        ? `calc(100dvh - ${getBannerCompensation()})`
        : `calc(100dvh - ${getDefaultCompensation()})`;
    }

    return 'inherit';
  }};
`;

export const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  #root {
    width: 100%;
    height: 100dvh;
  }

  body {
    background-color: ${props => getColor(props, 'primary')};
    color: ${props => getColor(props, 'onPrimary')};
    font-family: ${fonts.primary.normal}, sans-serif;
    margin: 0;
  }

  form {
    display: flex;
    flex-direction: column;
    width: 100%;

    button {
      width: 100%;
    }

    @media screen and (max-width: ${mobile}rem) {
      max-width: unset;
    }
  }

  a:link,
  a:visited {
    color: ${props => getColor(props, 'accent', colors.neutral.raisinBlack)};
  }

  a:hover {
    color: ${props => getColor(props, 'accentHover', colors.neutral.greyWeb)};
  }

  ::-webkit-scrollbar {
    width: 0.3125rem;
  }

  ::-webkit-scrollbar-track {
    border-radius: 1rem;
    background: ${props => getColor(props, 'lightGrey', colors.neutral.lightGrey)};
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background: ${props => getColor(props, 'grey', colors.neutral.greyWeb)};
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${props => getColor(props, 'darkGrey', colors.neutral.davysGrey)};
  }
`;