
// =============== Balances ===============
export const getBalances = args => {
  const { type, payload, callbacks } = args;
  const { token, meta, linkedAccountId } = payload;

  const linkedAccountParam = `?linkedAccountId=${linkedAccountId || null}`;
  const params = `${linkedAccountParam}`;

  return {
    type,
    path: `/expenses/balances${params}`,
    method: 'GET',
    meta,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    ...callbacks,
  };
};

// =============== Expenses ===============
export const getExpensesData = args => {
  const { type, payload, callbacks } = args;
  const {
    token,
    meta,
    linkedAccountId,
    expensesSortIdOverride,
    goalsSortIdOverride,
  } = payload;

  const linkedAccountParam = `?linkedAccountId=${linkedAccountId || null}`;
  const expenseParam = expensesSortIdOverride && `&expensesSortIdOverride=${expensesSortIdOverride}`;
  const goalParam = goalsSortIdOverride && `&goalsSortIdOverride=${goalsSortIdOverride}`;
  const params = `${linkedAccountParam}${expenseParam || ''}${goalParam || ''}`;

  return {
    type,
    path: `/expenses${params}`,
    method: 'GET',
    meta,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    ...callbacks,
  };
};

export const addExpense = args => {
  const { type, payload, callbacks } = args;
  const { token, meta, ...withoutToken } = payload;

  return {
    type,
    path: '/expenses',
    method: 'POST',
    body: JSON.stringify(withoutToken),
    meta,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    ...callbacks,
  };
};

export const updateExpense = args => {
  const { type, payload, callbacks } = args;
  const { token, meta, ...withoutToken } = payload;

  return {
    type,
    path: '/expenses',
    method: 'PUT',
    body: JSON.stringify(withoutToken),
    meta,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    ...callbacks,
  };
};

export const removeExpense = args => {
  const { type, payload, callbacks } = args;
  const { token, meta, ...withoutToken } = payload;

  return {
    type,
    path: '/expenses',
    method: 'DELETE',
    body: JSON.stringify(withoutToken),
    meta,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    ...callbacks,
  };
};

export const rebalanceExpense = args => {
  const { type, payload, callbacks } = args;
  const { token, meta, ...withoutToken } = payload;

  return {
    type,
    path: '/expenses/rebalance',
    method: 'PUT',
    body: JSON.stringify(withoutToken),
    meta,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    ...callbacks,
  };
};

export const importExpenses = args => {
  const { type, payload, callbacks } = args;
  const { token, meta, linkedAccountId, ...withoutToken } = payload;

  const linkedAccountParam = `?linkedAccountId=${linkedAccountId || null}`;

  return {
    type,
    path: `/expenses/import${linkedAccountParam}`,
    method: 'PUT',
    body: JSON.stringify(withoutToken),
    meta,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    ...callbacks,
  };
};



// =============== Auto-funding Sources ===============
export const getAutoFundSources = args => {
  const { type, payload, callbacks } = args;
  const { token } = payload;

  return {
    type,
    path: '/expenses/auto-fund-sources',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    ...callbacks,
  };
};

export const addAutoFundSource = args => {
  const { type, payload, callbacks } = args;
  const { token, ...withoutToken } = payload;

  return {
    type,
    path: '/expenses/auto-fund-sources',
    method: 'POST',
    body: JSON.stringify(withoutToken),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    ...callbacks,
  };
};

export const updateAutoFundSource = args => {
  const { type, payload, callbacks } = args;
  const { token, ...withoutToken } = payload;

  return {
    type,
    path: '/expenses/auto-fund-sources',
    method: 'PUT',
    body: JSON.stringify(withoutToken),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    ...callbacks,
  };
};

export const removeAutoFundSource = args => {
  const { type, payload, callbacks } = args;
  const { token, ...withoutToken } = payload;

  return {
    type,
    path: '/expenses/auto-fund-sources',
    method: 'DELETE',
    body: JSON.stringify(withoutToken),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    ...callbacks,
  };
};