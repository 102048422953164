/* eslint-disable no-unused-vars */
// NOTE: We need to pull out the access and refreshTokens, immutably, from localStorage.
import { appConstants } from 'modules';
import { jwtDecode } from 'jwt-decode';
import moment from 'moment';

const { names } = appConstants;
const { appName, refreshTokenKeyName, accessTokenKeyName } = names;
const existingSettings = JSON.parse(localStorage.getItem(appName));

const asSeconds = 1000;
let autoActionComplete = false;
export let sessionCheck;

export const tokenValid = token => {
  try {
    const decoded = jwtDecode(token);
    const tokenExpiration = decoded?.exp * asSeconds;
    const expires = moment(tokenExpiration);

    return moment() <= expires;
  } catch (error) {
    return false;
  }
};

export const removeLocalTokens = () => {
  if (existingSettings) {
    const {
      [accessTokenKeyName]: accessToken,
      [refreshTokenKeyName]: refreshToken,
      ...withoutTokens
    } = existingSettings;

    localStorage.setItem(appName, JSON.stringify(withoutTokens));
  }
};

export const autoLogoutOrRefresh = args => {
  const {
    token,
    refreshToken,
    trustedDevice,
    logout,
    addNotification,
    getNewTokens,
    invalidateToken,
    restart,
  } = args;

  if (restart) {
    autoActionComplete = false;
    clearInterval(sessionCheck);
  }

  if (token) {
    sessionCheck = setInterval(() => {
      const expired = !tokenValid(token);
      const tokenExpiration = jwtDecode(token).exp * asSeconds;
      const expires = moment(tokenExpiration);
      const secondsToExpiration = Math.round(moment.duration(expires.diff(moment())).asSeconds());
      const time = 10;
      const aboutToExpire = secondsToExpiration <= time && secondsToExpiration > 0;
      const untrustedDeviceNotification = {
        message: `Your session expires in ${secondsToExpiration}s. Please log back in to continue.`,
        type: 'warning',
      };

      if (expired && trustedDevice && autoActionComplete) {
        autoLogoutOrRefresh({ ...args, restart: true });
        return;
      }

      if (aboutToExpire && trustedDevice && !autoActionComplete) {
        const callbacks = {
          onSuccess: () => {
            autoLogoutOrRefresh({ ...args, restart: true });
          },
        };

        const payload = {
          refreshToken,
          trustedDevice,
          meta: { noLoadingState: true },
        };

        autoActionComplete = true;
        getNewTokens?.(payload, callbacks);
        return;
      }

      if (aboutToExpire && !trustedDevice && !autoActionComplete) {
        addNotification?.(untrustedDeviceNotification);
        autoActionComplete = true;
      }

      if (expired && !trustedDevice && autoActionComplete) {
        const callbacks = {
          onSuccess: () => logout?.(),
        };

        autoActionComplete = false;
        invalidateToken?.({ accessToken: token }, callbacks);
      }
    }, 1000);
  }
};

export const cleanApp = () => {
  removeLocalTokens();
  autoActionComplete = false;
  clearInterval(sessionCheck);
  window.location.href = '/';
};