/* eslint-disable no-unused-vars */
import { actionCreator } from 'helpers';
import { authConstants } from 'modules';
import * as api from 'modules/auth/authApi';

const authActions = {
  // =============== Authentication ===============
  login: (payload, callbacks) => {
    const type = authConstants.actions.LOG_IN;
    const args = { type, payload, callbacks };
    return api.login(args);
  },

  verifyToken: (payload, callbacks) => {
    const type = authConstants.actions.VERIFY_TOKEN;
    const args = { type, payload, callbacks };
    return api.verifyToken(args);
  },

  getNewTokens: (payload, callbacks) => {
    const type = authConstants.actions.GET_NEW_TOKENS;
    const args = { type, payload, callbacks };
    return api.getNewTokens(args);
  },

  invalidateToken: (payload, callbacks) => {
    const type = authConstants.actions.INVALIDATE_TOKEN;
    const args = { type, payload, callbacks };
    return api.invalidateToken(args);
  },

  // ==================== User ====================
  createUser: (payload, callbacks) => {
    const type = authConstants.actions.CREATE_USER;
    const args = { type, payload, callbacks };
    return api.createUser(args);
  },

  getUserInfo: (payload, callbacks) => {
    const type = authConstants.actions.GET_USER_INFO;
    const args = { type, payload, callbacks };
    return api.getUserInfo(args);
  },

  sendVerificationEmail: (payload, callbacks) => {
    const type = authConstants.actions.SEND_VERIFICATION_EMAIL;
    const args = { type, payload, callbacks };
    return api.sendVerificationEmail(args);
  },

  verifyEmail: (payload, callbacks) => {
    const type = authConstants.actions.VERIFY_EMAIL,
      args = { type, payload, callbacks };
    return api.verifyEmail(args);
  },

  sendPasswordResetEmail: (payload, callbacks) => {
    const type = authConstants.actions.SEND_PASSWORD_RESET_EMAIL;
    const args = { type, payload, callbacks };
    return api.sendPasswordResetEmail(args);
  },

  updatePassword: (payload, callbacks) => {
    const type = authConstants.actions.UPDATE_PASSWORD;
    const args = { type, payload, callbacks };
    return api.updatePassword(args);
  },

  deleteUser: (payload, callbacks) => {
    const type = authConstants.actions.DELETE_USER;
    const args = { type, payload, callbacks };
    return api.deleteUser(args);
  },

  // ================== Waitlist ==================
  addUserToWaitlist: (payload, callbacks) => {
    const type = authConstants.actions.ADD_USER_TO_WAITLIST;
    const args = { type, payload, callbacks };
    return api.addUserToWaitlist(args);
  },

  removeUserFromWaitlist: (payload, callbacks) => {
    const type = authConstants.actions.REMOVE_USER_FROM_WAITLIST;
    const args = { type, payload, callbacks };
    return api.removeUserFromWaitlist(args);
  },

  // ================== Stastics ==================
  getUserStatistics: (payload, callbacks) => {
    const type = authConstants.actions.GET_USER_STATISTICS;
    const args = { type, payload, callbacks };
    return api.getUserStatistics(args);
  },
};

export { authActions };