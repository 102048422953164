import { useSelector } from 'react-redux';
import { Font, XymSpacer } from 'components';
import { Percent } from 'xerum';
import { StyledStatistic } from './styles';
import { withTheme } from 'styled-components';

const Statistic = withTheme(props => {
  const { theme, title, total, current } = props;
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const onPrimary = theme.modes[selectedTheme]?.onPrimary;

  return (
    <StyledStatistic>
      <Font weight='semibold'>
        {title}
        <XymSpacer across={true} />
        {current}

        {total && (
          <>
            <XymSpacer across={true} />

            <Percent
              theme={theme}
              selectedTheme={selectedTheme}
              positiveColor={onPrimary + 50}
              total={total}
              current={current}
            />
          </>
        )}
      </Font>
    </StyledStatistic>
  );
});

export { Statistic };