import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { institutionsActions } from 'modules';
import { Font, Footnote, XymSpacer, XymConfirmButtons } from 'components';
import { StyledConfirm, RemovedItem } from './styles';
import { withTheme } from 'styled-components';
import _ from 'lodash';

const RemoveInstitutionConfirm = withTheme(props => {
  const { theme, institution, setConfirmContent } = props;
  const { tokenInfo } = useSelector(state => state.auth);
  const { generalPreferences: { privacyMode, selectedTheme } } = useSelector(state => state.preferences);
  const itemId = institution?.itemId;
  const selfManaged = institution?.selfManaged;
  const totalAccounts = institution?.accounts.length;
  const token = tokenInfo?.refreshToken || tokenInfo?.accessToken;
  const error = theme.modes[selectedTheme]?.error;
  const errorHover = theme.modes[selectedTheme]?.errorHover;
  const dispatch = useDispatch();

  const removeInstitution = useCallback((payload, callbacks) => {
    dispatch(institutionsActions.removeInstitution(payload, callbacks));
  }, [ dispatch ]);

  return (
    <StyledConfirm>
      <Font weight='semibold'>
        Are you sure you want to remove this institution?
      </Font>

      <XymSpacer />

      <RemovedItem>
        <XymSpacer across={true} />
        {totalAccounts} account{totalAccounts > 1 || totalAccounts === 0 ? 's' : ''} will be removed.
      </RemovedItem>

      <XymSpacer size={1.875} />

      <Footnote>
        All transactions, expenses and goals associated
        with {totalAccounts > 1 ? 'these accounts' : 'this account'} will be removed!
        If you decide to link this institution again, you will need to re-add your expenses, goals,
        auto-funding and auto-spending settings.  You will also need to re-categorize your transactions.
      </Footnote>

      <XymSpacer />

      <XymConfirmButtons
        confirmText='Remove'
        privacy={privacyMode}
        setConfirmContent={setConfirmContent}
        confirmColor={error}
        confirmHoverColor={errorHover}
        callback={() => {
          const payload = { token, selfManaged, itemId };

          if (!_.isEmpty(institution)) {
            removeInstitution(payload);
            setConfirmContent(null);
          }
        }}
      />
    </StyledConfirm>
  );
});

export { RemoveInstitutionConfirm };