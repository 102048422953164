import { useMemo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { appConstants } from 'modules';
import { Font, NoData, XymSpacer, Center } from 'components';
import { StyledSessionHistory } from './styles';
import { Table, PrivacyMask, Pagination } from 'xerum';
import { withTheme } from 'styled-components';
import moment from 'moment';
import _ from 'lodash';

const { breakpoints, themes } = appConstants;
const { tablet } = breakpoints;
const { light } = themes;

const SessionHistory = withTheme(props => {
  const { theme } = props;
  const { sessionHistory, securityDataLoading } = useSelector(state => state.security);
  const { mobileMode } = useSelector(state => state.app);
  const { generalPreferences: { privacyMode, selectedTheme } } = useSelector(state => state.preferences);
  const [ currentPage, setCurrentPage ] = useState(1);

  const pageSize = mobileMode ? 2 : 5;
  const totalPages = Math.ceil(sessionHistory?.length / pageSize);
  const darkGrey = theme.modes[selectedTheme]?.darkGrey;
  const offWhite = theme.modes[selectedTheme]?.offWhite;
  const accent = theme.modes[selectedTheme]?.accent;
  const onPrimary = theme.modes[selectedTheme]?.onPrimary;
  const lightTheme = selectedTheme === light;
  const titleColor = lightTheme ? darkGrey : offWhite;

  const lastUsedValue = lastUsed => {
    const today = moment().format('MMM Do, YYYY');
    const yesterday = moment().subtract(1, 'days').format('MMM Do, YYYY');
    const lastKnownDate = moment(lastUsed).format('MMM Do, YYYY');
    const timestamp = moment(lastUsed).format('hh:mm:ss A');
    const usedToday = lastKnownDate === today && `Today @ ${timestamp}`;
    const usedYesterday = lastKnownDate === yesterday && `Yesterday @ ${timestamp}`;

    return usedToday || usedYesterday || moment(lastUsed).format('MMM DD, YYYY @ hh:mm:ss A');
  };

  const rowContent = useMemo(() => (
    sessionHistory?.map((session, index) => {
      const { browser, platform, trustedDevice, lastUsed, location } = session;
      const currentSession = index === sessionHistory.length - 1;

      return {
        td1: `${browser} ${currentSession ? ' (this session)' : ''}`,
        td2: privacyMode ? <PrivacyMask /> : platform,
        td3: privacyMode ? <PrivacyMask /> : trustedDevice ? 'Trusted' : 'Not trusted',
        td4: privacyMode ? <PrivacyMask /> : lastUsedValue(lastUsed),
        td5: privacyMode ? <PrivacyMask /> : location,
      };
    })
    .reverse()
    .filter((session, index) => {
      const maxIndex = currentPage * pageSize;
      const minIndex = maxIndex - pageSize;

      if (index >= minIndex && index < maxIndex) {
        return session;
      }
    })
  ), [ privacyMode, sessionHistory, currentPage, pageSize ]);

  const defaultContent = {
    headers: [
      <Font key='browser' weight='semibold'>Browser</Font>,
      <Font key='platform' weight='semibold'>Platform</Font>,
      <Font key='trustedDevice' weight='semibold'>Trusted</Font>,
      <Font key='date' weight='semibold'>Date</Font>,
      <Font key='location' weight='semibold'>Location</Font>,
  ],
    rows: rowContent || [],
  };

  const [ content, setContent ] = useState(defaultContent);

  useEffect(() => {
    if (!_.isEmpty(sessionHistory) && !_.isEqual(content.rows, rowContent)) {
      setContent({ ...content, rows: rowContent });
    }
  }, [ sessionHistory, content, rowContent, privacyMode ]);

  const buildSessionTable = () => {
    if (securityDataLoading) {
      return <NoData text='Loading sessions...' />;
    }

    if (!_.isEmpty(sessionHistory)) {
      const receivedArray = sortedArray => setContent({ ...content, rows: sortedArray });

      return (
        <>
          <Table
            theme={theme}
            selectedTheme={selectedTheme}
            content={content}
            sortable={receivedArray}
            mobileSize={tablet}
            columnLayout='1fr 10rem repeat(3, 7rem)'
          />

          {totalPages > 1 && (
            <Center>
              <XymSpacer />
              <Pagination
                $theme={theme}
                $selectedTheme={selectedTheme}
                totalPages={totalPages}
                currentPage={currentPage}
                activeColor={accent}
                inactiveColor={onPrimary}
                visible={totalPages > 1}
                boldActive={true}
                truncateLimit={5}
                markerWidth={0.5}
                markerHeight={0.25}
                markerSpacing={0.125}
                onPageChange={setCurrentPage}
              />
            </Center>
          )}
        </>
      );
    }

    return (
      <NoData
        icon='fa-solid fa-table'
        text='No session history'
        subText='Sign-in activity will appear here.'
      />
    );
  };

  return (
    <StyledSessionHistory $theme={theme} $selectedTheme={selectedTheme}>
      <Font weight='semibold' size={1.25} mobileSize={1} tabletSize={1} color={titleColor}>
        Session History
      </Font>

      <XymSpacer size={1.375} />
      {buildSessionTable()}
    </StyledSessionHistory>
  );
});

export { SessionHistory };