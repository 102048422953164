import { H3, P, Center, XymSpacer } from 'components';
import { MoreDetails, FullWidth } from './styles';

const {
  VITE_GCP_STORAGE_BUCKET: storageBucket,
} = process.env;

const AboutTransactionDetails = () => {
  const src = `${storageBucket}/Waitlist/Transaction-Details.png`;

  return (
    <MoreDetails>
      <FullWidth>
        <H3>It&apos;s all in the details</H3>
        <XymSpacer />
        <P>
          Transactions have a lot more data than an activity feed can show.
          You can open a transaction to view more point of sale information &mdash; such as a map,
          address, time, payment method, check numbers etc.
        </P>

        <XymSpacer />

        <P>
          You can also assign this merchant an Expense to always pull from, or leave a memo for later.
        </P>
      </FullWidth>

      <Center>
        <img src={src} id='transactionDetails' alt='Transaction details preview.' />
      </Center>
    </MoreDetails>
  );
};

export { AboutTransactionDetails };