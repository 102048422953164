import { useSelector } from 'react-redux';
import { appConstants } from 'modules';
import { P, Font, XymPrice, XymSpacer } from 'components';
import { DetailsArea } from './styles';
import { withTheme } from 'styled-components';
import moment from 'moment';

const { light } = appConstants.themes;

const ExpenseDetails = withTheme(props => {
  const { theme, funded, current, needed, dueDate } = props;
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const lightTheme = selectedTheme === light;
  const onPrimary = theme.modes[selectedTheme]?.onPrimary;
  const accent = theme.modes[selectedTheme]?.accent;
  const frenchGrey = theme.colors.neutral.frenchGrey;
  const rawDate = moment(dueDate, 'YYYY-MM-DD');
  const transactionYear = rawDate.year();
  const today = moment().format('YYYY-MM-DD');
  const yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD');
  const thisYear = moment().year();
  const isToday = today === moment(dueDate).format('YYYY-MM-DD');
  const isYesterday = yesterday === moment(dueDate).format('YYYY-MM-DD');
  const tomorrow = moment().add(1, 'days').format('YYYY-MM-DD') === moment(dueDate).format('YYYY-MM-DD');
  const yearFormat = transactionYear !== thisYear ? ', YYYY' : '';

  return (
    <DetailsArea $funded={funded}>
      <Font weight='semibold'>
        <XymPrice value={current} color={onPrimary}
        />

        <XymSpacer size={0.5} across={true} />
        <Font color={lightTheme ? frenchGrey : onPrimary + 50}>|</Font>
        <XymSpacer size={0.5} across={true} />

        <XymPrice value={needed} color={accent} />
      </Font>

      {dueDate && (
        <P>
          <Font size={0.875}>
            By&nbsp;
            {isToday && 'Today'}
            {isYesterday && 'Yesterday'}
            {tomorrow && 'Tomorrow'}
            {!isToday && !isYesterday && !tomorrow && moment(dueDate).format(`MMMM Do${yearFormat}`)}
          </Font>
        </P>
      )}
    </DetailsArea>
  );
});

export { ExpenseDetails };