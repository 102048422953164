import { appConstants } from 'modules';
import { getColor, hexValid } from 'helpers';
import { theme } from 'theme';
import styled, { css } from 'styled-components';

const { themes, spacing, breakpoints } = appConstants;
const { mediumGap, gap, smallGap, extraSmallGap } = spacing;
const { mobile } = breakpoints;
const { light } = themes;
const colors = theme.colors;

export const CloseExpense = styled('div')`
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0.1875rem;
  left: 0.35rem;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 50%;
  background-color: ${props => getColor(props, 'primary')};
`;

export const ExpenseHolder = styled('div')`
  position: relative;
  width: 100%;
  background-color: ${props => {
    const frenchGrey = colors.neutral.frenchGrey;
    const bgColor = frenchGrey + 15;

    return props.$isDraggingOver ? bgColor : 'transparent';
  }};

  border: 0.125rem solid ${props => {
    const lightTheme = props.$selectedTheme === light;
    const paynesGrey = colors.neutral.paynesGrey;
    const frenchGrey = colors.neutral.frenchGrey;
    const borderColor = lightTheme ? frenchGrey : paynesGrey;

    return props.$isDraggingOver ? borderColor : 'transparent';
  }};

  border-radius: 0.5rem;
  transition: all 0.2s ease;
`;

export const StyledExpense = styled('div')`
  position: relative;
  display: grid;
  grid-template-columns: 2rem 1fr auto;
  grid-template-rows: repeat(3, auto);
  border-radius: 0.5rem;
  gap: ${smallGap + extraSmallGap}rem;
  grid-template-areas:
    'icon name details'
    'progressBar progressBar progressBar'
    'autospend autospend addTag';

  ${props => props.$isGoal && css`
    grid-template-rows: repeat(2, auto);
    grid-template-areas:
      'icon name details'
      'progressBar progressBar progressBar';
  `}

  padding: ${gap}rem;
  width: 100%;
  height: ${props => props.$isGoal ? 'auto' : 'fit-content'};
  user-select: none;
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      ${CloseExpense} {
        display: flex;
      }
    }
  }
`;

export const IconArea = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  grid-area: icon;
  user-select: none;
  min-width: ${props => props.$size || 2}rem;
  min-height: ${props => props.$size || 2}rem;
  background-color: ${props => {
    const lightTheme = props.$selectedTheme === light;
    const paynesGrey = colors.neutral.paynesGrey;
    const lightColor = hexValid(props.$bgColorLight) || paynesGrey;
    const darkColor = hexValid(props.$bgColorDark) || paynesGrey + 75;

    return `${lightTheme ? lightColor : darkColor}`;
  }};
  color: ${props => {
    const lightTheme = props.$selectedTheme === light;
    const offWhite = colors.shades.offWhite;
    const frenchGrey = colors.neutral.frenchGrey;

    return `${lightTheme ? offWhite : frenchGrey}`;
  }};
  border-radius: ${props => props.$borderRadius || 0.25}rem;
  margin-left: ${extraSmallGap}rem;

  ${props => props.$noMargin && css`margin: 0;`}

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: inherit;
  }
`;

export const NameArea = styled('div')`
  grid-area: name;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: ${gap}rem;
`;

export const TitleAndTooltips = styled('div')`
  display: inline-flex;
`;

export const DetailsArea = styled('div')`
  grid-area: details;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: ${extraSmallGap}rem;
  align-items: flex-end;
`;

export const ProgressArea = styled('div')`
  display: flex;
  flex-direction: column;
  grid-area: progressBar;
  height: fit-content;
`;

export const AutoSpendArea = styled('div')`
  grid-area: autospend;
  overflow: hidden;
  height: 2.1875rem;
  ${props => props.$pushOutOfFrame && css`padding-top: ${mediumGap}rem;`}
`;

export const AddTagArea = styled('div')`
  display: inline-flex;
  grid-area: addTag;
  align-items: center;
  justify-content: flex-end;
  width: auto;
`;

export const TagOverflowCount = styled('div')`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: ${props => hexValid(props.$bgColor) || getColor(props, 'grey')};

  span {
    margin: -0.125rem 0 0 -0.125rem;
  }
`;

export const TagOverflow = styled('div')`
  display: ${props => props.$visible ? 'inline-flex' : 'none'};
  flex-direction: column;
  gap: ${extraSmallGap}rem;
  position: fixed;
  bottom: ${props => {
    const yPosition = props.$yPosition && (props.$yPosition / 16) - 1.5;
    return `calc(100dvh - ${yPosition || 0}rem)`;
  }};
  width: fit-content;
  min-width: 17.5rem;
  max-width: 23rem;
  flex-wrap: wrap;
  height: auto;
  padding: ${gap}rem;
  border-radius: 0.25rem;
  z-index: 1;

  background-color: ${props => {
    const lightTheme = props.$selectedTheme === light;
    return getColor(props, lightTheme ? 'secondary' : 'darkGrey');
  }};

  border: 0.0625rem solid ${props => {
    const lightTheme = props.$selectedTheme === light;
    return lightTheme ? getColor(props, 'lightGrey') : getColor(props, 'lightGrey') + 50;
  }};

  box-shadow: 0 0.125rem 0.125rem ${props => {
    const lightTheme = props.$selectedTheme === light;
    return getColor(props, lightTheme ? 'lightGrey' : 'black');
  }};
`;

export const CompactLayout = styled('div')`
  position: relative;
  display: grid;
  gap: 0.5rem ${smallGap}rem;
  width: 100%;
  align-items: center;
  padding: ${smallGap}rem;
  cursor: ${props => props.$isDraggable ? 'pointer' : 'default'};

  ${props => {
    if (props.$hideAutoExpenses || props.$isGoal) {
      return css`
        grid-template-columns: auto 1fr;
        grid-template-rows: repeat(2, auto);
        grid-template-areas:
          'icon name'
          'progressBar progressBar';
        `;
    }

    return css`
      grid-template-columns: auto 1fr auto;
      grid-template-rows: repeat(2, auto) 2.5rem;
      grid-template-areas:
        'icon name name'
        'progressBar progressBar progressBar'
        'autospend autospend addTag';
    `;
  }}
`;

export const FrequencyName = styled('div')`
  display: flex;
  align-items: center;
`;

export const DragIcon = styled('div')`
  position: absolute;
  top: 1.325rem;
  left: ${props => props.$isDraggingOver ? -1.5 : -0.5}rem;
  display: ${props => props.$visible !== false ? 'flex' : 'none'};
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;

  @media screen and (max-width: ${mobile}rem) {
    top: 1.825rem;
    left: ${props => props.$isDraggingOver ? -1.5 : -1}rem;
  }
`;