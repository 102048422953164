import { authConstants } from 'modules/auth/authConstants';
import { updateTokenStorage, request } from 'helpers';
import StateManager from 'state-wrangler';

const { actions, selectors } = authConstants;

const initial = {};

const authReducer = (initialState = initial, action = {}) => {
  const { payload, meta } = action;
  const state = new StateManager(initialState);

  switch(action.type) {
    // =============== Authentication ===============
    // Log in
    case request(actions.LOG_IN).start:
      return state.update(selectors.STATE_KEY_TOKEN_INFO_LOADING, true);

    case request(actions.LOG_IN).success:
      updateTokenStorage(payload.tokens);

      return state.merge([
        { method: 'update', key: selectors.STATE_KEY_TOKEN_INFO, payload: payload.tokens },
        { method: 'update', key: selectors.STATE_KEY_USER_INFO, payload: payload.user },
      ]);

    case request(actions.LOG_IN).complete:
      return state.update(selectors.STATE_KEY_TOKEN_INFO_LOADING, false);



    // Verify access token for untrusted devices
    case request(actions.VERIFY_TOKEN).start:
      return state.update(selectors.STATE_KEY_TOKEN_INFO_LOADING, true);

    case request(actions.VERIFY_TOKEN).success:
      updateTokenStorage(payload.tokens);

      return state.merge([
        { method: 'update', key: selectors.STATE_KEY_TOKEN_INFO, payload: payload.tokens },
        { method: 'update', key: selectors.STATE_KEY_USER_INFO, payload: payload.user },
      ]);

    case request(actions.VERIFY_TOKEN).complete:
      return state.update(selectors.STATE_KEY_TOKEN_INFO_LOADING, false);



    // Get new access token and refresh token for trusted devices
    case request(actions.GET_NEW_TOKENS).start:
      return state.update(selectors.STATE_KEY_TOKEN_INFO_LOADING, !meta?.noLoadingState);

    case request(actions.GET_NEW_TOKENS).success:
      updateTokenStorage(payload.tokens);

      return state.merge([
        { method: 'update', key: selectors.STATE_KEY_TOKEN_INFO, payload: payload.tokens },
        { method: 'update', key: selectors.STATE_KEY_USER_INFO, payload: payload.user },
      ]);

    case request(actions.GET_NEW_TOKENS).complete:
      return state.update(selectors.STATE_KEY_TOKEN_INFO_LOADING, false);



    // Invalidate accessToken and refreshToken
    case request(actions.INVALIDATE_TOKEN).start:
      return state.update(selectors.STATE_KEY_INVALIDATING_TOKENS, true);

    case request(actions.INVALIDATE_TOKEN).success:
      return state.remove(selectors.STATE_KEY_TOKEN_INFO, payload.tokens);



    // ==================== User ====================
    // Create user
    case request(actions.CREATE_USER).success:
      updateTokenStorage(payload.tokens);

      return state.merge([
        { method: 'update', key: selectors.STATE_KEY_TOKEN_INFO, payload: payload.tokens },
        { method: 'update', key: selectors.STATE_KEY_USER_INFO, payload: payload.user },
      ]);


    // Get user info
    case request(actions.GET_USER_INFO).start:
      return state.merge([
        { method: 'update', key: selectors.STATE_KEY_USER_INFO_LOADING, payload: true },
        { method: 'update', key: selectors.STATE_KEY_TOKEN_INFO_LOADING, payload: true },
      ]);

    case request(actions.GET_USER_INFO).success:
      updateTokenStorage(payload.tokens);

      return state.merge([
        { method: 'update', key: selectors.STATE_KEY_TOKEN_INFO, payload: payload.tokens },
        { method: 'update', key: selectors.STATE_KEY_USER_INFO, payload: payload.user },
      ]);

    case request(actions.GET_USER_INFO).complete:
      return state.merge([
        { method: 'update', key: selectors.STATE_KEY_USER_INFO_LOADING, payload: false },
        { method: 'update', key: selectors.STATE_KEY_TOKEN_INFO_LOADING, payload: false },
      ]);



    // Send verification email
    case request(actions.SEND_VERIFICATION_EMAIL).success:
      return state.update(selectors.STATE_KEY_USER_INFO, payload.user);

    // Verify email
    case request(actions.VERIFY_EMAIL).success:
      return state.update(selectors.STATE_KEY_USER_INFO, payload.user);

    // Send password reset email
    case request(actions.SEND_PASSWORD_RESET_EMAIL).success:
      return initialState;

    // Update password
    case request(actions.UPDATE_PASSWORD).success:
      updateTokenStorage(payload.tokens);

      return state.merge([
        { method: 'update', key: selectors.STATE_KEY_TOKEN_INFO, payload: payload.tokens },
        { method: 'update', key: selectors.STATE_KEY_USER_INFO, payload: payload.user },
      ]);

    // Delete target user (Admins only)
    case request(actions.DELETE_USER).success:
      return initialState;



    // ================== Statistics ==================
    // Get statistics
    case request(actions.GET_USER_STATISTICS).success:
      return state.update(selectors.STATE_KEY_USER_STATISTICS_DATA, payload);

    default:
      return initialState;
  }
};

export { authReducer };