import { appConstants } from 'modules';
import styled from 'styled-components';

const { layout, spacing, breakpoints } = appConstants;
const { layoutWidth, settingsNavWidth } = layout;
const { mediumGap, extraLargeGap } = spacing;
const { tabletLandscape, mobile } = breakpoints;

export const StyledMyAccount = styled('div')`
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const Layout = styled('div')`
  display: grid;
  grid-template-columns: ${settingsNavWidth}rem 1fr;
  gap: 0 ${extraLargeGap}rem;
  max-width: ${layoutWidth}rem;
  width: 100%;
  height: 100%;

  @media screen and (max-width: ${layoutWidth}rem) {
    gap: 0 ${mediumGap}rem;
  }

  @media screen and (max-width: ${tabletLandscape}rem) {
    grid-template-columns: 1fr;
    gap: unset;
  }
`;

export const AccountContent = styled('div')`
  padding: ${extraLargeGap}rem ${extraLargeGap}rem ${extraLargeGap}rem 0;
  overflow: auto;
  width: 100%;

  @media screen and (max-width: ${layoutWidth}rem) {
    padding: ${mediumGap}rem ${mediumGap}rem ${mediumGap}rem 0;
  }

  @media screen and (max-width: ${tabletLandscape}rem) {
    padding: ${mediumGap}rem;
  }

  @media screen and (max-width: ${mobile}rem) {
    padding: 1rem;
  }
`;