import { useSelector } from 'react-redux';
import { appConstants } from 'modules';
import { Font, Plus } from 'components';
import { Tag } from 'xerum';
import styled, { withTheme } from 'styled-components';

const { light } = appConstants.themes;

const TagHolder = styled('div')`
  div, svg {
    cursor: ${props => props.$disabled ? 'not-allowed' : 'pointer'};
  }
`;

const HeaderActionTag = withTheme(props => {
  const { theme, visible, text, icon, callback, disabled } = props;
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const white = theme.modes[selectedTheme]?.white;
  const grey = theme.modes[selectedTheme]?.grey;
  const darkGrey = theme.modes[selectedTheme]?.darkGrey;
  const lightTheme = selectedTheme === light;

  const getColor = color => {
    return disabled ? color + 75 : color;
  };

  if (visible !== true) return null;

  return (
    <Font color={getColor(white)}>
      <TagHolder $disabled={disabled}>
        <Tag
          theme={theme}
          selectedTheme={selectedTheme}
          text={text}
          textSize={0.9375}
          removable={true}
          textColor={getColor(white)}
          bgColor={getColor(darkGrey)}
          borderRadius={1}
          horizontalPadding={0.5}
          closeIcon={icon || (
            <Plus
              color={lightTheme ? getColor(grey) : getColor(darkGrey)}
              bgColor={getColor(white)}
            />
          )}
          iconLeft={true}
          allClick={true}
          callback={() => !disabled && callback?.()}
        />
      </TagHolder>
    </Font>
  );
});

export { HeaderActionTag };