import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { appConstants, expensesActions } from 'modules';
import { Font, Close, XymSpacer } from 'components';
import { Tag } from 'xerum';
import { withTheme } from 'styled-components';

const { light } = appConstants.themes;

const AutoSpendTag = withTheme(props => {
  const { theme, expenseId, autoSpendTags, tag, setEditedTags, removable } = props;
  const { generalPreferences: { privacyMode, selectedTheme } } = useSelector(state => state.preferences);
  const { selectedAccountId } = useSelector(state => state.institutions);
  const { tokenInfo } = useSelector(state => state.auth);
  const token = tokenInfo?.refreshToken || tokenInfo?.accessToken;
  const lightTheme = selectedTheme === light;
  const paynesGrey = theme.colors.neutral.paynesGrey;
  const secondary = theme.modes[selectedTheme]?.secondary;
  const onSecondary = theme.modes[selectedTheme]?.onSecondary;
  const white = theme.modes[selectedTheme]?.white;
  const darkGrey = theme.modes[selectedTheme]?.darkGrey;
  const dispatch = useDispatch();

  const updateExpense = useCallback((payload, callbacks) => {
    dispatch(expensesActions.updateExpense(payload, callbacks));
  }, [ dispatch ]);

  const removeAutoSpendTag = () => {
    const withoutTag = autoSpendTags?.filter(tagItem => tagItem._id !== tag._id);
    const payload = {
      id: expenseId,
      linkedAccountId: selectedAccountId,
      autoSpendTags: withoutTag,
      meta: { noLoadingState: true },
      token,
    };

    setEditedTags ? setEditedTags(withoutTag) : updateExpense(payload);
  };

  return (
    <Font key={tag?._id} weight='regular'>
      <div>
        <XymSpacer size={0.125} />

        <Tag
          text={<Font size={0.875} mobileSize={0.75}>{privacyMode ? 'Private' : tag?.name || 'Missing tag'}</Font>}
          textColor={lightTheme ? secondary : onSecondary}
          removable={removable}
          allClick={!removable}
          bgColor={lightTheme ? paynesGrey : paynesGrey + 75}
          borderRadius={1}
          verticalPadding={0.5}
          callback={removeAutoSpendTag}
          closeIcon={
            <Close
              visible={true}
              color={white}
              bgColor={darkGrey}
              rotation={45}
            />
          }
        />

        <XymSpacer size={0.35} />
      </div>

      <XymSpacer size={0.5} across={true} />
    </Font>
  );
});

export { AutoSpendTag };