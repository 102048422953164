import { useState } from 'react';
import { useSelector } from 'react-redux';
import { appConstants } from 'modules';
import { Close, XymConfirm, GripIcon } from 'components';
import { ExpenseIcon } from './ExpenseIcon';
import { ExpenseName } from './ExpenseName';
import { ExpenseDetails } from './ExpenseDetails';
import { ExpenseProgress } from './ExpenseProgress';
import { ExpenseAutoSpend } from './ExpenseAutoSpend';
import { RemoveExpense } from './RemoveExpense';
import { ExpenseHolder, StyledExpense, CloseExpense, DragIcon } from './styles';
import { withTheme } from 'styled-components';
import _ from 'lodash';

const { themes } = appConstants;
const { light } = themes;

const Expense = withTheme(props => {
  const { theme, callback, setAutoSpendConfirmContent, expense, dragOverId, setDragOverId } = props;
  const [ confirmContent, setConfirmContent ] = useState(null);
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const { _id, isGoal } = expense;

  const lightTheme = selectedTheme === light;
  const isDraggingOver = dragOverId === _id;
  const paynesGrey = theme.colors.neutral.paynesGrey;
  const frenchGrey = theme.colors.neutral.frenchGrey;
  const white = theme.modes[selectedTheme]?.white;
  const expenseType = isGoal ? 'goal' : 'expense';

  const closeExpense = e => {
    e.stopPropagation();

    const payload = (
      <RemoveExpense
        expenseType={expenseType}
        expense={expense}
        setConfirmContent={setConfirmContent}
      />
    );

    setConfirmContent(payload);
  };

  const handleDragStart = e => {
    e.dataTransfer.setData('text/plain', _id);
    if (!isDraggingOver) setDragOverId(_id);
  };

  const handleDragOver = e => {
    e.preventDefault();
    if (!isDraggingOver) setDragOverId(_id);
  };

  const handleDragLeave = () => setDragOverId(null);
  const handleDragEnd = () => setDragOverId(null);

  return (
    <ExpenseHolder
      $theme={theme}
      $selectedTheme={selectedTheme}
      $isDraggingOver={isDraggingOver}
      draggable={_.isEmpty(confirmContent)}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDragEnd={handleDragEnd}
      data-expense-id={_id}
    >
      <DragIcon $isDraggingOver={isDraggingOver}>
        <GripIcon width={1.25} color={lightTheme ? frenchGrey : frenchGrey + 35} />
      </DragIcon>

      <StyledExpense
        $theme={theme}
        $selectedTheme={selectedTheme}
        $isGoal={isGoal}
        onClick={() => _.isEmpty(confirmContent) && callback?.()}
      >
        <CloseExpense $theme={theme} $selectedTheme={selectedTheme} onClick={closeExpense}>
          <Close width={1.25} height={1.25} color={white} bgColor={paynesGrey} />
        </CloseExpense>

        <ExpenseIcon size={2.5} {...expense} />
        <ExpenseName expense={expense} />
        <ExpenseDetails {...expense} />
        <ExpenseProgress {...expense} />
        <ExpenseAutoSpend
          visible={!isGoal}
          setAutoSpendConfirmContent={setAutoSpendConfirmContent}
          {...expense}
        />

        <XymConfirm
          confirmContent={confirmContent}
          blank={true}
          onClose={() => setConfirmContent(null)}
        />
      </StyledExpense>
    </ExpenseHolder>
  );
});

export { Expense };