const expensesConstants = {
  actions: {
    GET_EXPENSES_DATA: 'modules/expenses/GET_EXPENSES_DATA',
    ADD_EXPENSE: 'modules/expenses/ADD_EXPENSE',
    UPDATE_EXPENSE: 'modules/expenses/UPDATE_EXPENSE',
    REMOVE_EXPENSE: 'modules/expenses/REMOVE_EXPENSE',
    GET_AUTO_FUND_SOURCES: 'modules/expenses/GET_AUTO_FUND_SOURCES',
    ADD_AUTO_FUND_SOURCE: 'modules/expenses/ADD_AUTO_FUND_SOURCE',
    UPDATE_AUTO_FUND_SOURCE: 'modules/expenses/UPDATE_AUTO_FUND_SOURCE',
    REMOVE_AUTO_FUND_SOURCE: 'modules/expenses/REMOVE_AUTO_FUND_SOURCE',
    SET_SLIDE_OVER_EXPENSE_ID: 'modules/expenses/STATE_KEY_SLIDE_OVER_EXPENSE_ID',
    REBALANCE_EXPENSE: 'modules/expenses/STATE_KEY_REBALANCE_EXPENSE',
    IMPORT_EXPENSES: 'modules/expenses/IMPORT_EXPENSES',
    SET_EXPENSES_IMPORTING: 'modules/expenses/SET_EXPENSES_IMPORTING',
    CLEAR_ANALYSIS_DATA: 'modules/expenses/CLEAR_ANALYSIS_DATA',
    RESET_BALANCES: 'modules/institutions/RESET_BALANCES',
    GET_BALANCES: 'modules/institutions/GET_BALANCES',
  },

  selectors: {
    STATE_KEY_EXPENSES_DATA: 'expensesData',
    STATE_KEY_EXPENSES_DATA_LOADING: 'expensesDataLoading',
    STATE_KEY_AUTO_FUND_SOURCES: 'autoFundSources',
    STATE_KEY_AUTO_FUND_SOURCES_LOADING: 'autoFundSourcesLoading',
    STATE_KEY_SLIDE_OVER_EXPENSE_ID: 'slideOverExpenseId',
    STATE_KEY_EXPENSES_IMPORTING: 'expensesImporting',
    STATE_KEY_EXPENSES_ANALYSIS: 'expensesAnalysis',

    STATE_KEY_SAFE_BALANCE: 'safeBalance',
    STATE_KEY_TOTAL_FUNDS: 'totalFunds',
    STATE_KEY_PENDING: 'pending',
    STATE_KEY_IN_EXPENSES: 'inExpenses',
    STATE_KEY_IN_GOALS: 'inGoals',
  },
};

export { expensesConstants };