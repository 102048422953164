import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { institutionsActions } from 'modules';
import { Font } from 'components';
import { StyledActions } from './styles';
import { Button } from 'xerum';
import { withTheme } from 'styled-components';

const { NODE_ENV } = process.env;
const inProduction = NODE_ENV === 'production';

const Actions = withTheme(props => {
  const { theme } = props;
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const { tokenInfo } = useSelector(state => state.auth);
  const token = tokenInfo?.refreshToken || tokenInfo?.accessToken;
  const dispatch = useDispatch();

  const purgeUnsubscribedUserData = useCallback((payload, callbacks) => {
    dispatch(institutionsActions.purgeUnsubscribedUserData(payload, callbacks));
  }, [ dispatch ]);

  return (
    <StyledActions>
      <Button
        theme={theme}
        selectedTheme={selectedTheme}
        text={(
          <Font weight='medium' mobileSize={0.875}>
            {inProduction ? 'Available in Dev env, only' : 'Purge Unsubscribed User Data'}
          </Font>
        )}
        disabled={inProduction}
        callback={() => {
          const payload = { token };
          purgeUnsubscribedUserData(payload);
        }}
      />
    </StyledActions>
  );
});

export { Actions };