import PackageJSON from '../../package.json';
import * as Sentry from '@sentry/react';
import _ from 'lodash';

import '@fortawesome/fontawesome-free/css/all.css';
import '../fontFaces.css';

const { NODE_ENV, VITE_API_V1: basePath } = process.env;
const { name, version } = PackageJSON;
const inProduction = NODE_ENV === 'production';

const startErrorMonitoring = async () => {
  if (inProduction) {
    const res = await fetch(`${basePath}/monitoring/sentry`);
    const sentryData = await res?.json();
    const sentryDsn = sentryData?.sentryDsn;

    if (!res.ok) throw new Error('Failed to fetch Sentry DSN.');

    if (!_.isEmpty(sentryDsn)) {
      Sentry.init({
        dsn: sentryDsn,
        release: `${name}@${version}`,
        integrations: [ Sentry.browserTracingIntegration() ],
        tracesSampleRate: 1.0,
      });
    }
  }
};

export {
  startErrorMonitoring,
};