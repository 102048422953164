import { useState } from 'react';
import { appConstants } from 'modules';
import { useSelector } from 'react-redux';
import { Font, TextSection, XymConfirm, XymSpacer } from 'components';
import { StyledDetails, Actions, DetailsHolder } from './styles';
import { Button } from 'xerum';
import { UnsubscribeConfirm } from './UnsubscribeConfirm';
import { withTheme } from 'styled-components';
import moment from 'moment';
import _ from 'lodash';

const { light } = appConstants.themes;

const Details = withTheme(props => {
  const { theme } = props;
  const [ confirmContent, setConfirmContent ] = useState(null);
  const { mobileMode, tabletMode, tabletLandscapeMode } = useSelector(state => state.app);
  const { userInfo } = useSelector(state => state.auth);
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const desktopMode = !mobileMode && !tabletMode && !tabletLandscapeMode;
  const onPrimary = theme.modes[selectedTheme]?.onPrimary;
  const darkGrey = theme.modes[selectedTheme]?.darkGrey;
  const offWhite = theme.modes[selectedTheme]?.offWhite;
  const lightTheme = selectedTheme === light;
  const titleColor = lightTheme ? darkGrey : offWhite;

  const handleUnsubscribe = expires => {
    const payload = <UnsubscribeConfirm expires={expires} />;
    setConfirmContent(payload);
  };

  // TODO: Implement subscribe flow:
  // Payment method selection and frequency (monthly or annual).
  // If no payment method, prompt to add one. See how this can be handed off to Stripe.
  const handleSubscribe = _.noop;

  const buildDetails = () => {
    if (!_.isEmpty(userInfo)) {
      const { subscriptionFrequency, subscriptionType, active, expires } = userInfo || {};
      const sectionText = active ? `${_.capitalize(subscriptionFrequency?.name)} subscriber` : 'Expired';
      const tagText = active ? _.capitalize(subscriptionType?.name) : 'Inactive';

      return (
        <DetailsHolder>
          <XymSpacer size={1.75} />

          <TextSection
            label='Expires'
            labelColor={onPrimary}
            sectionText={moment(expires).format('MMMM Do, YYYY')}
          />

          <XymSpacer size={1.75} />

          <TextSection
            label='Status'
            labelColor={onPrimary}
            sectionText={sectionText}
            tagText={tagText}
          />

          <XymSpacer size={1.75} />
        </DetailsHolder>
      );
    }
  };

  return (
    <StyledDetails>
      <Font weight='semibold' size={1.25} mobileSize={1} tabletSize={1} color={titleColor}>
        Details
      </Font>

      {buildDetails()}

      <Actions>
        <Button
          theme={theme}
          selectedTheme={selectedTheme}
          text={(
            <Font weight='medium' mobileSize={0.875}>
              {userInfo?.active ? 'Unsubscribe' : 'Subscribe'}
            </Font>
          )}
          callback={() => userInfo?.active ? handleUnsubscribe(userInfo?.expires) : handleSubscribe()}
        />

        {desktopMode && <XymSpacer size={0.5} />}
      </Actions>

      <XymConfirm
        confirmContent={confirmContent}
        confirmText='Unsubscribe'
        onConfirm={_.noop}
        onClose={() => setConfirmContent(null)}
      />
    </StyledDetails>
  );
});

export { Details };