import { Font, XymSelect, XymSpacer, XymConfirmButtons } from 'components';
import _ from 'lodash';

export const autoSpendTabs = props => {
  const {
    merchants,
    categories,
    privacyMode,
    setConfirmContent,
    editedTags,
    handleEditedTagsChange,
    autoSpendMerchants,
    handleUpdateExpense,
    fromForm,
  } = props;

  const tabContent = [
    {
      name: 'Categories',
      content: (
        <>
          {autoSpendMerchants && <XymSpacer />}

          <XymSelect
            data={categories?.map(category => ({ value: category._id, label: category.name }))}
            name='categoryOptions'
            label={<Font size={0.875} weight='semibold'>Categories</Font>}
            noResultsText='No categories found'
            noSpacing={true}
          />

          <XymSpacer />

          <XymConfirmButtons
            confirmText='Add category'
            privacy={privacyMode}
            setConfirmContent={setConfirmContent}
            callback={() => {
              const element = document.getElementsByName('categoryOptions');
              const inputValue = element?.[0]?.value?.trim?.();
              const category = categories?.find(category => category.name === inputValue);
              const existsInTempList = editedTags?.find(item => item._id === `${category?._id}`);

              if (fromForm && category && !existsInTempList) {
                handleEditedTagsChange([ ...editedTags || [], category ]);
              }

              if (!fromForm && category && _.isEmpty(editedTags) && handleUpdateExpense) {
                handleUpdateExpense(category);
              }

              setConfirmContent(null);
            }}
          />
        </>
      ),
    },

    {
      name: 'Merchants',
      content: (
        <>
          {autoSpendMerchants && <XymSpacer />}

          <XymSelect
            data={merchants?.map(merchant => ({ value: merchant._id, label: merchant.name }))}
            name='merchantOptions'
            label={<Font size={0.875} weight='semibold'>Merchants</Font>}
            noResultsText='Merchant not found'
            noDataText='No merchants found...'
            noSpacing={true}
            privacy={privacyMode}
          />

          <XymSpacer />

          <XymConfirmButtons
            confirmText='Add merchant'
            privacy={privacyMode}
            setConfirmContent={setConfirmContent}
            callback={() => {
              const element = document.getElementsByName('merchantOptions');
              const inputValue = element?.[0]?.value?.trim?.();
              const merchant = merchants?.find(merchant => merchant.name === inputValue);
              const existsInTempList = editedTags?.find(item => item._id === `${merchant?._id}`);

              if (fromForm && merchant && editedTags && !existsInTempList) {
                handleEditedTagsChange([ ...editedTags, merchant ]);
              }

              if (!fromForm && merchant && !editedTags && handleUpdateExpense) {
                handleUpdateExpense(merchant);
              }

              setConfirmContent(null);
            }}
          />
        </>
      ),
    },
  ];

  return tabContent;
};