import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { H3, Font, XymSpacer } from 'components';
import { Description, StepHolder, StepItem } from './styles';
import { withTheme } from 'styled-components';

const { VITE_APP_NAME: appName } = process.env;

const PlaidDescription = withTheme(props => {
  const { theme, visible } = props;
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);

  if (!visible) return;

  return (
    <Description $theme={theme} $selectedTheme={selectedTheme}>
      <H3>Let&apos;s get everything hooked up...</H3>

      <XymSpacer />

      Plaid is a service that allows you to link your institutions to {appName}.
      The process is secure, and should only take a minute or two.

      <XymSpacer />

      Here is the process:

      <XymSpacer size={1.5} />

      <StepHolder $theme={theme} $selectedTheme={selectedTheme}>
        <StepItem>
          <i className='fa-solid fa-1' />
          <XymSpacer across={true} />
          <Font weight='semibold'>
            Authenticate with your institution.
          </Font>
        </StepItem>

        <StepItem>
          <i className='fa-solid fa-2' />
          <XymSpacer across={true} />
          <Font weight='semibold'>
            Answer any questions your institution may have.
          </Font>
        </StepItem>

        <StepItem>
          <i className='fa-solid fa-3' />
          <XymSpacer across={true} />
          <Font weight='semibold'>
            Select the accounts you want to use.
          </Font>
        </StepItem>
      </StepHolder>

      <XymSpacer size={1.5} />

      For more information, check out&nbsp;
      <Link to='https://plaid.com/what-is-plaid/' target='_blank' rel='noreferrer'>what is plaid</Link>.
    </Description>
  );
});

export { PlaidDescription };