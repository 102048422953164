import { XymTooltip, Font, XymPrice } from 'components';
import { appConstants } from 'modules';
import * as fileSaver from 'file-saver';
import * as yup from 'yup';
import _ from 'lodash';

export const expenseTooltip = args => {
  const { expense, condition, isWarning, isInfo } = args;
  const { onTrackData, isGoal, current, needed, funded, frequencyId } = expense || {};
  const { amountNeededToCatchUp, catchUpRatePerDay, daysBeforeDueDate } = onTrackData || {};

  if (condition && isInfo) {
    const overageAmount = (current - needed).toFixed(2);

    return (
      <XymTooltip>
        <Font size={0.75} block={true}>
            This {isGoal ? 'goal' : 'expense'} is over-funded by&nbsp;
            <Font size={0.75}>
              <XymPrice value={overageAmount} size={0.75} />.
            </Font>
            Consider rebalancing this {isGoal ? 'goal' : 'expense'} to
            help others stay on track.
        </Font>
      </XymTooltip>
    );
  }

  if (condition && !isWarning) {
    return (
      <XymTooltip isSuccess={funded}>
        <Font size={0.75} block={true}>
          This {isGoal ? 'goal' : 'expense'} is fully funded.
        </Font>
      </XymTooltip>
    );
  }

  if (condition) {
    return (
      <XymTooltip isWarning={isWarning}>
        <Font size={0.75} weight='semibold'>Off track</Font>
        <Font size={0.75} block={true}>
          Add&nbsp;
          <Font size={0.75}>
            <XymPrice
              value={daysBeforeDueDate === 0 ? catchUpRatePerDay : amountNeededToCatchUp}
              size={0.75}
            />&nbsp;to catch up now
          </Font>
            {amountNeededToCatchUp !== catchUpRatePerDay && frequencyId !== 2 && daysBeforeDueDate !== 0 && (
              <>
                , or wait for&nbsp;
                <Font size={0.75}>
                  <XymPrice value={catchUpRatePerDay} size={0.75} />
                </Font>

                /day to be automatically moved to catch up by the {isGoal ? 'date needed' : 'due date'}
              </>
            )}
          .
        </Font>
      </XymTooltip>
    );
  }
};

export const serializeExpenses = args => {
  const { accountExpenses, accountInfo, callback } = args || {};
  const { names } = appConstants;

  const appName = names.appName;
  const exportData = accountExpenses?.map?.(expense => {
    const workingExpense = { ...expense };

    // The following is being removed because it will be auto-created, as a new expense, on import.
    delete workingExpense._id;
    delete workingExpense.createdAt;
    delete workingExpense.updatedAt;

    // The following is being removed because it will be auto-calculated, on import.
    delete workingExpense.onTrackData;

    // The following is being reset, because it will be auto-calculated, on import.
    workingExpense.current = 0;
    workingExpense.funded = false;

    // The following is being cleared, because they may have changed between exports and imports.
    // linkedAccountId will automatically be inherited from the user's account they are importing into.
    // autoFundSourceId will nee to be manually re-linked, by the user, after import.
    workingExpense.autoFundSourceId = null;
    workingExpense.linkedAccountId = null;
    workingExpense.lastFundedDate = null;

    return workingExpense;
  }) || [];

  const accountName = accountInfo?.name ? `${_.camelCase(accountInfo.name)}_` : '';
  const accountMask = accountInfo?.mask ? `${accountInfo.mask}_` : '';
  const exportedExpenses = new Blob([ JSON.stringify(exportData) ], { type: 'application/json' });
  const filename = `${accountName}${accountMask}${appName}_expenses.json`;

  fileSaver.saveAs(exportedExpenses, filename);
  callback?.();

  return exportData;
};

export const exportExpenses = args => {
  const { institutionsData, selectedAccountId, expensesData, callback } = args || {};

  let accountInfo = {};
  let accountExpenses = [];

  if (!_.isEmpty(expensesData)) {
    institutionsData?.find(institution => {
      const workingAccount = institution.accounts.find(account => account.account_id === selectedAccountId);

      if (workingAccount) {
        accountInfo = workingAccount;
        accountExpenses = expensesData?.filter(expenses => expenses.linkedAccountId === selectedAccountId);
        return institution;
      }
    });

    serializeExpenses({ accountExpenses, accountInfo, callback });
  }
};

export const normalizeExpenses = args => {
  const { importData, addNotification, isMergeOperation, callback } = args || {};

  const expenseValidationSchema = yup.object().shape({
    linkedAccountId: yup.string().nullable(),
    name: yup.string().required('name is missing.'),
    icon: yup.string().nullable(),
    current: yup.number().min(0).required('current is missing.'),
    needed: yup.number().required('needed is missing.'),
    expenseTypeId: yup.number().required('expenseTypeId is missing.'),
    dueDate: yup.date().nullable(),
    frequencyId: yup.number().required('frequencyId is missing.'),
    funded: yup.boolean().required('funded is missing.'),
    color: yup.string().required('color is missing.'),
    isGoal: yup.boolean().required('isGoal status is missing.'),
    autoFundSourceId: yup.string().nullable(),
    useDailyAutoFundCalculations: yup.boolean().required('useDailyAutoFundCalculations is missing.'),
    lastFundedDate: yup.date().nullable(),
    autoSpendTags: yup.array().of(
      yup.object().shape({
        _id: yup.string().required('_id is missing.'),
        name: yup.string().required('name is missing.'),
      }),
    ),
  });

  let dataValid = !_.isEmpty(importData) && _.isArray(importData);
  let validationCheckComplete = false;

  const failNotification = {
    message: !dataValid
      ? 'No file provided.'
      : `${isMergeOperation ? 'Merge' : 'Restore'} failed, file not valid.`,
    type: 'error',
  };

  // Check each expense's import data for schema compatibility.
  if (dataValid) {
    for (let i = 0; i < importData.length; i++) {
      const workingExpense = importData[i];

      try {
        expenseValidationSchema.validateSync(workingExpense);
      } catch (error) {
        console.error(`Expense ${i + 1} failed validation: `, error.errors);
        dataValid = false;
        break;
      }
    }

    validationCheckComplete = true;
  }

  // Proceed with import if schema validation was successful.
  if (dataValid && validationCheckComplete) {
    callback?.();
    return;
  }

  // If data is not valid, abort import and let user know.
  addNotification?.(failNotification);
};