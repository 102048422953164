import { appConstants } from 'modules';
import { getColor } from 'helpers';
import styled from 'styled-components';

const { spacing } = appConstants;
const { gap, smallGap } = spacing;

export const StyledInbox = styled('div')`
  position: relative;
  display: ${props => props.$visible ? 'flex' : 'none'};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: fit-content;
  padding: ${smallGap}rem ${gap}rem;
`;

export const MessageActions = styled('div')`
  display: ${props => props.$visible ? 'inline-flex' : 'none'};
  align-items: center;
  justify-content: flex-end;
  height: fit-content;
  padding: 0 ${smallGap}rem;

  span {
    user-select: none;
    cursor: ${props => props.$hasUnreadMessages ? 'pointer' : 'default'};
    color: ${props => getColor(props, props.$hasUnreadMessages ? 'onPrimary' : 'lightGrey')};

    @media (hover: hover) {
      &:hover {
        color: ${props => getColor(props, props.$hasUnreadMessages ? 'accent' : 'lightGrey')};
      }
    }
  }
`;

export const NoMessages = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  color: ${props => getColor(props, 'onPrimary')};
`;

export const MessagesArea = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 0 ${smallGap}rem;
`;

export const Message = styled('div')`
  display: grid;
  gap: ${gap}rem;
  grid-template-columns: 1fr auto;
  grid-template-areas: 'subject read';
  cursor: pointer;
  padding: ${smallGap}rem ${gap}rem;
  border-radius: 0.25rem;
  user-select: none;

  @media (hover: hover) {
    &:hover {
      background-color: ${props => getColor(props, 'accent')};
      color: ${props => getColor(props, 'onAccent')};
    }
  }
`;

export const Subject = styled('div')`
  grid-area: subject;
  width: 100%;
`;

export const ReadIcon = styled('div')`
  display: inline-flex;
  grid-area: read;
  align-items: center;
`;

export const BadgeHolder = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;