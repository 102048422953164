import { useSelector } from 'react-redux';
import { Font } from 'components';
import { SubTitle } from './styles';
import { SpentFrom } from './SpentFrom';

const TransactionSubTitle = props => {
  const { transaction, hasSubTitle, size, active } = props;
  const { generalPreferences: { privacyMode } } = useSelector(state => state.preferences);

  const buildSubTitle = () => {
    if (transaction) {
      const { data, metadata } = transaction || {};
      const { autoSpendExpenseId } = metadata || {};
      const { amount } = data || {};
      const isCredit = amount < 0;
      const isDebit = amount > 0;

      return (
        <>
          {privacyMode && <Font size={size || 0.75}>Private</Font>}

          {!privacyMode && (
            <SpentFrom
              size={size}
              amount={amount}
              isCredit={isCredit}
              isDebit={isDebit}
              // NOTE: Component ready for transferIn/Out, but doesn't exist in Plaid data, yet.
              isTransferIn={null}
              isTransferOut={null}
              autoSpendExpenseId={autoSpendExpenseId}
              active={active}
            />
          )}
        </>
      );
    }
  };

  return (
    <SubTitle $visible={hasSubTitle}>
      {buildSubTitle()}
    </SubTitle>
  );
};

export { TransactionSubTitle };