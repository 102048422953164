import { useSelector } from 'react-redux';
import { Font, SettingsNav, XymTabs } from 'components';
import { StyledSubscription, Layout, MobileLayout, SubscriptionContent } from './styles';
import { subscriptionTabs } from './localControllers/subscriptionTabs';
import { Details } from './Sections/Details';
import { Payments } from './Sections/Payments';
import { PaymentHistory } from './Sections/PaymentHistory';

const Subscription = () => {
  const { mobileMode, tabletMode, tabletLandscapeMode, hybridMode, bannerContent } = useSelector(state => state.app);
  const { tokenInfo } = useSelector(state => state.auth);
  const desktopMode = !mobileMode && !tabletMode && !tabletLandscapeMode && !hybridMode;
  const subscriptionArgs = { desktopMode, bannerContent };

  const buildSections = () => {
    if (tokenInfo?.accessToken) {
      return (
        <>
          <Details />
          <Payments />
          <PaymentHistory />
        </>
      );
    }

    return <Font weight='semibold'>No token found.</Font>;
  };

  return (
    <StyledSubscription>
      <Layout>
        <SettingsNav />

        <SubscriptionContent>
          {buildSections()}
        </SubscriptionContent>
      </Layout>

      <MobileLayout>
        <XymTabs spacing={1} content={subscriptionTabs(subscriptionArgs)} contentFirst={true} />
      </MobileLayout>
    </StyledSubscription>
  );
};

export { Subscription };