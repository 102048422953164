import { useSelector } from 'react-redux';
import { appConstants } from 'modules';
import styled from 'styled-components';

const { layout } = appConstants;
const { textWidth } = layout;

const StyledUL = styled('ul')`
  margin: 0;
  max-width: ${textWidth}rem;
  font-size: ${props => props.$mobileSize || props.$tabletSize || props.$size || 0.9375}rem;

  li {
    padding: 0.25rem 0;
  }
`;

const UL = props => {
  const { children, size, tabletSize, mobileSize } = props;
  const { tabletMode, mobileMode } = useSelector(state => state.app);

  return (
    <StyledUL
      $size={size}
      $tabletSize={tabletMode && tabletSize}
      $mobileSize={mobileMode && mobileSize}
    >
      {children}
    </StyledUL>
  );
};

export { UL };