import { useSelector } from 'react-redux';
import styled, { withTheme } from 'styled-components';

const PaymentIconSVG = styled('svg')`
  width: ${props => props.$width || 2}rem;
  height: ${props => props.$height || 3}rem;
`;

const PaymentIcon = withTheme(props => {
  const { theme, color, width, height, direction } = props;
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const offWhite = theme.colors.shades.offWhite;

  return (
    <PaymentIconSVG
      $theme={theme}
      $selectedTheme={selectedTheme}
      $width={width}
      $height={height}
      $direction={direction}
      color={color}
      viewBox='0 0 33 48'
      fill='currentColor'
    >
      <rect x='33' height='33' width='48' rx='2' transform='rotate(90 33 0)' />
      <rect x='17.5' y='38' height='8' width='6' transform='rotate(90 20 38)' fill={offWhite} />
    </PaymentIconSVG>
  );
});

export { PaymentIcon };