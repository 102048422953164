import { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { animate } from 'helpers';
import { XymDropdown } from './XymDropdown';
import styled, { withTheme } from 'styled-components';

const StyledColorPicker = styled('div')`
  display: flex;
  position: absolute;
  top: ${props => props.$mobileMode ? 1.25 : 1.75}rem;
  width: ${props => props.$width ? `${props.$width}rem` : '100%'};
  height: ${props => props.$height || 3}rem;
  border-radius: 50%;
  background-color: ${props => props.$color};

  &:hover {
    cursor: pointer;
  }
`;

const SwatchHolder = styled('div')`
  display: inline-flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  width: 100%;
  height: 100%;
`;

const ActiveSwatchHolder = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: 0.125rem solid ${props => props.$activeColor ? props.$color : 'transparent'};
`;

const Swatch = styled('div')`
  display: flex;
  width: ${props => props.$activeColor ? 1.5 : 1.75}rem;
  height: ${props => props.$activeColor ? 1.5 : 1.75}rem;
  border-radius: 50%;
  background-color: ${props => props.$color};
  cursor: pointer;
`;

const ColorPicker = withTheme(props => {
  const { theme, width, height, color, callback } = props;
  const [ paletteVisible, setPaletteVisible ] = useState(false);
  const { colors, selectedTheme, mobileMode } = useSelector(state => state.app);
  const randomColorIndex = Math.floor(Math.random() * colors?.length);
  const randomColor = colors?.[randomColorIndex]?.hex || theme.modes[selectedTheme]?.accent;
  const animationRef = useRef();

  const handleAnimationIn = useCallback(() => {
    animate({ ref: animationRef.current, offset: -10, duration: 0.2 });
  }, []);

  const handleAnimationOut = () => {
    animate({
      ref: animationRef.current,
      offset: -10,
      transitionOut: true,
      onComplete: () => setPaletteVisible(!paletteVisible),
    });
  };

  useEffect(() => {
    if (!color) callback?.(randomColor);
  }, [ color, callback, randomColor ]);

  useEffect(() => {
    if (paletteVisible) handleAnimationIn();
  }, [ paletteVisible, handleAnimationIn ]);

  const buildSwatches = () => {
    return colors.map((item, index) => (
      <ActiveSwatchHolder key={index} $color={item.hex} $activeColor={item.hex === color}>
        <Swatch
          $theme={theme}
          $selectedTheme={selectedTheme}
          $color={item.hex}
          $activeColor={item.hex === color}
          onClick={() => {
            setPaletteVisible(!paletteVisible);
            callback?.(item.hex);
          }}
        />
      </ActiveSwatchHolder>
    ));
  };

  return (
    <>
      <StyledColorPicker
        $theme={theme}
        $selectedTheme={selectedTheme}
        $color={color || randomColor}
        $width={width}
        $height={height}
        $mobileMode={mobileMode}
        onClick={handleAnimationOut}
      />

      <XymDropdown
        ref={animationRef}
        visible={paletteVisible}
        posX={mobileMode ? 1 : 2.5}
        posY={mobileMode ? 4 : 4.5}
        width={22.5}
        setVisible={handleAnimationOut}
      >
        <SwatchHolder>
          {buildSwatches()}
        </SwatchHolder>
      </XymDropdown>
    </>
  );
});

export { ColorPicker };