import { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { appConstants, expensesActions } from 'modules';
import {
  Font,
  XymField,
  XymSelect,
  Plus,
  Minus,
  NoData,
  AutoSpendTag,
  XymTabs,
  XymSpacer,
  ColorPicker,
  XymDatePicker,
  XymLink,
  H4,
  UL,
  P,
} from 'components';
import { Formik, Form } from 'formik';
import { Button } from 'xerum';
import { autoFundTabs } from 'pages/App/Header/localControllers/autoFundTabs';
import { autoSpendTabs } from 'pages/Expenses/localControllers/autoSpendTabs';
import { RemoveExpense } from 'components/Expenses/RemoveExpense';
import { flags } from 'utility';
import styled, { withTheme } from 'styled-components';
import moment from 'moment';
import * as yup from 'yup';
import _ from 'lodash';

const { themes, spacing, names } = appConstants;
const { smallGap } = spacing;
const { light } = themes;

const AdvancedTitle = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  user-select: none;
`;

const AdvancedIcon = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: fit-content;
`;

const AdvancedSection = styled('div')`
  display: ${props => props.$visible ? 'flex' : 'none'};
  flex-direction: column;
`;

export const AutoSpendArea = styled('div')`
  display: ${props => props.$visible ? 'flex' : 'none'};
  gap: ${smallGap}rem;
  flex-wrap: wrap;
  width: 100%;
`;

const ExpenseForm = withTheme(props => {
  const {
    theme,
    isGoal,
    setLearnMoreContent,
    setAutoFundConfirmContent,
    setAutoSpendConfirmContent,
    setRemoveConfirmContent,
  } = props;

  const { merchants, categories, frequencies, expenseTypes, mobileMode } = useSelector(state => state.app);
  const { slideOverExpenseId, expensesData, autoFundSources } = useSelector(state => state.expenses);
  const { generalPreferences: { privacyMode, selectedTheme } } = useSelector(state => state.preferences);
  const { selectedAccountId } = useSelector(state => state.institutions);

  const expense = expensesData?.find(expense => expense._id === slideOverExpenseId);

  const [ advanced, setAdvanced ] = useState(false);
  const [ hasDueDate, setHasDueDate ] = useState(!_.isEmpty(expense?.dueDate));
  const [ editedTags, setEditedTags ] = useState(expense?.autoSpendTags || []);
  const [ color, setColor ] = useState(expense?.color);
  const { tokenInfo } = useSelector(state => state.auth);
  const { features: { autoFunding, autoSpendMerchants } } = flags;

  const frequencyOptions = useMemo(() => frequencies?.map(item => {
    const { _id, name } = item;
    return { value: _id, label: name };
  }), [ frequencies ]);

  const expenseTypeOptions = useMemo(() => expenseTypes?.map(item => {
    const { _id, name } = item;
    return { value: _id, label: name };
  }), [ expenseTypes ]);

  const noAutoFundingText = 'No auto-funding';
  const automaticFundingText = 'automatic';
  const baseAutoFundOptions = useMemo(() => ([
    { value: noAutoFundingText, label: noAutoFundingText },
    {
      value: automaticFundingText,
      label: 'Automatic',
      disabled: !hasDueDate,
      note: !hasDueDate && 'Due date required',
    },
  ]), [ hasDueDate ]);

  const autoFundOptions = useMemo(() => autoFundSources?.map(item => {
    const { _id, name } = item;
    return { value: _id, label: name };
  }), [ autoFundSources ]);

  const allAutoFundOptions = useMemo(() => {
    return [ ...baseAutoFundOptions, ...autoFundOptions || [] ];
  }, [ autoFundOptions, baseAutoFundOptions ]);

  const token = tokenInfo?.refreshToken || tokenInfo?.accessToken;
  const lightTheme = selectedTheme === light;
  const accent = theme.modes[selectedTheme]?.accent;
  const darkGrey = theme.modes[selectedTheme]?.darkGrey;
  const lightGrey = theme.modes[selectedTheme]?.lightGrey;
  const onPrimary = theme.modes[selectedTheme]?.onPrimary;
  const error = theme.modes[selectedTheme]?.error;
  const errorHover = theme.modes[selectedTheme]?.errorHover;
  const offWhite = theme.modes[selectedTheme]?.offWhite;
  const titleColor = lightTheme ? darkGrey : offWhite;
  const prevExpenseRef = useRef(expense);
  const dispatch = useDispatch();

  const defaults = useMemo(() => ({
    name: expense?.name || '',
    needed: expense?.needed ? `$${expense?.needed.toLocaleString()}` : '',
    dueDate: expense?.dueDate ? moment(expense.dueDate).format('MMMM Do, YYYY') : '',
    frequencyId: expense?.frequencyId || frequencyOptions[0].value,
    expenseTypeId: expense?.expenseTypeId || expenseTypeOptions[0].value,
    autoFundSourceId: expense?.useDailyAutoFundCalculations
      ? baseAutoFundOptions[1].value
      : expense?.autoFundSourceId || baseAutoFundOptions[0].value,
    autoSpendTags: editedTags?.map(tag => tag.name).join?.(', ') || '',
  }), [
    expense,
    frequencyOptions,
    expenseTypeOptions,
    baseAutoFundOptions,
    editedTags,
  ]);

  const expenseNames = lowercase => expensesData?.map(expense => {
    const { linkedAccountId: expenseLinkedAccountId, isGoal: expenseIsGoal } = expense;
    const linkedWithThisAccount = expenseLinkedAccountId === selectedAccountId;
    const matchingType = isGoal ? expenseIsGoal : !expenseIsGoal;
    const differentId = expense._id !== slideOverExpenseId;

    if (linkedWithThisAccount && matchingType && differentId) {
      return lowercase ? _.toLower(expense.name) : expense.name;
    }
  }) || [];

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .required(`${isGoal ? 'Goal' : 'Expense'} name is required.`)
      .notOneOf(
        new Set([ ...expenseNames(), ...expenseNames(true) ]),
        `${isGoal ? 'Goal' : 'Expense'} with that name already exists.`,
      ),
    needed: yup.string().required('Amount needed is required.')
      .test('amount-test', 'Amount invalid.', value => {
        const amount = parseFloat(value?.replace(/[$,+-]/g, ''));
        return !Number.isNaN(amount) && _.isNumber(amount) && amount !== 0;
      }),
    dueDate: yup.string(),
    frequencyId: yup.string(),
  });

  const handleEditedTagsChange = useCallback(newTags => {
    setEditedTags(newTags);
  }, [ setEditedTags ]);

  const handleColorChange = useCallback(newColor => {
    setColor(newColor);
  }, [ setColor ]);

  useEffect(() => {
    if (!_.isEqual(prevExpenseRef.current, expense)) {
      prevExpenseRef.current = expense;
      handleEditedTagsChange(expense?.autoSpendTags || []);
      handleColorChange(expense?.color);
      setHasDueDate(!_.isEmpty(expense?.dueDate));
    }
  }, [ expense, handleEditedTagsChange, handleColorChange ]);

  const addExpense = useCallback((payload, callbacks) => {
    dispatch(expensesActions.addExpense(payload, callbacks));
  }, [ dispatch ]);

  const updateExpense = useCallback((payload, callbacks) => {
    dispatch(expensesActions.updateExpense(payload, callbacks));
  }, [ dispatch ]);

  const setSlideOverExpenseId = useCallback((payload, callbacks) => {
    dispatch(expensesActions.setSlideOverExpenseId(payload, callbacks));
  }, [ dispatch ]);

  const buildTags = useCallback(() => {
    return editedTags?.map?.((item, index) => (
      <AutoSpendTag
        key={index}
        expenseId={expense?._id}
        autoSpendTags={editedTags}
        setEditedTags={handleEditedTagsChange}
        removable={true}
        tag={item}
      />
    ));
  }, [ editedTags, expense?._id, handleEditedTagsChange ]);

  const handleAutoFundSources = useCallback(() => {
    const tabArgs = { setConfirmContent: setAutoFundConfirmContent, selectedTheme, autoFundSources };
    const payload = (
      <XymTabs
        content={autoFundTabs(tabArgs)}
        spacing={!_.isEmpty(autoFundSources)}
        visible={!_.isEmpty(autoFundSources)}
      />
    );

    setAutoFundConfirmContent(payload);
  }, [ autoFundSources, selectedTheme, setAutoFundConfirmContent ]);

  const addAutoSpend = () => {
    const tabArgs = {
      merchants,
      categories,
      privacyMode,
      setConfirmContent: setAutoSpendConfirmContent,
      autoSpendMerchants,
      editedTags,
      fromForm: true,
      handleEditedTagsChange,
    };

    const payload = <XymTabs visible={autoSpendMerchants} content={autoSpendTabs(tabArgs)} />;
    setAutoSpendConfirmContent(payload);
  };

  const handleRemoveExpense = () => {
    const isGoal = expense?.isGoal;
    const expenseType = isGoal ? 'goal' : 'expense';
    const payload = (
      <RemoveExpense
        expenseType={expenseType}
        expense={expense}
        setConfirmContent={setRemoveConfirmContent}
      />
    );

    setRemoveConfirmContent(payload);
  };

  const handleSubmit = args => {
    const { values, setSubmitting } = args;
    const { name, needed, frequencyId, autoFundSourceId, expenseTypeId, dueDate } = values;
    const useDailyAutoFundCalculations = autoFundSourceId === automaticFundingText;

    const callbacks = {
      onSuccess: () => {
        setAutoFundConfirmContent(null);
        setAutoSpendConfirmContent(null);
        setSlideOverExpenseId(null);
      },
      onComplete: () => setSubmitting(false),
    };

    const getAutoFundSourceIdValue = () => {
      if (useDailyAutoFundCalculations) return null;
      return autoFundSourceId !== noAutoFundingText ? autoFundSourceId : null;
    };

    const payload = {
      id: expense?._id,
      linkedAccountId: selectedAccountId,
      name: name.trim(),
      icon: null,
      current: _.isNumber(expense?.current) ? +expense.current?.toFixed(2) : 0,
      needed: _.toNumber(needed.replace?.(/[$, ]/g, '')),
      dueDate: !_.isEmpty(dueDate) ? moment(dueDate, 'MMMM Do, YYYY') : null,
      frequencyId: +frequencyId,
      expenseTypeId: isGoal ? +expenseTypeOptions[0].value : +expenseTypeId,
      color,
      isGoal: isGoal || false,
      autoFundSourceId: getAutoFundSourceIdValue(),
      useDailyAutoFundCalculations,
      autoSpendTags: editedTags || [],
      token,
      meta: { noLoadingState: true },
    };

    _.isObject(expense)
      ? updateExpense(payload, callbacks)
      : addExpense(payload, callbacks);
  };

  const openLearnMore = () => setLearnMoreContent({
    title: <H4>Expense Types</H4>,
    content: (
      <>
        <P size={0.875}>
          This is used to calculate your overall financial health, in your overview, using the 50:30:20 rule.

          <XymSpacer />

          The rule states that no more than 50% of your income should go to your essentials,
          30% to personal spending, and 20% to debt.
        </P>

        <XymSpacer />

        <P size={0.875}>
          An expense can be narrowed down to three main types:
        </P>

        <XymSpacer />

        <UL>
          <li>
            <Font size={0.875} block={true}>
              <Font size={0.875} weight='semibold'>Essentials</Font>:&nbsp;

              Money needed for things that affect your survival, such as rent,
              food, electricity, car etc.
            </Font>

            <XymSpacer size={0.5} />
          </li>

          <li>
            <Font size={0.875} block={true}>
              <Font size={0.875} weight='semibold'>Debt</Font>:&nbsp;

              Money owed to, or borrowed from, a person, or institution, that must be paid/repaid.
            </Font>

            <XymSpacer size={0.5} />
          </li>

          <li>
            <Font size={0.875} block={true}>
              <Font size={0.875} weight='semibold'>Personal spending</Font>:&nbsp;

              Also known as discretionary income. This is money spent on non-essentials, that is also not debt,
              such as subscriptions, hobbies, entertainment etc.
            </Font>
          </li>
        </UL>

        <XymSpacer />

        <P size={0.875}>
          Sometimes an expense can be both essential, and a debt, such as a mortgage or car payment.
          If this is the case, prioritize the expense as an essential &mdash; by default, everything
          is considered personal spending unless you tell {_.startCase(names.appName)} otherwise.
        </P>
      </>
    ),
  });

  return (
    <Formik
      initialValues={defaults}
      validationSchema={validationSchema}
      enableReinitialize={!_.isEqual(prevExpenseRef.current, expense)}
      onSubmit={(values, handlers) => {
        const { setSubmitting } = handlers;
        handleSubmit({ values, setSubmitting });
      }}
    >
      {form => (
        <Form>
          <ColorPicker
            width={1.75}
            height={1.75}
            color={color}
            callback={handleColorChange}
          />

          <XymField
            form={form}
            type={privacyMode ? 'password' : 'text'}
            name='name'
            placeholder={`i.e. ${isGoal ? 'Vacation' : 'Mortgage'}`}
            label={<Font size={0.875} weight='semibold'>{isGoal ? 'Goal' : 'Expense'} Name</Font>}
          />

          <XymField
            form={form}
            name='needed'
            type={privacyMode ? 'password' : 'text'}
            placeholder='Dollar signs and commas okay'
            textColor={accent}
            label={<Font size={0.875} weight='semibold'>How much do you need?</Font>}
          />

          <XymDatePicker
            form={form}
            name='dueDate'
            label={<Font size={0.875} weight='semibold'>When do you need it by?</Font>}
            optional={true}
            disablePastDates={true}
            spacing={isGoal ? 3 : 1.5}
            callback={newValue => {
              const autoFundSourceId = form?.values.autoFundSourceId;
              const frequencyId = form?.values.frequencyId;

              if (autoFundSourceId === automaticFundingText && _.isEmpty(newValue)) {
                form.setFieldValue('autoFundSourceId', noAutoFundingText);
              }

              if (frequencyId !== 1 && _.isEmpty(newValue)) {
                form.setFieldValue('frequencyId', 1);
              }

              setHasDueDate(!_.isEmpty(newValue));
            }}
          />

          <XymSelect
            form={form}
            data={frequencyOptions}
            name='frequencyId'
            label={<Font size={0.875} weight='semibold'>How often do you need it?</Font>}
            noResultsText='Frequency not found'
            optional={true}
            disabled={_.isEmpty(form?.values.dueDate)}
            callback={_.noop}
            visible={!isGoal}
          />

          <XymSelect
            form={form}
            data={expenseTypeOptions}
            name='expenseTypeId'
            label={
              <Font size={0.875} weight='semibold'>
                What type of expense is this? &nbsp;

                <XymLink size={0.75} weight='medium' callback={openLearnMore}>
                  Learn more
                </XymLink>
              </Font>
            }
            noResultsText='Expense type not found'
            optional={true}
            localDefault={expenseTypeOptions[0].value}
            callback={_.noop}
            spacing={3}
            privacy={privacyMode}
            visible={!isGoal}
          />

          <AdvancedTitle>
            <Font size={1.17} weight='semibold' color={titleColor}>
              Advanced
            </Font>

            <AdvancedIcon onClick={() => setAdvanced(!advanced)}>
              {advanced
                ? <Minus
                    color={darkGrey}
                    width={1.25}
                    height={1.25}
                    bgColor={lightGrey}
                  />
                : <Plus
                    color={darkGrey}
                    width={1.25}
                    height={1.25}
                    bgColor={lightGrey}
                  />
              }
            </AdvancedIcon>
          </AdvancedTitle>

          {advanced && <XymSpacer />}

          <AdvancedSection $theme={theme} $selectedTheme={selectedTheme} $visible={advanced}>
            <XymSelect
              form={form}
              data={allAutoFundOptions}
              name='autoFundSourceId'
              label={<Font size={0.875} weight='semibold'>Auto-funding method</Font>}
              noResultsText='Source not found'
              noDataText='No auto-fund sources...'
              optional={true}
              spacing={1}
              privacy={privacyMode}
              visible={autoFunding}
              callback={_.noop}
            />

            {autoFunding && (
              <Button
                theme={theme}
                selectedTheme={selectedTheme}
                type='button'
                text={<Font weight='medium' mobileSize={0.875}>Add or edit source</Font>}
                buttonType='ghost'
                color={onPrimary}
                callback={handleAutoFundSources}
              />
            )}

            {!isGoal && (
              <>
                {autoFunding && <XymSpacer size={1.5} />}

                <XymField
                  form={form}
                  name='autoSpendTags'
                  label={<Font size={0.875} weight='semibold'>Auto-spend tags</Font>}
                  optional={true}
                  hideField={true}
                />

                <XymSpacer />

                {_.isEmpty(editedTags) && (
                  <NoData
                    icon='fa-solid fa-tags'
                    text='No auto-spend tags'
                    subText={`Add an auto-spend category${autoSpendMerchants ? ' or merchant' : ''}.`}
                  />
                )}

                <AutoSpendArea $visible={!_.isEmpty(editedTags)}>
                  {buildTags()}
                </AutoSpendArea>

                <XymSpacer size={1.25} />

                <Button
                  theme={theme}
                  selectedTheme={selectedTheme}
                  type='button'
                  text={(
                    <Font weight='medium' mobileSize={0.875}>
                      Add category{autoSpendMerchants ? ' or merchant' : ''}
                    </Font>
                  )}
                  buttonType='ghost'
                  color={onPrimary}
                  callback={addAutoSpend}
                />
              </>
            )}
          </AdvancedSection>

          <XymSpacer size={2} />

          <Button
            theme={theme}
            selectedTheme={selectedTheme}
            type='submit'
            text={
              <Font weight='medium' mobileSize={0.875}>
                {_.isObject(expense) ? 'Save' : 'Add'} {isGoal ? 'goal' : 'expense'}
              </Font>
            }
            disabled={form.isSubmitting}
            callback={form.handleSubmit}
          />

          {_.isObject(expense) && (
            <>
              <XymSpacer size={0.5} />

              <Button
                theme={theme}
                selectedTheme={selectedTheme}
                type='button'
                text={<Font weight='medium' mobileSize={0.875}>Remove {isGoal ? 'goal' : 'expense'}</Font>}
                disabled={form.isSubmitting}
                color={error}
                hoverColor={errorHover}
                callback={handleRemoveExpense}
              />
            </>
          )}

          {mobileMode && <XymSpacer size={8} />}
        </Form>
      )}
    </Formik>
  );
});

export { ExpenseForm };