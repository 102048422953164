import { useSelector } from 'react-redux';
import { appConstants } from 'modules';
import { XymSpacer } from 'components';
import { Tag } from 'xerum';
import { withTheme } from 'styled-components';

const { light } = appConstants.themes;

const LabelTag = withTheme(props => {
  const { theme, text, visible } = props;
  const { generalPreferences: { privacyMode, selectedTheme } } = useSelector(state => state.preferences);
  const lightTheme = selectedTheme === light;
  const onSecondary = theme.modes[selectedTheme]?.onSecondary;
  const secondary = theme.modes[selectedTheme]?.secondary;
  const lightGrey = theme.modes[selectedTheme]?.lightGrey;

  if (!visible) return null;

  return (
    <>
      <XymSpacer across={true} />

      <Tag
        theme={theme}
        selectedTheme={selectedTheme}
        text={privacyMode ? 'Private' : text}
        textColor={onSecondary}
        verticalPadding={0.156}
        bgColor={secondary}
        borderSize={0.0625}
        borderRadius={1}
        borderColor={lightTheme ? lightGrey : lightGrey + 50}
      />
    </>
  );
});

export { LabelTag };