import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { authActions, appConstants } from 'modules';
import { emailIncrementorCheck } from 'helpers';
import { Font, H3, XymField, Center, XymSpacer } from 'components';
import { Button } from 'xerum';
import { Form, Formik } from 'formik';
import styled, { withTheme } from 'styled-components';
import * as yup from 'yup';

const { themes, spacing } = appConstants;
const { extraLargeGap, gap } = spacing;
const { light } = themes;

const StyledResetPassword = styled('div')`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: ${extraLargeGap}rem ${gap}rem ${gap}rem;

  form {
    max-width: 27rem;
  }
`;

const defaultValues = {
  email: '',
};

const validationSchema = yup.object().shape({
  email: yup.string()
    .email('Invalid email.')
    .required('Email is required.')
    .test('email-incrementor', 'Email incrementors are not allowed.', value => emailIncrementorCheck(value)),
});

const ResetPasswordForm = withTheme(props => {
  const { theme } = props;
  const { tokenInfo } = useSelector(state => state.auth);
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const white = theme.modes[selectedTheme]?.white;
  const darkGrey = theme.modes[selectedTheme]?.darkGrey;
  const lightTheme = selectedTheme === light;
  const token = tokenInfo?.accessToken;
  const dispatch = useDispatch();

  const sendPasswordResetEmail = useCallback((payload, callbacks) => {
    dispatch(authActions.sendPasswordResetEmail(payload, callbacks));
  }, [ dispatch ]);

  const handleSubmit = (values, actions) => {
    const { setSubmitting } = actions;
    const { email } = values;
    const payload = { email };
    const callbacks = {
      onComplete: () => setSubmitting(false),
    };

    sendPasswordResetEmail(payload, callbacks);
  };

  const buildForm = () => {
    return (
      <Formik
        initialValues={defaultValues}
        validationSchema={validationSchema}
        enableReinitialization={true}
        onSubmit={handleSubmit}
      >
        {form => (
          <Form>
            <H3 color={lightTheme ? darkGrey : white}>Reset password</H3>
            <XymSpacer size={2} />

            <XymField
              form={form}
              name='email'
              type='email'
              autoComplete='email'
              icon='fa-solid fa-envelope'
              label={<Font size={0.875} weight='semibold'>Email</Font>}
              spacing={2}
            />

            <Button
              theme={theme}
              selectedTheme={selectedTheme}
              type='submit'
              disabled={form.isSubmitting}
              callback={form.handleSubmit}
              text={
                <Font weight='medium' mobileSize={0.875}>
                  {form.isSubmitting ? 'Sending reset request...' : 'Send reset request'}
                </Font>
              }
            />

            {!token && (
              <>
                <XymSpacer />

                <Center>
                  <Link to='/login'>Log in</Link>
                  &nbsp;or&nbsp;
                  <Link to='/create-account'>Create account</Link>
                </Center>
              </>
            )}
          </Form>
        )}
      </Formik>
    );
  };

  return (
    <StyledResetPassword>
      {buildForm()}
    </StyledResetPassword>
  );
});

export { ResetPasswordForm };