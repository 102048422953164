import { useCallback, Fragment, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { appConstants, institutionsActions, appActions } from 'modules';
import { handlePlaidUpdate } from 'helpers';
import { P, Font, XymSpacer, XymConfirm, PlaidLink, Close, Footnote, UL, SelfManagedSetupForm } from 'components';
import { Button, Loading } from 'xerum';
import {
  StyledAccounts,
  AccountsHolder,
  AccountsArea,
  RemoveItem,
  InstitutionName,
  LinkSource,
  RepairText,
  RightPane,
} from './styles';
import { Account } from './Account';
import { RemoveInstitutionConfirm } from './RemoveInstitutionConfirm';
import { withTheme } from 'styled-components';
import _ from 'lodash';

const { themes, misc } = appConstants;
const { maxInstitutions } = misc;
const { light } = themes;

const Accounts = withTheme(props => {
  const { theme } = props;
  const { tokenInfo } = useSelector(state => state.auth);
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const {
    mobileMode,
    tabletMode,
    tabletLandscapeMode,
    hybridMode,
    globalConfirmContent,
  } = useSelector(state => state.app);

  const {
    institutionsData,
    institutionDataLoading,
    linkToken,
    linkTokenLoading,
  } = useSelector(state => state.institutions);

  const [ confirmContent, setConfirmContent ] = useState(null);

  const token = tokenInfo?.refreshToken || tokenInfo?.accessToken;
  const desktopMode = !mobileMode && !tabletMode && !tabletLandscapeMode && !hybridMode;
  const hasInstitutions = useMemo(() => !_.isEmpty(institutionsData), [ institutionsData ]);
  const paynesGrey = theme.colors.neutral.paynesGrey;
  const white = theme.modes[selectedTheme]?.white;
  const error = theme.modes[selectedTheme]?.error;
  const errorHover = theme.modes[selectedTheme]?.errorHover;
  const dispatch = useDispatch();

  const handleRemove = institution => {
    const args = { institution, setConfirmContent };
    const payload = <RemoveInstitutionConfirm {...args} />;
    setConfirmContent(payload);
  };

  const setGlobalConfirmContent = useCallback(payload => {
    dispatch(appActions.setGlobalConfirmContent(payload));
  }, [ dispatch ]);

  const updatePlaidLink = useCallback((payload, callbacks) => {
    dispatch(institutionsActions.updatePlaidLink(payload, callbacks));
  }, [ dispatch ]);

  const darkGrey = theme.modes[selectedTheme]?.darkGrey;
  const offWhite = theme.modes[selectedTheme]?.offWhite;
  const lightTheme = selectedTheme === light;

  const unlinkButton = args => {
    const { institution, disabled } = args || {};

    return (
      <RemoveItem
        $theme={theme}
        $selectedTheme={selectedTheme}
        onClick={() => !disabled && handleRemove(institution)}
      >
        <Close width={1.25} height={1.25} color={white} bgColor={paynesGrey} />
      </RemoveItem>
    );
  };

  const renderAccounts = id => {
    const workingInstitution = institutionsData?.find(institution => institution.itemId === id) || [];
    const institutionAccounts = workingInstitution.accounts || [];
    const onlyAccount = institutionsData?.length === 1 && institutionAccounts?.length === 1;

    if (!_.isEmpty(institutionAccounts)) {
      return institutionAccounts.map((account, index) => {
        const { account_id } = account;

        return (
          <Fragment key={account_id || index}>
            <Account account={account} onlyAccount={onlyAccount} />
          </Fragment>
        );
      });
    }
  };

  const editSelfManagedInstitution = args => {
    const { itemId } = args || {};

    const selfManagedSetupArgs = {
      content: (
        <SelfManagedSetupForm
          setConfirmContent={setGlobalConfirmContent}
          editMode={true}
          itemId={itemId}
        />
      ),
      blank: true,
    };

    setGlobalConfirmContent(selfManagedSetupArgs);
  };

  const renderInstitutions = () => {
    const accounts = institutionsData?.map((institution, index) => {
      const { itemId, institutionName, needsAttention, selfManaged } = institution;
      const linkArgs = { token, itemId, updatePlaidLink };
      const disabled = institutionDataLoading || linkToken || linkTokenLoading;

      return (
        <Fragment key={itemId || index}>
          <AccountsHolder
            $theme={theme}
            $selectedTheme={selectedTheme}
            $needsAttention={needsAttention}
          >
            <InstitutionName $theme={theme} $selectedTheme={selectedTheme}>
              <Font weight='semibold'>
                {institutionName}
              </Font>
            </InstitutionName>

            <LinkSource $theme={theme} $selectedTheme={selectedTheme}>
              <Font weight='semibold'>
                {selfManaged ? 'Self-managed' : 'Plaid'}
              </Font>
            </LinkSource>

            {renderAccounts(itemId)}
            {unlinkButton({ institution, disabled })}

            {selfManaged && (
              <Button
                theme={theme}
                selectedTheme={selectedTheme}
                width='100%'
                disabled={globalConfirmContent}
                text={(
                  <Font weight='semibold' mobileSize={0.875}>
                    Edit institution
                  </Font>
                )}
                callback={() => editSelfManagedInstitution({ itemId })}
              />
            )}

            {needsAttention && (
              <Button
                theme={theme}
                selectedTheme={selectedTheme}
                width='100%'
                color={needsAttention && error}
                hoverColor={needsAttention && errorHover}
                disabled={disabled}
                text={(
                  <Font weight='semibold' mobileSize={0.875}>
                    Reauthenticate
                  </Font>
                )}
                callback={() => handlePlaidUpdate(linkArgs)}
              />
            )}

            <RepairText $theme={theme} $selectedTheme={selectedTheme} $visible={needsAttention}>
              <Font weight='semibold' mobileSize={0.875}>
                Needs attention
              </Font>

              <XymSpacer size={0.5} across={true} />

              <Font size={1.25}>
                <i className='fa-solid fa-exclamation-circle' />
              </Font>
            </RepairText>
          </AccountsHolder>

          {index !== (institutionsData.length - 1) && <XymSpacer size={1.5} />}
        </Fragment>
      );
    });

    return accounts;
  };

  return (
    <StyledAccounts>
      <Font weight='semibold' size={1.25} mobileSize={1} tabletSize={1} color={lightTheme ? darkGrey : offWhite}>
        Institutions ({institutionsData?.filter(item => !item.selfManaged).length || 0}/{maxInstitutions} Plaid links)
      </Font>

      {!mobileMode && <XymSpacer />}

      <AccountsArea $isLoading={institutionDataLoading}>
        <Loading
          theme={theme}
          selectedTheme={selectedTheme}
          isLoading={institutionDataLoading}
          hasData={hasInstitutions}
          column={true}
          noFailIcon={true}
          renderOnFail={true}
          text={
            <Font size={1} weight='semibold'>
              Loading institutions...
            </Font>
          }
        >
          <UL mobileSize={0.875}>
            <li>Link up to {maxInstitutions} institutions, with Plaid, unlimited if self-managed.</li>
            <li>Share as many accounts as you want, from a single institution.</li>
            <li>Repair a link to an institution by reauthenticating, when available.</li>
            <li>
              Remove an institution by {desktopMode ? 'hovering and' : ''} using &nbsp;
              <Close width={1} height={1} color={white} bgColor={paynesGrey} />
            </li>
            <li>
              Remove and relink a Plaid institution to add or remove accounts.
              Edit self-managed institutions to do the same, and more...*
            </li>

            <XymSpacer />

            <Footnote plural={true}>
              <XymSpacer size={0.5} />

              <P size={0.75}>
                *Removing <Font size={0.75} weight='bold'>any</Font> institution will purge
                all of it&apos;s accounts and data. This includes all transactions, categories,
                auto-funding, auto-spending, expenses and goals related to all accounts from that
                institution &mdash; if the institution was linked via Plaid, it will also disconnect
                it from Plaid.
              </P>

              <XymSpacer />

              <P size={0.75}>
                If you are removing an institution to relink it with new accounts, use the backup wizard to back up your
                expenses and goals, for all accounts you plan to add back after removing the institution.
              </P>
            </Footnote>
          </UL>

          <RightPane>
            {renderInstitutions()}
            <PlaidLink noButton={mobileMode} subText='Linked institutions will appear here' />
          </RightPane>
        </Loading>
      </AccountsArea>

      {mobileMode && (
        <PlaidLink
          buttonOnly={true}
          buttonText={`Link an institution with Plaid`}
        />
      )}

      <XymConfirm confirmContent={confirmContent} blank={true} onClose={() => setConfirmContent(null)} />
    </StyledAccounts>
  );
});

export { Accounts };