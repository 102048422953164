import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { P, Font, H3, XymField, Center, XymSpacer } from 'components';
import { appConstants, authActions } from 'modules';
import { paramsToObject } from 'helpers';
import { FieldReqs, Button } from 'xerum';
import { Form, Formik } from 'formik';
import styled, { withTheme } from 'styled-components';
import * as yup from 'yup';

const { names, spacing, themes } = appConstants;
const { sessionTokenKeyName } = names;
const { extraLargeGap, gap } = spacing;
const { light } = themes;
const autoLogin = true;

const StyledSetPassword = styled('div')`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: ${extraLargeGap}rem ${gap}rem ${gap}rem;

  form {
    max-width: 27rem;
  }
`;

const FieldReqsHolder = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const defaultValues = {
  password: '',
};

const validationSchema = yup.object().shape({
  password: yup.string().required('New password is required.'),
});

const SetPasswordForm = withTheme(props => {
  const { theme } = props;
  const [ passwordVisible, setPasswordVisible ] = useState(false);
  const { search } = useLocation();
  const { tokenInfo } = useSelector(state => state.auth);
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const { mobileMode } = useSelector(state => state.app);
  const white = theme.modes[selectedTheme]?.white;
  const darkGrey = theme.modes[selectedTheme]?.darkGrey;
  const onPrimary = theme.modes[selectedTheme]?.onPrimary;
  const lightTheme = selectedTheme === light;

  const navigate = useNavigate();
  const token = tokenInfo?.accessToken;
  const sessionToken = paramsToObject(search)?.[sessionTokenKeyName];
  const dispatch = useDispatch();

  const updatePassword = useCallback((payload, callbacks) => {
    dispatch(authActions.updatePassword(payload, callbacks));
  }, [ dispatch ]);

  const handleSubmit = (values, actions) => {
    const { setSubmitting } = actions;
    const { password } = values;
    const payload = {
      password,
      sessionToken,
    };

    const handleRedirect = () => {
      if (autoLogin) return navigate('/');
      navigate('/login');
    };

    const callbacks = {
      onSuccess: handleRedirect,
      onComplete: () => setSubmitting(false),
    };

    updatePassword(payload, callbacks);
  };

  const buildForm = () => {
    return (
      <Formik
        initialValues={defaultValues}
        validationSchema={validationSchema}
        enableReinitialization={true}
        onSubmit={handleSubmit}
      >
        {form => (
          <Form>
            <H3 color={lightTheme ? darkGrey : white}>Set password</H3>
            <XymSpacer size={2} />

            <XymField
              form={form}
              name='password'
              type={passwordVisible ? 'text' : 'password'}
              icon={passwordVisible ? 'fa-solid fa-eye' : 'fa-solid fa-eye-slash'}
              iconCallback={() => setPasswordVisible(!passwordVisible)}
              label={<Font size={0.875} weight='semibold'>New password</Font>}
              autoComplete='current-password'
              disabled={!sessionToken}
            />

            <FieldReqsHolder>
              <Font size={0.875}>
                <FieldReqs
                  theme={theme}
                  selectedTheme={selectedTheme}
                  value={form.values.password}
                  upper={true}
                  upperText={!mobileMode && 'Uppercase'}
                  lower={true}
                  lowerText={!mobileMode && 'Lowercase'}
                  number={true}
                  min={8}
                  minText={!mobileMode && 'Minimum'}
                />
              </Font>

              <XymSpacer size={0.5} />

              <Center>
                <P size={0.875} color={onPrimary + 50}>
                  Consider a password manager for{!mobileMode ? ' generating' : ''} a secure password.
                </P>
              </Center>
            </FieldReqsHolder>

            <XymSpacer size={2} />

            <Button
              theme={theme}
              selectedTheme={selectedTheme}
              type='submit'
              disabled={form.isSubmitting || !sessionToken}
              callback={form.handleSubmit}
              text={
                <Font weight='medium' mobileSize={0.875}>
                  {!sessionToken && 'Missing session token'}
                  {sessionToken && !form.isSubmitting && 'Set password'}
                  {sessionToken && form.isSubmitting && 'Setting new password...'}
                </Font>
              }
            />

              <>
                <XymSpacer />

                <Center>
                  {!token && (
                    <>
                      <Link to='/login'>Log in</Link>,&nbsp;
                      <Link to='/create-account'>Create account</Link> or&nbsp;
                    </>
                  )}

                  <Link to='/reset-password'>Resend reset request</Link>
                </Center>
              </>
          </Form>
        )}
      </Formik>
    );
  };

  return (
    <StyledSetPassword>
      {buildForm()}
    </StyledSetPassword>
  );
});

export { SetPasswordForm };