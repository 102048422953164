import { appConstants } from 'modules';
import styled from 'styled-components';

const { layout, breakpoints, spacing } = appConstants;
const { layoutWidth } = layout;
const { gap, smallGap } = spacing;
const { tabletLandscape } = breakpoints;

export const StyledUpcoming = styled('div')`
  grid-area: ${props => props.$isGoals ? 'goals' : 'upcoming'};
  display: grid;
  grid-template-rows: 3rem 1fr 3rem;
  gap: ${gap}rem;
  width: 100%;

  @media screen and (max-width: ${layoutWidth}rem) {
    display: flex;
    flex-direction: column;
    gap: ${gap}rem;
    height: fit-content;

    button {
      display: none;
    }
  }
`;

export const ExpensesList = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${smallGap}rem;
  width: 100%;
  overflow-y: auto;
  padding: 0 ${smallGap}rem;

  @media screen and (max-width: ${layoutWidth}rem) {
    height: 100%;
    overflow-y: unset;
  }
`;

export const TitleArea = styled('div')`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1.5rem;
  align-items: center;
  width: 100%;

  @media screen and (max-width: ${tabletLandscape}rem) {
    display: inline-flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: unset;
  }
`;