import { messagesConstants } from 'modules/messages/messagesConstants';
import { request } from 'helpers';
import StateManager from 'state-wrangler';

const { actions, selectors } = messagesConstants;

const initial = {
  [selectors.STATE_KEY_MESSAGES_DATA]: null,
};

const messagesReducer = (initialState = initial, action = {}) => {
  const { payload } = action;
  const state = new StateManager(initialState);

  switch(action.type) {
    // Get all messages
    case request(actions.GET_MESSAGES).start:
      return state.update(selectors.STATE_KEY_MESSAGES_DATA_LOADING, true);

    case request(actions.GET_MESSAGES).success:
        return state.update(selectors.STATE_KEY_MESSAGES_DATA, payload);

    case request(actions.GET_MESSAGES).complete:
      return state.update(selectors.STATE_KEY_MESSAGES_DATA_LOADING, false);

    // Update a single message
    case request(actions.UPDATE_MESSAGE).success:
        return state.update(selectors.STATE_KEY_MESSAGES_DATA, payload);

    // Mark all messages as read
    case request(actions.MARK_ALL_MESSAGES_READ).success:
        return state.update(selectors.STATE_KEY_MESSAGES_DATA, payload);

    default:
      return initialState;
  }
};

export { messagesReducer };