import _ from 'lodash';

export const hexValid = val => {
  const isValid = val?.startsWith?.('#') && (
    val?.length === 4
    || val?.length === 7
    || val?.length === 9
  );

  if (isValid) return val;
};

export const iconValid = val => {
  const types = [ 'solid', 'regular', 'light', 'thin', 'duotone', 'sharp', 'brands' ];
  const isValid =  types.find(type => val?.startsWith?.(`fa-${type} fa-`));
  if (isValid) return val;
};

export const urlValid = val => {
  const regex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w-]+)+[\w\-_~:?#[\]@!&',;=.]+$/g;
  const hasProtocol = val?.includes?.('https://');
  const isValid = regex.test(val);

  if (isValid) {
    return hasProtocol ? val : `https://${val}`;
  }

  return val?.includes?.('localhost') ? val : '';
};

export const emailIncrementorCheck = email => {
  const emailFormatCheck = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const incrementorCheck = /\+(\d*)(?=@)/;
  const incrementorFormat = /^\d+$/;
  const isValid = emailFormatCheck.test(email);

  if (isValid) {
    const atIndex = email.indexOf('@');
    const userPart = email.substring(0, atIndex + 1);
    const incrementors = userPart.match(incrementorCheck) || [];
    const lastIncrementor = !_.isEmpty(incrementors) && incrementors[incrementors.length - 1];
    const hasIncrementor = lastIncrementor?.match?.(incrementorFormat);

    return !hasIncrementor ? email : null;
  }

  return null;
};