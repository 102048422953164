import { useSelector } from 'react-redux';
import { AppLogo } from 'components';
import { StyledHeader, LeftGroup, Layout } from './styles';
import { UserNav } from './UserNav';
import { AppNav } from './AppNav';

const PrimaryHeader = () => {
  const { mobileMode } = useSelector(state => state.app);

  // NOTE: There is a bug in styled-components that causes <StyledHeader /> and
  // <StyledRibbon /> (in <SafeToUse />) to share the same className.  We have to
  // turn it off here, rather than using a propr or a breakpoint in CSS.
  if (mobileMode) return;

  return (
    <StyledHeader>
      <Layout>
        <LeftGroup>
          <AppLogo />
          <AppNav />
        </LeftGroup>

        <UserNav />
      </Layout>
    </StyledHeader>
  );
};

export { PrimaryHeader };