import { useCallback, useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { appConstants, preferencesActions } from 'modules';
import { Font, XymSelect, NoData, XymSpacer } from 'components';
import { Loading, Toggle } from 'xerum';
import { LegendItem } from './LegendItem';
import { StyledTrends, TitleArea, Legend, YearToDateToggle } from './styles';
import { TrendsChart } from './TrendsChart';
import { withTheme } from 'styled-components';
import _ from 'lodash';

const { light } = appConstants.themes;

const earnedVsSpent = 0;

const Trends = withTheme(props => {
  const { theme } = props;
  const { mobileMode, tabletMode, tabletLandscapeMode, bannerContent } = useSelector(state => state.app);
  const { trends, institutionDataLoading, transactionsDataLoading } = useSelector(state => state.institutions);
  const { tokenInfo } = useSelector(state => state.auth);

  const trendOptions = useMemo(() => {
    const baseCategory = [ { value: 0, label: 'Earned vs Spent' } ];
    const activeCategories = trends?.activeCategories;
    const withCategories = activeCategories?.map(category => ({ value: category._id, label: category?.name })) || [];

    return [ ...baseCategory, ...withCategories ];
  }, [ trends ]);

  const {
    trendsPreferences: { yearToDate, categoryId },
    generalPreferences: { privacyMode, selectedTheme },
  } = useSelector(state => state.preferences);

  const [ workingYtd, setWorkingYtd ] = useState(yearToDate);
  const [ selectedTrend, setSelectedTrend ] = useState(null);

  const token = tokenInfo?.refreshToken || tokenInfo?.accessToken;
  const desktopMode = !mobileMode && !tabletMode && !tabletLandscapeMode;
  const lightTheme = selectedTheme === light;
  const earnSpentSelected = _.isEqual(selectedTrend, trendOptions[earnedVsSpent]?.value);
  const accent = theme.colors.accent.celestialBlue;
  const mountbattenPink = theme.colors.neutral.mountbattenPink;
  const tropicalIndigo = theme.colors.neutral.tropicalIndigo;
  const darkGrey = theme.modes[selectedTheme]?.darkGrey;
  const offWhite = theme.modes[selectedTheme]?.offWhite;
  const lightGrey = theme.modes[selectedTheme]?.lightGrey;
  const error = theme.modes[selectedTheme]?.error;
  const grey = theme.modes[selectedTheme]?.grey;
  const secondaryColor = lightTheme ? lightGrey : grey;
  const titleColor = lightTheme ? darkGrey : offWhite;
  const dataLoading = transactionsDataLoading || institutionDataLoading;
  const dispatch = useDispatch();

  const getFirstLabel = () => {
    if (earnSpentSelected && !workingYtd) return 'Earned this month';
    if (earnSpentSelected && workingYtd) return 'Earned this year';
    if (!earnSpentSelected && workingYtd) return 'Spent this year';
    if (!earnSpentSelected && !workingYtd) return 'Spent this month';
  };

  const geSecondLabel = () => {
    if (earnSpentSelected && workingYtd) return 'Spent this year';
    if (earnSpentSelected && !workingYtd) return 'Spent this month';
    if (!earnSpentSelected && !workingYtd) return 'Spent last month';
    if (!earnSpentSelected && workingYtd) return 'Spent last year';
  };

  const getThirdLabel = () => {
    if (earnSpentSelected && !workingYtd) return 'Earned last month';
    if (earnSpentSelected && workingYtd) return 'Earned last year';
  };

  const getFourthLabel = () => {
    if (earnSpentSelected && workingYtd) return 'Spent last year';
    if (earnSpentSelected && !workingYtd) return 'Spent last month';
  };

  const updateUserPreferences = useCallback((payload, callbacks) => {
    dispatch(preferencesActions.updateUserPreferences(payload, callbacks));
  }, [ dispatch ]);

  useEffect(() => {
    setWorkingYtd(yearToDate);
  }, [ yearToDate, setWorkingYtd ]);

  useEffect(() => {
    const categoryExists = trendOptions.find(option => _.isEqual(option.value, categoryId));
    const newValue = categoryExists ? categoryId : trendOptions[earnedVsSpent]?.value;

    setSelectedTrend(newValue);

    if (!_.isEmpty(trends) && !categoryExists) {
      updateUserPreferences({ trendsCategoryId: newValue, token });
    }
  }, [ token, trends, categoryId, trendOptions, updateUserPreferences,  setSelectedTrend ]);

  const buildChart = useCallback(() => {
    if (!privacyMode) {
      return (
        <TrendsChart
          yearToDate={workingYtd}
          selectedTrend={selectedTrend}
          secondaryColor={secondaryColor}
          earnSpentSelected={earnSpentSelected}
        />
      );
    }

    return (
      <NoData
        icon='fa-solid fa-lock'
        text='Privacy mode is enabled'
        subText='Disable privacy mode to view your trends.'
      />
    );
  }, [ earnSpentSelected, privacyMode, secondaryColor, selectedTrend, workingYtd ]);

  return (
    <StyledTrends $theme={theme} $selectedTheme={selectedTheme} $bannerContent={bannerContent}>
      <TitleArea>
        {desktopMode && (
          <Font id='trendsTitle' size={1.25} weight='semibold' color={titleColor}>
            Trends
          </Font>
        )}

        <YearToDateToggle id='trendsYtd'>
          <Font weight='semibold' mobileSize={0.875} tabletSize={0.875}>
            Annual
          </Font>

          <XymSpacer across={true} />

          <Toggle
            theme={theme}
            selectedTheme={selectedTheme}
            localDefault={workingYtd}
            callback={newValue => {
              const payload = {
                trendsYearToDate: newValue,
                token,
              };

              setWorkingYtd(newValue);
              updateUserPreferences(payload);
            }}
          />
        </YearToDateToggle>

        <XymSelect
          id='trendsMenu'
          data={trendOptions}
          name='trendOptions'
          loading={dataLoading}
          loadingText={_.isEmpty(trends) ? 'Loading categories...' : 'Updating categories...'}
          localDefault={selectedTrend}
          noDataText='No categories found...'
          noResultsText='Category not found'
          noSpacing={!desktopMode}
          spacing={1.5}
          privacy={privacyMode}
          callback={newValue => {
            if (!_.isEqual(selectedTrend, +newValue)) {
              setSelectedTrend(+newValue);
              updateUserPreferences({ trendsCategoryId: +newValue, token });
            }
          }}
        />

        <Legend id='trendsLegend' $earnSpentSelected={earnSpentSelected}>
          <LegendItem
            color={earnSpentSelected ? accent : error}
            label={getFirstLabel()}
          />

          <LegendItem
            visible={earnSpentSelected}
            color={earnSpentSelected && (lightTheme ? mountbattenPink : tropicalIndigo)}
            label={getThirdLabel()}
          />

          <LegendItem
            color={earnSpentSelected ? error : secondaryColor}
            label={geSecondLabel()}
          />

          <LegendItem
            visible={earnSpentSelected}
            color={earnSpentSelected && secondaryColor}
            label={getFourthLabel()}
          />
        </Legend>
      </TitleArea>

      <Loading
        theme={theme}
        selectedTheme={selectedTheme}
        isLoading={dataLoading}
        hasData={!dataLoading}
        renderOnFail={true}
        noFailIcon={true}
        text={
          <Font size={1} weight='semibold'>
            Loading trends...
          </Font>
        }
      >
        {buildChart()}
      </Loading>
    </StyledTrends>
  );
});

export { Trends };