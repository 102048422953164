import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { authActions } from 'modules';
import { P, H3, Font, XymField, XymConfirmButtons, XymSpacer } from 'components';
import { Formik, Form } from 'formik';
import { withTheme } from 'styled-components';
import * as yup from 'yup';

const { VITE_APP_NAME: appName } = process.env;

const DeleteAccountForm = withTheme(props => {
  const { theme, setConfirmContent } = props;
  const { tokenInfo } = useSelector(state => state.auth);
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const error = theme.modes[selectedTheme]?.error;
  const errorHover = theme.modes[selectedTheme]?.errorHover;
  const dispatch = useDispatch();

  const deleteUser = useCallback((payload, callbacks) => {
    dispatch(authActions.deleteUser(payload, callbacks));
  }, [ dispatch ]);

  const defaults = { confirmation: '' };
  const validationSchema = yup.object().shape({
    confirmation: yup.string()
      .required('Confirmation is required.')
      .oneOf([ 'DELETE' ], 'Please type "DELETE" to confirm.')
      .trim(),
  });

  const handleSubmit = args => {
    const { values, setSubmitting } = args;
    const { confirmation } = values;

    const callbacks = {
      onSuccess: () => setConfirmContent(null),
      onComplete: () => setSubmitting(false),
    };

    const payload = {
      token: tokenInfo?.accessToken,
      confirmation,
    };

    deleteUser(payload, callbacks);
  };

  return (
    <Formik
      initialValues={defaults}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={(values, handlers) => {
        const { setSubmitting } = handlers;
        handleSubmit({ values, setSubmitting });
      }}
    >
      {form => (
        <Form>
          <H3>Warning!</H3>

          <XymSpacer />

          <P>
            Your account will be scheduled for deletion 30 days from when you send this request.
            Logging in at any time during this period will cancel the process.
          </P>

          <XymSpacer />

          <P>
            This action is irreversible and will scrub all of your information, security preferences,
            subscription history, institution data, settings, expenses, goals etc. from our servers.
          </P>

          <XymSpacer />

          <P>
            <Font weight='semibold'>Once deleted, we will not be able to service your
            account</Font> and you will need to sign up for {appName}&trade; again if you change
            your mind.
          </P>

          <XymSpacer />

          <P>
            We are sorry to see you go, but if you are sure you want to proceed, please
            type <Font weight='semibold'>“DELETE”</Font> in the field below and confirm.
          </P>

          <XymSpacer />

          <XymField
            form={form}
            name='confirmation'
            placeholder='Type "DELETE" here...'
            spacing={0.5}
          />

          <XymSpacer />

          <XymConfirmButtons
            form={form}
            confirmText='Send request'
            confirmColor={error}
            confirmHoverColor={errorHover}
            setConfirmContent={setConfirmContent}
          />
        </Form>
      )}
    </Formik>
  );
});

export { DeleteAccountForm };