import { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { appConstants } from 'modules';
import { getColor } from 'helpers';
import { Font, XymSpacer } from 'components';
import styled, { css, withTheme } from 'styled-components';
import _ from 'lodash';

const { themes, breakpoints, spacing } = appConstants;
const { gap, smallGap, extraSmallGap } = spacing;
const { mobile } = breakpoints;
const { light } = themes;

const TabHeaders = styled('div')`
  display: ${props => props.$visible === false ? 'none' : 'flex'};
  align-items: center;
  gap: 0.125rem;
  height: 3rem;
  width: fit-content;
  cursor: pointer;
  margin: 0 auto;
  border-radius: 0.25rem;
  user-select: none;
  background-color: ${props => {
    const lightTheme = props.$selectedTheme === light;
    const color = getColor(props, lightTheme ? 'lightGrey' : 'darkGrey');
    return color;
  }};

  @media screen and (max-width: ${mobile}rem) {
    width: 100%;
  }
`;

const TabItem = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${props => !props.$active ? `calc(100% - ${extraSmallGap}rem)` : '100%'};
  width: 100%;
  min-width: 10rem;
  padding: ${smallGap}rem ${gap}rem;
  color: ${props => getColor(props, props.$active ? 'white' : 'onPrimary')};
  background-color: ${props => {
    const lightTheme = props.$selectedTheme === light;
    const color = lightTheme ? getColor(props, 'primary') : getColor(props, 'darkGrey');

    if (props.$active) return getColor(props, 'accent');
    return color;
  }};

  ${props => props.$firstTab && css`
    border-radius: 0.1875rem 0 0 0.1875rem;
    border-left: 0.125rem solid ${props => {
      const lightTheme = props.$selectedTheme === light;
      if (props.$active) return getColor(props, 'accent');
      return getColor(props, lightTheme ? 'lightGrey' : 'darkGrey');
    }};
  `}

  ${props => props.$lastTab && css`
    border-radius: 0rem 0.1875rem 0.1875rem 0;
    border-right: 0.125rem solid ${props => {
      const lightTheme = props.$selectedTheme === light;
      if (props.$active) return getColor(props, 'accent');
      return getColor(props, lightTheme ? 'lightGrey' : 'darkGrey');
    }};
  `}

  ${props => props.$firstTab && props.$active && css`border-radius: 0.25rem 0 0 0.25rem;`}
  ${props => props.$lastTab && props.$active && css`border-radius: 0rem 0.25rem 0.25rem 0;`}

  span {
    width: max-content;
    text-align: center;
  }

  @media screen and (max-width: ${mobile}rem) {
    min-width: unset;
  }
`;

const XymTabs = withTheme(props => {
  const { theme, content, spacing, contentFirst, visible } = props;
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const [ selectedTab, setSelectedTab ] = useState(content?.[0]?.name);
  const isVisible = visible !== undefined ? visible : true;

  const buildTabs = () => {
    return content?.map((tab, index) => {
      const { name } = tab;
      const firstTab = index === 0;
      const lastTab = index === content.length - 1;
      const active = _.isEqual(_.toLower(selectedTab), _.toLower(name));

      return (
        <TabItem
          key={index}
          $theme={theme}
          $selectedTheme={selectedTheme}
          $firstTab={firstTab}
          $lastTab={lastTab}
          $active={active}
          onClick={() => setSelectedTab(name)}
          >
          <Font weight='medium' mobileSize={0.875}>
            {name}
          </Font>
        </TabItem>
      );
    });
  };

  const buildContent = () => (
    content?.map((tab, index) => {
      const { name } = tab;

      if (_.isEqual(_.toLower(selectedTab), _.toLower(name))) {
        return (
          <Fragment key={index}>
            {tab.content}
          </Fragment>
        );
      }
    })
  );

  return (
    <>
      {contentFirst && (
        <>
          {buildContent()}
          {spacing && isVisible && <XymSpacer size={spacing} />}
        </>
      )}

      <TabHeaders
        $theme={theme}
        $selectedTheme={selectedTheme}
        $firstTab={_.isEqual(selectedTab, content?.[0])}
        $lastTab={_.isEqual(selectedTab, content?.[content.length - 1])}
        $visible={isVisible}
      >
        {buildTabs()}
      </TabHeaders>

      {!contentFirst && (
        <>
          {spacing && isVisible && <XymSpacer size={spacing} />}
          {buildContent()}
        </>
      )}
    </>
  );
});

export { XymTabs };