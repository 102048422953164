import { appConstants, timeFrames } from 'modules';
import { XymSpacer, XymSelect } from 'components';
import { Activity } from 'pages/Overview/Activity/Activity';
import { Analysis } from 'pages/Overview/Analysis/Analysis';
import { Upcoming } from 'pages/Overview/Upcoming/Upcoming';
import { Trends } from 'pages/Overview/Trends/Trends';
import styled from 'styled-components';
import _ from 'lodash';

const { breakpoints, spacing } = appConstants;
const { extraLargeGap } = spacing;
const { hybrid, mobile } = breakpoints;
const activity = 'activity';
const analysis = 'analysis';
const upcoming = 'upcoming';
const trends = 'trends';

const MobileContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;

  @media screen and (max-width: ${hybrid}rem) {
    height: calc(100% - ${extraLargeGap}rem);
  }

  @media screen and (max-width: ${mobile}rem) {
    height: calc(100dvh - ${props => props.$bannerContent ? 19 : 16}rem);
  }
`;

const UpcomingContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

export const overviewTabs = args => {
  const {
    token,
    selectedWindow,
    updateUserPreferences,
    desktopMode,
    setSelectedWindow,
    bannerContent,
  } = args || {};

  const overviewContent = [
    {
      name: _.capitalize(activity),
      content: <Activity />,
    },

    {
      name: _.capitalize(analysis),
      content: <Analysis />,
    },

    {
      name: _.capitalize(upcoming),
      content: (
        <MobileContainer $bannerContent={bannerContent}>
          <XymSelect
            id='upcomingDateWindow'
            data={timeFrames}
            localDefault={selectedWindow}
            noResultsText='Time frames not found'
            callback={newValue => {
              if (!_.isEqual(selectedWindow, +newValue)) {
                setSelectedWindow(+newValue);
                updateUserPreferences({ token, upcomingRangeId: +newValue });
              }
            }}
            noSpacing={!desktopMode}
          />

          <XymSpacer />

          <UpcomingContainer>
            <Upcoming {...args} />
          </UpcomingContainer>
        </MobileContainer>
      ),
    },

    {
      name: _.capitalize(trends),
      content: <Trends />,
    },
  ];

  return overviewContent;
};