import { useSelector } from 'react-redux';
import { withTheme } from 'styled-components';
import { Spacer } from 'xerum';

const XymSpacer = withTheme(props => {
  const { size, tabletSize, mobileSize, across } = props;
  const { tabletMode, mobileMode } = useSelector(state => state.app);

  return (
    <Spacer
      size={size}
      tabletSize={tabletMode && tabletSize}
      mobileSize={mobileMode && mobileSize}
      across={across}
    />
  );
});

export { XymSpacer };