import { useSelector } from 'react-redux';
import { appConstants } from 'modules';
import { P, Font, TextSection, XymSpacer, XymTooltip, Footnote } from 'components';
import { StyledMaintenanceDetails, MaintenanceHolder } from './styles';
import { Actions } from './Actions';
import { withTheme } from 'styled-components';

const { light } = appConstants.themes;

const Maintenance = withTheme(props => {
  const { theme } = props;
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const { tokenInfo } = useSelector(state => state.auth);
  const darkGrey = theme.modes[selectedTheme]?.darkGrey;
  const onPrimary = theme.modes[selectedTheme]?.onPrimary;
  const offWhite = theme.modes[selectedTheme]?.offWhite;
  const lightTheme = selectedTheme === light;

  const { NODE_ENV } = process.env;
  const inProduction = NODE_ENV === 'production';

  const buildDetails = () => {
    if (tokenInfo) {
      return (
        <MaintenanceHolder>
          <Font weight='semibold' size={1.25} mobileSize={1} tabletSize={1} color={lightTheme ? darkGrey : offWhite}>
            Maintenance
          </Font>

          <XymSpacer size={1.75} />

          <TextSection
            label={
              <Font weight='semibold'>
                <P>Plaid token removal and database clean-up</P>
                <XymTooltip
                  text="Unlinks all Plaid access tokens from each linked institution and removes all of
                    the user's institutions, transactions, expenses, goals and auto-fund sources."
                />
              </Font>
            }
            minHeight={2.5}
            labelColor={onPrimary}
            sectionText={
              <Font block={true}>
                <P>
                  Notifies users @ 3 months, unsubscribed.
                </P>

                <P>
                  Purges unsubscribed data @ 4 months.
                </P>

                {inProduction && (
                  <>
                    <XymSpacer />
                    <Footnote text='Run in dev environment, to avoid production costs.' />
                  </>
                )}
              </Font>
            }
          />

          <XymSpacer size={1.75} />
        </MaintenanceHolder>
      );
    }
  };

  return (
    <StyledMaintenanceDetails>
      {buildDetails()}
      <Actions />
    </StyledMaintenanceDetails>
  );
});

export { Maintenance };