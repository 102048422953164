import { appConstants } from 'modules';
import { XymSpacer, ChangePasswordForm } from 'components';
import { MFA } from 'pages/Security/Sections/MFA';
import { TrustedDevices } from 'pages/Security/Sections/TrustedDevices';
import { SessionHistory } from 'pages/Security/Sections/SessionHistory';
import { MFAActions } from 'pages/Security/Sections/MFAActions';
import styled from 'styled-components';

const { spacing, layout, breakpoints } = appConstants;
const { mobile } = breakpoints;
const { layoutWidth } = layout;
const { gap } = spacing;

const MobileContainer = styled('div')`
  display: inline-flex;
  gap: ${gap}rem;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  @media screen and (max-width: ${layoutWidth}rem) {
    display: flex;
    flex-direction: column;
    height: calc(100dvh - ${props => props.$height || 21}rem);
  }

  @media screen and (max-width: ${mobile}rem) {
    height: calc(100dvh - ${props => props.$mobileHeight || 23}rem);
  }
`;

export const securityTabs = args => {
  const { desktopMode, mfa, bannerContent } = args;

  const securityContent = [
    {
      name: 'Password',
      content: <ChangePasswordForm />,
    },
    {
      name: 'Devices',
      content: (
        <>
          <MobileContainer $height={mfa && (bannerContent ? 32 : 29)} $mobileHeight={!mfa && (bannerContent ? 19 : 16)}>
            {mfa && <MFA />}
            <TrustedDevices />
          </MobileContainer>

          {mfa && (
            <>
              <XymSpacer />
              <MFAActions visible={!desktopMode} />
            </>
          )}
        </>
      ),
    },
    {
      name: 'Sessions',
      content: (
        <MobileContainer $mobileHeight={bannerContent ? 19 : 16}>
          <SessionHistory />
        </MobileContainer>
      ),
    },
  ];

  return securityContent;
};