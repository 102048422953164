const flags = {
  features: {
    accountDeletion: false,
    mfa: false,
    subscriptionSettings: false,
    autoFunding: true,
    autoSpendMerchants: false,
    messaging: false,
  },
};

export { flags };