
export const getMessages = args => {
  const { type, payload, callbacks } = args;
  const { token } = payload;

  return {
    type,
    path: '/messages',
    method: 'GET',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    ...callbacks,
  };
};

export const updateMessage = args => {
  const { type, payload, callbacks } = args;
  const { token } = payload;

 return {
    type,
    path: '/messages',
    method: 'PUT',
    body: JSON.stringify(payload),
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    ...callbacks,
  };
};

export const markAllMessagesRead = args => {
  const { type, payload, callbacks } = args;
  const { token } = payload;

 return {
    type,
    path: '/messages-read',
    method: 'PUT',
    body: JSON.stringify(payload),
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    ...callbacks,
  };
};

export const deleteMessage = args => {
  const { type, payload, callbacks } = args;
  const { token } = payload;

 return {
    type,
    path: '/messages',
    method: 'DELETE',
    body: JSON.stringify(payload),
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    ...callbacks,
  };
};

export const sendSupportMessage = args => {
  const { type, payload, callbacks } = args;
  const { token, ...withoutToken } = payload;

  return {
    type,
    path: '/messages/support',
    method: 'POST',
    body: JSON.stringify(withoutToken),
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    ...callbacks,
  };
};