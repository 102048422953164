import { appConstants } from 'modules';
import { getColor } from 'helpers';
import styled from 'styled-components';

const { themes, spacing, breakpoints } = appConstants;
const { mobile, tabletLandscape } = breakpoints;
const { gap, extraSmallGap } = spacing;
const { light } = themes;

export const StyledDetails = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  @media screen and (max-width: ${mobile}rem) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
  }
`;

export const Actions = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const StyledRemoveConfirm = styled('div')`
  max-width: 27rem;
`;

export const RemovedItem = styled('div')`
  display: inline-flex;
`;

export const StyledPaymentMethod = styled('div')`
  display: grid;
  gap: ${extraSmallGap}rem ${gap}rem;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: repeat(2, auto);
  grid-template-areas:
    'icon name actions'
    'icon number actions';

  align-items: center;
`;

export const Icon = styled('div')`
  display: flex;
  grid-area: icon;
  align-items: center;
`;

export const Name = styled('div')`
  display: inline-flex;
  align-items: center;
  grid-area: name;
`;

export const Number = styled('div')`
  grid-area: number;
`;

export const StyledPaymentActions = styled('div')`
  display: inline-flex;
  grid-area: actions;
`;

export const StyledPaymentHistory = styled('div')`
  grid-area: history;
  width: 100%;
  height: fit-content;

  ul {
    border: 0.0625rem solid ${props => {
      const lightTheme = props.$selectedTheme === light;
      return getColor(props, lightTheme ? 'lightGrey' : 'darkGrey');
    }};

    li {
      min-height: 4.125rem;

      &:nth-child(even) {
        @media (hover: hover) {
          &:hover {
            background-color: ${props => getColor(props, 'grey') + 15};
          }
        }
      }

      &:nth-child(odd) {
        background-color: ${props => {
          const lightTheme = props.$selectedTheme === light;
          return getColor(props, lightTheme ? 'offWhite' : 'darkGrey');
        }};

        @media (hover: hover) {
          &:hover {
            background-color: ${props => getColor(props, 'grey') + 15};
          }
        }
      }

      &:first-child {
        background-color: ${props => getColor(props, 'darkGrey')};

        @media (hover: hover) {
          &:hover {
            background-color: ${props => getColor(props, 'darkGrey')};
          }
        }
      }
    }

    li:first-of-type {
      div:first-of-type {
        display: inline-flex;
        gap: ${extraSmallGap}rem;
        align-items: center;
      }
    }
  }

  @media screen and (max-width: ${tabletLandscape}rem) {
    height: ${props => props.$bannerContent ? 'calc(100dvh - 19rem)' : 'calc(100dvh - 16rem)'};
  }
`;

export const DetailsHolder = styled('div')`
  display: flex;
  width: 100%;
  height: inherit;
  flex-direction: column;
  padding: 0 ${gap}rem;
`;