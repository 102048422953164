import { useSelector } from 'react-redux';
import { Plus } from 'components';

const Close = props => {
  const { width, height, color, bgColor } = props;
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);

  return (
    <Plus
      selectedTheme={selectedTheme}
      width={width}
      height={height}
      color={color}
      bgColor={bgColor}
      rotation={45}
    />
  );
};

export { Close };