import _ from 'lodash';

export const handlePlaidLink = args => {
  const { token, atMaxLinked, addNotification, getLinkToken } = args;

  const payload = { token };
  const message = { type: 'warning', message: 'Max institution limit reached.' };

  atMaxLinked ? addNotification?.(message) : getLinkToken?.(payload);
};

export const handlePlaidUpdate = args => {
  const { token, itemId, updatePlaidLink } = args;
  const payload = { token, itemId };

  if (itemId) {
    updatePlaidLink?.(payload);
  }
};

export const formatName = name => {
  // eslint-disable-next-line max-len
  const smallWords = [ 'the', 'an', 'and', 'to', 'for', 'from', 'it', 'its', "it's", 'but', 'a', 'in', 'of', 'so', 'we', 'us', 'me', 'my' ];
  const base = name
    ?.replace('DEBIT FOR', '')
    .replace('CREDIT FOR', '')
    .replace(/[*/]/, ' ')
    .replace(/\//g, '')
    .toLowerCase()
    .trim();
  const simplified = base?.split('co ref')[0];
  const asArray = simplified?.split(' ');
  const noDuplicateObj = !_.isEmpty(asArray) && new Set(asArray);
  const noDuplicates = Array.from(noDuplicateObj).filter(word => word);
  const formatted = noDuplicates.map((word, index) => {
    const beginning = index === 0;
    const end = index === noDuplicates.length - 1;

    if (smallWords.includes(word) && !beginning && !end) {
      return word;
    }

    return _.capitalize(word);
  }).join(' ');

  return formatted;
};

export const institutionByAccountId = args => {
  const { institutionsData, accountId } = args || {};

  return institutionsData?.find(institution => {
    const accounts = institution?.accounts || [];
    const account = accounts?.find(account => account.account_id === accountId);

    if (!_.isEmpty(account)) return institution;
  });
};