import { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { authActions } from 'modules';
import { emailIncrementorCheck } from 'helpers';
import { Font, XymField, XymConfirmButtons } from 'components';
import { Formik, Form } from 'formik';
import * as yup from 'yup';

const UpdateEmailForm = props => {
  const { setConfirmContent } = props;
  const { userInfo, tokenInfo } = useSelector(state => state.auth);
  const [ passwordVisible, setPasswordVisible ] = useState(false);
  const token = tokenInfo?.refreshToken || tokenInfo?.accessToken;
  const dispatch = useDispatch();

  const sendVerificationEmail = useCallback((payload, callbacks) => {
    dispatch(authActions.sendVerificationEmail(payload, callbacks));
  }, [ dispatch ]);

  const defaults = { newEmail: '', password: '' };
  const validationSchema = yup.object().shape({
    password: yup.string().required('Your password is required.').trim(),
    newEmail: yup.string()
      .email('Invalid email.')
      .required('A new email is required.')
      .notOneOf([ userInfo?.email ], 'This is already your email.')
      .test('email-incrementor', 'Email incrementors are not allowed.', value => emailIncrementorCheck(value))
      .trim(),
  });

  const handleSubmit = args => {
    const { values, setSubmitting } = args;
    const { newEmail, password } = values;

    const payload = { newEmail, password, token };
    const callbacks = {
      onSuccess: () => setConfirmContent(null),
      onComplete: () => setSubmitting(false),
    };

    sendVerificationEmail(payload, callbacks);
  };

  return (
    <Formik
      initialValues={defaults}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={(values, handlers) => {
        const { setSubmitting } = handlers;
        handleSubmit({ values, setSubmitting });
      }}
    >
      {form => (
        <Form>
          <XymField
            form={form}
            name='newEmail'
            type='email'
            icon='fa-solid fa-envelope'
            label={<Font size={0.875} weight='semibold'>New email</Font>}
          />

          <XymField
            form={form}
            name='password'
            type={passwordVisible ? 'text' : 'password'}
            icon={passwordVisible ? 'fa-solid fa-eye' : 'fa-solid fa-eye-slash'}
            iconCallback={() => setPasswordVisible(!passwordVisible)}
            label={<Font size={0.875} weight='semibold'>Current password</Font>}
            autoComplete='current-password'
            spacing={1.5}
          />

          <XymConfirmButtons
            form={form}
            confirmText='Verify'
            setConfirmContent={setConfirmContent}
          />
        </Form>
      )}
    </Formik>
  );
};

export { UpdateEmailForm };