import { useSelector } from 'react-redux';
import { Font, XymPrice, XymSpacer } from 'components';
import { BreakdownItem, StyledTypeBreakdown } from './styles';
import { withTheme } from 'styled-components';
import { Percent, Loading } from 'xerum';
import _ from 'lodash';

const TypeBreakdown = withTheme(props => {
  const { theme } = props;

  const {
    generalPreferences: { privacyMode, selectedTheme },
  } = useSelector(state => state.preferences);
  const { expensesAnalysis, expensesDataLoading } = useSelector(state => state.expenses);
  const { expensesData } = useSelector(state => state.expenses);

  const lightGrey = theme.modes[selectedTheme]?.lightGrey;
  const warning = theme.modes[selectedTheme]?.warning;
  const success = theme.modes[selectedTheme]?.success;
  const onPrimary = theme.modes[selectedTheme]?.onPrimary;
  const useColorHealth = false;

  const {
    debtPercentage,
    essentialPercentage,
    personalSpendingPercentage,
    totalExpensesAndGoalsPersonalSpending,
    monthlyExpenses,
    isDti,
  } = expensesAnalysis || {};

  const getBreakdownColor = args => {
    const { percentage, limit } = args;

    if (!useColorHealth) return onPrimary;
    if (privacyMode || !expensesAnalysis || _.isEmpty(expensesData)) return lightGrey;
    if (percentage > limit) return warning;
    if (percentage && (percentage <= limit)) return success;

    return onPrimary;
  };

  const debtColor = getBreakdownColor({ percentage: debtPercentage, limit: 20 });
  const essentialsColor = getBreakdownColor({ percentage: essentialPercentage, limit: 50 });
  const personalSpendingColor = getBreakdownColor({ percentage: personalSpendingPercentage, limit: 30 });

  return (
    <StyledTypeBreakdown>
      <BreakdownItem>
        <XymSpacer size={0.125} />

        <Loading
          theme={theme}
          selectedTheme={selectedTheme}
          isLoading={expensesDataLoading}
          hasData={!_.isEmpty(expensesAnalysis) && !expensesDataLoading}
          failIcon='fa-solid fa-minus'
          column={true}
          noText={true}
          iconSize={!expensesDataLoading && 0.675}
          height='fit-content'
        >
          <Font weight='bold'>
            <XymPrice
              value={monthlyExpenses?.essential}
              size={1}
            /> &nbsp;/ mo <Font weight='regular'>&nbsp;|</Font> &nbsp;

            <Percent
              positiveColor={essentialsColor}
              negativeColor={essentialsColor}
              current={essentialPercentage}
              privacy={privacyMode}
              total={100}
              size={1}
            />
          </Font>
        </Loading>

        <Font weight='semibold' color={onPrimary + 90}>
          Essentials
        </Font>

        <XymSpacer size={0.5} />
      </BreakdownItem>

      <BreakdownItem>
        <XymSpacer size={0.125} />

        <Loading
          theme={theme}
          selectedTheme={selectedTheme}
          isLoading={expensesDataLoading}
          hasData={!_.isEmpty(expensesAnalysis) && !expensesDataLoading}
          failIcon='fa-solid fa-minus'
          column={true}
          noText={true}
          iconSize={!expensesDataLoading && 0.675}
          height='fit-content'
        >
          <Font weight='bold'>
            <XymPrice
              value={monthlyExpenses?.debt}
              size={1}
            /> &nbsp;/ mo <Font weight='regular'>&nbsp;|</Font> &nbsp;

            <Percent
              positiveColor={debtColor}
              negativeColor={debtColor}
              current={debtPercentage}
              privacy={privacyMode}
              total={100} size={1}
            />
          </Font>
        </Loading>

        <Font weight='semibold' color={onPrimary + 90}>
          Debt{isDti ? ' (DTI) ' : ''}
        </Font>

        <XymSpacer size={0.5} />
      </BreakdownItem>

      <BreakdownItem>
        <XymSpacer size={0.125} />

        <Loading
          theme={theme}
          selectedTheme={selectedTheme}
          isLoading={expensesDataLoading}
          hasData={!_.isEmpty(expensesAnalysis) && !expensesDataLoading}
          failIcon='fa-solid fa-minus'
          column={true}
          noText={true}
          iconSize={!expensesDataLoading && 0.675}
          height='fit-content'
        >
          <Font weight='bold'>
            <XymPrice
              value={totalExpensesAndGoalsPersonalSpending}
              size={1}
            /> &nbsp;/ mo <Font weight='regular'>&nbsp;|</Font> &nbsp;

            <Percent
              positiveColor={personalSpendingColor}
              negativeColor={personalSpendingColor}
              current={personalSpendingPercentage}
              privacy={privacyMode}
              total={100}
              size={1}
            />
          </Font>
        </Loading>

        <Font weight='semibold' color={onPrimary + 90}>
          Personal Spending
        </Font>

        <XymSpacer size={0.5} />
      </BreakdownItem>
    </StyledTypeBreakdown>
  );
});

export { TypeBreakdown };