import { appConstants } from 'modules';
import { Details } from 'pages/Subscription/Sections/Details';
import { Payments } from 'pages/Subscription/Sections/Payments';
import { PaymentHistory } from 'pages/Subscription/Sections/PaymentHistory';
import styled from 'styled-components';

const { spacing, layout, breakpoints } = appConstants;
const { mobile } = breakpoints;
const { layoutWidth } = layout;
const { gap } = spacing;

const MobileContainer = styled('div')`
  display: inline-flex;
  gap: ${gap}rem;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  @media screen and (max-width: ${layoutWidth}rem) {
    display: flex;
    flex-direction: column;
    height: calc(100dvh - ${props => props.$height || 21}rem);
  }

  @media screen and (max-width: ${mobile}rem) {
    height: calc(100dvh - ${props => props.$mobileHeight || 23}rem);
  }
`;

export const subscriptionTabs = props => {
  const { bannerContent } = props;

  const subscriptionContent = [
    {
      name: 'Details',
      content: (
        <MobileContainer $mobileHeight={bannerContent ? 19 : 16}>
          <Details />
        </MobileContainer>
      ),
    },
    {
      name: 'Payments',
      content: (
        <MobileContainer $mobileHeight={bannerContent ? 19 : 16}>
          <Payments />
        </MobileContainer>
      ),
    },
    {
      name: 'History',
      content: (
        <MobileContainer $mobileHeight={bannerContent ? 19 : 16}>
          <PaymentHistory />
        </MobileContainer>
      ),
    },
  ];

  return subscriptionContent;
};