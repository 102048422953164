import { useSelector } from 'react-redux';
import { appConstants } from 'modules';
import { Font, NoData, XymSpacer } from 'components';
import { StyledTrustedDevices } from './styles';
import { SecurityDevice } from './SecurityDevice';
import { withTheme } from 'styled-components';
import _ from 'lodash';

const { light } = appConstants.themes;

const TrustedDevices = withTheme(props => {
  const { theme } = props;
  const { generalPreferences: { privacyMode, selectedTheme } } = useSelector(state => state.preferences);
  const { trustedDevices, trustedDevicesLoading, securityDataLoading } = useSelector(state => state.security);
  const darkGrey = theme.modes[selectedTheme]?.darkGrey;
  const offWhite = theme.modes[selectedTheme]?.offWhite;
  const lightTheme = selectedTheme === light;
  const titleColor = lightTheme ? darkGrey : offWhite;

  const buildTrustedDevicesList = () => {
    if (securityDataLoading || trustedDevicesLoading) {
      return <NoData text='Loading trusted devices...' />;
    }

    if (!_.isEmpty(trustedDevices)) {
      return trustedDevices.map((device, index) => {
        const lastItem = index === trustedDevices.length - 1;

        return (
          <SecurityDevice
            key={device?.id || index}
            device={device}
            lastItem={lastItem}
            xymDeviceType='device'
            privacy={privacyMode}
          />
        );
      });
    }

    return (
      <>
        <XymSpacer />
        <NoData
          icon='fa-solid fa-keyboard'
          text='No trusted devices'
          subText='Log in as a trusted device to add it to your list.'
        />
      </>
    );
  };

  return (
    <StyledTrustedDevices>
      <Font weight='semibold' size={1.25} mobileSize={1} tabletSize={1} color={titleColor}>
        Trusted Devices
      </Font>

      <XymSpacer size={0.375} />
      {buildTrustedDevicesList()}
    </StyledTrustedDevices>
  );
});

export { TrustedDevices };