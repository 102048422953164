/* eslint-disable max-len */
import styled from 'styled-components';

const XymLogoSVG = styled('svg')`
  width: ${props => props.$width || 2}rem;
  height: ${props => props.$height || 2}rem;
  fill-rule: evenodd;
  clip-rule:evenodd;
  stroke-linejoin:round;
  stroke-miterlimit:2;
`;

const XymLogo = props => {
  const { width, height, square } = props;
  const charcoal = '#333f47';
  const lightGrey = '#c4cdd4';
  const blue = '#009bf9';
  const yellow = '#f0b800';
  const magenta = '#cd61c2';

  return (
    <XymLogoSVG
      $width={width}
      $height={height}
      viewBox='0 0 512 512'
    >
      {square && (
        <g>
          <path fill={lightGrey} d='M512,32c0,-8.487 -3.371,-16.626 -9.373,-22.627c-6.001,-6.002 -14.14,-9.373 -22.627,-9.373l-447.953,0c-17.699,0 -32.047,14.348 -32.047,32.047l0,447.953c-0,8.487 3.371,16.626 9.373,22.627c6.001,6.002 14.14,9.373 22.627,9.373l448,-0c8.487,0 16.626,-3.371 22.627,-9.373c6.002,-6.001 9.373,-14.14 9.373,-22.627l0,-448Z' />
          <path fill={charcoal} d='M476.405,51.595c0,-8.837 -7.163,-16 -16,-16l-408.81,-0c-8.837,-0 -16,7.163 -16,16l-0,408.81c-0,8.837 7.163,16 16,16l408.81,0c8.837,0 16,-7.163 16,-16l0,-408.81Z' />

          <g>
            <path fill={blue} d='M35.595,308.966l256.373,-0c8.526,-0 15.448,7.911 15.448,17.655l-0,35.31c-0,9.744 -6.922,17.655 -15.448,17.655l-256.373,0l-0,-70.62Z' />
            <path fill={yellow} d='M35.595,220.69l364.335,-0c8.526,-0 15.448,7.911 15.448,17.655l0,35.31c0,9.744 -6.922,17.655 -15.448,17.655l-364.335,0l-0,-70.62Z' />
            <path fill={magenta} d='M35.595,132.414l256.373,-0c8.526,-0 15.448,7.911 15.448,17.655l-0,35.31c-0,9.744 -6.922,17.655 -15.448,17.655l-256.373,0l-0,-70.62Z' />
          </g>
        </g>
      )}

      {!square && (
        <g>
        <circle fill={lightGrey} cx='256' cy='256' r='256' />
        <circle fill={charcoal} cx='256' cy='256' r='208' />

        <g>
          <path fill={blue} d='M88.727,379.586c-15.467,-20.891 -27.116,-44.776 -33.911,-70.62l237.152,-0c8.526,-0 15.448,7.911 15.448,17.655l-0,35.31c-0,9.744 -6.922,17.655 -15.448,17.655l-203.241,0Z' />
          <path fill={yellow} d='M50.991,291.31c-1.967,-11.477 -2.991,-23.275 -2.991,-35.31c0,-12.035 1.024,-23.833 2.991,-35.31l348.939,-0c8.526,-0 15.448,7.911 15.448,17.655l0,35.31c0,9.744 -6.922,17.655 -15.448,17.655l-348.939,0Z' />
          <path fill={magenta} d='M54.816,203.034c6.795,-25.844 18.444,-49.729 33.911,-70.62l203.241,-0c8.526,-0 15.448,7.911 15.448,17.655l-0,35.31c-0,9.744 -6.922,17.655 -15.448,17.655l-237.152,0Z' />
        </g>
      </g>
      )}
    </XymLogoSVG>
  );
};

export { XymLogo };

