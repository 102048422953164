import { XymSpacer } from 'components';
import styled, { css } from 'styled-components';

const StyledFootnote = styled('div')`
  width: 100%;
  font-size: 0.75rem;

  ${props => props.$maxWidth && css`
    max-width: ${props.$maxWidth}rem;
  `}
`;

const Footnote = props => {
  const { title, titleBreak, breakSpacing, text, maxWidth, plural, children } = props;

  return (
    <StyledFootnote $maxWidth={maxWidth}>
      <strong>{title || `Note${plural ? 's' : ''}:`}</strong>&nbsp;

      {titleBreak && (
        <>
          <br />
          <XymSpacer size={breakSpacing || 0.5} />
        </>
      )}

      {text || children}
    </StyledFootnote>
  );
};

export { Footnote };