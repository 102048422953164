import { appConstants } from 'modules';
import { Form } from 'formik';
import styled from 'styled-components';

const { spacing, breakpoints } = appConstants;
const { mobile } = breakpoints;
const { gap } = spacing;

export const StyledForm = styled(Form)`
  display: grid;
  gap: ${gap}rem;
  grid-template-rows: repeat(3, auto);
  grid-template-areas:
    'personal'
    'card'
    'billing';

  max-width: 30.5rem;

  @media screen and (max-width: ${mobile}rem) {
    max-width: unset;
  }
`;

export const PersonalFields = styled('div')`
  display: inline-flex;
  gap: ${gap}rem;

  @media screen and (max-width: ${mobile}rem) {
    display: flex;
    flex-direction: column;
  }
`;

export const CardFields = styled('div')`
  display: grid;
  gap: ${gap}rem;
  grid-template-columns: 1fr 5rem;

  @media screen and (max-width: ${mobile}rem) {
    grid-template-columns: 1fr;
  }
`;

export const CardDetailFields = styled('div')`
  display: grid;
  gap: ${gap}rem;
  grid-template-columns: 13rem repeat(2, 1fr);

  @media screen and (max-width: ${mobile}rem) {
    grid-template-columns: 1fr;
  }
`;

export const AddressFields = styled('div')`
  display: grid;
  gap: ${gap}rem;
  grid-template-columns: 1fr 10rem;

  @media screen and (max-width: ${mobile}rem) {
    grid-template-columns: 1fr;
  }
`;

export const PostalFields = styled('div')`
  display: grid;
  gap: ${gap}rem;
  grid-template-columns: 1fr 10rem;
  grid-template-rows: repeat(2, auto);

  @media screen and (max-width: ${mobile}rem) {
    grid-template-columns: 1fr;
  }
`;

export const DefaultToggle = styled('div')`
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;