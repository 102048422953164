import { preferencesConstants } from 'modules/preferences/preferencesConstants';
import { appConstants } from 'modules';
import { getLocalStorageSetting, request, updateLocalStorage } from 'helpers';
import StateManager from 'state-wrangler';

const { actions, selectors } = preferencesConstants;
const { themes, names: { selectedThemeName } } = appConstants;
const existingSelectedTheme = getLocalStorageSetting(selectedThemeName) || themes.light;

const initial = {
  [selectors.STATE_KEY_GENERAL_PREFERENCES]: { privacyMode: true, selectedTheme: existingSelectedTheme },
  [selectors.STATE_KEY_ANALYSIS_PREFERENCES]: { contractor: false, taxBracketId: null },
  [selectors.STATE_KEY_TRENDS_PREFERENCES]: { yearToDate: false },
  [selectors.STATE_KEY_UPCOMING_PREFERENCES]: { rangeId: null },
  [selectors.STATE_KEY_GOALS_PREFERENCES]: { sortId: null },
  [selectors.STATE_KEY_EXPENSES_PREFERENCES]: { sortId: null },
};

const preferencesReducer = (initialState = initial, action = {}) => {
  const { payload } = action;
  const state = new StateManager(initialState);

  switch(action.type) {
    // =============== Get preferences ===============
    // Get all user preferences
    case request(actions.GET_USER_PREFERENCES).start:
      return state.update(selectors.STATE_KEY_PREFERENCES_LOADING, true);

    case request(actions.GET_USER_PREFERENCES).success:
      updateLocalStorage(selectedThemeName, payload.preferences.general.selectedTheme);

      return state.merge([
        { method: 'update', key: selectors.STATE_KEY_GENERAL_PREFERENCES, payload: payload.preferences.general },
        { method: 'update', key: selectors.STATE_KEY_EXPENSES_PREFERENCES, payload: payload.preferences.expenses },
        { method: 'update', key: selectors.STATE_KEY_GOALS_PREFERENCES, payload: payload.preferences.goals },
        {
          method: 'update',
          key: selectors.STATE_KEY_ANALYSIS_PREFERENCES,
          payload: payload.preferences.overview.analysis,
        },
        {
          method: 'update',
          key: selectors.STATE_KEY_UPCOMING_PREFERENCES,
          payload: payload.preferences.overview.upcoming,
        },
        { method: 'update', key: selectors.STATE_KEY_TRENDS_PREFERENCES, payload: payload.preferences.overview.trends },
        {
          method: 'update',
          key: selectors.STATE_KEY_TRANSACTION_PREFERENCES,
          payload: payload.preferences.overview.transactions,
        },
      ]);

    case request(actions.GET_USER_PREFERENCES).complete:
      return state.update(selectors.STATE_KEY_PREFERENCES_LOADING, false);



    // =============== Update preferences ===============
    // Update all user preferences
    case request(actions.UPDATE_USER_PREFERENCES).start:
      return state.update(selectors.STATE_KEY_PREFERENCES_LOADING, true);

    case request(actions.UPDATE_USER_PREFERENCES).success:
      return state.merge([
        { method: 'update', key: selectors.STATE_KEY_GENERAL_PREFERENCES, payload: payload.preferences.general },
        { method: 'update', key: selectors.STATE_KEY_EXPENSES_PREFERENCES, payload: payload.preferences.expenses },
        { method: 'update', key: selectors.STATE_KEY_GOALS_PREFERENCES, payload: payload.preferences.goals },
        {
          method: 'update',
          key: selectors.STATE_KEY_ANALYSIS_PREFERENCES,
          payload: payload.preferences.overview.analysis,
        },
        {
          method: 'update',
          key: selectors.STATE_KEY_UPCOMING_PREFERENCES,
          payload: payload.preferences.overview.upcoming,
        },
        {
          method: 'update',
          key: selectors.STATE_KEY_TRENDS_PREFERENCES,
          payload: payload.preferences.overview.trends,
        },
        {
          method: 'update',
          key: selectors.STATE_KEY_TRANSACTION_PREFERENCES,
          payload: payload.preferences.overview.transactions,
        },
      ]);

    case request(actions.UPDATE_USER_PREFERENCES).complete:
      return state.update(selectors.STATE_KEY_PREFERENCES_LOADING, false);



    // =============== Single preferences updates ===============
    // Update privacy mode
    case actions.SET_PRIVACY_MODE:
      return state.update(selectors.STATE_KEY_GENERAL_PREFERENCES, payload, 'privacyMode');

    // Update selected theme
    case actions.SET_SELECTED_THEME:
      updateLocalStorage(selectedThemeName, payload);
      return state.update(selectors.STATE_KEY_GENERAL_PREFERENCES, payload, 'selectedTheme');



    default:
      return initialState;
  }
};

export { preferencesReducer };