import { appConstants } from 'modules';
import { getColor } from 'helpers';
import styled, { css } from 'styled-components';

const { breakpoints, themes, spacing } = appConstants;
const { gap, mediumGap } = spacing;
const { mobile } = breakpoints;
const { light } = themes;

export const StyledMobileNav = styled('div')`
  display: ${props => props.$visible ? 'flex' : 'none'};
  position: absolute;
  z-index: 3;

  ${props => props.$mobileMode ? css`bottom: 0;` : css`top: 0;`}

  left: 0;
  height: 100dvh;
  width: 100%;
  max-width: ${props => props.$mobileMode ? '19rem' : '23rem'};
  padding: 1.75rem 2.5rem 2.5rem;
  overflow-y: auto;

  background-color: ${props => {
    const lightTheme = props.$selectedTheme === light;
    return getColor(props, lightTheme ? 'secondary' : 'primary');
  }};

  border: 0.0625rem solid ${props => {
    const lightTheme = props.$selectedTheme === light;
    return getColor(props, lightTheme ? 'lightGrey' : 'darkGrey');
  }};

  box-shadow: 0 0.25rem 0.5rem ${props => getColor(props, 'darkGrey')};

  @media screen and (max-width: ${mobile}rem) {
    width: 100dvw;
    padding: 1.25rem ${gap}rem ${mediumGap}rem ${gap}rem;
  }
`;

export const UserName = styled('div')`
  position: relative;
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 0;
  grid-area: username;
`;

export const FooterNav = styled('div')`
  display: flex;
  flex-direction: column;
  height: fit-content;
`;

export const FooterInfo = styled('div')`
  display: ${props => props.$visible ? 'inline-flex' : 'none'};
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const CloseHolder = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  right: -1rem;
  min-width: 3rem;
  min-height: 3rem;
  grid-area: close;
  cursor: pointer;

  @media screen and (max-width: ${mobile}rem) {
    right: -1rem;
  }
`;

export const Background = styled('div')`
  display: ${props => props.$visible ? 'flex' : 'none'};
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  height: 100dvh;
  width: 100dvw;
  background-color: ${props => getColor(props, 'black') + 90};
`;

export const UserNameArea = styled('div')`
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 1fr auto;
  grid-template-areas:
    'username close';
`;

export const Accounts = styled('div')`
  display: ${props => props.$visible ? 'flex' : 'none'};
  flex-direction: column;
  gap: 0.25rem;
  grid-area: accounts;
`;

export const Account = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 3rem;
  padding: 0.5rem 1rem;
  border-radius: 0.125rem;
  gap: 0.75rem;
  cursor: pointer;
  user-select: none;
  color: ${props => getColor(props, props.$active ? 'onAccent' : 'onPrimary')};
  background-color: ${props => {
    const active = props.$active;
    const lightTheme = props.$selectedTheme === light;
    const color = getColor(props, lightTheme ? 'onPrimary' : 'lightGrey');

    return active ? color : 'transparent';
  }};

  span {
    display: flex;
    align-items: center;
    min-width: 5rem;
  }

  span:last-child {
    justify-content: flex-end;
  }

  @media (hover: hover) {
    &:hover {
      color: ${props => !props.$active && getColor(props, 'onPrimary')};
      background-color: ${props => {
        const lightTheme = props.$selectedTheme === light;
        return !props.$active && getColor(props, lightTheme ? 'lightGrey' : 'darkGrey') + (lightTheme ? 50 : '');
      }};
    }
  }
`;