/* eslint-disable max-len */
import { useSelector } from 'react-redux';
import { getColor } from 'helpers';
import styled, { withTheme } from 'styled-components';

const UserIconSVG = styled('svg')`
  width: ${props => props.$width || 1}rem;
  height: ${props => props.$height || 1}rem;
  fill: ${props => props.color || getColor(props, 'onPrimary')};
`;

const UserIcon = withTheme(props => {
  const { theme, color, width, height, direction } = props;
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);

  return (
    <UserIconSVG
      $theme={theme}
      $selectedTheme={selectedTheme}
      $width={width}
      $height={height}
      $direction={direction}
      color={color}
      viewBox='0 0 256 256'
      fill='currentColor'
    >
      <path d='M128,0c35.451,0 64.233,28.782 64.233,64.233c-0,35.451 -28.782,64.233 -64.233,64.233c-35.451,-0 -64.233,-28.782 -64.233,-64.233c0,-35.451 28.782,-64.233 64.233,-64.233Zm80.577,256l-161.137,-0c-20.442,-0 -32.841,-16.42 -32.841,-32.506l0,-35.186c0,-10.724 10.389,-43.9 44.235,-57.304c-0,-0 20.43,29.824 69.195,29.824c48.739,0 69.16,-29.824 69.16,-29.824c33.829,13.404 44.212,46.58 44.212,57.304l-0,35.186c-0,16.086 -12.393,32.506 -32.824,32.506Z' />
    </UserIconSVG>
  );
});

export { UserIcon };