import { expensesConstants } from 'modules/expenses/expensesConstants';
import { request } from 'helpers';
import StateManager from 'state-wrangler';

const { actions, selectors } = expensesConstants;

const initial = {
  [selectors.STATE_KEY_SAFE_BALANCE]: 0,
  [selectors.STATE_KEY_TOTAL_FUNDS]: 0,
  [selectors.STATE_KEY_PENDING]: 0,
  [selectors.STATE_KEY_IN_EXPENSES]: 0,
  [selectors.STATE_KEY_IN_GOALS]: 0,
};

const expensesReducer = (initialState = initial, action = {}) => {
  const { payload, meta } = action;
  const state = new StateManager(initialState);

  switch(action.type) {
    // =============== Balances ===============
    case request(actions.GET_BALANCES).success:
      return state.merge([
        { method: 'update', key: selectors.STATE_KEY_SAFE_BALANCE, payload: payload?.balances.safeBalance },
        { method: 'update', key: selectors.STATE_KEY_TOTAL_FUNDS, payload: payload?.balances.totalFunds },
        { method: 'update', key: selectors.STATE_KEY_PENDING, payload: payload?.balances.pending },
        { method: 'update', key: selectors.STATE_KEY_IN_EXPENSES, payload: payload?.balances.inExpenses },
        { method: 'update', key: selectors.STATE_KEY_IN_GOALS, payload: payload?.balances.inGoals },
      ]);

    case actions.RESET_BALANCES:
      return state.merge([
        { method: 'update', key: selectors.STATE_KEY_SAFE_BALANCE, payload: 0 },
        { method: 'update', key: selectors.STATE_KEY_TOTAL_FUNDS, payload: 0 },
        { method: 'update', key: selectors.STATE_KEY_PENDING, payload: 0 },
        { method: 'update', key: selectors.STATE_KEY_IN_EXPENSES, payload: 0 },
        { method: 'update', key: selectors.STATE_KEY_IN_GOALS, payload: 0 },
      ]);



    // =============== Expenses ===============
    // Get all expenses data, including auto fund sources
    case request(actions.GET_EXPENSES_DATA).start:
      return state.update(selectors.STATE_KEY_EXPENSES_DATA_LOADING, !meta?.noLoadingState);

    case request(actions.GET_EXPENSES_DATA).success:
      return state.merge([
        { method: 'update', key: selectors.STATE_KEY_EXPENSES_DATA, payload: payload?.expenses },
        { method: 'update', key: selectors.STATE_KEY_AUTO_FUND_SOURCES, payload: payload?.autoFundSources },
        { method: 'update', key: selectors.STATE_KEY_EXPENSES_ANALYSIS, payload: payload?.analysis },
        { method: 'update', key: selectors.STATE_KEY_SAFE_BALANCE, payload: payload?.balances.safeBalance },
        { method: 'update', key: selectors.STATE_KEY_TOTAL_FUNDS, payload: payload?.balances.totalFunds },
        { method: 'update', key: selectors.STATE_KEY_PENDING, payload: payload?.balances.pending },
        { method: 'update', key: selectors.STATE_KEY_IN_EXPENSES, payload: payload?.balances.inExpenses },
        { method: 'update', key: selectors.STATE_KEY_IN_GOALS, payload: payload?.balances.inGoals },
      ]);

    case request(actions.GET_EXPENSES_DATA).complete:
      return state.update(selectors.STATE_KEY_EXPENSES_DATA_LOADING, false);



    // Add expense
    case request(actions.ADD_EXPENSE).start:
      return state.update(selectors.STATE_KEY_EXPENSES_DATA_LOADING, !meta?.noLoadingState);

    case request(actions.ADD_EXPENSE).success:
      return state.merge([
        { method: 'update', key: selectors.STATE_KEY_EXPENSES_DATA, payload: payload?.expenses },
        { method: 'update', key: selectors.STATE_KEY_AUTO_FUND_SOURCES, payload: payload?.autoFundSources },
        { method: 'update', key: selectors.STATE_KEY_EXPENSES_ANALYSIS, payload: payload?.analysis },
        { method: 'update', key: selectors.STATE_KEY_SAFE_BALANCE, payload: payload?.balances.safeBalance },
        { method: 'update', key: selectors.STATE_KEY_TOTAL_FUNDS, payload: payload?.balances.totalFunds },
        { method: 'update', key: selectors.STATE_KEY_PENDING, payload: payload?.balances.pending },
        { method: 'update', key: selectors.STATE_KEY_IN_EXPENSES, payload: payload?.balances.inExpenses },
        { method: 'update', key: selectors.STATE_KEY_IN_GOALS, payload: payload?.balances.inGoals },
      ]);

    case request(actions.ADD_EXPENSE).complete:
      return state.update(selectors.STATE_KEY_EXPENSES_DATA_LOADING, false);



    // Update expense
    case request(actions.UPDATE_EXPENSE).start:
      return state.update(selectors.STATE_KEY_EXPENSES_DATA_LOADING, !meta?.noLoadingState);

    case request(actions.UPDATE_EXPENSE).success:
      return state.merge([
        { method: 'update', key: selectors.STATE_KEY_EXPENSES_DATA, payload: payload?.expenses },
        { method: 'update', key: selectors.STATE_KEY_AUTO_FUND_SOURCES, payload: payload?.autoFundSources },
        { method: 'update', key: selectors.STATE_KEY_EXPENSES_ANALYSIS, payload: payload?.analysis },
        { method: 'update', key: selectors.STATE_KEY_SAFE_BALANCE, payload: payload?.balances.safeBalance },
        { method: 'update', key: selectors.STATE_KEY_TOTAL_FUNDS, payload: payload?.balances.totalFunds },
        { method: 'update', key: selectors.STATE_KEY_PENDING, payload: payload?.balances.pending },
        { method: 'update', key: selectors.STATE_KEY_IN_EXPENSES, payload: payload?.balances.inExpenses },
        { method: 'update', key: selectors.STATE_KEY_IN_GOALS, payload: payload?.balances.inGoals },
      ]);

    case request(actions.UPDATE_EXPENSE).complete:
      return state.update(selectors.STATE_KEY_EXPENSES_DATA_LOADING, false);



    // Remove expense
    case request(actions.REMOVE_EXPENSE).start:
      return state.update(selectors.STATE_KEY_EXPENSES_DATA_LOADING, !meta?.noLoadingState);

    case request(actions.REMOVE_EXPENSE).success:
      return state.merge([
        { method: 'update', key: selectors.STATE_KEY_EXPENSES_DATA, payload: payload?.expenses },
        { method: 'update', key: selectors.STATE_KEY_AUTO_FUND_SOURCES, payload: payload?.autoFundSources },
        { method: 'update', key: selectors.STATE_KEY_EXPENSES_ANALYSIS, payload: payload?.analysis },
        { method: 'update', key: selectors.STATE_KEY_SAFE_BALANCE, payload: payload?.balances.safeBalance },
        { method: 'update', key: selectors.STATE_KEY_TOTAL_FUNDS, payload: payload?.balances.totalFunds },
        { method: 'update', key: selectors.STATE_KEY_PENDING, payload: payload?.balances.pending },
        { method: 'update', key: selectors.STATE_KEY_IN_EXPENSES, payload: payload?.balances.inExpenses },
        { method: 'update', key: selectors.STATE_KEY_IN_GOALS, payload: payload?.balances.inGoals },
      ]);

    case request(actions.REMOVE_EXPENSE).complete:
      return state.update(selectors.STATE_KEY_EXPENSES_DATA_LOADING, false);



    // Rebalance expense
    case request(actions.REBALANCE_EXPENSE).start:
      return state.update(selectors.STATE_KEY_EXPENSES_DATA_LOADING, !meta?.noLoadingState);

    case request(actions.REBALANCE_EXPENSE).success:
      return state.merge([
        { method: 'update', key: selectors.STATE_KEY_EXPENSES_DATA, payload: payload?.expenses },
        { method: 'update', key: selectors.STATE_KEY_EXPENSES_ANALYSIS, payload: payload?.analysis },
        { method: 'update', key: selectors.STATE_KEY_SAFE_BALANCE, payload: payload?.safeBalance },
        { method: 'update', key: selectors.STATE_KEY_SAFE_BALANCE, payload: payload?.balances.safeBalance },
        { method: 'update', key: selectors.STATE_KEY_TOTAL_FUNDS, payload: payload?.balances.totalFunds },
        { method: 'update', key: selectors.STATE_KEY_PENDING, payload: payload?.balances.pending },
        { method: 'update', key: selectors.STATE_KEY_IN_EXPENSES, payload: payload?.balances.inExpenses },
        { method: 'update', key: selectors.STATE_KEY_IN_GOALS, payload: payload?.balances.inGoals },
      ]);

    case request(actions.REBALANCE_EXPENSE).complete:
      return state.update(selectors.STATE_KEY_EXPENSES_DATA_LOADING, false);



    // Import expenses
    case request(actions.IMPORT_EXPENSES).start:
      return state.update(selectors.STATE_KEY_EXPENSES_IMPORTING, true);

    case request(actions.IMPORT_EXPENSES).success:
      return state.merge([
        { method: 'update', key: selectors.STATE_KEY_EXPENSES_DATA, payload: payload?.expenses },
        { method: 'update', key: selectors.STATE_KEY_AUTO_FUND_SOURCES, payload: payload?.autoFundSources },
        { method: 'update', key: selectors.STATE_KEY_EXPENSES_ANALYSIS, payload: payload?.analysis },
        { method: 'update', key: selectors.STATE_KEY_SAFE_BALANCE, payload: payload?.balances.safeBalance },
        { method: 'update', key: selectors.STATE_KEY_TOTAL_FUNDS, payload: payload?.balances.totalFunds },
        { method: 'update', key: selectors.STATE_KEY_PENDING, payload: payload?.balances.pending },
        { method: 'update', key: selectors.STATE_KEY_IN_EXPENSES, payload: payload?.balances.inExpenses },
        { method: 'update', key: selectors.STATE_KEY_IN_GOALS, payload: payload?.balances.inGoals },
      ]);

    case request(actions.IMPORT_EXPENSES).complete:
      return state.update(selectors.STATE_KEY_EXPENSES_IMPORTING, false);



    // Clear analysis data
    case actions.CLEAR_ANALYSIS_DATA:
      return state.update(selectors.STATE_KEY_EXPENSES_ANALYSIS, null);




    // =============== Auto-funding Sources ===============
    // Get auto-fund sources
    case request(actions.GET_AUTO_FUND_SOURCES).start:
      return state.update(selectors.STATE_KEY_AUTO_FUND_SOURCES_LOADING, true);

    case request(actions.GET_AUTO_FUND_SOURCES).success:
      return state.update(selectors.STATE_KEY_AUTO_FUND_SOURCES, payload?.autoFundSources);

    case request(actions.GET_AUTO_FUND_SOURCES).complete:
      return state.update(selectors.STATE_KEY_AUTO_FUND_SOURCES_LOADING, false);



    // Add auto-fund source
    case request(actions.ADD_AUTO_FUND_SOURCE).start:
      return state.update(selectors.STATE_KEY_AUTO_FUND_SOURCES_LOADING, true);

    case request(actions.ADD_AUTO_FUND_SOURCE).success:
      return state.update(selectors.STATE_KEY_AUTO_FUND_SOURCES, payload?.autoFundSources);

    case request(actions.ADD_AUTO_FUND_SOURCE).complete:
      return state.update(selectors.STATE_KEY_AUTO_FUND_SOURCES_LOADING, false);



    // Update auto-fund source
    case request(actions.UPDATE_AUTO_FUND_SOURCE).start:
      return state.update(selectors.STATE_KEY_AUTO_FUND_SOURCES_LOADING, true);

    case request(actions.UPDATE_AUTO_FUND_SOURCE).success:
      return state.update(selectors.STATE_KEY_AUTO_FUND_SOURCES, payload?.autoFundSources);

    case request(actions.UPDATE_AUTO_FUND_SOURCE).complete:
      return state.update(selectors.STATE_KEY_AUTO_FUND_SOURCES_LOADING, false);



    // Remove auto-fund source
    case request(actions.REMOVE_AUTO_FUND_SOURCE).start:
      return state.update(selectors.STATE_KEY_AUTO_FUND_SOURCES_LOADING, true);

    case request(actions.REMOVE_AUTO_FUND_SOURCE).success:
      return state.merge([
        { method: 'update', key: selectors.STATE_KEY_AUTO_FUND_SOURCES, payload: payload?.autoFundSources },
        { method: 'update', key: selectors.STATE_KEY_EXPENSES_DATA, payload: payload?.expenses },
      ]);

    case request(actions.REMOVE_AUTO_FUND_SOURCE).complete:
      return state.update(selectors.STATE_KEY_AUTO_FUND_SOURCES_LOADING, false);



    // =============== Misc Data ===============
    // Get misc data (Categories, colors, frequencies etc.)
    case request(actions.GET_MISC_DATA).success:
      return state.update(selectors.STATE_KEY_MISC_DATA, payload);



    // =============== General ===============
    case actions.SET_SLIDE_OVER_EXPENSE_ID:
      return state.update(selectors.STATE_KEY_SLIDE_OVER_EXPENSE_ID, payload);

    default:
      return initialState;
  }
};

export { expensesReducer };