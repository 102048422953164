import { appConstants } from 'modules';
import styled, { css } from 'styled-components';

const { layout, spacing, breakpoints } = appConstants;
const { layoutWidth, settingsNavWidth } = layout;
const { extraLargeGap, mediumGap, gap } = spacing;
const { tabletLandscape, mobile } = breakpoints;

export const StyledSecurity = styled('div')`
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const Layout = styled('div')`
  display: grid;
  grid-template-columns: ${settingsNavWidth}rem 1fr;
  gap: 0 ${extraLargeGap}rem;
  max-width: ${layoutWidth}rem;
  width: 100%;
  height: 100%;

  @media screen and (max-width: ${layoutWidth}rem) {
    gap: 0 ${mediumGap}rem;
  }

  @media screen and (max-width: ${tabletLandscape}rem) {
    display: none;
  }
`;

export const MobileLayout = styled('div')`
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
  padding: ${mediumGap}rem;
  overflow-y: auto;

  @media screen and (max-width: ${tabletLandscape}rem) {
    display: flex;
    padding: ${mediumGap}rem ${mediumGap}rem ${gap}rem ${mediumGap}rem;
  }

  @media screen and (max-width: ${mobile}rem) {
    padding: ${gap}rem;
    width: 100dvw;
  }
`;

export const SecurityContent = styled('div')`
  display: grid;
  gap: ${extraLargeGap}rem;
  grid-template-columns:
    repeat(
      ${props => props.$mfa ? 3 : 2},
      ${props => props.$mfa ? `${settingsNavWidth}rem` : '1fr'}
    );
  grid-template-rows: auto 1fr;

  ${props => {
    if (props.$mfa) {
      return css`
        grid-template-areas:
          'password mfa devices'
          'history history history';
      `;
    }

    return css`
      grid-template-areas:
        'password devices'
        'history history';
    `;
  }}

  max-width: ${layoutWidth}rem;
  padding: ${extraLargeGap}rem ${extraLargeGap}rem ${extraLargeGap}rem 0;
  overflow: auto;
  width: 100%;

  @media screen and (max-width: ${layoutWidth}rem) {
    padding: ${mediumGap}rem ${mediumGap}rem ${mediumGap}rem 0;
  }

  @media screen and (max-width: ${tabletLandscape}rem) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(${props => props.$mfa ? 4 : 3}, auto);

    ${props => {
      if (props.$mfa) {
        return css`
          grid-template-areas:
            'password'
            'mfa'
            'devices'
            'history';
        `;
      }

      return css`
        grid-template-areas:
          'password'
          'devices'
          'history';
      `;
    }}

    max-width: unset;
    padding-right: ${mediumGap}rem;
    height: calc(100dvh - 14rem);

    form {
      max-width: unset;
    }
  }

  @media screen and (max-width: ${tabletLandscape}rem) {
    padding: ${mediumGap}rem;
  }

  @media screen and (max-width: ${mobile}rem) {
    padding: 1rem;
  }
`;