import { useSelector } from 'react-redux';
import { H3, WaitlistForm, XymSpacer } from 'components';
import { withTheme } from 'styled-components';
import { StyledWaitlist, Layout, AppSummary, FormArea, MobileFormArea } from './Sections/styles';
import {
  AboutTheApp,
  AboutExpenses,
  AboutAutoFunding,
  AboutMovingMoney,
  AboutGoals,
  AboutTransactions,
  AboutTransactionNames,
  AboutAutomation,
  AboutTransactionDetails,
  AboutTrends,
  AboutUpcomingAndProgress,
} from './Sections';

const Waitlist = withTheme(props => {
  const { theme } = props;
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);

  return (
    <StyledWaitlist $theme={theme} $selectedTheme={selectedTheme}>
      <Layout>
        <AppSummary>
          <AboutTheApp />
          <XymSpacer size={8} />

          <AboutExpenses />
          <XymSpacer size={6} />

          <AboutAutoFunding />
          <XymSpacer size={6} />

          <AboutMovingMoney />
          <XymSpacer size={6} />

          <AboutGoals />
          <XymSpacer size={8} />

          <AboutTransactions />
          <XymSpacer size={2} />

          <AboutTransactionNames />
          <XymSpacer size={6} />

          <AboutAutomation />
          <XymSpacer size={6} />

          <AboutTransactionDetails />
          <XymSpacer size={8} />

          <AboutTrends />
          <XymSpacer size={8} />

          <AboutUpcomingAndProgress />

          <MobileFormArea>
            <XymSpacer size={8} />
            <WaitlistForm />
          </MobileFormArea>
        </AppSummary>

        <FormArea>
          <H3>
            A refreshing way to manage your personal finances.
            <XymSpacer size={2} />
          </H3>
          <WaitlistForm />
        </FormArea>
      </Layout>
    </StyledWaitlist>
  );
});

export { Waitlist };