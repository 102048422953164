import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { messagesActions } from 'modules';
import { Font, XymField } from 'components';
import { Formik, Form } from 'formik';
import { Button } from 'xerum';
import { withTheme } from 'styled-components';
import * as yup from 'yup';

const SupportForm = withTheme(props => {
  const { theme } = props;
  const { tokenInfo } = useSelector(state => state.auth);
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);

  const dispatch = useDispatch();
  const token = tokenInfo?.refreshToken || tokenInfo?.accessToken;
  const defaults = { subject: '', message: '' };
  const validationSchema = yup.object().shape({
    subject: yup.string().required('Subject is required.').trim(),
    message: yup.string().required('Message is required.').trim(),
  });

  const sendSupportMessage = useCallback((payload, callbacks) => {
    dispatch(messagesActions.sendSupportMessage(payload, callbacks));
  }, [ dispatch ]);

  const handleSubmit = args => {
    const { values, setSubmitting, resetForm } = args;
    const { subject, message } = values;

    const callbacks = {
      onSuccess: () => resetForm(),
      onComplete: () => setSubmitting(false),
    };

    const payload = {
      token,
      subject,
      message,
    };

    sendSupportMessage(payload, callbacks);
  };

  return (
    <Formik
      initialValues={defaults}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={(values, handlers) => {
        const { setSubmitting, resetForm } = handlers;
        handleSubmit({ values, setSubmitting, resetForm });
      }}
    >
      {form => (
        <Form>
          <div>
            <XymField
              form={form}
              name='subject'
              label={<Font size={0.875} weight='semibold'>Subject</Font>}
              spacing={1.5}
            />

            <XymField
              form={form}
              name='message'
              textarea={true}
              textareaRows={8}
              placeholder='Feedback, bug, or question...'
              label={<Font size={0.875} weight='semibold'>Message</Font>}
              spacing={1.5}
            />
          </div>

          <Button
            theme={theme}
            selectedTheme={selectedTheme}
            type='submit'
            disabled={form.isSubmitting}
            callback={form.handleSubmit}
            text={
              <Font weight='medium' mobileSize={0.875}>
                {!form.isSubmitting && 'Send message'}
                {form.isSubmitting && 'Sending message...'}
              </Font>
            }
          />
        </Form>
      )}
    </Formik>
  );
});

export { SupportForm };