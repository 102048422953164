/* eslint-disable max-len */
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { appConstants } from 'modules';
import { H1, H4, Font, XymSpacer, WaitlistRemoveForm } from 'components';
import { FormArea } from './Sections/styles';
import { Link } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';

const { themes, spacing } = appConstants;
const { largeGap } = spacing;
const { light } = themes;

const StyledWaitlistRemove = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100dvh;
`;

const RemoveArea = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: fit-content;
  width: fit-content;
  padding: ${largeGap}rem;
  border-radius: 0.5rem;

  #emoji {
    // Keyframe animation to slow move emoji up and down
    animation: bounce 2s infinite;
    animation-timing-function: ease-in-out;

    @keyframes bounce {
      0% { transform: translateY(0); }
      50% { transform: translateY(-0.5rem); }
      100% { transform: translateY(0); }
    }
  }

  h3 {
    text-align: center;
  }
`;

const TwemojiSadButRelievedFace = props => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='6rem' height='6rem' viewBox='0 0 36 36' {...props}>
      <path fill='#FFCC4D' d='M36 18c0 9.941-8.059 18-18 18c-9.94 0-18-8.059-18-18C0 8.06 8.06 0 18 0c9.941 0 18 8.06 18 18' />
      <ellipse cx='11.5' cy='16.5' fill='#664500' rx='2.5' ry='3.5' />
      <ellipse cx='24.5' cy='16.5' fill='#664500' rx='2.5' ry='3.5' />
      <path fill='#664500' d='M23.485 27.879C23.474 27.835 22.34 23.5 18 23.5s-5.474 4.335-5.485 4.379a.496.496 0 0 0 .232.544a.51.51 0 0 0 .596-.06c.009-.007 1.013-.863 4.657-.863c3.59 0 4.617.83 4.656.863a.496.496 0 0 0 .59.073a.5.5 0 0 0 .239-.557' />
      <path fill='#5DADEC' d='M10 30c0 2.762-2.238 5-5 5s-5-2.238-5-5s4-10 5-10s5 7.238 5 10' />
      <path fill='#664500' d='M30 13c-5.554 0-7.802-4.367-7.895-4.553a1 1 0 0 1 1.787-.899C23.967 7.694 25.713 11 30 11a1 1 0 1 1 0 2M6 13a1 1 0 0 1 0-2c5.083 0 5.996-3.12 6.033-3.253c.145-.528.69-.848 1.219-.709c.53.139.851.673.718 1.205C13.921 8.437 12.704 13 6 13' />
    </svg>
  );
};

const WaitlistRemove = withTheme(props => {
  const { theme } = props;
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);

  const [ removed, setRemoved ] = useState(false);

  const lightTheme = selectedTheme === light;
  const offWhite = theme.modes[selectedTheme]?.offWhite;
  const darkGrey = theme.modes[selectedTheme]?.darkGrey;
  const accent = theme.modes[selectedTheme]?.accent;
  const titleColor = lightTheme ? darkGrey : offWhite;

  useEffect(() => {
    return () => setRemoved(false);
  }, [ setRemoved ]);

  const renderRemoved = () => {
    if (removed) {
      return (
        <StyledWaitlistRemove>
          <RemoveArea $theme={theme} $selectedTheme={selectedTheme}>
            <TwemojiSadButRelievedFace id='emoji' />

            <XymSpacer />

            <H1 color={accent}>Sorry to see you go...</H1>

            <XymSpacer size={0.5} />

            <H4 color={titleColor}>
              You&apos;ve been removed from the waitlist.
            </H4>

            <Font>
              <Link to='/waitlist'>Sign up</Link>&nbsp;
              again, if you change your mind.
            </Font>
          </RemoveArea>
        </StyledWaitlistRemove>
      );
    }

    return (
      <FormArea>
        <XymSpacer size={4} />
        <WaitlistRemoveForm setRemoved={setRemoved} />
      </FormArea>
    );
  };

  return renderRemoved();
});

export { WaitlistRemove };