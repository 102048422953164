import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { appConstants } from 'modules';
import { formatName } from 'helpers';
import { withTheme } from 'styled-components';
import { Font, XymPrice, XymSpacer } from 'components';
import { PrivacyMask } from 'xerum';
import { TransactionTitle, Pending } from './styles';

const { light } = appConstants.themes;

const TxTitle = withTheme(props => {
  const { theme, transaction } = props;
  const { categories } = useSelector(state => state.app);
  const { generalPreferences: { privacyMode, selectedTheme } } = useSelector(state => state.preferences);
  const lightTheme = selectedTheme === light;
  const darkGrey = theme.modes[selectedTheme]?.darkGrey;
  const offWhite = theme.modes[selectedTheme]?.offWhite;
  const onPrimary = theme.modes[selectedTheme]?.onPrimary;
  const accent = theme.modes[selectedTheme]?.accent;
  const frenchGrey = theme.colors.neutral.frenchGrey;
  const pendingColor = lightTheme ? frenchGrey : frenchGrey + 50;
  const titleColor = lightTheme ? darkGrey : offWhite;

  const { data, metadata } = transaction || {};
  const { categoryId } = metadata || {};
  const { amount, name, merchant_name, pending } = data || {};
  const isCredit = amount < 0;
  const isDebit = amount > 0;
  const category = categories?.find(category => category._id === categoryId)?.name || 'No category';

  return (
    <Fragment>
      <TransactionTitle>
        <Font size={1.25} weight='semibold' color={titleColor} wrap={true}>
          {privacyMode
            ? <PrivacyMask length={(merchant_name || formatName(name)).length} />
            : merchant_name || formatName(name)
          }
        </Font>

        <Font id='slideOverPrice' weight='semibold' wrap={true}>
          <XymPrice
            theme={theme}
            selectedTheme={selectedTheme}
            size={1.125}
            value={amount * -1}
            useMinus={isDebit}
            usePlus={isCredit}
            color={isDebit ? onPrimary : accent}
          />
        </Font>
      </TransactionTitle>

      <Pending>
        <Font weight='semibold' color={pendingColor} mobileSize={0.875}>
          {privacyMode ? 'Private' : category}
        </Font>

        {pending && (
          <Fragment>
            <XymSpacer size={0.5} across={true} />
            <Font color={lightTheme ? frenchGrey : onPrimary + 50} mobileSize={0.875}>|</Font>
            <XymSpacer size={0.5} across={true} />

            <Font weight='semibold' color={pendingColor} mobileSize={0.875}>
              Pending
            </Font>
          </Fragment>
        )}
      </Pending>
    </Fragment>
  );
});

export { TxTitle };