import _ from 'lodash';

const { VITE_APP_NAME } = process.env;

const appConstants = {
  actions: {
    SET_BANNER_CONTENT: 'modules/app/SET_BANNER_CONTENT',
    ADD_NOTIFICATION: 'modules/app/ADD_NOTIFICATION',
    REMOVE_NOTIFICATION: 'modules/app/REMOVE_NOTIFICATION',
    CLEAR_NOTIFICATIONS: 'modules/app/CLEAR_NOTIFICATIONS',
    SET_MODAL_CONTENT: 'modules/app/SET_MODAL_CONTENT',
    SET_USER_MENU_VISIBLE: 'modules/app/SET_USER_MENU_VISIBLE',
    SET_TABLET_MODE: 'modules/app/SET_TABLET_MODE',
    SET_TABLET_LANDSCAPE_MODE: 'modules/app/SET_TABLET_LANDSCAPE_MODE',
    SET_MOBILE_MODE: 'modules/app/SET_MOBILE_MODE',
    SET_HYBRID_MODE: 'modules/app/SET_HYBRID_MODE',
    SET_MOBILE_USER_MENU: 'modules/app/SET_MOBILE_USER_MENU',
    GET_MISC_DATA: 'modules/app/GET_MISC_DATA',
    SET_GLOBAL_CONFIRM_CONTENT: 'modules/app/SET_GLOBAL_CONFIRM_CONTENT',
  },

  selectors: {
    STATE_KEY_BANNER_CONTENT: 'bannerContent',
    STATE_KEY_NOTIFICATIONS: 'notifications',
    STATE_KEY_MODAL_CONTENT: 'modalContent',
    STATE_KEY_USER_MENU_VISIBLE: 'userMenuVisible',
    STATE_KEY_TABLET_MODE: 'tabletMode',
    STATE_KEY_TABLET_LANDSCAPE_MODE: 'tabletLandscapeMode',
    STATE_KEY_MOBILE_MODE: 'mobileMode',
    STATE_KEY_HYBRID_MODE: 'hybridMode',
    STATE_KEY_MOBILE_USER_MENU: 'mobileUserMenu',
    STATE_KEY_CATEGORIES: 'categories',
    STATE_KEY_COLORS: 'colors',
    STATE_KEY_FREQUENCIES: 'frequencies',
    STATE_KEY_EXPENSE_TYPES: 'expenseTypes',
    STATE_KEY_TAX_BRACKETS: 'taxBrackets',
    STATE_KEY_MISC_DATA_LOADING: 'miscDataLoading',
    STATE_KEY_GLOBAL_CONFIRM_CONTENT: 'globalConfirmContent',
  },

  themes: {
    light: 'light',
    dark: 'dark',
  },

  messageTypes: {
    success: 'success',
    warning: 'warning',
    error: 'error',
    info: 'info',
    loading: 'loading',
  },

  breakpoints: {
    mobile: 40, // 640px
    tablet: 60, // 960px
    tabletLandscape: 80, // 1280px
    hybrid: 112, // 1792px
  },

  names: {
    appName: _.camelCase(VITE_APP_NAME),
    devCycle: 'alpha',
    accessTokenKeyName: 'accessToken',
    refreshTokenKeyName: 'refreshToken',
    sessionTokenKeyName: 'sessionToken',
    safeBalanceName: 'Safe-to-Use',
    privacyName: 'privacyMode',
    selectedThemeName: 'selectedTheme',
  },

  spacing: {
    extraLargeGap: 4,
    largeGap: 3,
    mediumGap: 2,
    gap: 1,
    smallGap: 0.5,
    extraSmallGap: 0.25,
  },

  layout: {
    textWidth: 32,
    layoutWidth: 112,
    settingsNavWidth: 24,
    headerHeight: 4,
    ribbonHeight: 6,
    footerHeight: 4,
  },

  misc: {
    maxInstitutions: 3,
  },
};

export { appConstants };