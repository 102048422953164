import { useSelector } from 'react-redux';
import { appConstants } from 'modules';
import { Font, Chevron, XymSpacer } from 'components';
import { Select, FieldError } from 'xerum';
import { withTheme } from 'styled-components';

const { light } = appConstants.themes;

const XymSelect = withTheme(props => {
  const {
    theme,
    form,
    name,
    data,
    label,
    noDataText,
    noResultsText,
    loading,
    loadingText,
    spacing,
    noSpacing,
    visible,
    optional,
    fontSize,
    mobileSize,
    tabletSize,
    privacy,
    localDefault,
    disabled,
    callback,
  } = props;

  const { mobileMode } = useSelector(state => state.app);
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const frenchGrey = theme.colors.neutral.frenchGrey;
  const onPrimary = theme.modes[selectedTheme]?.onPrimary;
  const darkGrey = theme.modes[selectedTheme]?.darkGrey;
  const lightTheme = selectedTheme === light;
  const showField = visible ?? true;

  if (!showField) return null;

  return (
    <>
      <Select
        theme={theme}
        selectedTheme={selectedTheme}
        form={form}
        data={data}
        name={name}
        optional={optional}
        icon={<Chevron />}
        label={label}
        labelColor={lightTheme ? darkGrey : onPrimary}
        placeholderColor={lightTheme ? frenchGrey : frenchGrey + 50}
        borderRadius={0.25}
        fontFamily='Inter-Regular'
        fontSize={fontSize || 0.875}
        loading={loading}
        loadingText={loadingText}
        noDataText={noDataText}
        noResultsText={
          <Font weight='semibold' size={fontSize || 0.875} tabletSize={tabletSize} mobileSize={mobileSize || 0.875}>
            {noResultsText}
          </Font>
        }
        disabled={privacy || disabled}
        disabledOptionTextColor={lightTheme ? frenchGrey : frenchGrey + 50}
        privacy={privacy}
        localDefault={localDefault}
        callback={callback}
        mobileSize={mobileMode && (mobileSize || 0.875)}
      />

      {form && <FieldError theme={theme} selectedTheme={selectedTheme} name={name} />}
      {!noSpacing && <XymSpacer size={spacing || 1.5} />}
    </>
  );
});

export { XymSelect };