import { Font, XymSpacer, Column } from 'components';
import { PrivacyMask } from 'xerum';

const accountChangeNotification = args => {
  const { addNotification, mask, name, privacyMode } = args || {};

  addNotification({
    message: (
      <Column>
        <Font weight='semibold' size={0.875}>
          Account change
        </Font>

        <XymSpacer size={0.25} />

        {privacyMode ? <PrivacyMask length={8} /> : name}<br />
        <PrivacyMask length={4} /> ... {mask}
      </Column>
    ),
  });
};

export { accountChangeNotification };