/* eslint-disable */
import { actionCreator } from 'helpers';
import { expensesConstants } from 'modules';
import * as api from 'modules/expenses/expensesApi';

const expensesActions = {
  resetBalances: payload => actionCreator(expensesConstants.actions.RESET_BALANCES, payload),

  getBalances: (payload, callbacks) => {
    const type = expensesConstants.actions.GET_BALANCES;
    const args = { type, payload, callbacks };
    return api.getBalances(args);
  },

  getExpensesData: (payload, callbacks) => {
    const type = expensesConstants.actions.GET_EXPENSES_DATA;
    const args = { type, payload, callbacks };
    return api.getExpensesData(args);
  },

  addExpense: (payload, callbacks) => {
    const type = expensesConstants.actions.ADD_EXPENSE;
    const args = { type, payload, callbacks };
    return api.addExpense(args);
  },

  updateExpense: (payload, callbacks) => {
    const type = expensesConstants.actions.UPDATE_EXPENSE;
    const args = { type, payload, callbacks };
    return api.updateExpense(args);
  },

  removeExpense: (payload, callbacks) => {
    const type = expensesConstants.actions.REMOVE_EXPENSE;
    const args = { type, payload, callbacks };
    return api.removeExpense(args);
  },

  getAutoFundSources: (payload, callbacks) => {
    const type = expensesConstants.actions.GET_AUTO_FUND_SOURCES;
    const args = { type, payload, callbacks };
    return api.getAutoFundSources(args);
  },

  addAutoFundSource: (payload, callbacks) => {
    const type = expensesConstants.actions.ADD_AUTO_FUND_SOURCE;
    const args = { type, payload, callbacks };
    return api.addAutoFundSource(args);
  },

  updateAutoFundSource: (payload, callbacks) => {
    const type = expensesConstants.actions.UPDATE_AUTO_FUND_SOURCE;
    const args = { type, payload, callbacks };
    return api.updateAutoFundSource(args);
  },

  removeAutoFundSource: (payload, callbacks) => {
    const type = expensesConstants.actions.REMOVE_AUTO_FUND_SOURCE;
    const args = { type, payload, callbacks };
    return api.removeAutoFundSource(args);
  },

  rebalanceExpense: (payload, callbacks) => {
    const type = expensesConstants.actions.REBALANCE_EXPENSE;
    const args = { type, payload, callbacks };
    return api.rebalanceExpense(args);
  },

  importExpenses: (payload, callbacks) => {
    const type = expensesConstants.actions.IMPORT_EXPENSES;
    const args = { type, payload, callbacks };
    return api.importExpenses(args);
  },

  clearAnalysisData: payload => actionCreator(expensesConstants.actions.CLEAR_ANALYSIS_DATA, payload),
  setSlideOverExpenseId: payload => actionCreator(expensesConstants.actions.SET_SLIDE_OVER_EXPENSE_ID, payload),
};

export { expensesActions };