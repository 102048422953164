import { Fragment, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { appConstants } from 'modules';
import { formatName } from 'helpers';
import { Font, XymTooltip, XymSpacer } from 'components';
import { PrivacyMask } from 'xerum';
import { Title } from './styles';
import { withTheme } from 'styled-components';

const { themes, names } = appConstants;
const { safeBalanceName } = names;
const { light } = themes;

const TransactionTitle = withTheme(props => {
  const { theme, transaction, active } = props;
  const { expensesData } = useSelector(state => state.expenses);
  const { generalPreferences: { privacyMode, selectedTheme } } = useSelector(state => state.preferences);
  const expenses = useMemo(() => expensesData?.filter(expense => !expense.isGoal) || [], [ expensesData ]);
  const goals = useMemo(() => expensesData?.filter(expense => expense.isGoal) || [], [ expensesData ]);
  const allExpenses = [ ...expenses, ...goals ];
  const darkGrey = theme.modes[selectedTheme]?.darkGrey;
  const offWhite = theme.modes[selectedTheme]?.offWhite;
  const onPrimary = theme.modes[selectedTheme]?.onPrimary;
  const paynesGrey = theme.colors.neutral.paynesGrey;
  const frenchGrey = theme.colors.neutral.frenchGrey;
  const orangeYellow = theme.colors.warning.orangeYellow;
  const lightTheme = selectedTheme === light;
  const mutedColor = lightTheme ? frenchGrey : frenchGrey + 50;
  const activeColor = lightTheme ? offWhite + 75 : darkGrey + 75;
  const activeTitleColor = lightTheme ? offWhite : darkGrey;
  const inactiveTitleColor = lightTheme ? darkGrey : offWhite;
  const titleColor = active ? activeTitleColor : inactiveTitleColor;
  const activeIconColor = lightTheme ? mutedColor : paynesGrey;
  const inactiveIconColor = lightTheme ? onPrimary : frenchGrey;
  const activeWarningIconColor = !lightTheme && orangeYellow;

  const buildTitle = () => {
    if (transaction) {
      const { data, metadata } = transaction || {};
      const { memo, autoSpendExpenseId } = metadata || {};
      const { pending, name, merchant_name, amount } = data || {};
      const autoSpendExpense = allExpenses?.find(expense => expense._id === autoSpendExpenseId);
      const autoSpendName = autoSpendExpense?.name || 'Unknown';
      const autoSpendBalance = (autoSpendExpense?.current - amount) || 0;
      const insufficientExpenseBalance = autoSpendBalance < 0;

      // TODO: Need tooltip if expense no longer exists.

      return (
        <Fragment>
          <Font weight='semibold' color={titleColor} mobileSize={0.875}>
            {privacyMode
              ? <PrivacyMask length={(merchant_name || formatName(name)).slice(0, 20).length} />
              : merchant_name || formatName(name)
            }
          </Font>

          <XymSpacer size={0.25} across={true} />

          {!privacyMode && memo && (
            <XymTooltip
              theme={theme}
              selectedTheme={selectedTheme}
              icon='fa-solid fa-message'
              iconColor={active ? activeIconColor : inactiveIconColor}
              verticalOffset={0.125}
              text={memo}
            />
          )}

          {!privacyMode && insufficientExpenseBalance && (
            <XymTooltip
              theme={theme}
              selectedTheme={selectedTheme}
              icon='fa-solid fa-exclamation-circle'
              iconColor={active && activeWarningIconColor}
              isWarning={true}
              verticalOffset={-0.125}
              text={(
                <Fragment>
                  There wasn&apos;t enough in {autoSpendName} to cover this transaction.
                  Spent from {safeBalanceName} instead.

                  {/*
                    NOTE: If auto-spend < transaction amount, leave the auto-spend amount
                    alone.  If the transaction is pending, it will already be accounted for
                    in the "Pending" math.  If it is not pending, it will still be accounted
                    for becuase the transaction would have posted at the institution and been
                    deducted from the account "Total".

                    If the user moves money into auto-spend after the fact, and reassigns the
                    "Spent from..." expense, the transaction should be updated to reflect this,
                    and the auto-spend expense should be updated to reflect the new balance --
                    this will automatically update the safe balance math the moment the expense
                    is updated.
                  */}
                </Fragment>
              )}
            />
          )}

          {pending && (
            <Font size={0.875} mobileSize={0.75} weight='semibold' color={active ? activeColor : mutedColor}>
              <XymSpacer size={0.25} across={true} />
              Pending
            </Font>
          )}
        </Fragment>
      );
    }
  };

  return (
    <Title>
      {buildTitle()}
    </Title>
  );
});

export { TransactionTitle };