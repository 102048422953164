import { Fragment } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { appConstants, authConstants, userMenuRoutes, adminRoutes } from 'modules';
import { getColor } from 'helpers';
import { Font, HR } from 'components';
import { flags } from 'utility';
import styled, { withTheme, css } from 'styled-components';
import _ from 'lodash';

const { themes, spacing, breakpoints } = appConstants;
const { smallGap, mediumGap } = spacing;
const { tabletLandscape, hybrid } = breakpoints;
const { light } = themes;

const StyledNav = styled('div')`
  height: 100%;
  padding: 3.25rem 4rem;
  background-color: ${props => getColor(props, 'secondary')};
  border: 0.0625rem solid ${props => {
    const lightTheme = props.$selectedTheme === light;
    return getColor(props, lightTheme ? 'lightGrey' : 'darkGrey');
  }};

  @media screen and (max-width: ${hybrid}rem) {
    padding: 1.5rem ${mediumGap}rem;
  }

  @media screen and (max-width: ${tabletLandscape}rem) {
    display: none;
  }
`;

export const Nav = styled('div')`
  height: fit-content;
`;

const AdminNav = styled('div')`
  display: ${props => props.$visible ? 'flex' : 'none'};
  height: fit-content;
`;

export const MenuItem = styled('div')`
  width: 100%;
  padding: 0.75rem 1.5rem;
  ${props => props.$useFooterPadding && css`padding: ${smallGap}rem;`}
  padding-left: ${props => props.$noLeftPadding ? 0 : 'inherit'};
  user-select: none;
  color: ${props => getColor(props, props.$active ? 'accent' : 'onSecondary')};
  cursor: pointer;
  user-select: none;

  @media (hover: hover) {
    &:hover {
      color: ${props => getColor(props, 'accent')};
    }
  }
`;

export const NavHolder = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: space-between;
`;

const SettingsNav = withTheme(props => {
  const { theme } = props;
  const { userInfo } = useSelector(state => state.auth);
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const { pathname } = useLocation();
  const { features: { subscriptionSettings } } = flags;
  const isAdmin = userInfo?.roles?.includes(authConstants.roles.admin);
  const navigate = useNavigate();

  const buildNav = adminNav => {
    const links = adminNav ? adminRoutes : userMenuRoutes;
    const filtered = links.filter(route => {
      if (!subscriptionSettings) {
        const validRoute = route !== 'subscription';
        return validRoute;
      }

      return route;
    });

    return filtered.map((route, index) => {
      const formatted = _.startCase(route);
      const title = _.capitalize(_.toLower(formatted));
      const visible = index !== filtered.length - 1;
      const active = pathname.includes(route);

      return (
        <Fragment key={route}>
          <MenuItem
            $theme={theme}
            $selectedTheme={selectedTheme}
            $active={active}
            onClick={() => !active && navigate(`/${route}`)}
          >
            <Font weight='semibold'>
              {title}
            </Font>
          </MenuItem>

          <HR height={0.125} visible={visible} />
        </Fragment>
      );
  });
  };

  return (
    <StyledNav $theme={theme} $selectedTheme={selectedTheme}>
      <NavHolder>
        <Nav>
          {buildNav()}
        </Nav>

        <AdminNav $visible={isAdmin}>
          {buildNav(true)}
        </AdminNav>
      </NavHolder>
    </StyledNav>
  );
});

export { SettingsNav };