import { useSelector } from 'react-redux';
import { appConstants } from 'modules';
import { expenseTooltip } from 'helpers';
import { Font, StandardTag, XymSpacer, GripIcon } from 'components';
import { Progress, PrivacyMask } from 'xerum';
import { theme } from 'theme';
import styled, { withTheme } from 'styled-components';
import moment from 'moment';
import _ from 'lodash';

const { themes, spacing } = appConstants;
const { gap, extraSmallGap } = spacing;
const { light } = themes;
const colors = theme.colors;

const StyledPreviewExpense = styled('div')`
  display: flex;
  padding-left: ${extraSmallGap}rem;
`;

const ExpenseHolder = styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0.5rem;
  gap: 0.75rem;
  width: 100%;
  cursor: pointer;
  background-color: ${props => {
    const frenchGrey = colors.neutral.frenchGrey;
    const bgColor = frenchGrey + 15;

    return props.$isDraggingOver ? bgColor : 'transparent';
  }};

  border: 0.125rem solid ${props => {
    const lightTheme = props.$selectedTheme === light;
    const paynesGrey = colors.neutral.paynesGrey;
    const frenchGrey = colors.neutral.frenchGrey;
    const borderColor = lightTheme ? frenchGrey : paynesGrey;

    return props.$isDraggingOver ? borderColor : 'transparent';
  }};

  border-radius: 0.5rem;
  transition: all 0.2s ease;
`;

const TopArea = styled('div')`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: ${gap}rem;
  align-items: center;
  width: 100%;
`;

export const TitleAndTooltips = styled('div')`
  display: inline-flex;
  width: fit-content;
`;

const BottomArea = styled('div')`
  display: flex;
`;

export const DragIcon = styled('div')`
  position: absolute;
  top: -0.125rem;
  left: ${props => props.$isDraggingOver ? -2.25 : -1.75}rem;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
`;


const PreviewExpense = withTheme(props => {
  const { theme, expense, dragOverId, setDragOverId } = props;
  const { _id, name, current, needed, dueDate, color, onTrackData, funded } = expense || {};
  const { mobileMode } = useSelector(state => state.app);
  const { generalPreferences: { privacyMode, selectedTheme } } = useSelector(state => state.preferences);
  const { expensesData } = useSelector(state => state.expenses);

  const isDraggingOver = dragOverId === _id;
  const lightTheme = selectedTheme === light;
  const accent = theme.modes[selectedTheme]?.accent;
  const lightGrey = theme.modes[selectedTheme]?.lightGrey;
  const darkGrey = theme.modes[selectedTheme]?.darkGrey;
  const onPrimary = theme.modes[selectedTheme]?.onPrimary;
  const offWhite = theme.modes[selectedTheme]?.offWhite;
  const frenchGrey = theme.colors.neutral.frenchGrey;
  const offTrackColor = lightTheme ? onPrimary : darkGrey;
  const titleColor = lightTheme ? darkGrey : offWhite;
  const offTrackExists = expensesData?.some(expense => !expense.onTrackData?.onTrack);

  const handleDragStart = e => {
    e.dataTransfer.setData('text/plain', _id);
    if (!isDraggingOver) setDragOverId(_id);
  };

  const handleDragOver = e => {
    e.preventDefault();
    if (!isDraggingOver) setDragOverId(_id);
  };

  const handleDragLeave = () => setDragOverId(null);
  const handleDragEnd = () => setDragOverId(null);

  const buildPreviewExpenses = () => {
    if (!_.isEmpty(expense)) {
      const { onTrack } = onTrackData || {};

      const dueThisYear = moment(dueDate).format('YYYY') === moment().format('YYYY');
      const dateFormat = mobileMode ? `MM/DD/YYYY` : `MMMM Do${!dueThisYear ? ', YYYY' : ''}`;
      const dueToday = moment(dueDate).format(dateFormat) === moment().format(dateFormat);
      const dueTomorrow = moment(dueDate).format(dateFormat) === moment().add(1, 'days').format(dateFormat);
      const dueYesterday = moment(dueDate).format(dateFormat) === moment().subtract(1, 'days').format(dateFormat);

      const dueBy = () => {
        if (!dueDate) return 'No due date';
        if (dueToday) return 'Today';
        if (dueTomorrow) return 'Tomorrow';
        if (dueYesterday) return 'Yesterday';
        return moment(dueDate).format(dateFormat);
      };

      return (
        <ExpenseHolder
          $theme={theme}
          $selectedTheme={selectedTheme}
          $isDraggingOver={isDraggingOver}
          draggable={true}
          onDragStart={handleDragStart}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDragEnd={handleDragEnd}
          data-expense-id={_id}
        >
          <DragIcon $isDraggingOver={isDraggingOver}>
            <XymSpacer across={true} />
            <GripIcon width={1.25} color={lightTheme ? frenchGrey : frenchGrey + 35} />
          </DragIcon>

          <TopArea>
            <TitleAndTooltips>
              <Font color={titleColor} size={0.875} weight='semibold'>
                <XymSpacer size={0.25} across={true} />
                {privacyMode ? <PrivacyMask length={name.length} /> : name}
              </Font>

              <XymSpacer size={0.25} across={true} />
              {expenseTooltip({ expense, condition: onTrackData?.onTrack === false && !privacyMode, isWarning: true })}
              {expenseTooltip({ expense, condition: funded === true && !privacyMode })}
              {expenseTooltip({
                expense,
                condition: current > needed && !privacyMode && offTrackExists,
                isInfo: true,
              })}
            </TitleAndTooltips>

            <StandardTag
              noClose={true}
              tag={<Font size={0.8125} weight='semibold'>{dueBy()}</Font>}
              isOnTrack={onTrack}
              textColor={!onTrack && offTrackColor}
              bgColor={!onTrack && lightGrey}
            />
          </TopArea>

          <BottomArea>
            <Progress
              current={current}
              total={needed}
              noPct={true}
              color={color || accent}
              bgColor={lightTheme ? lightGrey : darkGrey}
            />
          </BottomArea>
        </ExpenseHolder>
      );
    }
  };

  return (
    <StyledPreviewExpense>
      {buildPreviewExpenses()}
    </StyledPreviewExpense>
  );
});

export { PreviewExpense };