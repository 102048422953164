import { H3, P, Center, XymSpacer } from 'components';
import { Automation, FullWidth } from './styles';
import { appConstants } from 'modules';

const {
  VITE_APP_NAME: appName,
  VITE_GCP_STORAGE_BUCKET: storageBucket,
} = process.env;

const { safeBalanceName } = appConstants.names;

const AboutAutomation = () => {
  const src = `${storageBucket}/Waitlist/Automation.png`;

  return (
    <Automation>
      <FullWidth>
        <H3>Automation</H3>
        <XymSpacer />
        <P>
          Set your transaction items to automatically spend from an Expense.
          You&apos;ll even see how much is left after it pulls from that Expense.  If there isn&apos;t
          enough in your Expense to cover the transaction, {appName} will fall back to your&nbsp;
          {safeBalanceName} balance and alert you of what went wrong &mdash; you can always correct
          the issue at any time.
        </P>
      </FullWidth>

      <Center>
        <img src={src} id='automation' alt='Automation preview.' />
      </Center>
    </Automation>
  );
};

export { AboutAutomation };