import { useSelector } from 'react-redux';
import { Price } from 'xerum';
import { withTheme } from 'styled-components';

const XymPrice = withTheme(props => {
  const {
    theme,
    value,
    color,
    size,
    positiveColor,
    negativeColor,
    useMinus,
    usePlus,
    tabletSize,
    mobileSize,
    noSymbol,
  } = props;

  const { generalPreferences: { privacyMode, selectedTheme } } = useSelector(state => state.preferences);
  const { tabletMode, mobileMode } = useSelector(state => state.app);

  return (
    <Price
      size={size}
      tabletSize={tabletMode && tabletSize}
      mobileSize={mobileMode && mobileSize}
      theme={theme}
      selectedTheme={selectedTheme}
      privacy={privacyMode}
      value={value}
      useMinus={useMinus}
      usePlus={usePlus}
      color={color}
      noSymbol={noSymbol}
      positiveColor={positiveColor}
      negativeColor={negativeColor}
    />
  );
});

export { XymPrice };