import { Font, HR, XymSpacer } from 'components';
import { appConstants } from 'modules';
import moment from 'moment';
import styled from 'styled-components';

const { mobile } = appConstants.breakpoints;

const StyledDateHeader = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;
const Title = styled('div')`
  width: 100%;
  padding: 0 0.65rem;

  @media screen and (max-width: ${mobile}rem) {
    padding: 0;
  }
`;

const DateHeader = props => {
  const { visible, dateGroup, firstItem, titleColor, firstActive } = props;
  const today = moment().format('MMMM Do');
  const yesterday = moment().subtract(1, 'days').format('MMMM Do');
  const isToday = today === dateGroup || dateGroup === 'Today';
  const isYesterday = yesterday === dateGroup || dateGroup === 'Yesterday';

  if (visible) {
    return (
      <StyledDateHeader>
        <XymSpacer size={firstItem ? 0.5 : 2} />

        <Title>
          <Font weight='semibold' color={titleColor}>
            {isToday && 'Today'}
            {isYesterday && 'Yesterday'}
            {!isToday && !isYesterday && dateGroup}
          </Font>
        </Title>

        <XymSpacer size={0.5} />
        <HR visible={true} color={firstActive && '#00000000'} />
      </StyledDateHeader>
    );
  }
};

export { DateHeader };