import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { appConstants, expensesActions } from 'modules';
import { Font, Footnote, CompactExpense, XymSpacer, XymConfirmButtons } from 'components';
import styled, { withTheme } from 'styled-components';

const { names } = appConstants;
const { safeBalanceName } = names;

const RemovedItem = styled('div')`
  display: inline-flex;
`;

const RemoveExpense = withTheme(props => {
  const { theme, expenseType, expense, setConfirmContent } = props;
  const { tokenInfo } = useSelector(state => state.auth);
  const { generalPreferences: { privacyMode, selectedTheme } } = useSelector(state => state.preferences);
  const token = tokenInfo?.refreshToken || tokenInfo?.accessToken;
  const error = theme.modes[selectedTheme]?.error;
  const errorHover = theme.modes[selectedTheme]?.errorHover;
  const dispatch = useDispatch();

  const removeExpense = useCallback((payload, callbacks) => {
    dispatch(expensesActions.removeExpense(payload, callbacks));
  }, [ dispatch ]);

  const setSlideOverExpenseId = useCallback((payload, callbacks) => {
    dispatch(expensesActions.setSlideOverExpenseId(payload, callbacks));
  }, [ dispatch ]);

  return (
    <>
      <Font weight='semibold'>
        Are you sure you want to remove the following?
      </Font>

      <XymSpacer size={1.875} />

      <RemovedItem>
        <XymSpacer size={2} mobileSize={1} across={true} />
        <CompactExpense hideAutoExpenses={true} hideTooltips={true} expense={expense} disableDrag={true} />
        <XymSpacer size={2} mobileSize={1} across={true} />
      </RemovedItem>

      <XymSpacer size={1.875} />
      <Footnote text={`Funds in this ${expenseType} will be placed back in ${safeBalanceName}.`} />
      <XymSpacer />

      <XymConfirmButtons
        confirmText='Remove'
        privacy={privacyMode}
        setConfirmContent={setConfirmContent}
        confirmColor={error}
        confirmHoverColor={errorHover}
        callback={() => {
          const callbacks = {
            onSuccess: () => {
              setSlideOverExpenseId(null);
              setConfirmContent(null);
            },
          };

          const payload = {
            id: expense?._id,
            linkedAccountId: expense?.linkedAccountId,
            meta: { noLoadingState: true },
            token,
          };

          removeExpense(payload, callbacks);
        }}
      />
    </>
  );
});

export { RemoveExpense };