import { appConstants } from 'modules';
import { request } from 'helpers';
import { notificationExists } from 'helpers/utilityHelpers';
import StateManager from 'state-wrangler';

const { actions, selectors } = appConstants;

const initial = {
  [selectors.STATE_KEY_NOTIFICATIONS]: [],
  [selectors.STATE_KEY_BANNER_CONTENT]: null,
};

const appReducer = (initialState = initial, action = {}) => {
  const { payload } = action;
  const state = new StateManager(initialState);

  switch(action.type) {
    case actions.SET_BANNER_CONTENT:
      return state.update(selectors.STATE_KEY_BANNER_CONTENT, payload);

    case actions.ADD_NOTIFICATION:
      return !notificationExists(state, payload, selectors.STATE_KEY_NOTIFICATIONS)
        ? state.add(selectors.STATE_KEY_NOTIFICATIONS, payload)
        : initialState;

    case actions.REMOVE_NOTIFICATION:
      return state.remove(selectors.STATE_KEY_NOTIFICATIONS, payload);

    case actions.CLEAR_NOTIFICATIONS:
      return state.update(selectors.STATE_KEY_NOTIFICATIONS, []);

    case actions.SET_MODAL_CONTENT:
      return state.update(selectors.STATE_KEY_MODAL_CONTENT, payload);

    case actions.SET_USER_MENU_VISIBLE:
      return state.update(selectors.STATE_KEY_USER_MENU_VISIBLE, payload);

    case actions.SET_TABLET_MODE:
      return state.update(selectors.STATE_KEY_TABLET_MODE, payload);

    case actions.SET_MOBILE_MODE:
      return state.update(selectors.STATE_KEY_MOBILE_MODE, payload);

    case actions.SET_TABLET_LANDSCAPE_MODE:
      return state.update(selectors.STATE_KEY_TABLET_LANDSCAPE_MODE, payload);

    case actions.SET_HYBRID_MODE:
      return state.update(selectors.STATE_KEY_HYBRID_MODE, payload);

    case actions.SET_MOBILE_USER_MENU:
      return state.update(selectors.STATE_KEY_MOBILE_USER_MENU, payload);

    case request(actions.GET_MISC_DATA).start:
      return state.update(selectors.STATE_KEY_MISC_DATA_LOADING, true);

    case request(actions.GET_MISC_DATA).success:
      return state.merge([
        { method: 'update', key: selectors.STATE_KEY_CATEGORIES, payload: payload.categories },
        { method: 'update', key: selectors.STATE_KEY_COLORS, payload: payload.colors },
        { method: 'update', key: selectors.STATE_KEY_FREQUENCIES, payload: payload.frequencies },
        { method: 'update', key: selectors.STATE_KEY_EXPENSE_TYPES, payload: payload.expenseTypes },
        { method: 'update', key: selectors.STATE_KEY_TAX_BRACKETS, payload: payload.taxBrackets },
      ]);

    case request(actions.GET_MISC_DATA).complete:
      return state.update(selectors.STATE_KEY_MISC_DATA_LOADING, false);

    case actions.SET_GLOBAL_CONFIRM_CONTENT:
      return state.update(selectors.STATE_KEY_GLOBAL_CONFIRM_CONTENT, payload);

    default:
      return initialState;
  }
};

export { appReducer };