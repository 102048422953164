/* eslint-disable */
import { actionCreator } from 'helpers';
import { securityConstants } from 'modules';
import * as api from 'modules/security/securityApi';

const securityActions = {
  getSecurityData: (payload, callbacks) => {
    const type = securityConstants.actions.GET_SECURITY_DATA;
    const args = { type, payload, callbacks };
    return api.getSecurityData(args);
  },

  updateTrustedDevice: (payload, callbacks) => {
    const type = securityConstants.actions.UPDATE_TRUSTED_DEVICE;
    const args = { type, payload, callbacks };
    return api.updateTrustedDevice(args);
  },

  removeTrustedDevice: (payload, callbacks) => {
    const type = securityConstants.actions.REMOVE_TRUSTED_DEVICE;
    const args = { type, payload, callbacks };
    return api.removeTrustedDevice(args);
  },
};

export { securityActions };