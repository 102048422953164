import { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { appConstants, preferencesActions, expensesActions } from 'modules';
import { Font, XymSpacer, XymSelect } from 'components';
import { StyledAnalysis, TitleArea, ContractorToggle } from './styles';
import { withTheme } from 'styled-components';
import { BreakdownTotal } from './BreakdownTotal';
import { TypeBreakdown } from './TypeBreakdown';
import { Recommendations } from './Recommendations';
import { Toggle } from 'xerum';
import _ from 'lodash';

const { light } = appConstants.themes;

const Analysis = withTheme(props => {
  const { theme } = props;
  const { tokenInfo } = useSelector(state => state.auth);
  const { selectedAccountId } = useSelector(state => state.institutions);
  const { tabletMode, tabletLandscapeMode, mobileMode, taxBrackets, bannerContent } = useSelector(state => state.app);
  const {
    analysisPreferences: { contractor, taxBracketId },
    generalPreferences: { privacyMode, selectedTheme },
  } = useSelector(state => state.preferences);

  const [ isContractor, setIsContractor ] = useState(contractor);
  const [ selectedTaxBracket, setSelectedTaxBracket ] = useState(taxBracketId);

  const token = tokenInfo?.refreshToken || tokenInfo?.accessToken;
  const desktopMode = !mobileMode && !tabletMode && !tabletLandscapeMode;
  const lightTheme = selectedTheme === light;
  const darkGrey = theme.modes[selectedTheme]?.darkGrey;
  const offWhite = theme.modes[selectedTheme]?.offWhite;
  const titleColor = lightTheme ? darkGrey : offWhite;
  const dispatch = useDispatch();

  const taxBracketOptions = useMemo(() => {
    const brackets = taxBrackets?.map(bracket => {
      const startRange = bracket.rangeStart?.toLocaleString();
      const endRange = bracket.rangeEnd ? ` - $${bracket.rangeEnd.toLocaleString()}` : '+';

      return {
        value: bracket._id,
        label: `${bracket.filingStatus.name} $${startRange}${endRange}`,
      };
    }) || [];

    return brackets;
  }, [ taxBrackets ]);

  const updateUserPreferences = useCallback((payload, callbacks) => {
    dispatch(preferencesActions.updateUserPreferences(payload, callbacks));
  }, [ dispatch ]);

  const getExpensesData = useCallback((payload, callbacks) => {
    dispatch(expensesActions.getExpensesData(payload, callbacks));
  }, [ dispatch ]);

  useEffect(() => {
    setSelectedTaxBracket(taxBracketId);
  }, [ taxBracketId, taxBracketOptions, setSelectedTaxBracket ]);

  useEffect(() => {
    setIsContractor(isContractor);
  }, [ isContractor, setIsContractor ]);

  return (
    <StyledAnalysis $theme={theme} $selectedTheme={selectedTheme} $bannerContent={bannerContent}>
      <TitleArea>
        {desktopMode && (
          <Font size={1.25} weight='semibold' color={titleColor}>
            Analysis
          </Font>
        )}

        <ContractorToggle>
          <Font weight='semibold' mobileSize={0.875} tabletSize={0.875}>
            Self-employed
          </Font>

          <XymSpacer across={true} />

          <Toggle
            theme={theme}
            selectedTheme={selectedTheme}
            localDefault={isContractor}
            callback={newValue => {
              const payload = {
                contractor: newValue,
                token,
              };

              setIsContractor(newValue);
              updateUserPreferences(payload);
            }}
          />
        </ContractorToggle>

        <XymSelect
          data={taxBracketOptions}
          name='trendOptions'
          localDefault={selectedTaxBracket}
          noDataText='No tax brackets found...'
          noResultsText='Tax bracket not found'
          noSpacing={!desktopMode}
          spacing={0.375}
          mobileSize={0.675}
          disabled={contractor}
          privacy={privacyMode}
          callback={newValue => {
            if (!_.isEqual(selectedTaxBracket, +newValue)) {
              const expensesPayload = {
                token,
                linkedAccountId: selectedAccountId,
                meta: { noLoadingState: true },
              };

              const callbacks = {
                onSuccess: () => getExpensesData(expensesPayload),
              };

              const payload = {
                contractor: false,
                taxBracketId: +newValue,
                token,
              };

              isContractor && setIsContractor(false);
              setSelectedTaxBracket(+newValue);
              updateUserPreferences(payload, callbacks);
            }
          }}
        />
      </TitleArea>

      <BreakdownTotal />
      <TypeBreakdown />
      <Recommendations />
    </StyledAnalysis>
  );
});

export { Analysis };