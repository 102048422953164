import { useSelector } from 'react-redux';
import { appConstants } from 'modules';
import { getColor } from 'helpers';
import { Font } from 'components';
import { Tooltip } from 'xerum';
import styled, { withTheme } from 'styled-components';

const { light } = appConstants.themes;

const TooltipHolder = styled('div')`
  display: flex;
  margin: ${props => props.$verticalOffset ?? 0}rem 0.375rem 0 0.375rem;

  div {
    max-width: 17rem;

    i.fa-exclamation-circle {
      background: ${props => {
        const innerColor = getColor(props, 'darkGrey');
        return `linear-gradient(${innerColor}, ${innerColor}) center bottom / 20% 84% no-repeat`;
      }};
    }
  }
`;

const XymTooltip = withTheme(props => {
  const { theme, text, isWarning, isSuccess, icon, iconColor, position, verticalOffset, children } = props;
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const lightTheme = selectedTheme === light;
  const warning = theme.modes[selectedTheme]?.warning;
  const success = theme.modes[selectedTheme]?.success;
  const paynesGrey = theme.colors.neutral.paynesGrey;
  const frenchGrey = theme.colors.neutral.frenchGrey;
  const offWhite = theme.colors.neutral.offWhite;
  const darkGrey = theme.modes[selectedTheme]?.darkGrey;
  const lightGrey = theme.modes[selectedTheme]?.lightGrey;
  const secondary = theme.modes[selectedTheme]?.secondary;
  const normalIconColor = lightTheme ? paynesGrey : offWhite;

  return (
    <TooltipHolder
      $theme={theme}
      $selectedTheme={selectedTheme}
      $verticalOffset={verticalOffset}
      onClick={e => e.stopPropagation()}
    >
      <Tooltip
        theme={theme}
        selectedTheme={selectedTheme}
        position={position || 'top'}
        icon={icon || (isWarning && 'fa-solid fa-exclamation-circle') || (isSuccess && 'fa-solid fa-check-circle')}
        iconColor={iconColor || (isWarning && warning) || (isSuccess && success) || normalIconColor}
        bgColor={lightTheme ? secondary : darkGrey}
        textColor={lightTheme ? paynesGrey : frenchGrey}
        borderSize={0.0625}
        borderColor={lightTheme ? lightGrey : lightGrey + 50}
      >
        {text
          ? <Font size={0.75}>
              {text}
            </Font>
          : children
        }
      </Tooltip>
    </TooltipHolder>
  );
});

export { XymTooltip };