import { useSelector } from 'react-redux';
import { Font } from 'components';
import { styled } from 'styled-components';
import { theme } from 'theme';

const StyledLink = styled('span')`
  cursor: pointer;
  user-select: none;
`;

const XymLink = props => {
  const { size, weight, color, callback, children } = props;
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);

  const accent = theme.modes[selectedTheme]?.accent;

  return (
    <StyledLink onClick={() => callback?.()}>
      <Font size={size || 0.875} weight={weight || 'regular'} color={color || accent}>
        {children}
      </Font>
    </StyledLink>
  );
};

export { XymLink };