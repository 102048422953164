import { getColor } from 'helpers';
import { appConstants } from 'modules';
import styled from 'styled-components';

const { breakpoints } = appConstants;
const { mobile, tabletLandscape } = breakpoints;

export const StyledLink = styled('div')`
  display: grid;
  gap: 4rem;
  grid-template-columns: ${props => props.$description ? 'repeat(2, 30rem)' : '1fr'};
  justify-content: center;
  align-items: center;
  grid-template-areas: ${props => props.$description ? `'description action'` : `'action'`};
  width: 100%;
  height: 100%;
  overflow-y: auto;

  @media screen and (max-width: ${tabletLandscape}rem) {
    display: block;
    padding: 1rem;
  }

  @media screen and (max-width: ${mobile}rem) {
    align-items: center;
  }
`;

export const ActionArea = styled('div')`
  display: flex;
  flex-direction: column;
  grid-area: action;
  width: 100%;
  height: fit-content;

  @media screen and (max-width: ${tabletLandscape}rem) {
    margin: 0 auto;
  }
`;

export const Description = styled('div')`
  display: 'flex';
  flex-direction: column;
  grid-area: description;
  padding-right: 4rem;
  border-right: 0.0625rem solid ${props => getColor(props, 'lightGrey')};

  @media screen and (max-width: ${tabletLandscape}rem) {
    border-right: unset;
    padding-right: unset;
    padding-bottom: 3rem;
  }
`;

export const StepHolder = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  padding: 0.25rem 1rem;
  border-radius: 0.25rem;

  @media screen and (max-width: ${mobile}rem) {
    display: flex;
    flex-direction: column;
  }
`;

export const StepItem = styled('div')`
  display: inline-flex;
  align-items: center;
  width: 100%;
`;

export const CTA = styled('div')`
  display: flex;
  flex-direction: column;
`;