import { appConstants } from 'modules';
import { getColor } from 'helpers';
import styled from 'styled-components';

const { light } = appConstants.themes;
const { layout, spacing, breakpoints } = appConstants;
const { layoutWidth } = layout;
const { extraLargeGap, largeGap, mediumGap, gap } = spacing;
const { mobile } = breakpoints;

export const StyledWaitlist = styled('div')`
  display: flex;
  justify-content: center;
  height: 100dvh;
  width: 100%;

  img {
    border-radius: 0.5rem;
    border: 0.0625rem solid ${props => {
      const lightTheme = props.$selectedTheme === light;
      return getColor(props, lightTheme ? 'lightGrey' : 'onPrimary');
    }};

    box-shadow: 0 0.25rem 0.25rem ${props => {
      const lightTheme = props.$selectedTheme === light;
      return getColor(props, lightTheme ? 'lightGrey' : 'black');
    }};
  }
`;

export const Layout = styled('div')`
  display: grid;
  grid-template-columns: 1fr 27rem;
  gap: ${extraLargeGap}rem;
  grid-template-areas:
    'appSummary form';

  max-width: ${layoutWidth}rem;
  width: 100%;
  padding: ${largeGap}rem ${extraLargeGap}rem;

  @media screen and (max-width: 1366px) {
    grid-template-columns: 1fr 23rem;
  }

  @media screen and (max-width: 1080px) {
    display: flex;
    padding: ${mediumGap}rem;
  }

  @media screen and (max-width: 540px) {
    padding: ${mediumGap}rem ${gap}rem;
  }
`;

export const Title = styled('div')`
  display: flex;
  gap: ${mediumGap}rem;
  align-items: center;

  @media screen and (max-width: 860px) {
    flex-direction: column;
    gap: ${gap}rem;
    width: 100%;
  }
`;

export const AppSummary = styled('div')`
  width: 100%;
  overflow-y: auto;
  padding: 0 ${extraLargeGap}rem 0 0;
  grid-area: appSummary;

  @media screen and (max-width: 1080px) {
    padding: ${mediumGap}rem;
  }

  @media screen and (max-width: 540px) {
    h1 {
      font-size: ${mediumGap}rem;
    }
  }
`;

export const FormArea = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-area: form;

  form {
    max-width: 27rem;
  }

  @media screen and (max-width: 1080px) {
    display: none;
  }
`;

export const MobileFormArea = styled('div')`
  display: none;
  flex-direction: column;
  justify-content: center;

  h3 {
    text-align: center;
  }

  @media screen and (max-width: 1080px) {
    display: block;
    width: 100%;
  }
`;

export const HeroImage = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;

  #overview {
    width: 100%;
    height: auto;
  }
`;

export const FullWidth = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  @media screen and (max-width: 1568px) {
    align-items: center;
  }
`;

export const SecureSync = styled('div')`
  display: inline-flex;
  gap: ${extraLargeGap}rem;
  align-items: center;
  width: 100%;

  @media screen and (max-width: 1568px) {
    display: flex;
    flex-direction: column;
    gap: ${mediumGap}rem;
  }
`;

export const SafeToUse = styled('div')`
  display: inline-flex;
  gap: ${extraLargeGap}rem;
  align-items: center;
  width: 100%;

  @media screen and (max-width: 1568px) {
    display: flex;
    flex-direction: column-reverse;
    gap: ${mediumGap}rem;
  }

  #safeBalance {
    width: 100%;
    max-width: 32rem;
    height: auto;
  }
`;

export const ExpensesAndGoals = styled('div')`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: ${mediumGap}rem;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media screen and (max-width: 1568px) {
    flex-direction: column-reverse;
  }

  p {
    margin: 0 auto;

    @media screen and (max-width: 1568px) {
      text-align: left;
    }
  }

  #expenses {
    width: 100%;
    height: auto;
  }
`;

export const AutoFunding = styled('div')`
  display: inline-flex;
  gap: ${extraLargeGap}rem;
  align-items: center;
  width: 100%;

  @media screen and (max-width: 1568px) {
    display: flex;
    flex-direction: column;
    gap: ${mediumGap}rem;
  }

  #addAutoFundSource {
    width: 27rem;
    height: auto;

    @media screen and (max-width: ${mobile}rem) {
      width: 100%;
    }
  }
`;

export const MoveMoney = styled('div')`
  display: inline-flex;
  gap: ${extraLargeGap}rem;
  align-items: center;
  width: 100%;

  @media screen and (max-width: 1568px) {
    display: flex;
    flex-direction: column;
    gap: ${mediumGap}rem;
  }

  #moveMoney {
    width: 27rem;
    height: auto;

    @media screen and (max-width: ${mobile}rem) {
      width: 100%;
    }
  }
`;

export const Goals = styled('div')`
  display: inline-flex;
  gap: ${extraLargeGap}rem;
  align-items: center;
  width: 100%;

  @media screen and (max-width: 1568px) {
    display: flex;
    flex-direction: column;
    gap: ${mediumGap}rem;
  }

  #goal {
    width: 100%;
    height: auto;
  }
`;

export const Transactions = styled('div')`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: ${mediumGap}rem;
  align-items: center;
  justify-content: center;
  width: 100%;

  p {
    margin: 0 auto;

    @media screen and (max-width: 1568px) {
      text-align: left;
    }
  }
`;

export const TransactionNames = styled('div')`
  display: inline-flex;
  gap: ${extraLargeGap}rem;
  align-items: center;
  width: 100%;

  @media screen and (max-width: 1568px) {
    display: flex;
    flex-direction: column-reverse;
    gap: ${mediumGap}rem;
  }

  #activity {
    width: 100%;
    height: auto;

    @media screen and (max-width: 1568px) {
      width: 23rem;
    }

    @media screen and (max-width: 540px) {
      width: 100%;
    }
  }
`;

export const Automation = styled('div')`
  display: inline-flex;
  gap: ${extraLargeGap}rem;
  align-items: center;
  width: 100%;

  @media screen and (max-width: 1568px) {
    display: flex;
    flex-direction: column-reverse;
    gap: ${mediumGap}rem;
  }

  #automation {
    width: 100%;
    height: auto;

    @media screen and (max-width: 1568px) {
      width: 23rem;
    }

    @media screen and (max-width: 540px) {
      width: 100%;
    }
  }
`;

export const MoreDetails = styled('div')`
  display: inline-flex;
  gap: ${extraLargeGap}rem;
  align-items: center;
  width: 100%;

  @media screen and (max-width: 1568px) {
    display: flex;
    flex-direction: column-reverse;
    gap: ${mediumGap}rem;
  }

  #transactionDetails {
    width: 23rem;
    height: auto;

    @media screen and (max-width: 540px) {
      width: 100%;
    }
  }

`;

export const Trends = styled('div')`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: ${mediumGap}rem;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media screen and (max-width: 1568px) {
    flex-direction: column-reverse;
  }

  p {
    margin: 0 auto;

    @media screen and (max-width: 1568px) {
      text-align: left;
    }
  }

  #trends {
    width: 50rem;
    height: auto;

    @media screen and (max-width: 1280px) {
      width: 100%;
    }
  }
`;

export const QuickGlance = styled('div')`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: ${mediumGap}rem;
  align-items: center;
  justify-content: center;
  width: 100%;

  h1 {
    text-align: center;
  }

  @media screen and (max-width: 1568px) {
    flex-direction: column-reverse;
  }

  p {
    margin: 0 auto;
    text-align: center;

    @media screen and (max-width: 1568px) {
      text-align: left;
    }
  }

  #analysis {
    width: 50rem;
    height: auto;

    @media screen and (max-width: 1280px) {
      width: 100%;
    }
  }
`;

export const InlineImages = styled('div')`
  display: inline-flex;
  gap: ${extraLargeGap}rem;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media screen and (max-width: 1568px) {
    flex-direction: column;
    gap: ${mediumGap}rem;
  }
`;