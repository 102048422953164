import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { animate } from 'helpers';
import { Font } from 'components';
import { Modal } from 'xerum';
import { withTheme } from 'styled-components';
import _ from 'lodash';

const XymConfirm = withTheme(props => {
  const {
    theme,
    confirmContent,
    confirmText,
    onConfirm,
    onClose,
    confirmButtonColor,
    confirmButtonHoverColor,
    confirmDisabled,
    useOverflow,
    blank,
    privacy,
  } = props;

  const { mobileMode } = useSelector(state => state.app);
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const onPrimary = theme.modes[selectedTheme]?.onPrimary;
  const animationRef = useRef();

  const handleAnimationIn = useCallback(() => {
    animate({ ref: animationRef.current, noFade: true, offset: -400 });
  }, []);

  const handleAnimationOut = () => {
    animate({
      ref: animationRef.current,
      offset: -400,
      noFade: true,
      transitionOut: true,
      onComplete: onClose,
    });
  };

  useEffect(() => {
    if (!_.isEmpty(confirmContent)) handleAnimationIn();
  }, [ confirmContent, handleAnimationIn ]);

  return (
    <Modal
      ref={animationRef}
      theme={theme}
      selectedTheme={selectedTheme}
      confirm={true}
      confirmText={
        <Font weight='medium' mobileSize={0.875}>
          {typeof confirmText === 'string' && (confirmText || 'Confirm')}
          {typeof confirmText === 'function' && confirmText()}
        </Font>
      }
      cancelText={<Font weight='medium' mobileSize={0.875}>Cancel</Font>}
      onClose={handleAnimationOut}
      onConfirm={onConfirm}
      visible={!_.isEmpty(confirmContent)}
      confirmButtonColor={confirmButtonColor}
      confirmButtonHoverColor={confirmButtonHoverColor}
      cancelButtonColor={onPrimary}
      cancelButtonHoverColor={onPrimary}
      confirmDisabled={confirmDisabled}
      useOverflow={useOverflow}
      mobileMode={mobileMode}
      bgClose={true}
      blank={blank}
      privacy={privacy}
    >
      {confirmContent}
    </Modal>
  );
});

export { XymConfirm };