import { useSelector } from 'react-redux';
import { hexValid, getFontFamily } from 'helpers';
import styled, { withTheme, css } from 'styled-components';

const StyledFont = styled('span')`
  position: relative;
  display: ${props => props.$block ? 'block' : 'inline-flex'};
  ${props => !props.$block && css`align-items: center;`}
  font-family: ${props => getFontFamily(props)}, sans-serif;
  font-size: ${props => props.$mobileSize || props.$tabletSize || props.$size || 0.9375}rem;
  height: fit-content;
  ${props => props.$wrap && css`overflow-wrap: anywhere;`}
  ${props => hexValid(props.$color) && css`color: ${props.$color};`}
`;

const Font = withTheme(props => {
  const { theme, id, weight, color, size, tabletSize, mobileSize, block, children, wrap, onClick } = props;
  const { tabletMode, mobileMode } = useSelector(state => state.app);
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);

  return (
    <StyledFont
      id={id}
      $theme={theme}
      $selectedTheme={selectedTheme}
      $size={size}
      $tabletSize={tabletMode && tabletSize}
      $mobileSize={mobileMode && mobileSize}
      $color={color}
      $block={block}
      $weight={weight}
      $wrap={wrap}
      onClick={onClick}
    >
      {children}
    </StyledFont>
  );
});

export { Font };