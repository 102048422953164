/* eslint-disable max-len */
import { useSelector } from 'react-redux';
import { getColor } from 'helpers';
import styled, { withTheme } from 'styled-components';

const GripIconSVG = styled('svg')`
  width: ${props => props.$width || 2}rem;
  height: ${props => props.$height || 2}rem;
  fill: ${props => props.color || getColor(props, 'onPrimary')};
  transform: ${props => props.$direction === 'horizontal' ? 'rotate(90deg)' : 'unset'};
`;

const GripIcon = withTheme(props => {
  const { theme, color, width, height, direction } = props;
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);

  return (
    <GripIconSVG
      $theme={theme}
      $selectedTheme={selectedTheme}
      $width={width}
      $height={height}
      $direction={direction}
      color={color}
      viewBox='0 0 16 16'
      fill='currentColor'
    >
      <path fillRule='evenodd' d='M11.5 3.25a1.25 1.25 0 1 1-2.5 0a1.25 1.25 0 0 1 2.5 0Zm0 4.75A1.25 1.25 0 1 1 9 8a1.25 1.25 0 0 1 2.5 0Zm-5.75 6a1.25 1.25 0 1 0 0-2.5a1.25 1.25 0 0 0 0 2.5Zm4.5 0a1.25 1.25 0 1 0 0-2.5a1.25 1.25 0 0 0 0 2.5Zm-4.5-4.75a1.25 1.25 0 1 0 0-2.5a1.25 1.25 0 0 0 0 2.5Zm0-4.75a1.25 1.25 0 1 0 0-2.5a1.25 1.25 0 0 0 0 2.5Z' clipRule='evenodd' />
    </GripIconSVG>
  );
});

export { GripIcon };