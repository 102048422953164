import { useSelector } from 'react-redux';
import { Font, SettingsNav } from 'components';
import { StyledAdminDashboard, Layout, DashboardContent } from './styles';
import { Statistics } from './AdminStatistics';
import { Maintenance } from './Maintenance';

const AdminDashboard = () => {
  const { tokenInfo } = useSelector(state => state.auth);

  const buildSections = () => {
    if (tokenInfo?.accessToken) {
      return (
      <>
        <Statistics />
        <Maintenance />
      </>
      );
    }

    return <Font weight='semibold'>No token found.</Font>;
  };

  return (
    <StyledAdminDashboard>
      <Layout>
        <SettingsNav />

        <DashboardContent>
          {buildSections()}
        </DashboardContent>
      </Layout>
    </StyledAdminDashboard>
  );
};

export { AdminDashboard };