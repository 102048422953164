import { appConstants } from 'modules';
import styled from 'styled-components';

const { breakpoints } = appConstants;
const { mobile } = breakpoints;

export const StyledStatisticsDetails = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 0;

  @media screen and (max-width: ${mobile}rem) {
    justify-content: space-between;
  }
`;

export const StatisticsHolder = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const StyledStatistic = styled('div')`
  display: flex;
`;