import { appReducer } from 'modules/app/appReducer';
import { authReducer } from 'modules/auth/authReducer';
import { institutionsReducer } from 'modules/institutions/institutionsReducer';
import { stripeReducer } from 'modules/stripe/stripeReducer';
import { expensesReducer } from 'modules/expenses/expensesReducer';
import { preferencesReducer } from 'modules/preferences/preferencesReducer';
import { messagesReducer } from 'modules/messages/messagesReducer';
import { securityReducer } from 'modules/security/securityReducer';

const reducers = {
  app: appReducer,
  auth: authReducer,
  institutions: institutionsReducer,
  stripe: stripeReducer,
  expenses: expensesReducer,
  preferences: preferencesReducer,
  messages: messagesReducer,
  security: securityReducer,
};

export { reducers };