import { useEffect, useState, useCallback } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { appActions, appConstants, authActions } from 'modules';
import { tokenValid, emailIncrementorCheck } from 'helpers';
import { P, Font, H3, H4, XymField, XymTooltip, Center, XymSpacer } from 'components';
import { TermsOfService, PrivacyPolicy, SecurityPolicy } from 'pages';
import { FieldError, FieldReqs, Button, Checkbox } from 'xerum';
import { Form, Formik } from 'formik';
import styled, { withTheme } from 'styled-components';
import * as yup from 'yup';
import _ from 'lodash';

const { themes, spacing } = appConstants;
const { extraLargeGap, gap } = spacing;
const { light } = themes;

const StyledCreateAccount = styled('div')`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: ${extraLargeGap}rem ${gap}rem ${gap}rem;

  form {
    max-width: 27rem;
  }
`;

const TrustedDevice = styled('div')`
  display: inline-flex;
  align-items: center;
`;

const FieldReqsHolder = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const defaultValues = {
  email: '',
  password: '',
  inviteCode: '',
  trustedDevice: false,
  conditions: false,
};

const validationSchema = yup.object().shape({
  email: yup.string()
    .email('Invalid email.')
    .required('Email is required.')
    .test('email-incrementor', 'Email incrementors are not allowed.', value => emailIncrementorCheck(value)),
  password: yup.string().required('Password is required.'),

  inviteCode: yup.string().required('Invite code is required.'),

  conditions: yup
    .boolean()
    .oneOf([ true ], 'Terms and conditions, and privacy/security policies required.'),
});

const CreateAccountForm = withTheme(props => {
  const { theme } = props;
  const { tokenInfo } = useSelector(state => state.auth);
  const [ passwordVisible, setPasswordVisible ] = useState(false);
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const { mobileMode } = useSelector(state => state.app);
  const accent = theme.modes[selectedTheme]?.accent;
  const onPrimary = theme.modes[selectedTheme]?.onPrimary;
  const white = theme.modes[selectedTheme]?.white;
  const darkGrey = theme.modes[selectedTheme]?.darkGrey;
  const privacyPolicy = <PrivacyPolicy inModal={true} />;
  const securityPolicy = <SecurityPolicy inModal={true} />;
  const termsOfService = <TermsOfService inModal={true} />;

  const dispatch = useDispatch();
  const createUser = useCallback((payload, callbacks) => {
    dispatch(authActions.createUser(payload, callbacks));
  }, [ dispatch ]);

  const setModalContent = useCallback(payload => {
    dispatch(appActions.setModalContent(payload));
  }, [ dispatch ]);

  const navigate = useNavigate();
  const { state } = useLocation();
  const token = tokenInfo?.accessToken;
  const lightTheme = selectedTheme === light;

  useEffect(() => {
    if (token) {
      const expired = !tokenValid(token);
      const route = !_.isEmpty(state) ? state.from.pathname + state.from.search : '/';

      if (!expired) navigate(route);
    }
  }, [ token, state, navigate ]);

  const handleSubmit = (values, actions) => {
    const { setSubmitting } = actions;
    const { email, password, inviteCode, trustedDevice } = values;

    const callbacks = {
      onComplete: () => setSubmitting(false),
    };

    const payload = {
      email: email.toLowerCase(),
      password,
      inviteCode,
      trustedDevice,
    };

    createUser(payload, callbacks);
  };

  const buildForm = () => {
    if (!token) {
      return (
        <Formik
          initialValues={defaultValues}
          validationSchema={validationSchema}
          enableReinitialization={true}
          onSubmit={handleSubmit}
        >
          {form => (
            <Form>
              <H3 color={lightTheme ? darkGrey : white}>Create account</H3>
              <XymSpacer size={2} />

              <XymField
                form={form}
                name='email'
                type='email'
                autoComplete='email'
                icon='fa-solid fa-envelope'
                label={<Font size={0.875} weight='semibold'>Email</Font>}
              />

              <XymField
                form={form}
                name='password'
                type={passwordVisible ? 'text' : 'password'}
                icon={passwordVisible ? 'fa-solid fa-eye' : 'fa-solid fa-eye-slash'}
                iconCallback={() => setPasswordVisible(!passwordVisible)}
                label={<Font size={0.875} weight='semibold'>Password</Font>}
                autoComplete='current-password'
              />

              <XymField
                form={form}
                name='inviteCode'
                type='text'
                icon='fa-solid fa-key'
                label={<Font size={0.875} weight='semibold'>Invite code</Font>}
              />

              <FieldReqsHolder>
                <Font size={0.875}>
                  <FieldReqs
                    theme={theme}
                    selectedTheme={selectedTheme}
                    value={form.values.password}
                    upper={true}
                    upperText={!mobileMode && 'Uppercase'}
                    lower={true}
                    lowerText={!mobileMode && 'Lowercase'}
                    number={true}
                    min={8}
                    minText={!mobileMode && 'Minimum'}
                  />
                </Font>

                <XymSpacer size={0.5} />

                <Center>
                  <P size={0.875} color={onPrimary + 50}>
                    Consider a password manager for{!mobileMode ? ' generating' : ''} a secure password.
                  </P>
                </Center>
              </FieldReqsHolder>

              <XymSpacer size={2} />

              <Center>
                <TrustedDevice>
                  <Checkbox
                    theme={theme}
                    selectedTheme={selectedTheme}
                    form={form}
                    name='trustedDevice'
                    label={<Font>Trusted device</Font>}
                    checkColor={lightTheme ? accent : onPrimary}
                  />

                  <XymTooltip
                    text='Adds this device to your trusted devices and keeps you logged in.'
                  />
                </TrustedDevice>

                <Checkbox
                  theme={theme}
                  selectedTheme={selectedTheme}
                  name='conditions'
                  form={form}
                  checkColor={lightTheme ? accent : white}
                  label={
                    <Font>
                      <Link
                        href={null}
                        onClick={() => setModalContent({
                          title: <H4>Terms of Service</H4>,
                          content: termsOfService,
                        })}
                      >
                        terms and conditions
                      </Link>&nbsp;|&nbsp;

                      <Link
                        to={null}
                        onClick={() => setModalContent({
                          title: <H4>Privacy Policy</H4>,
                          content: privacyPolicy,
                        })}
                      >
                        privacy policy
                      </Link>&nbsp;|&nbsp;

                      <Link
                        to={null}
                        onClick={() => setModalContent({
                          title: <H4>Security Policy</H4>,
                          content: securityPolicy,
                        })}
                      >
                        security policy
                      </Link>
                    </Font>
                  }
                  />

                <FieldError theme={theme} selectedTheme={selectedTheme} name='conditions' />
              </Center>

              <XymSpacer size={0.75} />

              <Button
                theme={theme}
                selectedTheme={selectedTheme}
                type='submit'
                text={
                  <Font weight='medium' mobileSize={0.9375}>
                    {form.isSubmitting ? 'Creating account...' : 'Create account'}
                  </Font>
                }
                disabled={form.isSubmitting}
                callback={form.handleSubmit}
              />

              <XymSpacer />

              <Center>
                <Link to='/login'>Log in</Link> or&nbsp;
                <Link to='/reset-password'>Reset Password</Link>
              </Center>
            </Form>
          )}
        </Formik>
      );
    }
  };

  return (
    <StyledCreateAccount>
      {buildForm()}
    </StyledCreateAccount>
  );
});

export { CreateAccountForm };