import React from 'react';
import { useSelector } from 'react-redux';
import { H4, P, UL, XymSpacer } from 'components';
import { LegalDoc, Layout } from './styles';
import { withTheme } from 'styled-components';
import _ from 'lodash';

const { VITE_APP_NAME: appName } = process.env;

const SecurityPolicy = withTheme(props => {
  const { theme, inModal } = props;
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const company = appName;

  return (
    <LegalDoc $theme={theme} $selectedTheme={selectedTheme}>
      <Layout $inModal={inModal}>
        {!inModal && <XymSpacer mobileSize={1} tabletSize={1} />}

        <P>
          Last Updated: August 24th, 2024
        </P>

        <XymSpacer mobileSize={1} tabletSize={1} />

        <P>
          At {company}Software&trade;, from now on referred to as {company}, we take reasonable steps to
          protect your information from loss, misuse, and unauthorized access, disclosure, alteration,
          or destruction. However, you should keep in mind that no Internet transmission is ever
          completely secure or error-free.
        </P>

        <XymSpacer mobileSize={1} tabletSize={1} size={2} />

        <H4>Our Commitment to Security</H4>

        <XymSpacer mobileSize={1} tabletSize={1} />

        <P>
          {company} prioritizes the security and privacy of our users. We use advanced encryption
          methods to protect your data at all times, whether it&apos;s being transmitted or stored.
          Your information is only accessible to authorized personnel under strict access controls.
        </P>

        <XymSpacer mobileSize={1} tabletSize={1} />

        <P>
          We implement secure authentication processes, including encrypted passwords and session tokens,
          to ensure that only you can access your account.
        </P>

        <XymSpacer mobileSize={1} tabletSize={1} />

        <P>
          Our systems regularly monitor for suspicious
          activity, and we have procedures in place to respond swiftly to any potential security incidents.
        </P>

        <XymSpacer mobileSize={1} tabletSize={1} />

        <P>
          We collect only the essential information needed to provide our services, such as your email and a password,
          and never share your data with third parties for marketing purposes. Additionally, we perform regular
          backups of all data, ensuring that it remains secure and recoverable if an issue occurs.
        </P>

        <XymSpacer mobileSize={1} tabletSize={1} />

        <P>
          Your trust is our top priority, and we continually review and enhance our security measures to
          protect your data.
        </P>

        <XymSpacer mobileSize={1} tabletSize={1} size={2} />

        <H4>Additional Security Practices</H4>

        <XymSpacer mobileSize={1} tabletSize={1} />

        <P>
          {company}, any of its licensors, or officers will never ask for your password!
        </P>

        <XymSpacer mobileSize={1} tabletSize={1} />

        <P>
          {company} will also never ask for funds, fiat or digital, to be sent to any specific individual&apos;s bank
          account or cryptographic wallet address. If payment is required for any of our services, it
          will always be handled securely via our third-party payment processor: Stripe.
        </P>

        <XymSpacer mobileSize={1} tabletSize={1} />

        <P>
          Please exercise caution and good practices to protect your information:
        </P>

        <XymSpacer mobileSize={1} tabletSize={1} />

        <UL>
          <li>
            Do not send any sensitive information on public devices or devices you do not explicitly trust.
          </li>

          <li>
            Always check the receiving address of any communications for legitimacy. Communications from us
            will use an <em>@{_.toLower(company)}software.com</em> format &mdash; specifically,
            be cautious if the domain extension is not &quot;.com&quot; or if there are spelling errors
            present that resemble our domain.
          </li>

          <li>
            Always check for spelling errors in the subject or body of any communications.
          </li>

          <li>
          Always copy links from emails and manually paste them into a trusted web browser&apos;s URL field for
          vetting first before you hit enter.
          </li>

          <li>
            Always check any domains or sub-domains for a valid SSL encrypted certificate via &quot;https&quot;.
          </li>

          <li>
            If any of these best practices are not up to your standards or do not seem legitimate in practice,
            immediately navigate away from, delete, or close the website, service, chat, communication, etc.
          </li>
        </UL>

        <XymSpacer mobileSize={1} tabletSize={1} />

        <P>
          By following these guidelines and our security practices, we can work together to keep your
          information secure.
        </P>

        <XymSpacer mobileSize={1} tabletSize={1} />
      </Layout>
    </LegalDoc>
  );
});

export { SecurityPolicy };