import { Font, Footnote, AuthCodeForm, XymSpacer } from 'components';
import { SecurityDevice } from './SecurityDevice';
import { StyledRemoveConfirm, RemovedItem } from './styles';

const RemoveDeviceConfirm = props => {
  const { device, xymDeviceType, privacy, setConfirmContent } = props;

  return (
    <StyledRemoveConfirm>
      <Font weight='semibold'>
        Are you sure you want to remove the following?
      </Font>

      <XymSpacer />

      <RemovedItem>
        <XymSpacer across={true} />

        <SecurityDevice
          device={device}
          lastItem={true}
          hideActions={true}
          hideHR={true}
          privacy={privacy}
          inModal={true}
          xymDeviceType={xymDeviceType}
        />

        <XymSpacer across={true} />
      </RemovedItem>

      <XymSpacer size={1.875} />

      {xymDeviceType === 'securityKey' && (
        <Footnote>
          Insert your key and tap it, when prompted by your OS, to confirm.
        </Footnote>
      )}

      {xymDeviceType === 'device' && (
        <Footnote>
          You will need to log in to this device every time you visit.
          You will also receive a new device email each time you log in.
        </Footnote>
      )}

      {xymDeviceType === 'authenticator' && (
        <AuthCodeForm setConfirmContent={setConfirmContent} />
      )}
    </StyledRemoveConfirm>
  );
};

export { RemoveDeviceConfirm };