import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { appConstants } from 'modules';
import { getColor } from 'helpers';
import { Font, XymSpacer, XymLogo } from 'components';
import { TradeSymbol, Brand, BetaBadge } from 'pages/App/Header/PrimaryHeader/styles';
import { withTheme } from 'styled-components';

const { VITE_APP_NAME: appName } = process.env;
const { names } = appConstants;
const { devCycle } = names;

const light = appConstants.themes.light;

const currentColor = props => {
  const lightTheme = props.$selectedTheme === light;
  return props.color || getColor(props, lightTheme ? 'onPrimary' : 'lightGrey');
};

const AppLogo = withTheme(props => {
  const { theme, size, disableHomeLink } = props;
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const navigate = useNavigate();

  return (
    <Brand
      $disableHomeLink={disableHomeLink}
      onClick={() => !disableHomeLink && navigate('/')}
    >
      <XymLogo />
      <XymSpacer size={0.5} across={true} />

      <Font color={currentColor(props)} weight='bold' size={size || 2} tabletSize={1.5} mobileSize={1.25}>
        {appName}

        <TradeSymbol $size={size && (size / 2.5)}>
          &trade;
        </TradeSymbol>
      </Font>

      <BetaBadge $theme={theme} $selectedTheme={selectedTheme}>
        <Font size={0.6875} weight='semibold' block={true}>
          {devCycle}
        </Font>
      </BetaBadge>

      <XymSpacer across={true} size={4.125} tabletSize={2} mobileSize={1} />
    </Brand>
  );
});

export { AppLogo };