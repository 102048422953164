import { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { expensesActions } from 'modules';
import {
  HeaderActionTag,
  XymConfirm,
  MoveMoneyForm,
  XymTabs,
  XymSpacer,
  PlaidLink,
  SelfManagedLink,
} from 'components';
import { StyledExtraActions } from './styles';
import { autoFundTabs } from '../localControllers/autoFundTabs';
import { flags } from 'utility';
import _ from 'lodash';

const SafeToUseActions = () => {
  const [ confirmContent, setConfirmContent ] = useState(null);
  const { pathname } = useLocation();
  const { institutionsData } = useSelector(state => state.institutions);
  const { slideOverExpenseId, autoFundSources } = useSelector(state => state.expenses);
  const { features: { autoFunding } } = flags;
  const hasInstitutions = !_.isEmpty(institutionsData);
  const onOverview = pathname.includes('overview');
  const onExpenses = pathname.includes('expenses');
  const onGoals = pathname.includes('goals');
  const dispatch = useDispatch();

  const setSlideOverExpenseId = useCallback(payload => {
    dispatch(expensesActions.setSlideOverExpenseId(payload));
  }, [ dispatch ]);

  const handleAutoFundSources = () => {
    const args = { setConfirmContent, autoFundSources };
    const payload = (
      <XymTabs
        content={autoFundTabs(args)}
        spacing={_.isEmpty(autoFundSources)}
        visible={!_.isEmpty(autoFundSources)}
      />
    );

    setConfirmContent(payload);
  };

  const handleMoveMoney = () => {
    const args = { setConfirmContent };
    const payload = <MoveMoneyForm {...args} />;
    setConfirmContent(payload);
  };

  return (
    <StyledExtraActions>
      <HeaderActionTag
        text='Move'
        icon='fa-solid fa-right-left'
        visible={hasInstitutions && (onOverview || onExpenses || onGoals)}
        callback={handleMoveMoney}
      />

      {hasInstitutions && (autoFunding || (onOverview || onExpenses || onGoals)) && (
        <XymSpacer tabletSize={0.5} mobileSize={0.25} across={true} />
      )}

      <HeaderActionTag
        text='Funding'
        icon='fa-solid fa-hand-holding-dollar'
        visible={hasInstitutions && autoFunding && (onOverview || onExpenses || onGoals)}
        callback={handleAutoFundSources}
      />

      {autoFunding && hasInstitutions && <XymSpacer tabletSize={0.5} mobileSize={0.25} across={true} />}

      <HeaderActionTag
        text={`${onExpenses ? 'Expense' : 'Goal'}`}
        visible={hasInstitutions && (onExpenses || onGoals)}
        callback={() => setSlideOverExpenseId(!slideOverExpenseId)}
      />

      <SelfManagedLink asTag={true} />

      <XymSpacer tabletSize={0.5} mobileSize={0.25} across={true} />

      <PlaidLink asTag={true} />

      <XymConfirm
        confirmContent={confirmContent}
        useOverflow={false}
        blank={true}
        onClose={() => setConfirmContent(null)}
      />
    </StyledExtraActions>
  );
};

export { SafeToUseActions };