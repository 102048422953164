import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { appConstants } from 'modules';
import { Font, TextSection, XymSpacer } from 'components';
import { StyledDetails, DetailsHolder } from './styles';
import { Actions } from './Actions';
import { withTheme } from 'styled-components';
import moment from 'moment';
import _ from 'lodash';

const { light } = appConstants.themes;

const Details = withTheme(props => {
  const { theme } = props;
  const { userInfo } = useSelector(state => state.auth);
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const darkGrey = theme.modes[selectedTheme]?.darkGrey;
  const onPrimary = theme.modes[selectedTheme]?.onPrimary;
  const offWhite = theme.modes[selectedTheme]?.offWhite;
  const lightTheme = selectedTheme === light;

  const buildDetails = useCallback(() => {
    if (userInfo) {
      const { email, createdAt, verified } = userInfo;

      return (
        <DetailsHolder>
          <XymSpacer size={1.75} />

          <TextSection
            label='Email'
            labelColor={onPrimary}
            sectionText={email}
            tagText={_.capitalize(verified ? 'verified' : 'unverified')}
          />

          <XymSpacer size={1.75} />

          <TextSection
            label='Member since'
            labelColor={onPrimary}
            sectionText={moment(createdAt).format('MMMM Do, YYYY')}
          />

          <XymSpacer size={1.75} />
        </DetailsHolder>
      );
    }
  }, [ onPrimary, userInfo ]);

  return (
    <StyledDetails>
      <Font weight='semibold' size={1.25} mobileSize={1} tabletSize={1} color={lightTheme ? darkGrey : offWhite}>
        Details
      </Font>

      {buildDetails()}
      <Actions {...userInfo} />
    </StyledDetails>
  );
});

export { Details };