import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { appConstants } from 'modules';
import { SearchIcon } from 'components';
import { Search } from 'xerum';
import { withTheme } from 'styled-components';

const { light } = appConstants.themes;

const XymSearch = withTheme(forwardRef((props, externalRef) => {
  const { theme, id, placeholder, visible, disabled, callback } = props;

  const { mobileMode } = useSelector(state => state.app);
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const lightTheme = selectedTheme === light;
  const frenchGrey = theme.colors.neutral.frenchGrey;
  const primary = theme.modes[selectedTheme]?.primary;
  const onPrimary = theme.modes[selectedTheme]?.onPrimary;

  if (visible) {
    return (
      <div id={id}>
        <Search
          ref={externalRef}
          theme={theme}
          selectedTheme={selectedTheme}
          placeholder={placeholder}
          disabled={disabled}
          borderRadius={0.5}
          bottomBorder={true}
          inputBGColor={primary}
          borderColor={onPrimary}
          noButton={true}
          useContinuousSearch={true}
          fontFamily='Inter-Regular'
          clearIcon='fa-solid fa-times'
          inputIcon={<SearchIcon />}
          inputIconColor={onPrimary}
          placeholderColor={lightTheme ? frenchGrey : frenchGrey + 50}
          textSize={0.9375}
          mobileSize={mobileMode && 0.875}
          callback={callback}
        />
      </div>
    );
  }
}));

export { XymSearch };