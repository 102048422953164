import { Fragment, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  Font,
  XymSpacer,
  NoData,
  InstitutionIcon,
  HeaderActionTag,
  SelfManagedSetupForm,
} from 'components';
import { appActions } from 'modules';
import { StyledSelfManagedLink, ActionArea } from './selfManagedLinkStyles';
import { Button } from 'xerum';
import { withTheme } from 'styled-components';
import _ from 'lodash';

const SelfManagedLink = withTheme(props => {
  const { theme, subText, asTag, noButton, buttonOnly, buttonText } = props;

  const { mobileMode, globalConfirmContent } = useSelector(state => state.app);
  const { institutionsData } = useSelector(state => state.institutions);
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const { pathname } = useLocation();

  const hasInstitutions = !_.isEmpty(institutionsData);
  const onPrimary = theme.modes[selectedTheme].onPrimary;
  const onOverview = pathname.includes('overview');
  const onExpenses = pathname.includes('expenses');
  const onGoals = pathname.includes('goals');
  const onMyInstitutions = pathname.includes('my-institutions');
  const onAllowedPage = onMyInstitutions || onGoals || onExpenses || onOverview;
  const dispatch = useDispatch();

  const setGlobalConfirmContent = useCallback(payload => {
    dispatch(appActions.setGlobalConfirmContent(payload));
  }, [ dispatch ]);

  const selfManagedSetupArgs = {
    content: <SelfManagedSetupForm setConfirmContent={setGlobalConfirmContent} />,
    blank: true,
  };

  return (
    <Fragment>
      <HeaderActionTag
        text='Create self-managed'
        visible={(
          !mobileMode && asTag && onMyInstitutions)
          || (!buttonOnly && !hasInstitutions && onAllowedPage && asTag)
        }
        disabled={!_.isEmpty(globalConfirmContent?.content)}
        callback={() => setGlobalConfirmContent(selfManagedSetupArgs)}
      />

      {buttonOnly && (
        <Button
          theme={theme}
          selectedTheme={selectedTheme}
          text={(
            <Font weight='medium' mobileSize={0.875}>
              {buttonText || 'Create self-managed institution'}
            </Font>
          )}
          disabled={!_.isEmpty(globalConfirmContent?.content)}
          callback={() => setGlobalConfirmContent(selfManagedSetupArgs)}
        />
      )}

      {!buttonOnly && !hasInstitutions && !asTag && (
        <StyledSelfManagedLink>
          <ActionArea>
            <NoData
              icon={<InstitutionIcon color={onPrimary} />}
              text='No institutions created'
              subText={subText || 'Create a self-managed institution to get started'}
            />

            <XymSpacer />

            {!noButton && (
              <Button
                theme={theme}
                selectedTheme={selectedTheme}
                text={(
                  <Font weight='medium' mobileSize={0.875}>
                    {buttonText || 'Create Self-managed institution'}
                  </Font>
                )}
                callback={null}
              />
            )}
          </ActionArea>
        </StyledSelfManagedLink>
      )}
    </Fragment>
  );
});

export { SelfManagedLink };