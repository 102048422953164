import { useSelector } from 'react-redux';
import { appConstants } from 'modules';
import { Filter } from 'xerum';
import { withTheme } from 'styled-components';

const { light } = appConstants.themes;

const XymFilter = withTheme(props => {
  const { theme, placeholder, data, visible, include, disabled, callback } = props;
  const { mobileMode } = useSelector(state => state.app);
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const lightTheme = selectedTheme === light;
  const frenchGrey = theme.colors.neutral.frenchGrey;
  const primary = theme.modes[selectedTheme]?.primary;
  const onPrimary = theme.modes[selectedTheme]?.onPrimary;

  if (visible) {
    return (
      <Filter
        theme={theme}
        selectedTheme={selectedTheme}
        placeholder={placeholder}
        data={data}
        include={include}
        disabled={disabled}
        borderRadius={0.5}
        bottomBorder={true}
        inputBGColor={primary}
        borderColor={onPrimary}
        fontFamily='Inter-Regular'
        clearIcon='fa-solid fa-times'
        inputIconColor={onPrimary}
        placeholderColor={lightTheme ? frenchGrey : frenchGrey + 50}
        textSize={0.9375}
        mobileSize={mobileMode && 0.875}
        callback={callback}
      />
    );
  }
});

export { XymFilter };