import { useSelector } from 'react-redux';
import { appConstants } from 'modules';
import { Progress } from 'xerum';
import { ProgressArea } from './styles';
import { withTheme } from 'styled-components';

const light = appConstants.themes.light;

const ExpenseProgress = withTheme(props => {
  const { theme, current, needed, color } = props;
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const accent = theme.modes[selectedTheme]?.accent;
  const darkGrey = theme.modes[selectedTheme]?.darkGrey;
  const lightGrey = theme.modes[selectedTheme]?.lightGrey;
  const lightTheme = selectedTheme === light;

  return (
    <ProgressArea>
      <Progress
        theme={theme}
        selectedTheme={selectedTheme}
        current={current}
        total={needed}
        noPct={true}
        color={color || accent}
        bgColor={lightTheme ? lightGrey : darkGrey}
      />
    </ProgressArea>
  );
});

export { ExpenseProgress };