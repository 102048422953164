import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Font, XymField, XymSpacer } from 'components';
import { appConstants, authActions } from 'modules';
import { emailIncrementorCheck } from 'helpers';
import { Button } from 'xerum';
import { Form, Formik } from 'formik';
import styled, { withTheme } from 'styled-components';
import * as yup from 'yup';

const { themes, breakpoints } = appConstants;
const { light } = themes;
const { mobile, tabletLandscape } = breakpoints;

const StyledChangePassword = styled('div')`
  grid-area: password;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  form {
    max-width: unset;
  }

  @media screen and (max-width: ${tabletLandscape}rem) {
    height: calc(100dvh - 21rem);
  }

  @media screen and (max-width: ${mobile}rem) {
    height: ${props => props.$bannerContent ? 'calc(100dvh - 19rem)' : 'calc(100dvh - 16rem)'};

    form {
      height: 100%;
      justify-content: space-between;
    }
  }
`;

const FieldHolder = styled('div')`
  width: 100%;
`;

const defaultValues = {
  email: '',
};

const validationSchema = yup.object().shape({
  email: yup.string()
    .email('Invalid email.')
    .required('Email is required.')
    .test('email-incrementor', 'Email incrementors are not allowed.', value => emailIncrementorCheck(value)),
});

const ChangePasswordForm = withTheme(props => {
  const { theme } = props;
  const { bannerContent } = useSelector(state => state.app);
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const darkGrey = theme.modes[selectedTheme]?.darkGrey;
  const offWhite = theme.modes[selectedTheme]?.offWhite;
  const lightTheme = selectedTheme === light;
  const titleColor = lightTheme ? darkGrey : offWhite;
  const dispatch = useDispatch();

  const sendPasswordResetEmail = useCallback((payload, callbacks) => {
    dispatch(authActions.sendPasswordResetEmail(payload, callbacks));
  }, [ dispatch ]);

  const handleSubmit = (values, actions) => {
    const { setSubmitting } = actions;
    const { email } = values;
    const payload = { email };
    const callbacks = {
      onComplete: () => setSubmitting(false),
    };

    sendPasswordResetEmail(payload, callbacks);
  };

  const buildForm = () => {
    return (
      <Formik
        initialValues={defaultValues}
        validationSchema={validationSchema}
        enableReinitialization={true}
        onSubmit={handleSubmit}
      >
        {form => (
          <Form>
            <FieldHolder>
              <XymSpacer size={2} />

              <XymField
                form={form}
                name='email'
                type='email'
                icon='fa-solid fa-envelope'
                autoComplete='email'
                label={<Font size={0.875} weight='semibold'>Email</Font>}
                spacing={2}
              />
            </FieldHolder>

            <Button
              theme={theme}
              selectedTheme={selectedTheme}
              type='submit'
              text={
                <Font weight='medium' mobileSize={0.875}>
                  {form.isSubmitting ? 'Sending reset request...' : 'Send reset request'}
                </Font>
              }
              disabled={form.isSubmitting}
              callback={form.handleSubmit}
            />
          </Form>
        )}
      </Formik>
    );
  };

  return (
    <StyledChangePassword $bannerContent={bannerContent}>
      <Font weight='semibold' size={1.25} mobileSize={1} tabletSize={1} color={titleColor}>
        Change Password
      </Font>

      {buildForm()}
    </StyledChangePassword>
  );
});

export { ChangePasswordForm };