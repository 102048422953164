import styled from 'styled-components';

const StyledCenter = styled('div')`
  width: 100%;
  text-align: center;
`;

const Center = props => {
  const { children } = props;

  return (
    <StyledCenter>
      {children}
    </StyledCenter>
  );
};

export { Center };