
const customStartCase = str => {
  const lowercaseWords = [ 'of', 'the', 'and', 'in', 'on', 'at', 'for', 'with', 'a', 'an', 'but', 'or', 'to' ];

  return str
    ?.split(/(\s+|-|')/)
    .map((word, index, array) => {
      // Check if the word is a three to four letter uppercase acronym
      if (word.match(/^[A-Z]{3,4}$/)) {
        return word; // Preserve the uppercase acronym
      }

      // Lowercase certain words unless they are the first word
      if (lowercaseWords.includes(word.toLowerCase()) && index !== 0) {
        return word.toLowerCase();
      }

      // Capitalize the first letter of other words
      if (word.match(/^[a-zA-Z]/) && (index === 0 || array[index - 1] !== "'")) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }

      return word;
    })
    .join('');
};

export {
  customStartCase,
};
