import { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { appConstants } from 'modules';
import { animate, institutionByAccountId } from 'helpers';
import {
  Font,
  XymPrice,
  XymSpacer,
  XymDropdown,
  Chevron,
  XymConfirm,
  MoveMoneyForm,
  GripIcon,
} from 'components';
import { BreakdownItems } from './BreakdownItems';
import { Loading } from 'xerum';
import { Balance, BreakdownBalances, MobileBalances, DragIcon } from './styles';
import { withTheme } from 'styled-components';

const { names, themes } = appConstants;
const { safeBalanceName } = names;
const { light } = themes;

const Balances = withTheme(props => {
  const { theme } = props;
  const [ mobileBreakdown, setMobileBreakdown ] = useState(false);
  const [ moveMoneyConfirmContent, setMoveMoneyConfirmContent ] = useState(null);
  const [ dragOverId, setDragOverId ] = useState(null);
  const { tabletMode, mobileMode } = useSelector(state => state.app);
  const { tokenInfo } = useSelector(state => state.auth);
  const { institutionsData, institutionDataLoading, selectedAccountId } = useSelector(state => state.institutions);

  const {
    expensesDataLoading,
    safeBalance,
    totalFunds,
    pending,
    inExpenses,
    inGoals,
  } = useSelector(state => state.expenses);

  const { generalPreferences: { privacyMode, selectedTheme } } = useSelector(state => state.preferences);

  const account = useMemo(() => {
    const institution = institutionByAccountId({ institutionsData, accountId: selectedAccountId });
    const account = institution?.accounts?.find(account => account.account_id === selectedAccountId) || {};

      return account;
  }, [ institutionsData, selectedAccountId ]);

  const lightTheme = selectedTheme === light;
  const aquamarine = theme.colors.success.aquamarine;
  const lightGrey = theme.modes[selectedTheme]?.lightGrey;
  const darkGrey = theme.modes[selectedTheme]?.darkGrey;
  const grey = theme.modes[selectedTheme]?.grey;
  const frenchGrey = theme.colors.neutral.frenchGrey;
  const desktopMode = !tabletMode && !mobileMode;
  const isDraggingOver = dragOverId === safeBalanceName;
  const animationRef = useRef();

  useEffect(() => {
    return () => setMobileBreakdown(false);
  }, [ account, setMobileBreakdown ]);

  const handleAnimationIn = useCallback(() => {
    animate({ ref: animationRef.current, offset: -10, duration: 0.2 });
  }, []);

  const handleAnimationOut = () => {
    animate({
      ref: animationRef.current,
      offset: -10,
      transitionOut: true,
      onComplete: () => setMobileBreakdown(!mobileBreakdown),
    });
  };

  useEffect(() => {
    if (mobileBreakdown) handleAnimationIn();
  }, [ mobileBreakdown, handleAnimationIn ]);

  const handleDrop = e => {
    e.preventDefault();
    const draggedExpenseId = e.dataTransfer.getData('text/plain');
    const droppedOnExpenseId = e.target.closest('[data-expense-id]')?.getAttribute('data-expense-id');

    if (dragOverId) setDragOverId(null);

    if (draggedExpenseId && droppedOnExpenseId && draggedExpenseId !== droppedOnExpenseId) {
      setMoveMoneyConfirmContent(
        <MoveMoneyForm
          from={draggedExpenseId}
          to={droppedOnExpenseId}
          setConfirmContent={setMoveMoneyConfirmContent}
        />,
      );
    }
  };

  const handleDragStart = e => {
    e.dataTransfer.setData('text/plain', safeBalanceName);
    if (!isDraggingOver) setDragOverId(safeBalanceName);
  };

  const handleDragOver = e => {
    e.preventDefault();
    if (!isDraggingOver) setDragOverId(safeBalanceName);
  };

  const handleDragLeave = () => setDragOverId(null);
  const handleDragEnd = () => setDragOverId(null);

  return (
    <Loading
      theme={theme}
      selectedTheme={selectedTheme}
      isLoading={institutionDataLoading || expensesDataLoading}
      hasData={tokenInfo}
      iconColor={lightGrey + 75}
      textColor={lightGrey + 75}
      text={
        <Font size={1} tabletSize={1} mobileSize={1} weight='semibold'>
          {institutionDataLoading ? 'Syncing account' : 'Crunching numbers'}...
        </Font>
      }
      renderOnFail={true}
    >
      <Balance
        $theme={theme}
        $selectedTheme={selectedTheme}
        $isDraggingOver={isDraggingOver}
        $desktopMode={desktopMode}
        draggable={true}
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDragEnd={handleDragEnd}
        onDrop={handleDrop}
        data-expense-id={safeBalanceName}
        onClick={() => {
          if (!desktopMode) {
            !mobileBreakdown ? setMobileBreakdown(true) : handleAnimationOut();
          }
        }}
      >
        <DragIcon $isDraggingOver={isDraggingOver}>
          <XymSpacer across={true} />
          <GripIcon width={1.25} color={lightTheme ? frenchGrey + 40 : frenchGrey + 35} />
        </DragIcon>

        <Font weight='semibold'>
          <XymPrice
            size={1.375}
            tabletSize={1.125}
            mobileSize={1.125}
            value={safeBalance}
            useMinus={true}
            positiveColor={aquamarine}
            negativeColor={privacyMode ? aquamarine : lightGrey + 75}
          />
        </Font>

        <XymSpacer size={0.75} across={true} />

        <Font size={1.3755} tabletSize={1.125} mobileSize={1.125} weight='semibold'>
          {safeBalanceName}
        </Font>

        {!desktopMode && (
          <>
            <XymSpacer size={0.75} across={true} />

            <Chevron
              width={0.75}
              height={0.75}
              color={lightGrey}
              direction={mobileBreakdown ? 'up' : 'down'}
            />
          </>
        )}
      </Balance>

      <BreakdownBalances $desktopMode={desktopMode}>
        <BreakdownItems
          desktopMode={desktopMode}
          totalFunds={totalFunds}
          inExpenses={inExpenses}
          inGoals={inGoals}
          pending={pending}
        />
      </BreakdownBalances>

      <XymDropdown
        ref={animationRef}
        posX={mobileMode ? 1 : 4}
        posY={4}
        width={16.5}
        leftJustify={!mobileMode}
        bgColor={darkGrey}
        borderColor={lightTheme && grey}
        visible={!desktopMode && mobileBreakdown}
        setVisible={handleAnimationOut}
      >
        <MobileBalances>
          <BreakdownItems
            desktopMode={desktopMode}
            totalFunds={totalFunds}
            inExpenses={inExpenses}
            inGoals={inGoals}
            pending={pending}
          />
        </MobileBalances>
      </XymDropdown>

      <XymConfirm
        confirmContent={moveMoneyConfirmContent}
        useOverflow={false}
        blank={true}
        onClose={() => setMoveMoneyConfirmContent(null)}
      />
    </Loading>
  );
});

export { Balances };