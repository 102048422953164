/* eslint-disable */
import { actionCreator } from 'helpers';
import { stripeConstants } from 'modules';
import * as api from 'modules/stripe/stripeApi';

const stripeActions = {
  getPaymentData: (payload, callbacks) => {
    const type = stripeConstants.actions.GET_PAYMENT_DATA;
    const args = { type, payload, callbacks };
    return api.getPaymentData(args);
  },

  updatePaymentMethod: (payload, callbacks) => {
    const type = stripeConstants.actions.UPDATE_PAYMENT_METHOD;
    const args = { type, payload, callbacks };
    return api.updatePaymentMethod(args);
  },

  addPaymentMethod: (payload, callbacks) => {
    const type = stripeConstants.actions.ADD_PAYMENT_METHOD;
    const args = { type, payload, callbacks };
    return api.addPaymentMethod(args);
  },

  removePaymentMethod: (payload, callbacks) => {
    const type = stripeConstants.actions.REMOVE_PAYMENT_METHOD;
    const args = { type, payload, callbacks };
    return api.removePaymentMethod(args);
  },
};

export { stripeActions };