import { useSelector } from 'react-redux';
import { appConstants } from 'modules';
import { Font, XymSpacer } from 'components';
import { StyledStatisticsDetails, StatisticsHolder } from './styles';
import { Statistic } from './Statistic';
import { withTheme } from 'styled-components';

const { light } = appConstants.themes;

const Statistics = withTheme(props => {
  const { theme } = props;
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const { tokenInfo, userStatisticsData } = useSelector(state => state.auth);
  const darkGrey = theme.modes[selectedTheme]?.darkGrey;
  const offWhite = theme.modes[selectedTheme]?.offWhite;
  const lightTheme = selectedTheme === light;

  const {
    totalUsers,
    waitlistedUsers,
    verifiedUsers,
    inactiveUsers,
    activeSubscriptions,
    plaidLinks,
    dormantPlaidLinks,
  } = userStatisticsData || {};

  const buildDetails = () => {
    if (tokenInfo) {
      return (
        <StatisticsHolder>
          <Font weight='semibold' size={1.25} mobileSize={1} tabletSize={1} color={lightTheme ? darkGrey : offWhite}>
            User Stats
          </Font>

          <XymSpacer size={1.75} />

          <Statistic title='Total:' current={totalUsers} />

          <Statistic title='Waitlisted:' current={waitlistedUsers} />

          <Statistic
            title='Verified:'
            total={totalUsers}
            current={verifiedUsers}
          />

          <Statistic
            title='Inactive:'
            total={totalUsers}
            current={inactiveUsers}
          />

          <Statistic
            title='Subscribed:'
            total={totalUsers}
            current={activeSubscriptions}
          />

          <Statistic
            title='Plaid Links:'
            total={totalUsers}
            current={plaidLinks}
          />

          <Statistic
            title='Dormant Plaid Links:'
            total={plaidLinks}
            current={dormantPlaidLinks}
          />
        </StatisticsHolder>
      );
    }
  };

  return (
    <StyledStatisticsDetails>
      {buildDetails()}
    </StyledStatisticsDetails>
  );
});

export { Statistics };