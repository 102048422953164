/* eslint-disable max-len */
import { useSelector } from 'react-redux';
import { getColor } from 'helpers';
import { appConstants } from 'modules';
import styled, { withTheme } from 'styled-components';

const light = appConstants.themes.light;

const getDirection = direction => {
  switch (direction) {
    case 'up':
      return 180;

    case 'left':
      return 90;

    case 'right':
      return 270;

    default:
      return 0;
  }
};

const ChevronSVG = styled('svg')`
  width: ${props => props.$width || 0.625}rem;
  height: ${props => props.$height || 0.625}rem;
  fill: ${props => props.color || getColor(props, props.$selectedTheme === light ? 'darkGrey' : 'lightGrey')};
  transform: ${props => `rotate(${getDirection(props.$direction)}deg)`};
`;

const Chevron = withTheme(props => {
  const { theme, color, width, height, direction, onClick } = props;
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);

  return (
    <ChevronSVG
      $theme={theme}
      $selectedTheme={selectedTheme}
      $width={width}
      $height={height}
      $direction={direction}
      color={color}
      viewBox='0 0 256 256'
      fill='currentColor'
      onClick={onClick}
    >
      <path d='M211.02,58.753c10.204,-10.205 26.774,-10.205 36.979,0c10.205,10.205 10.205,26.775 -0,36.979l-101.509,101.51c-10.212,10.211 -26.768,10.211 -36.98,-0l-101.509,-101.51c-10.205,-10.204 -10.205,-26.774 0,-36.979c10.205,-10.205 26.775,-10.205 36.979,0l83.02,83.02l83.02,-83.02Z' />
    </ChevronSVG>
  );
});

export { Chevron };