import { months } from 'modules';
import moment from 'moment';

export const getOptions = (args, setMousePosition) => {
  const { yearToDate, onPrimary, desktopMode } = args;
  const thisMonthIndex = moment().month();
  const thisMonth = `${months[thisMonthIndex].label}, ${moment().year()}`;
  const thisYear = `${moment().startOf('year').format('MMM Do')} - ${moment().format('MMM Do, YYYY')}`;

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: { duration: 0 },
    plugins: {
      legend: { display: false },
      tooltip: {
        mode: 'index',
        intersect: false,
      },
      verticalLinePlugin: {
        mousePosition: null,
      },
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
    scales: {
      x: {
        title: {
          display: true,
          text: yearToDate ? thisYear : thisMonth,
          color: onPrimary,
          font: { size: desktopMode ? 15 : 14 },
          padding: { top: desktopMode ? -8 : 8 },
        },
        ticks: {
          stepSize: 2,
          padding: desktopMode ? 20 : 0,
          minRotation: 0,
          maxRotation: 0,
        },
        border: { display: false },
        grid: { drawBorder: false, display: false },
      },
      y: {
        ticks: { stepSize: 10, padding: desktopMode ? 20 : 0 },
        border: { display: false },
        grid: { drawBorder: false, display: false },
        min: 0,
        afterDataLimits: axis => {
          axis.min -= 1;
        },
      },
    },
    events: [ 'mousemove', 'mouseout', 'click', 'touchstart', 'touchmove' ],
    onHover: event => {
      if (event.type === 'mouseout') {
        setMousePosition(null);
      }
    },
  };

  return options;
};
