import { useSelector } from 'react-redux';
import { fonts } from 'controllers';
import { getFontFamily, hexValid, getColor } from 'helpers';
import styled, { withTheme } from 'styled-components';

const StyledH4 = styled('h4')`
  font-weight: unset;
  font-family: ${props => props.$weight ? getFontFamily(props) : fonts?.primary?.bold};
  color: ${props => hexValid(props.$color) || getColor(props, 'onPrimary')};
  font-size: 1.25rem;
  margin: 0;
`;

const H4 = withTheme(props => {
  const { theme, children, weight, color } = props;
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);

  return (
    <StyledH4
      $theme={theme}
      $selectedTheme={selectedTheme}
      $color={color}
      $weight={weight}
    >
      {children}
    </StyledH4>
  );
});

export { H4 };