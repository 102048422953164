import { useSelector } from 'react-redux';
import { Font, SettingsNav } from 'components';
import { StyledMyAccount, Layout, AccountContent } from './styles';
import { Details } from './Details';

const MyAccount = () => {
  const { tokenInfo } = useSelector(state => state.auth);

  const buildSections = () => {
    if (tokenInfo?.accessToken) {
      return <Details />;
    }

    return <Font weight='semibold'>No token found.</Font>;
  };

  return (
    <StyledMyAccount>
      <Layout>
        <SettingsNav />

        <AccountContent>
          {buildSections()}
        </AccountContent>
      </Layout>
    </StyledMyAccount>
  );
};

export { MyAccount };