import { useSelector } from 'react-redux';
import { appConstants } from 'modules';
import { hexValid, getColor } from 'helpers';
import styled, { withTheme } from 'styled-components';
import { Font } from 'components';

const { spacing } = appConstants;
const { smallGap } = spacing;

export const StyledLegendItem = styled('div')`
  display: ${props => props.$visible === undefined || props.$visible ? 'inline-flex' : 'none'};
  align-items: center;
  gap: ${smallGap}rem;
`;

export const Color = styled('div')`
  height: 0.75rem;
  width: 0.75rem;
  border-radius: 50%;
  background-color: ${props => hexValid(props.$color) || getColor(props, 'grey')};
`;

const LegendItem = withTheme(props => {
  const { theme, color, label, visible } = props;
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);

  return (
    <StyledLegendItem $visible={visible}>
      <Color $theme={theme} $selectedTheme={selectedTheme} $color={color} />
      <Font weight='semibold' size={0.875}>
        {label}
      </Font>
    </StyledLegendItem>
  );
});

export { LegendItem };