import { Font, P, AppLogo } from 'components';
import { Title } from './styles';

const HeroDescription = () => {
  return (
    <Title>
      <AppLogo size={3} disableHomeLink={true} />

      <Font weight='medium'>
        <P size={1.25}>
          A personal finance application that automates your finances and
          tracks spending, while helping you achieve your goals.
        </P>
      </Font>
    </Title>
  );
};

export { HeroDescription };