import { useMemo, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { appConstants } from 'modules';
import { institutionByAccountId } from 'helpers';
import { Font, XymPrice, XymSpacer } from 'components';
import { PrivacyMask } from 'xerum';
import { RemainingAutoSpendBalance, SpentDetails, SpentFromText } from './styles';
import { withTheme } from 'styled-components';

const { names } = appConstants;
const { safeBalanceName } = names;
const { light } = appConstants.themes;

const SpentFrom = withTheme(props => {
  const { theme, isTransferIn, isTransferOut, isDebit, autoSpendExpenseId, amount, size, active } = props;
  const { expensesData } = useSelector(state => state.expenses);
  const { institutionsData, selectedAccountId } = useSelector(state => state.institutions);
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const lightTheme = selectedTheme === light;
  const onPrimary = theme.modes[selectedTheme]?.onPrimary;
  const offWhite = theme.modes[selectedTheme]?.offWhite;
  const darkGrey = theme.modes[selectedTheme]?.darkGrey;

  const expenses = useMemo(() => expensesData?.filter(expense => !expense.isGoal) || [], [ expensesData ]);
  const goals = useMemo(() => expensesData?.filter(expense => expense.isGoal) || [], [ expensesData ]);
  const allExpenses = [ ...expenses, ...goals ];

  const autoSpendExpense = allExpenses?.find(expense => expense._id === autoSpendExpenseId);
  const autoSpendName = autoSpendExpense?.name || 'Unknown';
  const autoSpendBalance = (autoSpendExpense?.current - amount) || 0;
  const insufficientExpenseBalance = autoSpendBalance <= 0;
  const usesAutoSpend = isDebit && autoSpendExpenseId;
  const accountMask = useMemo(() => {
    const institution = institutionByAccountId({ institutionsData, accountId: selectedAccountId });
    const account = institution?.accounts?.find(account => account.account_id === selectedAccountId) || {};

    return account.mask;
  }, [ institutionsData, selectedAccountId ]);
  const externalAccountMask = 4567;
  const activeColor = lightTheme ? offWhite + 75 : darkGrey + 75;

  const buildAutoSpendDetails = () => {
    return (
      <SpentDetails>
        <SpentFromText>
          {isTransferIn && (
            <Font size={size || 0.75}>
              <Font size={size || 0.75} weight='semibold'>
                <XymSpacer size={0.25} across={true} />
                <PrivacyMask length={4} /> ... {externalAccountMask}
              </Font>

              <Font size={size || 0.75}>
                <XymSpacer size={0.5} across={true} />
                to
                <XymSpacer size={0.5} across={true} />
              </Font>

              <Font size={size || 0.75} weight='semibold'>
                <XymSpacer size={0.25} across={true} />
                <PrivacyMask length={4} /> ... {accountMask}
              </Font>
            </Font>
          )}

          {isDebit && (
            <Font size={size || 0.75}>
              Spent from
              <XymSpacer size={0.25} across={true} />
              <Font size={size || 0.75} weight='semibold'>
                {insufficientExpenseBalance ? safeBalanceName : autoSpendName}
              </Font>
            </Font>
          )}

          {isTransferOut && (
            <Font size={size || 0.75}>
              <Font size={size || 0.75} weight='semibold'>
                <XymSpacer size={0.25} across={true} />
                <PrivacyMask length={4} /> ... {accountMask}
              </Font>

              <Font size={size || 0.75}>
                <XymSpacer size={0.5} across={true} />
                to
                <XymSpacer size={0.5} across={true} />
              </Font>

              <Font size={size || 0.75} weight='semibold'>
                <XymSpacer size={0.25} across={true} />
                <PrivacyMask length={4} /> ... {externalAccountMask}
              </Font>
            </Font>
          )}
        </SpentFromText>

        {usesAutoSpend && !insufficientExpenseBalance && (
          <Fragment>
            <RemainingAutoSpendBalance>
              <XymPrice
                size={size || 0.75}
                value={autoSpendBalance <= 0 ? 0 : autoSpendBalance}
                color={active ? activeColor : onPrimary}
              />

              <Font size={size || 0.75} weight='semibold' color={active ? activeColor : onPrimary}>
                <XymSpacer size={0.25} across={true} />
                left

                {/*
                  TODO: Amount left should be saved to the DB with the transaction ID
                  and should be a capture at the time of the transaction -- it should
                  not update to reflect current balance of the auto-spend expense as
                  future transactions alter the balance of the auto-spend expense. This
                  may be good for auditing and expense reporting to trace back expenditure.
                */}
              </Font>
            </RemainingAutoSpendBalance>
          </Fragment>
        )}
      </SpentDetails>
    );
  };

  return (
    <Font size={size || 0.75} weight='semibold'>
      {buildAutoSpendDetails()}
    </Font>
  );
});

export { SpentFrom };