import { appConstants } from 'modules';
import styled from 'styled-components';

const { layout, spacing, breakpoints } = appConstants;
const { settingsNavWidth } = layout;
const { mobile, tabletLandscape } = breakpoints;
const { gap, extraSmallGap } = spacing;

export const StyledDetails = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  @media screen and (max-width: ${mobile}rem) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
  }
`;

export const StyledActions = styled('div')`
  display: flex;
  flex-direction: column;
  max-width: ${settingsNavWidth}rem;

  @media screen and (max-width: ${tabletLandscape}rem) {
    max-width: unset;
  }
`;

export const DeleteMessage = styled('div')`
  padding: 0 ${extraSmallGap}rem;
  width: 100%;
`;

export const DetailsHolder = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0 ${gap}rem;
`;