import { getColor } from 'helpers';
import { theme } from 'theme';
import { appConstants } from 'modules';
import styled from 'styled-components';

const { layout, breakpoints, spacing } = appConstants;
const { layoutWidth } = layout;
const { gap, extraLargeGap } = spacing;
const { tablet } = breakpoints;
const colors = theme.colors;

export const LegalDoc = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 0.35rem;
  color: ${props => getColor(props, 'onPrimary', colors.black)};
  padding: ${gap}rem;
  overflow-y: auto;
`;

export const Layout = styled('div')`
  max-width: ${layoutWidth}rem;
  width: 100%;
  margin: 0 auto;
  padding: 0 ${props => props.$inModal ? 0 : extraLargeGap}rem;

  @media screen and (max-width: ${tablet}rem) {
    font-size: 0.875rem;

    h3 {
      font-size: 1.125rem;
    }
  }
`;