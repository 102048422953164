import { createContext, useState, useContext } from 'react';

const ChartContext = createContext();

export const useChartContext = () => useContext(ChartContext);

export const ChartProvider = ({ children }) => {
  const [ mousePosition, setMousePosition ] = useState(null);

  return (
    <ChartContext.Provider value={{ mousePosition, setMousePosition }}>
      {children}
    </ChartContext.Provider>
  );
};