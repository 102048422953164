import { BreakdownItem, XymSpacer } from 'components';

const BreakdownItems = props => {
  const { desktopMode, totalFunds, inExpenses, inGoals, pending } = props;

  return (
    <>
      <BreakdownItem value={totalFunds} label='Total' separate={desktopMode} noPlus={true} />
      {!desktopMode && <XymSpacer size={1.35} across={true} />}

      <BreakdownItem value={pending} label='Pending' />
      <XymSpacer size={1.35} across={true} />

      <BreakdownItem value={inExpenses} label='in Expenses' />
      <XymSpacer size={1.35} across={true} />

      <BreakdownItem value={inGoals} label='in Goals' />
    </>
  );
};

export { BreakdownItems };