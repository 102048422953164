import { Fragment, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { institutionsActions, expensesActions } from 'modules';
import { XymDatePicker, XymField, XymSpacer, Font } from 'components';
import { Form, Formik } from 'formik';
import { Button, Checkbox } from 'xerum';
import styled, { withTheme } from 'styled-components';
import moment from 'moment';
import * as yup from 'yup';
import _ from 'lodash';

export const DefaultToggle = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
`;

const ManualTransactionForm = withTheme(props => {
  const { theme, close } = props;
  const { generalPreferences: { selectedTheme, privacyMode } } = useSelector(state => state.preferences);
  const { tokenInfo } = useSelector(state => state.auth);
  const { selectedAccountId, searchQuery, paginationData } = useSelector(state => state.institutions);
  const currentPage = paginationData?.currentPage || 1;
  const token = tokenInfo?.refreshToken || tokenInfo?.accessToken;
  const dispatch = useDispatch();

  const addManualTransaction = useCallback((payload, callbacks) => {
    dispatch(institutionsActions.addTransaction(payload, callbacks));
  }, [ dispatch ]);

  const getBalances = useCallback((payload, callbacks) => {
    dispatch(expensesActions.getBalances(payload, callbacks));
  }, [ dispatch ]);

  const defaultValues = {
    transactionName: '',
    transactionAmount: '',
    transactionDate: moment().format('MMMM Do, YYYY'),
    isCredit: false,
    isPending: false,
  };

  const validationSchema = yup.object().shape({
    transactionName: yup.string().required('Please enter a transaction name.'),
    transactionAmount: yup.string().required('Please enter an amount.')
      .test('amount-test', 'Amount invalid.', value => {
        const amount = parseFloat(value?.replace(/[$,+-]/g, ''));
        return !Number.isNaN(amount) && _.isNumber(amount) && amount !== 0;
      }),
    transactionDate: yup.string(),
    isCredit: yup.boolean(),
    isPending: yup.boolean(),
  });

  const handleSubmit = (values, actions) => {
    const { setSubmitting, resetForm } = actions;
    const { transactionName, transactionAmount, transactionDate, isPending, isCredit } = values;
    const amountAsNumber = parseFloat(transactionAmount?.trim().replace(/[$,+-]/g, ''));

    const transactionPayload = {
      data: {
        pending: isPending,
        merchant_name: transactionName?.trim(),
        amount: isCredit ? amountAsNumber * -1 : amountAsNumber,
        authorized_date: moment(transactionDate.trim(), 'MMMM Do, YYYY'),
      },
    };

    const callbacks = {
      onSuccess: () => {
        close();
        resetForm();
        getBalances({ token, linkedAccountId: selectedAccountId });
      },
      onComplete: () => setSubmitting(false),
    };

    const payload = {
      token,
      linkedAccountId: selectedAccountId,
      search: searchQuery,
      pageNumber: currentPage,
      ...transactionPayload,
    };

    addManualTransaction(payload, callbacks);
  };

  const buildForm = () => {
    return (
      <Formik
        initialValues={defaultValues}
        validationSchema={validationSchema}
        enableReinitialization={true}
        onSubmit={handleSubmit}
      >
        {form => (
          <Form>
            <XymSpacer size={0.5} />

            <XymDatePicker
              form={form}
              name='transactionDate'
              disableFutureDates={true}
              spacing={1}
              label={<Font size={0.875} weight='semibold'>Date posted</Font>}
            />

            <XymField
              form={form}
              type={privacyMode ? 'password' : 'text'}
              name='transactionName'
              placeholder={'i.e. Starbucks, Transfer to/from... etc.'}
              spacing={1}
              label={<Font size={0.875} weight='semibold'>Name</Font>}
            />

            <XymField
              form={form}
              name='transactionAmount'
              type={privacyMode ? 'password' : 'text'}
              placeholder='Dollar signs and commas okay'
              spacing={1}
              label={<Font size={0.875} weight='semibold'>Amount</Font>}
            />

            <DefaultToggle>
              <Checkbox
                theme={theme}
                selectedTheme={selectedTheme}
                form={form}
                name='isPending'
                label='This is pending.'
              />

              <XymSpacer size={0.5} />

              <Checkbox
                theme={theme}
                selectedTheme={selectedTheme}
                form={form}
                name='isCredit'
                label='This increased my balance.'
              />
            </DefaultToggle>

            <XymSpacer />

            <Button
              theme={theme}
              selectedTheme={selectedTheme}
              type='submit'
              disabled={form.isSubmitting}
              callback={form.handleSubmit}
              text={
                <Font weight='medium' mobileSize={0.875}>
                  {form.isSubmitting ? 'Adding transaction...' : 'Add transaction'}
                </Font>
              }
            />
          </Form>
        )}
      </Formik>
    );
  };

  return (
    <Fragment>
      {buildForm()}
    </Fragment>
  );
});

export { ManualTransactionForm };