import { useCallback, Fragment, useRef, useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { appConstants, institutionsActions, expensesActions } from 'modules';
import { animate, institutionByAccountId } from 'helpers';
import { Font, XymSearch, NoData, XymSpacer, ManualTransactionForm, XymDropdown } from 'components';
import { DateHeader } from './DateHeader';
import { TransactionItem } from './TransactionItem';
import { StyledActivity, TitleArea, ActivityArea } from './styles';
import { Loading, Pagination, Button } from 'xerum';
import { withTheme } from 'styled-components';
import moment from 'moment';
import _ from 'lodash';

const { light } = appConstants.themes;

const Activity = withTheme(props => {
  const { theme } = props;
  const { tokenInfo } = useSelector(state => state.auth);
  const { mobileMode, tabletMode, tabletLandscapeMode, bannerContent } = useSelector(state => state.app);
  const { expensesDataLoading } = useSelector(state => state.expenses);

  const [ manualTransactionForm, setManualTransactionForm ] = useState(false);

  const {
    institutionsData,
    transactionsData,
    transactionsDataLoading,
    institutionDataLoading,
    slideOverTransactionId,
    selectedAccountId,
    paginationData,
    searchQuery,
  } = useSelector(state => state.institutions);

  const {
    generalPreferences: { selectedTheme },
  } = useSelector(state => state.preferences);

  const institution = useMemo(() => {
    return institutionByAccountId({ institutionsData, accountId: selectedAccountId });
  }, [ selectedAccountId, institutionsData ]);

  const dispatch = useDispatch();
  const { pageLimit, currentPage, totalPages } = paginationData || {};
  const token = tokenInfo?.refreshToken || tokenInfo?.accessToken;
  const desktopMode = !mobileMode && !tabletMode && !tabletLandscapeMode;
  const lightTheme = selectedTheme === light;
  const darkGrey = theme.modes[selectedTheme]?.darkGrey;
  const onPrimary = theme.modes[selectedTheme]?.onPrimary;
  const offWhite = theme.modes[selectedTheme]?.offWhite;
  const titleColor = lightTheme ? darkGrey : offWhite;
  const searchRef = useRef(null);
  const animationRef = useRef(null);
  const selectedAccountIdRef = useRef(selectedAccountId);
  const dataLoading = institutionDataLoading || transactionsDataLoading || expensesDataLoading;
  const isSelfManaged = institution?.selfManaged;

  const getTransactions = useCallback((payload, callbacks) => {
    dispatch(institutionsActions.getTransactions(payload, callbacks));
  }, [ dispatch ]);

  const setSearchQuery = useCallback((payload, callbacks) => {
    dispatch(institutionsActions.setSearchQuery(payload, callbacks));
  }, [ dispatch ]);

  const getBalances = useCallback((payload, callbacks) => {
    dispatch(expensesActions.getBalances(payload, callbacks));
  }, [ dispatch ]);

  const removeManualTransactions = useCallback((payload, callbacks) => {
    dispatch(institutionsActions.removeManualTransactions(payload, callbacks));
  }, [ dispatch ]);

  const handleAnimationIn = useCallback(() => {
    animate({ ref: animationRef.current, offset: -10, duration: 0.2 });
  }, []);

  const handleAnimationOut = () => {
    animate({
      ref: animationRef.current,
      offset: -10,
      transitionOut: true,
      onComplete: () => setManualTransactionForm(!manualTransactionForm),
    });
  };

  const resetSearch = useCallback(() => {
    setSearchQuery('');

    if (searchRef.current) {
      searchRef.current.value = '';
    }
  }, [ setSearchQuery ]);

  const handleSearch = args => {
    const { forceRefresh, search } = args;

    const syncPayload = {
      token,
      linkedAccountId: selectedAccountIdRef.current,
      pageLimit,
      search: search || '',

      // Prevents calling to Plaid, unless force checking updates.
      existingDataOnly: !forceRefresh,
    };

    const callbacks = {
      onSuccess: () => {
        const balancesPayload = { token, linkedAccountId: selectedAccountId };
        if (forceRefresh) getBalances(balancesPayload);
      },
    };

    getTransactions(syncPayload, callbacks);
    setSearchQuery(search);

    if (forceRefresh) resetSearch();
  };

  useEffect(() => {
    resetSearch();
    selectedAccountIdRef.current = selectedAccountId;

    return () => resetSearch();
  }, [ selectedAccountId, searchRef, resetSearch, setSearchQuery ]);

  useEffect(() => {
    if (manualTransactionForm) handleAnimationIn();
  }, [ manualTransactionForm, handleAnimationIn ]);

  const buildActivity = () => {
    if (!_.isEmpty(transactionsData) && !dataLoading) {
      return transactionsData.map((transaction, index) => {
        const { data, metadata } = transaction || {};
        const { transactionId } = metadata || {};
        const { authorized_date, date } = data || {};

        const workingDate = authorized_date || date;
        const firstItem = index === 0;
        const lastItem = index === transactionsData.length - 1;
        const nextItem = transactionsData[index + 1];
        const nextActive = nextItem?.metadata?.transactionId === slideOverTransactionId;
        const lastAuthorizedDate = transactionsData[index - 1]?.data?.authorized_date;
        const lastTxDate = transactionsData[index - 1]?.data?.date;
        const lastDateItem = lastAuthorizedDate || lastTxDate;
        const differentDay = workingDate !== lastDateItem;
        const isNewDateGroup = firstItem || differentDay;
        const firstActive = isNewDateGroup && transactionId === slideOverTransactionId;

        const rawDate = moment(workingDate, 'YYYY-MM-DD');
        const transactionYear = rawDate.year();
        const thisYear = moment().year();
        const yearFormat = transactionYear !== thisYear ? ', YYYY' : '';

        const firstDateGroupInResults = () => {
          const allDateGroups = [ ...new Set([ ...transactionsData
            ?.map(tx => tx.data.authorized_date || tx.data.date) || [] ]) ];

          const isFirstGroupInResults = _.toLower(workingDate) === _.toLower(allDateGroups[0]);

          return isFirstGroupInResults;
        };

        return (
          <Fragment key={index}>
            <DateHeader
              visible={isNewDateGroup}
              dateGroup={moment(workingDate, 'YYYY-MM-DD').format(`MMMM Do${yearFormat}`)}
              firstItem={firstItem || firstDateGroupInResults()}
              titleColor={onPrimary}
              firstActive={firstActive}
            />

            <TransactionItem transaction={transaction} lastItem={lastItem} nextActive={nextActive} />
          </Fragment>
        );
      });
    }

    if (_.isEmpty(transactionsData)) {
      return (
        <NoData
          text='No transactions found'
          subText='Try adjusting your search.'
          icon='fa-solid fa-search'
        />
      );
    }
  };

  return (
    <StyledActivity $bannerContent={bannerContent}>
      <TitleArea $isSelfManaged={isSelfManaged}>
        {desktopMode && (
          <Font id='activity' size={1.25} weight='semibold' color={titleColor}>
            Activity
          </Font>
        )}

        {!isSelfManaged && (
          <Button
            id='refresh'
            theme={theme}
            selectedTheme={selectedTheme}
            type='button'
            noText={true}
            icon={`fa-solid fa-refresh ${dataLoading ? 'fa-spin' : ''}`}
            width={1}
            height={1}
            buttonType='transparent'
            pill={true}
            textSize={1.25}
            color={onPrimary}
            disabled={dataLoading}
            callback={() => handleSearch({ forceRefresh: true })}
          />
        )}

        {isSelfManaged && (
          <>
            <Button
              id='removeManualTransactions'
              theme={theme}
              selectedTheme={selectedTheme}
              type='button'
              noText={true}
              icon='fa-solid fa-broom'
              width={1}
              height={1}
              buttonType='transparent'
              pill={true}
              textSize={1.25}
              color={onPrimary}
              disabled={dataLoading}
              callback={() => {
                const payload = {
                  token,
                  linkedAccountId: selectedAccountId,
                  search: !_.isEmpty(searchQuery) ? searchQuery : '',
                  pageNumber: currentPage,
                };

                const callbacks = {
                  onSuccess: () => {
                    const balancesPayload = { token, linkedAccountId: selectedAccountId };
                    getBalances(balancesPayload);
                  },
                };

                removeManualTransactions(payload, callbacks);
              }}
            />

            <Button
              id='addTransaction'
              theme={theme}
              selectedTheme={selectedTheme}
              type='button'
              noText={true}
              icon='fa-solid fa-plus-circle'
              width={1}
              height={1}
              buttonType='transparent'
              pill={true}
              textSize={1.25}
              color={onPrimary}
              disabled={dataLoading}
              callback={() => !manualTransactionForm ? setManualTransactionForm(true) : handleAnimationOut()}
            />

            <XymDropdown
              id='manualTransactionHolder'
              ref={animationRef}
              visible={manualTransactionForm}
              setVisible={handleAnimationOut}
              width={'calc(100% - 2rem)'}
              maxWidth={27}
              verticalPadding={0.5}
              horizontalPadding={0.5}
              posX={1}
              posY={mobileMode ? 8.9 : 2.5}
            >
              <ManualTransactionForm close={handleAnimationOut} />
            </XymDropdown>
          </>
        )}

        <XymSearch
          id='activitySearch'
          ref={searchRef}
          placeholder='Merchant, amount, date(s), category...'
          visible={true}
          callback={search => handleSearch({ search })}
        />
      </TitleArea>

      <XymSpacer size={0.5} />

      <ActivityArea>
        <Loading
          theme={theme}
          selectedTheme={selectedTheme}
          isLoading={dataLoading}
          hasData={!_.isEmpty(transactionsData) && !dataLoading}
          noFailIcon={true}
          renderOnFail={false}
          failText={
            <NoData
              icon='fa-solid fa-list'
              text='No activity found'
              subText='Your transactions will appear here.'
            />
          }
          text={
            <Font size={1} weight='semibold'>
              Loading activity...
            </Font>
          }
        >
          {buildActivity()}
        </Loading>
      </ActivityArea>

      <Pagination
        theme={theme}
        selectedTheme={selectedTheme}
        currentPage={currentPage || 1}
        totalPages={totalPages || 1}
        numberSize={0.9375}
        markerWidth={0.5}
        truncateLimit={3}
        boldActive={true}
        visible={totalPages > 1}
        prevIcon='fa-solid fa-caret-left'
        nextIcon='fa-solid fa-caret-right'
        onPageChange={pageNumber => {
          const payload = {
            token,
            pageLimit,
            pageNumber,
            linkedAccountId: selectedAccountId,
            search: !_.isEmpty(searchQuery) ? searchQuery : '',
            existingDataOnly: true,
          };

          getTransactions(payload);
        }}
      />
    </StyledActivity>
  );
});

export { Activity };