import { useSelector } from 'react-redux';
import { appConstants } from 'modules';
import { Font } from 'components';
import { Button } from 'xerum';
import styled, { withTheme } from 'styled-components';

const { breakpoints, spacing } = appConstants;
const { smallGap } = spacing;
const { mobile } = breakpoints;

const StyledConfirmButtons = styled('div')`
  display: inline-flex;
  gap: ${smallGap}rem;
  width: 100%;

  button {
    width: 100%;
  }

  @media screen and (max-width: ${mobile}rem) {
    flex-direction: column;
    font-size: 0.875rem;
  }
`;

const XymConfirmButtons = withTheme(props => {
  const {
    theme,
    form,
    buttonType,
    confirmText,
    confirmColor,
    confirmHoverColor,
    setConfirmContent,
    customCancel,
    customCancelText,
    customCancelType,
    customCancelButtonType,
    customCancelButtonColor,
    customCancelButtonHoverColor,
    customCancelCallback,
    customCancelDisabled,
    disabled,
    privacy,
    callback,
  } = props;

  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const onPrimary = theme.modes[selectedTheme]?.onPrimary;

  return (
    <StyledConfirmButtons>
      <Button
        theme={theme}
        selectedTheme={selectedTheme}
        type={buttonType || 'submit'}
        text={<Font weight='medium' mobileSize={0.875}>{confirmText || 'Confirm'}</Font>}
        color={confirmColor}
        hoverColor={confirmHoverColor}
        disabled={disabled || privacy || form?.isSubmitting}
        callback={() => form?.handleSubmit() || callback?.()}
      />

      <Button
        theme={theme}
        selectedTheme={selectedTheme}
        type={customCancelType || 'button'}
        text={<Font weight='medium' mobileSize={0.875}>{customCancelText || 'Cancel'}</Font>}
        buttonType={customCancelButtonType || 'ghost'}
        color={customCancelButtonColor || onPrimary}
        hoverColor={customCancelButtonHoverColor || onPrimary}
        disabled={customCancelDisabled}
        callback={() => {
          if (!customCancel) {
            form?.resetForm();
            form?.setSubmitting(false);
            setConfirmContent?.(null);
            return;
          }

          customCancelCallback?.();
        }}
      />
    </StyledConfirmButtons>
  );
});

export { XymConfirmButtons };