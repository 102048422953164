import { useCallback, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { appConstants, expensesActions } from 'modules';
import { animate } from 'helpers';
import { Font, Chevron, XymSpacer } from 'components';
import { SlideOver } from 'xerum';
import { withTheme } from 'styled-components';
import _ from 'lodash';

const { layout, themes } = appConstants;
const { headerHeight, footerHeight, ribbonHeight } = layout;
const { light } = themes;
const topOffset = headerHeight + ribbonHeight;

const ExpenseSlideOver = withTheme(props => {
  const { theme, content, isGoal } = props;
  const { mobileMode, tabletMode, tabletLandscapeMode } = useSelector(state => state.app);
  const { slideOverExpenseId } = useSelector(state => state.expenses);
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const desktopMode = !mobileMode && !tabletMode && !tabletLandscapeMode;
  const lightTheme = selectedTheme === light;
  const trueBlack = theme.colors.shades.trueBlack;
  const darkGrey = theme.modes[selectedTheme]?.darkGrey;
  const grey = theme.modes[selectedTheme]?.grey;
  const offWhite = theme.modes[selectedTheme]?.offWhite;
  const titleColor = lightTheme ? darkGrey : offWhite;
  const restPosition = 720;
  const animationRef = useRef();
  const dispatch = useDispatch();

  const setSlideOverExpenseId = useCallback((payload, callbacks) => {
    dispatch(expensesActions.setSlideOverExpenseId(payload, callbacks));
  }, [ dispatch ]);

  const handleAnimationIn = useCallback(() => {
    if (animationRef?.current) {
      animate({
        ref: animationRef.current,
        offset: restPosition,
        direction: !mobileMode && 'left',
        noFade: true,
      });
    }
  }, [ mobileMode ]);

  const handleAnimationOut = useCallback(() => {
    if (animationRef?.current) {
      animate({
        ref: animationRef.current,
        offset: restPosition,
        direction: !mobileMode && 'left',
        noFade: true,
        transitionOut: true,
        duration: 0.35,
        onComplete: () => setSlideOverExpenseId(null),
      });
    }
  }, [ mobileMode, setSlideOverExpenseId ]);

  useEffect(() => {
    slideOverExpenseId ? handleAnimationIn() : handleAnimationOut();
  }, [ slideOverExpenseId, handleAnimationIn, handleAnimationOut ]);

  const getTopOffset = () => {
    if (mobileMode) return 8;
    return !desktopMode ? 0 : topOffset;
  };

  const getBottomOffset = () => {
    if (mobileMode) return null;
    return !desktopMode ? 0 : footerHeight;
  };

  return (
    <SlideOver
      ref={animationRef}
      theme={theme}
      selectedTheme={selectedTheme}
      title={
        <Font size={1.17} weight='semibold' color={titleColor}>
          <XymSpacer size={2.5} across={true} />
          {!_.isEmpty(slideOverExpenseId) ? 'Edit ' : 'New '}
          {isGoal ? 'goal' : 'expense'}
        </Font>
      }
      closeIcon={<Chevron width={1} height={1} direction={mobileMode ? 'down' : 'right'} />}
      onClose={handleAnimationOut}
      width={27}
      boxShadow={`0 ${mobileMode ? -0.125 : 0}rem 0.5rem ${lightTheme ? grey : trueBlack}`}
      topOffset={getTopOffset()}
      bottomOffset={getBottomOffset()}
      visible={slideOverExpenseId}
      mobileMode={mobileMode}
      zIndex={3}
    >
      {content}
    </SlideOver>
  );
});

export { ExpenseSlideOver };