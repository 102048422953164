import { useCallback, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { appActions, messagesActions } from 'modules';
import { Font, H4, P } from 'components';
import { PrivacyMask } from 'xerum';
import { MessagesArea, Message, Subject, ReadIcon } from './styles';
import { withTheme } from 'styled-components';

const Messages = withTheme(props => {
  const { theme } = props;
  const { generalPreferences: { privacyMode, selectedTheme } } = useSelector(state => state.preferences);
  const { tokenInfo } = useSelector(state => state.auth);
  const { messagesData } = useSelector(state => state.messages);
  const messageAreaRef = useRef();
  const messages = useMemo(() => messagesData?.messageList || [], [ messagesData ]);
  const dispatch = useDispatch();

  const setModalContent = useCallback(payload => {
    dispatch(appActions.setModalContent(payload));
  }, [ dispatch ]);

  const updateMessage = useCallback((payload, callback) => (
    dispatch(messagesActions.updateMessage(payload, callback))
    ), [ dispatch ]);

  const handleMessageOpen = message => {
    const { id, subject, body, read } = message;

    if (!privacyMode) {
      const payload = {
        id,
        read: true,
        token: tokenInfo?.accessToken,
      };

      setModalContent({
        title: <H4>{subject}</H4>,
        content: <P>{body}</P>,
        onClose: () => !read && updateMessage(payload),
      });
    }
  };

  const messageList = messages.map?.((message, index) => {
    const { id, read, subject } = message;

    return (
      <Message
        key={id || index}
        $theme={theme}
        $selectedTheme={selectedTheme}
        $read={read}
        onClick={() => handleMessageOpen(message)}
      >
        <Subject>
          <Font size={0.9} weight={read && !privacyMode ? 'regular' : 'bold'}>
            {privacyMode ? <PrivacyMask length={subject.length} /> : subject}
          </Font>
        </Subject>

        <ReadIcon>
          <i className={`fa-solid fa-envelope${read ? '-open' : ''}`} />
        </ReadIcon>
      </Message>
    );
  });

  return (
    <MessagesArea ref={messageAreaRef}>
      {messageList}
    </MessagesArea>
  );
});

export { Messages };