import { actionCreator } from 'helpers';
import { preferencesConstants } from 'modules';
import * as api from 'modules/preferences/preferencesApi';

const preferencesActions = {
  setPrivacyMode: payload => actionCreator(preferencesConstants.actions.SET_PRIVACY_MODE, payload),
  setSelectedTheme: payload => actionCreator(preferencesConstants.actions.SET_SELECTED_THEME, payload),

  getUserPreferences: (payload, callbacks) => {
    const type = preferencesConstants.actions.GET_USER_PREFERENCES;
    const args = { type, payload, callbacks };
    return api.getUserPreferences(args);
  },

  updateUserPreferences: (payload, callbacks) => {
    const type = preferencesConstants.actions.UPDATE_USER_PREFERENCES;
    const args = { type, payload, callbacks };
    return api.updateUserPreferences(args);
  },
};

export { preferencesActions };