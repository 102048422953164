import { useSelector } from 'react-redux';
import { appConstants } from 'modules';
import { XymSpacer } from 'components';
import { Field, FieldError } from 'xerum';
import { withTheme } from 'styled-components';

const { light } = appConstants.themes;

const XymField = withTheme(props => {
  const {
    theme,
    form,
    name,
    type,
    textarea,
    textareaRows,
    label,
    placeholder,
    spacing,
    noSpacing,
    icon,
    iconCallback,
    textColor,
    optional,
    hideField,
    privacy,
    fontSize,
    autoComplete,
    disabled,
  } = props;

  const { mobileMode } = useSelector(state => state.app);
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const lightTheme = selectedTheme === light;
  const frenchGrey = theme.colors.neutral.frenchGrey;
  const primary = theme.modes[selectedTheme]?.primary;
  const onPrimary = theme.modes[selectedTheme]?.onPrimary;
  const darkGrey = theme.modes[selectedTheme]?.darkGrey;

  return (
    <>
      <Field
        theme={theme}
        selectedTheme={selectedTheme}
        form={form}
        name={name}
        autoComplete={autoComplete}
        component={textarea ? 'textarea' : 'input'}
        rows={textarea && (textareaRows || 4)}
        type={type || 'text'}
        textColor={textColor}
        labelSize={0.875}
        label={label}
        labelColor={lightTheme ? darkGrey : onPrimary}
        labelSpacing={privacy || textarea || disabled ? 0.5 : 0.1}
        borderRadius={0.25}
        placeholder={placeholder}
        placeholderColor={lightTheme ? frenchGrey : frenchGrey + 50}
        bottomBorder={!textarea}
        optional={optional}
        optionalTextSize={0.875}
        fontFamily='Inter-Regular'
        icon={icon}
        iconCallback={iconCallback}
        privacy={privacy}
        disabled={disabled}
        hideField={hideField}
        inputBGColor={primary}
        fontSize={fontSize || 0.875}
        mobileSize={mobileMode && 0.875}
      />

      {!hideField && <FieldError theme={theme} selectedTheme={selectedTheme} name={name} />}
      {!noSpacing && !hideField && <XymSpacer size={spacing || 1.5} />}
    </>
  );
});

export { XymField };