import { Fragment, useCallback, useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { institutionsActions, appConstants, appActions } from 'modules';
import { handlePlaidLink } from 'helpers';
import { Font, NoData, PlaidIcon, XymSpacer, HeaderActionTag, Center, SelfManagedLink, Footnote } from 'components';
import { PlaidDescription } from './PlaidDescription';
import { StyledLink, ActionArea, CTA } from './styles';
import { Button } from 'xerum';
import { usePlaidLink } from 'react-plaid-link';
import { withTheme } from 'styled-components';
import _ from 'lodash';

const { misc } = appConstants;
const { maxInstitutions } = misc;

const PlaidLink = withTheme(props => {
  const { theme, subText, asTag, description, noButton, buttonOnly, buttonText } = props;
  const { tokenInfo } = useSelector(state => state.auth);
  const { linkToken, institutionsData } = useSelector(state => state.institutions);
  const { mobileMode } = useSelector(state => state.app);
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const { pathname } = useLocation();
  const hasInstitutions = !_.isEmpty(institutionsData);
  const onOverview = pathname.includes('overview');
  const onExpenses = pathname.includes('expenses');
  const onGoals = pathname.includes('goals');
  const onMyInstitutions = pathname.includes('my-institutions');
  const token = tokenInfo?.refreshToken || tokenInfo?.accessToken;
  const atMaxLinked = useMemo(() => institutionsData?.length >= maxInstitutions, [ institutionsData ]);
  const onAllowedPage = onMyInstitutions || onGoals || onExpenses || onOverview;
  const dispatch = useDispatch();

  const itemId = useMemo(() => linkToken?.itemId, [ linkToken ]);
  const needsAttention = useMemo(() => linkToken?.needsAttention, [ linkToken ]);
  const updateMode = useMemo(() => linkToken?.updateMode, [ linkToken ]);

  const getLinkToken = useCallback((payload, callbacks) => {
    dispatch(institutionsActions.getLinkToken(payload, callbacks));
  }, [ dispatch ]);

  const exchangePublicToken = useCallback((payload, callbacks) => {
    dispatch(institutionsActions.exchangePublicToken(payload, callbacks));
  }, [ dispatch ]);

  const getInstitutions = useCallback((payload, callbacks) => {
    dispatch(institutionsActions.getInstitutions(payload, callbacks));
  }, [ dispatch ]);

  const updateInstitutionErrorState = useCallback((payload, callbacks) => {
    dispatch(institutionsActions.updateInstitutionErrorState(payload, callbacks));
  }, [ dispatch ]);

  const setLinkToken = useCallback(payload => dispatch(institutionsActions.setLinkToken(payload)), [ dispatch ]);
  const addNotification = useCallback(payload => dispatch(appActions.addNotification(payload)), [ dispatch ]);

  const linkConfig = useMemo(() => ({
    token: linkToken?.linkToken,
    onExit: () => setLinkToken(null),
    onSuccess: (publicToken, metadata) => {
      const isDuplicate = institutionsData.find(item => item.institutionId === metadata?.institution?.institution_id);

      setLinkToken(null);

      if (updateMode && needsAttention) {
        const callbacks = {
          onSuccess: () => {
            addNotification({
              message: 'Institution link repaired.',
              icon: 'fa-solid fa-handshake',
              type: appConstants.messageTypes.success,
            });
          },
        };

        updateInstitutionErrorState({ token, itemId, needsAttention: false }, callbacks);
      }

      if (updateMode) {
        getInstitutions({ token });
        return;
      }

      if (!_.isEmpty(isDuplicate)) {
        addNotification({
          message: 'This institution is already linked.',
          icon: 'fa-solid fa-hand',
          type: appConstants.messageTypes.error,
        });

        return;
      }

      exchangePublicToken({ token, publicToken });
    },
  }), [
    linkToken,
    token,
    updateMode,
    needsAttention,
    itemId,
    institutionsData,
    addNotification,
    updateInstitutionErrorState,
    exchangePublicToken,
    getInstitutions,
    setLinkToken,
  ]);

  const linkArgs = { token, atMaxLinked, addNotification, getLinkToken };

  const { open } = usePlaidLink(linkConfig);

  useEffect(() => {
    if (!_.isEmpty(linkToken?.linkToken) && asTag) open();
  }, [ linkToken, open, asTag ]);

  return (
    <Fragment>
      <HeaderActionTag
        text='Link with Plaid'
        visible={(
          !mobileMode && asTag && onMyInstitutions)
          || (!buttonOnly && !hasInstitutions && onAllowedPage && asTag)
        }
        disabled={linkToken || atMaxLinked}
        callback={() => handlePlaidLink(linkArgs)}
      />

      {buttonOnly && (
        <>
          <Button
            theme={theme}
            selectedTheme={selectedTheme}
            text={(
              <Font weight='medium' mobileSize={0.875}>
                {buttonText || 'Link with Plaid'}
              </Font>
            )}
            disabled={linkToken || atMaxLinked}
            callback={() => handlePlaidLink(linkArgs)}
          />

          <SelfManagedLink buttonOnly={true} />
        </>
      )}

      {!buttonOnly && !hasInstitutions && !asTag && (
        <StyledLink $description={description}>
          <PlaidDescription visible={description} />

          <ActionArea>
            <NoData
              icon={<PlaidIcon />}
              text='No institutions linked'
              subText={subText || 'Link an institution with Plaid to get started'}
            />

            <XymSpacer />

            {!noButton && (
              <CTA>
                <Button
                  theme={theme}
                  selectedTheme={selectedTheme}
                  text={(
                    <Font weight='medium' mobileSize={0.875}>
                      {buttonText || 'Link with Plaid'}
                    </Font>
                  )}
                  disabled={linkToken}
                  callback={() => handlePlaidLink(linkArgs)}
                />

                <XymSpacer size={0.5} />

                <Center>
                  <Font weight='bold'>OR</Font>
                </Center>

                <XymSpacer size={0.5} />

                <SelfManagedLink buttonOnly={true} />

                <XymSpacer />

                <Footnote>
                  Self-managed offers more control over transactions, CSV import support &mdash; resulting in
                  more historical data &mdash; and immediate transaction entry. You are responsible for all bookkeeping
                  and accuracy, with no third-party institution links.
                </Footnote>
              </CTA>
            )}
          </ActionArea>
        </StyledLink>
      )}
    </Fragment>
  );
});

export { PlaidLink };