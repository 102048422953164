import { H3, P, Center, XymSpacer } from 'components';
import { SafeToUse, FullWidth } from './styles';
import { appConstants } from 'modules';

const {
  VITE_GCP_STORAGE_BUCKET: storageBucket,
} = process.env;

const { safeBalanceName } = appConstants.names;

const SafeBalance = () => {
  const src = `${storageBucket}/Waitlist/Safe-Balance.png`;

  return (
    <SafeToUse>
      <FullWidth>
        <H3>{safeBalanceName}</H3>
        <XymSpacer />
        <P>
          Never guess how much you have available to safely spend again.
          Everything is accounted for, from pending transactions to how much you&apos;ve
          budgeted in Expenses and Goals.  Recently wrote a check?  Create a one-time
          Expense to make sure those funds aren&apos;t getting in the way.
        </P>
      </FullWidth>

      <Center>
        <img src={src} id='safeBalance' alt={`${safeBalanceName} preview.`} />
      </Center>
    </SafeToUse>
  );
};

export { SafeBalance };